import {
  getGraphData,
  getGraphSplitData
} from "world/core/Console/Console.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Console } from "world/core/Console/Console.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding consoles to the graph.
 * @param props
 */
export const addConsolesToGraph = (props: {
  graph: Graph;
  consoles: Console[];
}) => {
  const { graph, consoles } = props;

  const consolesGraphData = consoles
    .map((console) => {
      if (console.splits) return getGraphSplitData(console);
      return getGraphData(console);
    })
    .flat();
  addComponentsToGraph({ graph, components: consolesGraphData });
};

/**
 * Mutates the graph by removing consoles from the graph.
 * @param props
 */
export const removeConsolesFromGraph = (props: {
  graph: Graph;
  consoles: Console[];
}) => {
  const { graph, consoles } = props;

  const consolesGraphData = consoles
    .map((console) => {
      if (console.splits) return getGraphSplitData(console);
      return getGraphData(console);
    })
    .flat();
  removeComponentsFromGraph({
    graph,
    components: consolesGraphData,
    force: true
  });
};

export const setConsolesInGraph = (props: {
  graph: Graph;
  newConsoles: Console[];
  oldConsoles: Console[];
}) => {
  const { graph, newConsoles, oldConsoles } = props;
  removeConsolesFromGraph({ graph, consoles: oldConsoles });
  addConsolesToGraph({ graph, consoles: newConsoles });
};

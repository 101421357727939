import Graph from "graphology";
import { Frame } from "world/core/Frame/Frame.types";
import { getGraphData } from "world/core/Frame/Frame.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";

/**
 * Mutates the graph by adding frames to the graph.
 * @param props
 */
export const addFramesToGraph = (props: { graph: Graph; frames: Frame[] }) => {
  const { graph, frames } = props;

  const framesGraphData = frames
    .map((frame) => {
      return getGraphData(frame);
    })
    .flat();
  addComponentsToGraph({ graph, components: framesGraphData });
};

/**
 * Mutates the graph by removing frames from the graph.
 * @param props
 */
export const removeFramesFromGraph = (props: {
  graph: Graph;
  frames: Frame[];
}) => {
  const { graph, frames } = props;

  const framesGraphData = frames
    .map((frame) => {
      return getGraphData(frame);
    })
    .flat();

  removeComponentsFromGraph({
    graph,
    components: framesGraphData,
    force: true
  });
};

export const setFramesInGraph = (props: {
  graph: Graph;
  newFrames: Frame[];
  oldFrames: Frame[];
}) => {
  const { graph, newFrames, oldFrames } = props;
  removeFramesFromGraph({ graph, frames: oldFrames });
  addFramesToGraph({ graph, frames: newFrames });
};

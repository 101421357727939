import useStoreWithUndo from "store/store";

export const useUserActions = () =>
  useStoreWithUndo((state) => state.userActions);
export const useUserAuthorisation = () =>
  useStoreWithUndo((state) => state.userAuthorisation);
export const useUserEmail = () => useStoreWithUndo((state) => state.userEmail);
export const useUserFirstName = () =>
  useStoreWithUndo((state) => state.userFirstName);
export const useUserLastName = () =>
  useStoreWithUndo((state) => state.userLastName);
export const useUserId = () => useStoreWithUndo((state) => state.userUId);
export const useUserAppPreferences = () =>
  useStoreWithUndo((state) => state.userAppPreferences);
export const useUserScaffoldPreferences = () =>
  useStoreWithUndo((state) => state.userScaffoldPreferences);
export const useUserRole = () => useStoreWithUndo((state) => state.userRole);
export const useUserAccountType = () =>
  useStoreWithUndo((state) => state.userAccountType);
export const useUserExpires = () =>
  useStoreWithUndo((state) => state.userExpires);
export const useUserAchievements = () =>
  useStoreWithUndo((state) => state.userAchievements);

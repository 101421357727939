import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { ConsoleSlice } from "./console.interface";
import {
  addConsolesToGraph,
  removeConsolesFromGraph,
  setConsolesInGraph
} from "./console.utils";

/** Initial state */
const initialConsoleState = {
  consoles: []
};

/** Slice creation */
const createConsoleSlice: StateCreator<Store, [], [], ConsoleSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialConsoleState));

  /** Return state */
  return {
    ...initialConsoleState,
    addConsoles: (consoles) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addConsolesToGraph({ graph: newGraph, consoles });

        return {
          consoles: [...state.consoles, ...consoles],
          graph: newGraph
        };
      });
    },
    setConsoles: (consoles) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setConsolesInGraph({
          graph: newGraph,
          newConsoles: consoles,
          oldConsoles: [...state.consoles]
        });

        return {
          consoles,
          graph: newGraph
        };
      });
    },
    removeConsoles: (ids) => {
      set((state) => {
        const removeConsoles = [...state.consoles];
        const newGraph = state.graph.copy();
        removeConsolesFromGraph({ graph: newGraph, consoles: removeConsoles });
        return {
          consoles: state.consoles.filter(
            (console) => !ids.includes(console.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createConsoleSlice;

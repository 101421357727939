import useStoreWithUndo from "store/store";

export const useHelperAxes = () =>
  useStoreWithUndo((state) => state.helperAxes);

export const useHelperGrids = () =>
  useStoreWithUndo((state) => state.helperGrids);

export const useHelperActions = () =>
  useStoreWithUndo((state) => state.helperActions);

import { Palette } from "@mui/material";

const CornerMode4 = (props: { palette: Palette }) => {
  const { palette } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="8"
        transform="matrix(0 -1 -1 0 33 43)"
        fill={palette.world.box.main}
        fillOpacity="0.6"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="19"
        height="7"
        transform="matrix(-1 0 0 1 44 14)"
        fill={palette.world.placeholder.main}
        fillOpacity="0.2"
        stroke={palette.world.placeholder.main}
      />
      <rect
        x="-0.5"
        y="0.5"
        width="19"
        height="7"
        transform="matrix(-1 0 0 1 23 23)"
        fill={palette.world.placeholder.main}
        fillOpacity="0.2"
        stroke={palette.world.placeholder.main}
      />
      <rect
        x="-0.183013"
        y="0.683013"
        width="19"
        height="7"
        transform="matrix(-0.866025 0.5 0.5 0.866025 37.317 5.18301)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <rect
        x="-0.683013"
        y="0.183013"
        width="19"
        height="7"
        transform="matrix(-0.866025 -0.5 -0.5 0.866025 22.817 22.683)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="matrix(-1 0 0 1 27.5 20.5)"
        fill={palette.world.axes.x}
      />
    </svg>
  );
};

export default CornerMode4;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";
import { STANDARD_VARIANT } from "world/manager/StandardManager/StandardManager.enums";

export const componentsOLedgers: Component[] = [
  {
    label_no: "Doublekil",
    material: "",
    weight: 1.2,
    label_se: "Dubbelkil",
    label_en: "Twin wedge coupler",
    length: 0.15,
    article_id: "2629.000",
    width: 0
  },
  {
    label_no: "Horistontalstag 1,40",
    material: "",
    weight: 5,
    label_se: "Horistontalstag 1,40",
    label_en: "O-Ledger horizontal 1,40m",
    length: 1.4,
    article_id: "2601.140",
    width: 0
  },
  {
    length: 0.5,
    width: 0,
    label_se: "Horisontalstag 0.5",
    label_en: "O-ledger 0.5m",
    weight: 2.2,
    label_no: "O-ledger 0.5",
    article_id: "2601.050",
    material: ""
  },
  {
    width: 0,
    label_no: "Horisontalstag 0.25",
    label_en: "O-ledger",
    weight: 1.4,
    material: "",
    label_se: "Horisontalstag 0.25",
    article_id: "2601.025",
    length: 0.25
  },
  {
    width: 0,
    article_id: "2601.045",
    weight: 2.1,
    label_se: "Horisontalstag 0.45",
    label_no: "O-Ledger 0.45",
    length: 0.45,
    label_en: "O-Ledger 0.45",
    material: ""
  },
  {
    width: 0,
    label_en: "O-Ledger horizontal 0,73m",
    article_id: "2601.073",
    label_no: "Horistontalstag 0,73",
    label_se: "Horistontalstag 0,73",
    weight: 2.9,
    material: "",
    length: 0.73
  },
  {
    material: "",
    width: 0,
    label_no: "Horistontalstag 1,57",
    length: 1.57,
    label_se: "Horistontalstag 1,57",
    label_en: "O-Ledger horizontal 1,57",
    article_id: "2601.157",
    weight: 5.5
  },
  {
    width: 0,
    label_no: "Horistontalstag 2,57",
    material: "",
    label_en: "O-Ledger horizontal 2,57",
    label_se: "Horistontalstag 2,57",
    weight: 8.5,
    article_id: "2601.257",
    length: 2.57
  },
  {
    width: 0,
    article_id: "2601.207",
    material: "",
    label_no: "Horistontalstag 2,07",
    label_se: "Horistontalstag 2,07",
    label_en: "O-Ledger horizontal 2,07",
    weight: 7,
    length: 2.07
  },
  {
    article_id: "2601.109",
    label_se: "Horistontalstag 1,09",
    width: 0,
    length: 1.09,
    label_no: "Horistontalstag 1,09",
    weight: 4,
    material: "",
    label_en: "O-Ledger horizontal 1,09m"
  },
  {
    material: "",
    width: 0,
    label_no: "Horistontalstag 3,07",
    weight: 10.1,
    article_id: "2601.307",
    label_en: "O-Ledger horizontal 3,07",
    label_se: "Horistontalstag 3,07",
    length: 3.07
  },
  {
    label_se: "Horistontalstag 4,14",
    material: "",
    article_id: "2601.414",
    length: 4.14,
    weight: 13.4,
    width: 0,
    label_en: "O-Ledger horizontal 4,14m",
    label_no: "Horistontalstag 4,14"
  }
];

export const componentsULedgers: Component[] = [
  {
    label_se: "U-Tvärbom 2,07 (Förstärkt)",
    label_no: "U-Tverrstang 2,07 (forsterket)",
    label_en: "U-Ledger 2,07 (reinforced)",
    width: 0,
    length: 2.07,
    weight: 12.7,
    material: "",
    article_id: "2618.207",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    label_en: "U-Ledger 0,73",
    weight: 3.1,
    material: "",
    article_id: "2618.073",
    label_se: "U-Tvärbom 0,73",
    label_no: "U-Tverrstang 0,73",
    length: 0.73,
    width: 0,
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    width: 0,
    label_en: "U-Ledger 1,57 (reinforced)",
    label_se: "U-Tvärbom 1,57 (Förstärkt)",
    article_id: "2618.157",
    length: 1.57,
    weight: 9.4,
    material: "",
    label_no: "U-Tverrstang 1,57 (forsterket)",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    length: 2.57,
    width: 0,
    label_en: "U-Ledger 2,57 (reinforced)",
    label_se: "U-Tvärbom 2,57 (Förstärkt)",
    article_id: "2618.257",
    label_no: "U-Tverrstang 2,57 (forsterket)",
    weight: 15.7,
    material: "",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    length: 3.07,
    material: "",
    label_en: "U-Ledger 3,07 (reinforced)",
    weight: 19,
    label_no: "U-Tverrstang 3,07 (forsterket)",
    label_se: "U-Tvärbom 3,07 (Förstärkt)",
    article_id: "2618.307",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    label_no: "U-Tverrstang 1,40 (forsterket)",
    label_se: "U-Tvärbom 1,40 (Förstärkt)",
    label_en: "U-Ledger 1,40 (reinforced)",
    material: "",
    article_id: "2618.140",
    length: 1.4,
    weight: 8.9,
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    label_no: "U-Tverrstang 1,40",
    label_se: "U-Tvärbom 1,40",
    label_en: "U-Ledger 1,40",
    material: "",
    article_id: "2618.139",
    length: 1.4,
    weight: 8.9,
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    width: 0,
    article_id: "2618.045",
    length: 0.45,
    weight: 2.1,
    label_no: "U-Tverrstang 0,45",
    label_en: "U-Ledger 0,45",
    label_se: "U-Tvärbom 0,45",
    material: "",
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    length: 1.09,
    weight: 4.3,
    width: 0,
    label_se: "U-Tvärbom 1,09",
    article_id: "2618.109",
    label_no: "U-Tverrstang 1,09",
    material: "",
    label_en: "U-Ledger 1,09",
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    length: 1.57,
    weight: 4.3,
    width: 0,
    label_se: "U-Tvärbom 1.57",
    article_id: "3207.157",
    label_no: "U-Tverrstang 1.57",
    material: "",
    label_en: "U-Bridging Ledger 1.57",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 2.07,
    weight: 5.5,
    width: 0,
    label_se: "U-Tvärbom 2.07",
    article_id: "3207.207",
    label_no: "U-Tverrstang 2.07",
    material: "",
    label_en: "U-Bridging Ledger 2.07",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsToeBoards: Component[] = [
  {
    width: 0.15,
    article_id: "2640.414",
    weight: 7.5,
    label_se: "Sparklist 4,14 ",
    label_en: "Toe Board 4,14m ",
    material: "",
    length: 4.14,
    label_no: "Fotlist 4,14m "
  },
  {
    article_id: "2640.307",
    material: "",
    length: 3.07,
    weight: 7.1,
    label_no: "Fotlist 3,07m ",
    label_en: "Toe Board 3,07m ",
    label_se: "Sparklist 3,07 ",
    width: 0.15
  },
  {
    width: 0.15,
    article_id: "2640.140",
    weight: 3.5,
    material: "",
    label_se: "Sparklist 1,40 ",
    length: 1.4,
    label_en: "Toe Board 1,40m ",
    label_no: "Fotlist 1,40m "
  },
  {
    width: 0.15,
    label_en: "Toe Board 0,73m ",
    length: 0.73,
    material: "",
    weight: 1.5,
    label_se: "Sparklist 0,73 ",
    article_id: "2640.073",
    label_no: "Fotlist 0,73m "
  },
  {
    width: 0.15,
    material: "",
    label_en: "Toe Board 1,09m ",
    label_se: "Sparklist 1,09 ",
    label_no: "Fotlist 1,09m ",
    article_id: "2640.109",
    length: 1.09,
    weight: 2.5
  },
  {
    label_se: "Sparklist 2,07 ",
    width: 0.15,
    article_id: "2640.207",
    material: "",
    label_no: "Fotlist 2,07m ",
    label_en: "Toe Board 2,07m ",
    weight: 4.6,
    length: 2.07
  },
  {
    width: 0.15,
    label_en: "Toe Board 1,57m ",
    article_id: "2640.157",
    length: 1.57,
    label_no: "Fotlist 1,57m ",
    material: "",
    label_se: "Sparklist 1,57 ",
    weight: 3.5
  },
  {
    width: 0.15,
    article_id: "2640.257",
    label_no: "Fotlist 2,57m ",
    label_se: "Sparklist 2,57 ",
    label_en: "Toe Board 2,57m ",
    weight: 5.7,
    length: 2.57,
    material: ""
  }
];

export const componentsDecks: Component[] = [
  {
    width: 0.19,
    label_en: "Steel plank 0,19 wide 1,57 long",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "3801.157",
    label_se: "Stålplank 0,19 bred 1,57 lång",
    label_no: "Stålplanke 0,19 bred 1,57 lang",
    weight: 8.5,
    length: 1.57
  },
  {
    label_se: "Stålplank 0,19 bred 2,07 lång",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.19,
    label_en: "Steel plank 0,19 wide 2,07 long",
    length: 2.07,
    label_no: "Stålplanke 0,19 bred 2,07 lang",
    article_id: "3801.207",
    weight: 10.2
  },
  {
    label_en: "Steel plank 0,32m wide 0,73m long",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "3812.073",
    label_no: "Stålplank 0,32 bred 0,73 lang",
    label_se: "Stålplank 0,32 bred 0,73 lång",
    width: 0.32,
    weight: 6,
    length: 0.73
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank 0,32 bred 2,07 lång",
    label_en: "Alu plank 0,32 wide 2,07 long",
    width: 0.32,
    label_no: "Aluplank 0,32 bred 2,07 lang",
    length: 2.07,
    weight: 8,
    article_id: "3803.207"
  },
  {
    label_en: "Alu plank 0,19 wide 1,57 long",
    weight: 5.6,
    article_id: "3857.157",
    label_se: "Aluplank 0,19 bred 1.57 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.19,
    length: 1.57,
    label_no: "Aluplank 0,19 bred 1.57 lang"
  },
  {
    length: 2.07,
    label_no: "Aluplank 0,19 bred 2,07 lang",
    label_en: "Alu plank 0,19 wide 2,07 long",
    weight: 7.2,
    article_id: "3857.207",
    width: 0.19,
    label_se: "Aluplank 0,19 bred 2,07 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "3801.140",
    length: 1.4,
    weight: 8,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank 0,19 bred 1,40 lang",
    label_se: "Stålplank 0,19 bred 1,40 lång",
    label_en: "Steel plank 0,19m wide 1,40m long",
    width: 0.19
  },
  {
    label_en: "Steel plank 0,32m wide 1,09m long",
    label_se: "Stålplank 0,32 bred 1,09 lång",
    width: 0.32,
    article_id: "3812.109",
    length: 1.09,
    weight: 8.3,
    label_no: "Stålplank 0,32 bred 1,09 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    weight: 3.5,
    label_en: "Robust plank 0,32 wide 0,73 long",
    material: SCAFFOLD_PLANKTYPE.ROBUST,
    width: 0.32,
    label_no: "Robustplank 0,32 bred 0,73 lang",
    label_se: "Robustplank 0,32 bred 0,73 lång",
    length: 0.73,
    article_id: "3836.073"
  },
  {
    label_en: "Alu plank 0,32m wide 1,09m long",
    length: 1.09,
    article_id: "3803.109",
    label_no: "Aluplank 0,32 bred 1,09 lang",
    weight: 4.4,
    width: 0.32,
    label_se: "Aluplank 0,32 bred 1,09 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "3856.307",
    label_se: "Robustplank 0,32 bred 3,07 lång",
    material: SCAFFOLD_PLANKTYPE.ROBUST,
    weight: 13.3,
    length: 3.07,
    label_no: "Robustplank 0,32 bred 3,07 lang",
    width: 0.32,
    label_en: "Robust plank 0,32 wide 3,07 long"
  },
  {
    label_en: "Steel plank 0,32m wide 4,14m long",
    article_id: "3812.414",
    length: 4.14,
    width: 0.32,
    label_no: "Stålplank 0,32 bred 4,14 lang",
    weight: 29.8,
    label_se: "Stålplank 0,32 bred 4,14 lång",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_en: "Alu plank 0,32 wide 3,07 long",
    label_se: "Aluplank 0,32 bred 3,07 lång",
    width: 0.32,
    label_no: "Aluplank 0,32 bred 3,07 lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 3.07,
    weight: 11.5,
    article_id: "3803.307"
  },
  {
    length: 1.09,
    article_id: "3836.109",
    material: SCAFFOLD_PLANKTYPE.ROBUST,
    label_en: "Robust plank 0,32 wide 1,09 long",
    width: 0.32,
    label_se: "Robustplank 0,32 bred 1,09 lång",
    weight: 4.6,
    label_no: "Robustplank 0,32 bred 1,09 lang"
  },
  {
    width: 0.32,
    label_se: "Aluplank 0,32 bred 1,57 lång",
    length: 1.57,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.5,
    label_en: "Alu plank 0,32 wide 1,57 long",
    article_id: "3803.157",
    label_no: "Aluplank 0,32 bred 1,57 lang"
  },
  {
    article_id: "3883.307",
    label_no: "Stålplanke 0,32 bred 3,07 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 3.07,
    weight: 21.5,
    label_en: "Steel plank 0,32 wide 3,07 long",
    label_se: "Stålplank 0,32 bred 3,07 lång",
    width: 0.32
  },
  {
    label_en: "Robust plank 0,32 wide 1,57 long",
    weight: 7.4,
    length: 1.57,
    width: 0.32,
    label_se: "Robustplank 0,32 bred 1,57 lång",
    label_no: "Robustplank 0,32 bred 1,57 lang",
    article_id: "3856.157",
    material: SCAFFOLD_PLANKTYPE.ROBUST
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank 0,32 bred 0,73 lång",
    length: 0.73,
    weight: 3.1,
    width: 0.32,
    label_en: "Alu plank 0,32m wide 0,73m long",
    article_id: "3803.+73",
    label_no: "Aluplank 0,32 bred 0,73 lang"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.32,
    label_en: "Steel plank 0,32 wide 2,07 long",
    length: 2.07,
    weight: 15,
    article_id: "3883.207",
    label_no: "Stålplanke 0,32 bred 2,07 lang",
    label_se: "Stålplank 0,32 bred 2,07 lång"
  },
  {
    width: 0.19,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank 0,19 bred 3,07 lång",
    weight: 10.2,
    label_en: "Alu plank 0,19 wide 3,07 long",
    length: 3.07,
    article_id: "3857.307",
    label_no: "Aluplank 0,19 bred 3,07 lang"
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19m wide 0,73m long",
    length: 0.73,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 0,19 bred 0,73 lång",
    weight: 5.1,
    label_no: "Stålplank 0,19 bred 0,73 lang",
    article_id: "3801.073"
  },
  {
    label_en: "Steel plank 0,19m wide 1,09m long",
    length: 1.09,
    width: 0.19,
    label_se: "Stålplank 0,19 bred 1,09 lång",
    weight: 6.4,
    article_id: "3801.109",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank 0,19 bred 1,09 lang"
  },
  {
    weight: 8.7,
    article_id: "3857.257",
    label_se: "Aluplank 0,19 bred 2,57 lång",
    label_no: "Aluplank 0,19 bred 2,57 lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.57,
    label_en: "Alu plank 0,19 wide 2,57 long",
    width: 0.19
  },
  {
    weight: 10.6,
    width: 0.32,
    article_id: "3802.140",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 1.4,
    label_en: "Steel plank 0,32m wide 1,40m long",
    label_se: "Stålplank 0,32 bred 1,40 lång",
    label_no: "Stålplank 0,32 bred 1,40 lang"
  },
  {
    label_no: "Robustplank 0,32 bred 2,57 lang",
    material: SCAFFOLD_PLANKTYPE.ROBUST,
    length: 2.57,
    label_en: "Robust plank 0,32 wide 2,57 long",
    width: 0.32,
    label_se: "Robustplank 0,32 bred 2,57 lång",
    article_id: "3856.257",
    weight: 11
  },
  {
    label_se: "Stålplank 0,19 bred 2,57 lång",
    weight: 13.2,
    length: 2.57,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "3801.257",
    label_no: "Stålplanke 0,19 bred 2,57 lang",
    width: 0.19,
    label_en: "Steel plank 0,19 wide 2,57 long"
  },
  {
    width: 0.32,
    label_se: "Stålplank 0,32 bred 2,57 lång",
    length: 2.57,
    article_id: "3883.257",
    weight: 18.2,
    label_en: "Steel plank 0,32 wide 2,57 long",
    label_no: "Stålplanke 0,32 bred 2,57 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_se: "Stålplank 0,19 bred 3,07 lång",
    article_id: "3801.307",
    label_no: "Stålplanke 0,19 bred 3,07 lang",
    weight: 15.3,
    label_en: "Steel plank 0,19 wide 3,07 long",
    width: 0.19,
    length: 3.07,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    weight: 10,
    length: 2.57,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "3803.257",
    width: 0.32,
    label_no: "Aluplank 0,32 bred 2,57 lang",
    label_en: "Alu plank 0,32 wide 2,57 long",
    label_se: "Aluplank 0,32 bred 2,57 lång"
  },
  {
    width: 0.32,
    weight: 11.9,
    label_no: "Stålplanke 0,32 bred 1,57 lang",
    label_se: "Stålplank 0,32 bred 1,57 lång",
    article_id: "3883.157",
    length: 1.57,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank 0,32 wide 1,57 long"
  },
  {
    length: 2.07,
    label_no: "Robustplank 0,32 bred 2,07 lang",
    label_se: "Robustplank 0,32 bred 2,07 lång",
    article_id: "3856.207",
    label_en: "Robust plank 0,32 wide 2,07 long",
    material: SCAFFOLD_PLANKTYPE.ROBUST,
    width: 0.32,
    weight: 9.2
  }
];

export const componentsStandards: Component[] = [
  {
    article_id: "5603.050",
    label_en: "Standard, 0.5m",
    length: 0.5,
    weight: 3.2,
    label_se: "Spira, 0.5m",
    material: "",
    label_no: "Spire, 0.5m",
    width: 0
  },
  {
    article_id: "2603.100",
    label_en: "Standard, 1m",
    length: 1,
    weight: 5.5,
    label_se: "Spira, 1m",
    material: "",
    label_no: "Spire, 1m",
    width: 0
  },
  {
    article_id: "2603.150",
    label_en: "Standard, 1.5m",
    length: 1.5,
    weight: 7.8,
    label_se: "Spira, 1.5m",
    material: "",
    label_no: "Spire, 1.5m",
    width: 0
  },
  {
    label_en: "Standard, 3m",
    label_se: "Spira, 3m",
    material: "",
    weight: 14.6,
    article_id: "2603.300",
    length: 3,
    label_no: "Spire, 3m",
    width: 0
  },
  {
    label_no: "Spire, 2m",
    label_se: "Spira, 2m",
    article_id: "2603.200",
    weight: 10.1,
    length: 2,
    material: "",
    label_en: "Standard, 2m",
    width: 0
  },
  {
    label_no: "Spire, 2.5m",
    label_se: "Spira, 2.5m",
    article_id: "2603.250",
    weight: 12.4,
    length: 2.5,
    material: "",
    label_en: "Standard, 2.5m",
    width: 0
  },
  {
    article_id: "2603.400",
    weight: 19.2,
    label_se: "Spira, 4m",
    length: 4,
    label_en: "Standard, 4m",
    material: "",
    label_no: "Spire, 4m",
    width: 0
  }
];

export const componentsStartStandards: Component[] = [
  {
    article_id: "2617.221",
    label_en: "Initial Standard, 2.21m",
    length: 2.21,
    weight: 10,
    label_se: "Startspira, 2.21m",
    material: "",
    label_no: "Startspire, 2.21m",
    width: 0,
    variant: STANDARD_VARIANT.INITIAL
  }
];

export const componentsDiagonalBraces: Component[] = [
  /** 2 meter height diagonal braces */
  {
    label_en: "Diagonal Brace 4,14x2m",
    label_no: "Diagonalstag 4,14x2m",
    material: "",
    length: 4.14,
    weight: 13.7,
    article_id: "2683.414",
    label_se: "Diagonalstag 4,14x2m",
    width: 2
  },
  {
    article_id: "2683.073",
    label_se: "Diagonalstag 0,73x2m",
    length: 0.73,
    label_no: "Diagonalstag 0,73x2m",
    label_en: "Diagonal Brace 0,73x2m",
    weight: 7.1,
    material: "",
    width: 2
  },
  {
    length: 1.4,
    label_no: "Diagonalstag 1,4x2m",
    weight: 7.9,
    article_id: "2683.140",
    label_se: "Diagonalstag 1,4x2m",
    label_en: "Diagonal Brace 1,4x2m",
    material: "",
    width: 2
  },
  {
    label_no: "Diagonalstag 3,07x2m",
    label_se: "Diagonalstag 3,07x2m",
    article_id: "2683.307",
    material: "",
    label_en: "Diagonal Brace 3,07x2m",
    weight: 11.1,
    length: 3.07,
    width: 2
  },
  {
    length: 2.07,
    article_id: "2683.207",
    weight: 8.85,
    label_no: "Diagonalstag 2,07x2m",
    material: "",
    label_se: "Diagonalstag 2,07x2m",
    label_en: "Diagonal Brace 2,07x2m",
    width: 2
  },
  {
    label_no: "Diagonalstag 1,57x2m",
    label_en: "Diagonal Brace 1,57x2m",
    article_id: "2683.157",
    width: 2,
    label_se: "Diagonalstag 1,57x2m",
    material: "",
    length: 1.57,
    weight: 7.7
  },
  {
    article_id: "2683.109",
    label_en: "Diagonal Brace 1,09x2m",
    material: "",
    weight: 7.4,
    label_no: "Diagonalstag 1,09x2m",
    label_se: "Diagonalstag 1,09x2m",
    length: 1.09,
    width: 2
  },
  {
    article_id: "2683.257",
    width: 2,
    length: 2.57,
    label_en: "Diagonal Brace 2,57x2m",
    material: "",
    weight: 10,
    label_se: "Diagonalstag 2,57x2m",
    label_no: "Diagonalstag 2,57x2m"
  },
  /** 1.5 meter height diagonal braces */
  {
    article_id: "2682.073",
    label_se: "Diagonalstag 0,73x1,5m",
    length: 0.73,
    label_no: "Diagonalstag 0,73x1,5m",
    label_en: "Diagonal Brace 0,73x1,5m",
    weight: 5.8,
    material: "",
    width: 1.5
  },
  {
    length: 1.4,
    label_no: "Diagonalstag 1,4x1,5m",
    weight: 6.8,
    article_id: "2682.140",
    label_se: "Diagonalstag 1,4x1,5m",
    label_en: "Diagonal Brace 1,4x1,5m",
    material: "",
    width: 1.5
  },
  {
    label_no: "Diagonalstag 3,07x1,5m",
    label_se: "Diagonalstag 3,07x1,5m",
    article_id: "2682.307",
    material: "",
    label_en: "Diagonal Brace 3,07x1,5m",
    weight: 10.5,
    length: 3.07,
    width: 1.5
  },
  {
    length: 2.07,
    article_id: "2682.207",
    weight: 8.07,
    label_no: "Diagonalstag 2,07x1,5m",
    material: "",
    label_se: "Diagonalstag 2,07x1,5m",
    label_en: "Diagonal Brace 2,07x1,5m",
    width: 1.5
  },
  {
    label_no: "Diagonalstag 1,57x1,5m",
    label_en: "Diagonal Brace 1,57x1,5m",
    article_id: "2682.157",
    width: 1.5,
    label_se: "Diagonalstag 1,57x1,5m",
    material: "",
    length: 1.57,
    weight: 7.7
  },
  {
    article_id: "2682.109",
    label_en: "Diagonal Brace 1,09x1,5m",
    material: "",
    weight: 6.3,
    label_no: "Diagonalstag 1,09x1,5m",
    label_se: "Diagonalstag 1,09x1,5m",
    length: 1.09,
    width: 1.5
  },
  {
    article_id: "2682.257",
    width: 1.5,
    length: 2.57,
    label_en: "Diagonal Brace 2,57x1,5m",
    material: "",
    weight: 9.5,
    label_se: "Diagonalstag 2,57x1,5m",
    label_no: "Diagonalstag 2,57x1,5m"
  },

  /** 1 meter height diagonal braces */
  {
    article_id: "2681.073",
    label_se: "Diagonalstag 0,73x1m",
    length: 0.73,
    label_no: "Diagonalstag 0,73x1m",
    label_en: "Diagonal Brace 0,73x1m",
    weight: 4.5,
    material: "",
    width: 1
  },
  {
    length: 1.4,
    label_no: "Diagonalstag 1,4x1m",
    weight: 5.8,
    article_id: "2681.140",
    label_se: "Diagonalstag 1,4x1m",
    label_en: "Diagonal Brace 1,4x1m",
    material: "",
    width: 1
  },
  {
    label_no: "Diagonalstag 3,07x1m",
    label_se: "Diagonalstag 3,07x1m",
    article_id: "2681.307",
    material: "",
    label_en: "Diagonal Brace 3,07x1m",
    weight: 9.9,
    length: 3.07,
    width: 1
  },
  {
    length: 2.07,
    article_id: "2681.207",
    weight: 6.9,
    label_no: "Diagonalstag 2,07x1m",
    material: "",
    label_se: "Diagonalstag 2,07x1m",
    label_en: "Diagonal Brace 2,07x1m",
    width: 1
  },
  {
    label_no: "Diagonalstag 1,57x1m",
    label_en: "Diagonal Brace 1,57x1m",
    article_id: "2681.157",
    width: 1,
    label_se: "Diagonalstag 1,57x1m",
    material: "",
    length: 1.57,
    weight: 6.6
  },
  {
    article_id: "2681.109",
    label_en: "Diagonal Brace 1,09x1m",
    material: "",
    weight: 5.2,
    label_no: "Diagonalstag 1,09x1m",
    label_se: "Diagonalstag 1,09x1m",
    length: 1.09,
    width: 1
  },
  {
    article_id: "2681.257",
    width: 1,
    length: 2.57,
    label_en: "Diagonal Brace 2,57x1m",
    material: "",
    weight: 8.6,
    label_se: "Diagonalstag 2,57x1m",
    label_no: "Diagonalstag 2,57x1m"
  }
];

export const componentsStairs: Component[] = [
  /** 2m height stairs */
  {
    width: 0.64,
    height: 2,
    label_en: "Platform stairway 2,57x2m",
    article_id: "1753.257",
    length: 2.57,
    label_se: "Trappa 2,57x2m",
    weight: 21.9,
    material: "",
    label_no: "Trapp 2,57x2m"
  },
  {
    length: 3.07,
    material: "",
    label_no: "Trapp 3,07x2m",
    weight: 26.3,
    width: 0.64,
    height: 2,
    article_id: "1753.307",
    label_en: "Platform stairway 3,07x2m",
    label_se: "Trappa 3,07x2m"
  },
  /** 1.5m height stair */
  {
    width: 0.64,
    height: 1.5,
    label_en: "Platform stairway 2,57x1.5m",
    article_id: "1753.251",
    length: 2.57,
    label_se: "Trappa 2,57x1.5m",
    weight: 21.9,
    material: "",
    label_no: "Trapp 2,57x1.5m"
  }
];

export const componentsStartStairs: Component[] = [
  {
    width: 0.64,
    height: 1,
    label_en: "Starting stairway 1m",
    article_id: "1753.003",
    length: 1.4,
    label_se: "Start Trappa 1m",
    weight: 11.5,
    material: "",
    label_no: "Start Trapp 1m"
  },
  {
    width: 0.64,
    height: 1.2,
    label_en: "Starting stairway 1.2m",
    article_id: "1753.002",
    length: 1.4,
    label_se: "Start Trappa 1.2m",
    weight: 13.5,
    material: "",
    label_no: "Start Trapp 1.2m"
  },
  {
    width: 0.64,
    height: 1.7,
    label_en: "Starting stairway 1.2m",
    article_id: "1753.004",
    length: 1.4,
    label_se: "Start Trappa 1.2m",
    weight: 18.3,
    material: "",
    label_no: "Start Trapp 1.2m"
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    label_se: "Trappräcke 2,57",
    weight: 18.1,
    label_no: "Trapperekkverk 2,57",
    article_id: "2638.257",
    material: "",
    label_en: "Stairway guardrail 2,57",
    length: 2.57,
    width: 0,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    length: 3.07,
    weight: 20.1,
    height: 2,
    width: 0,
    label_se: "Trappräcke 3,07",
    material: "",
    label_en: "Stairway guardrail 3,07",
    article_id: "2638.307",
    label_no: "Trapperekkverk 3,07",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    label_se: "Trappräcke inner 2m",
    weight: 13.5,
    label_no: "Internt Trapperekkverk 2m",
    article_id: "1752.007",
    material: "",
    label_en: "Internal stairway guardrail T12 2m",
    length: 2.25,
    height: 2,
    width: 0,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  },
  {
    label_se: "Internt Trappräcke 1.5m",
    weight: 11.5,
    label_no: "Internt Trapperekkverk 1.5m",
    article_id: "1752.012",
    material: "",
    label_en: "Internal stairway guardrail T12 1.5m",
    length: 2,
    height: 1.5,
    width: 0,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
  // {
  //   label_se: "Internt Trappräcke 1m",
  //   weight: 7.8,
  //   label_no: "Internt Trapperekkverk 1m",
  //   article_id: "1752.011",
  //   material: "",
  //   label_en: "Internal stairway guardrail T12 1m",
  //   length: 0.9,
  //   height: 1,
  //   width: 0,
  //   variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  // }
];

export const componentsStairwayGuardrailsWideInner: Component[] = [
  {
    label_se: "Trappräcke inner",
    weight: 6.2,
    label_no: "Trapperekkverk indre",
    article_id: "1752.004",
    material: "",
    label_en: "Stairwell guardrail",
    length: 0.7,
    width: 0
  }
];

export const componentsConsoles: Component[] = [
  {
    label_en: "Console 0,39",
    label_se: "Konsol 0,39",
    width: 0.39,
    length: 0,
    material: "",
    weight: 3.9,
    label_no: "Konsol 0,39",
    article_id: "2632.039",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    article_id: "2632.019",
    label_en: "Console 0,28",
    weight: 3.4,
    label_no: "Konsol 0,28",
    width: 0.28,
    label_se: "Konsol 0,28",
    material: "",
    length: 0,
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    label_no: "Konsol 0,59",
    length: 0,
    width: 0.59,
    label_en: "Console 0,59",
    weight: 6.1,
    article_id: "2632.059",
    material: "",
    label_se: "Konsol 0,59"
  },
  {
    width: 0.73,
    length: 0,
    label_se: "Konsol 0,73",
    label_en: "Console 0,73",
    weight: 6.4,
    article_id: "2632.073",
    label_no: "Konsoll 0,73",
    material: "",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  }
];

export const componentsConsoleBracketBraces: Component[] = [
  {
    article_id: "2631.205",
    label_no: "Konsoll diagonal, 2.05m",
    label_se: "Konsol diagonal, 2.05m",
    label_en: "Bracket brace, 2.05 m",
    material: "",
    weight: 8.8,
    length: 2.05,
    height: 2,
    width: 0.73
  }
];

export const componentsBaseCollars: Component[] = [
  {
    article_id: "2602.000",
    weight: 1.4,
    width: 0,
    material: "",
    length: 0.24,
    label_en: "Base collar",
    label_no: "Bottenkrave",
    label_se: "Bottenkrage"
  }
  // {
  //   article_id: "2660.000",
  //   weight: 2.2,
  //   width: 0,
  //   material: "",
  //   length: 0.43,
  //   label_en: "Base collar long",
  //   label_no: "Detalj",
  //   label_se: "Detalj"
  // }
];

export const componentsAnchors: Component[] = [
  {
    width: 0,
    label_se: "Allround Förankringsrör 0.8m",
    label_no: "Allround Rörforankring 0.8m",
    label_en: "Allround Wall tie 0.8m",
    material: "",
    weight: 3.3,
    length: 0.8,
    article_id: "2639.080"
  },
  {
    width: 0,
    label_se: "Förankringsrör 0.38m",
    label_no: "Rörforankring 0.38m",
    label_en: "Wall tie 0.38m",
    material: "",
    weight: 1.6,
    length: 0.38,
    article_id: "1754.038"
  },
  {
    width: 0,
    label_se: "Förankringsrör 0.69m",
    label_no: "Rörforankring 0.69m",
    label_en: "Wall tie 0.69m",
    material: "",
    weight: 2.8,
    length: 0.69,
    article_id: "1754.069"
  },
  {
    width: 0,
    label_se: "Förankringsrör 0.95m",
    label_no: "Rörforankring 0.95m",
    label_en: "Wall tie 0.95m",
    material: "",
    weight: 3.7,
    length: 0.95,
    article_id: "1754.095"
  },
  {
    width: 0,
    label_se: "Förankringsrör 1.45m",
    label_no: "Rörforankring 1.45m",
    label_en: "Wall tie 1.45m",
    material: "",
    weight: 5.7,
    length: 1.45,
    article_id: "1754.145"
  },
  {
    width: 0,
    label_se: "Förankringsrör 1.75m",
    label_no: "Rörforankring 1.75m",
    label_en: "Wall tie 1.75m",
    material: "",
    weight: 5.8,
    length: 1.75,
    article_id: "1754.175"
  }
];
export const componentsCouplers: Component[] = [
  {
    label_no: "Dobbeltkobling",
    label_se: "Dubbelkoppling",
    article_id: "4700.019",
    material: "",
    length: 0.07,
    label_en: "Double coupler",
    weight: 1.3,
    width: 0
  }
];
export const componentsScrews: Component[] = [
  {
    article_id: "4009.096",
    weight: 0.16, // 1.6kg per 10 pcs
    material: "",
    length: 0.095,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.121",
    weight: 0.18, // 1.8kg per 10 pcs
    material: "",
    length: 0.12,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.191",
    weight: 0.25, // 2.5kg per 10 pcs
    material: "",
    length: 0.19,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.231",
    weight: 0.3, // 3kg per 10 pcs
    material: "",
    length: 0.23,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.301",
    weight: 0.35, // 3.5kg per 10 pcs
    material: "",
    length: 0.3,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.351",
    weight: 0.35, // 5kg per 10 pcs
    material: "",
    length: 0.5,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  }
];

export const componentsBaseplates: Component[] = [
  {
    length: 0.56,
    width: 0,
    label_se: "Bottenskruv 0.56m",
    material: "",
    label_no: "Bunnskrue 0.56m",
    article_id: "4001.060",
    label_en: "Bottom screw 0.56m",
    weight: 3.6
  },
  {
    length: 0.73,
    width: 0,
    label_se: "Bottenskruv 0.73m",
    material: "",
    label_no: "Bunnskrue 0.73m",
    article_id: "4002.080",
    label_en: "Bottom screw 0.73m",
    weight: 4.9
  }
];

export const componentsLiftOffPreventers: Component[] = [
  {
    length: 0.39,
    width: 0,
    label_se: "Plattformslås T8 0.39m",
    material: "",
    label_no: "Løftehindrer T8 0.39m",
    article_id: "2635.039",
    label_en: "Lift off preventer T8 0.39m",
    weight: 0.6
  },
  {
    length: 0.45,
    width: 0,
    label_se: "Plattformslås T8 0.45m",
    material: "",
    label_no: "Løftehindrer T8 0.45m",
    article_id: "2635.045",
    label_en: "Lift off preventer T8 0.45m",
    weight: 0.7
  },
  {
    length: 0.5,
    width: 0,
    label_se: "Plattformslås T8 0.50m",
    material: "",
    label_no: "Løftehindrer T8 0.50m",
    article_id: "2635.050",
    label_en: "Lift off preventer T8 0.50m",
    weight: 0.8
  },
  {
    length: 0.73,
    width: 0,
    label_se: "Plattformslås T8 0.73m",
    material: "",
    label_no: "Løftehindrer T8 0.73m",
    article_id: "2635.073",
    label_en: "Lift off preventer T8 0.73m",
    weight: 1.3
  },
  {
    length: 1,
    width: 0,
    label_se: "Plattformslås T8 1m",
    material: "",
    label_no: "Løftehindrer T8 1m",
    article_id: "2635.100",
    label_en: "Lift off preventer T8 1m",
    weight: 1.7
  },
  {
    length: 1.09,
    width: 0,
    label_se: "Plattformslås T8 1.09m",
    material: "",
    label_no: "Løftehindrer T8 1.09m",
    article_id: "2635.109",
    label_en: "Lift off preventer T8 1.09m",
    weight: 1.8
  },
  {
    length: 1.29,
    width: 0,
    label_se: "Plattformslås T8 1.29m",
    material: "",
    label_no: "Løftehindrer T8 1.29m",
    article_id: "2635.129",
    label_en: "Lift off preventer T8 1.29m",
    weight: 2.1
  },
  {
    length: 1.4,
    width: 0,
    label_se: "Plattformslås T8 1.4m",
    material: "",
    label_no: "Løftehindrer T8 1.4m",
    article_id: "2658.140",
    label_en: "Lift off preventer T8 1.4m",
    weight: 5.3
  },
  {
    length: 1.57,
    width: 0,
    label_se: "Plattformslås T8 1.57m",
    material: "",
    label_no: "Løftehindrer T8 1.57m",
    article_id: "2658.157",
    label_en: "Lift off preventer T8 1.57m",
    weight: 5.9
  },
  {
    length: 2.07,
    width: 0,
    label_se: "Plattformslås T8 2.07m",
    material: "",
    label_no: "Løftehindrer T8 2.07m",
    article_id: "2658.207",
    label_en: "Lift off preventer T8 2.07m",
    weight: 7.9
  },
  {
    length: 2.57,
    width: 0,
    label_se: "Plattformslås T8 2.57m",
    material: "",
    label_no: "Løftehindrer T8 2.57m",
    article_id: "2658.257",
    label_en: "Lift off preventer T8 2.57m",
    weight: 9.9
  },
  {
    length: 3.07,
    width: 0,
    label_se: "Plattformslås T8 3.07m",
    material: "",
    label_no: "Løftehindrer T8 3.07m",
    article_id: "2658.307",
    label_en: "Lift off preventer T8 3.07m",
    weight: 11.9
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    length: 0.1,
    width: 0,
    label_se: "Skarvtapp U-balk",
    material: "",
    label_no: "Bjelkespiss U-bjelke",
    article_id: "2656.000",
    label_en: "Spigot for U-section",
    weight: 1.8
  },
  {
    length: 0.1,
    width: 0,
    label_se: "Skarvtapp för U-gitterräcke, ",
    material: "",
    label_no: "Bjelkespiss U-gitterbjelke ",
    article_id: "2656.001",
    label_en: "Spigot for U-lattice beam",
    weight: 2.1
  },
  {
    length: 0.1,
    width: 0,
    label_se: "Skarvtapp för U-gitterräcke (förstärkt)",
    material: "",
    label_no: "Bjelkespiss U-gitterbjelke (forsterket)",
    article_id: "2656.002",
    label_en: "Spigot for U-lattice beam (reinforced)",
    weight: 2.1
  },
  {
    length: 0.1,
    width: 0,
    label_se: "Skarvtapp för O-gitterräcke",
    material: "",
    label_no: "Bjelkespiss O-gitterbjelke",
    article_id: "4706.019",
    label_en: "Spigot for O-lattice beam",
    weight: 1.8
  }
];

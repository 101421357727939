import {
  AuthUser,
  AuthenticationService,
  AuthenticationServiceProvider
} from "./authentication.types";
import { initAuthenticationService } from "./authentication.utils";

class Authentication {
  /** The authService holds the abstracted authentication service */
  public authService: AuthenticationService;

  /** The authService can be initialized with any AuthenticationServiceProvider but default is .FIREBASE */
  public constructor(serviceProvider = AuthenticationServiceProvider.FIREBASE) {
    /** A helper function is used to initialize the authServive  */
    this.authService = initAuthenticationService(serviceProvider);
  }

  public signIn = (email: string, password: string) => {
    return this.authService.signIn(email, password);
  };

  public signInWithToken = (token: string) => {
    return this.authService.signInWithToken(token);
  };

  public signOut = () => {
    return this.authService.signOut();
  };

  public onSessionUpdate = (onUpdate: (user: AuthUser | null) => void) => {
    return this.authService.onSessionUpdate(onUpdate);
  };

  public sendPasswordResetEmail = (email: string) => {
    return this.authService.sendPasswordResetEmail(email);
  };

  public changePassword = (currentPassword: string, newPassword: string) => {
    return this.authService.changePassword(currentPassword, newPassword);
  };

  public confirmPasswordReset = (code: string, newPassword: string) => {
    return this.authService.confirmPasswordReset(code, newPassword);
  };

  public verifyPasswordResetCode = (code: string) => {
    return this.authService.verifyPasswordResetCode(code);
  };

  public checkActionCode = (code: string) => {
    return this.authService.checkActionCode(code);
  };

  public getToken = async () => {
    return this.authService.getToken();
  };
}

/** Initialize a Authentication object */
const authentication = new Authentication();

export { authentication };

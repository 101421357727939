import { ReactElement } from "react";
import { Link, Stack } from "@mui/material";

import ErrorPageProps from "./ErrorPage.types";
import Text from "ui/core/Text";
import { ScaffcalcLogoVertical } from "assets/graphics";
import Button from "ui/core/Button";

const ErrorPage = (props: ErrorPageProps): ReactElement => {
  const { error, resetErrorBoundary } = props;

  const onClickHome = () => {
    window.location.href = "/";
  };

  const onClickContact = () => {
    window.open("https://www.scaffcalc.com/support", "_blank");
  };

  return (
    <Stack
      width={"100vw"}
      height={"100vh"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={16}
    >
      <Stack gap={1} maxWidth={500} px={{ xs: 2, sm: 0 }}>
        <Text variant="h1">Oops!</Text>
        <Text variant="h5" color="secondary">
          We're sorry, but something went wrong
        </Text>

        <Text variant="body1">
          Try again or return to the homepage. If you're still having trouble,
          please
          <Link underline="none" onClick={onClickContact} fontWeight={"bold"}>
            {" contact us"}
          </Link>
        </Text>
        <Text variant="body2" color="textSecondary">
          {error.message}
        </Text>
        <Stack direction={"row"} gap={1} mt={2}>
          <Button onClick={resetErrorBoundary} color={"primary"}>
            Try again
          </Button>
          <Button onClick={onClickHome} color={"primary"} variant={"outlined"}>
            Go to homepage
          </Button>
        </Stack>
      </Stack>
      <ScaffcalcLogoVertical height={96} color={"text.secondary"} />
    </Stack>
  );
};

export default ErrorPage;

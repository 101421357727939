export enum HOUSE_TYPE {
  POLYGON = "housePolygon",
  RECTANGLE = "houseRectangle",
  CYLINDER = "houseCylinder"
}

export enum GROUND_TYPE {
  POLYGON = "groundPolygon",
  FLAT = "groundFlat",
  SLOPE = "groundSlope"
}

export enum ELEVATOR_TIEORIENTATION {
  RIGHT = "right",
  LEFT = "left"
}

export enum ELEVATOR_MANUFACTURER {
  ALIMAK = "alimak"
}

export enum ELEVATOR_MODEL {
  ALIMAK_TPL1000 = "ALIMAKTPL1000"
}

export enum SNAPSHOT_EVENT {
  READY = "scaffcalc-snapshot-ready",
  FAILED = "scaffcalc-snapshot-failed"
}

export enum BOX_TYPE {
  STAIR = "stair",
  BAY = "bay",
  PASSAGE = "passage"
}

export enum STAIR_TYPE {
  SINGLE = "single",
  DOUBLE = "double"
}

export enum ROOF_TYPE {
  GABLE = "gable",
  SHED = "shed"
}

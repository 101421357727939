import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "shared/enums/scaffold";

export const BAYWIDTHS = {
  MM400: 0.4,
  MM740: 0.74,
  MM750: 0.75,
  MM1000: 1,
  MM1100: 1.1,
  MM1400: 1.4,
  MM1500: 1.5,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3.0,
};

export const BAYLENGTHS = {
  MM400: 0.4,
  MM740: 0.74,
  MM750: 0.75,
  MM1000: 1,
  MM1100: 1.1,
  MM1400: 1.4,
  MM1500: 1.5,
  MM2000: 2.0,
  MM2500: 2.5,
  MM3000: 3.0,
};

export const LOADINGBAYWIDTHS = {
  MM400: 0.4,
  MM740: 0.74,
  MM750: 0.75,
  MM1000: 1,
  MM1100: 1.1,
  MM1400: 1.4,
  MM1500: 1.5,
  MM2000: 2,
  MM2500: 2.5,
  MM3000: 3.0,
};

export const LOADINGBAYLENGTHS = {
  MM740: 0.74,
  MM750: 0.75,
  MM1000: 1,
  MM1100: 1.1,
  MM1400: 1.4,
  MM1500: 1.5,
  MM2000: 2.0,
  MM2500: 2.5,
  MM3000: 3.0,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const PLANKWIDTHS = { MM320: 0.32, MM340: 0.34 };

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  WOOD: SCAFFOLD_PLANKTYPE.WOOD,
  ALUMINIUM: SCAFFOLD_PLANKTYPE.ALUMINIUM,
};

export const CONSOLEWIDTHS = {
  MM400: 0.4,
  MM750: 0.75,
};

export const STAIRWIDTHS = { MM750: 0.75 };

export const STAIRLENGTHS = { MM2500: 2.5, MM3000: 3.0 };

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

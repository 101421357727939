export enum USER_ACCOUNTTYPE {
  TRIAL = "trial",
  STANDARD = "standard"
}

export enum USER_ACCOUNTTYPE_CHANGE {
  CHANGE = "accountTypeChange",
  DEACTIVATED = "accountDeactivated",
  ACTIVATED = "accountActivated"
}

export enum USER_ROLE {
  ADMIN = "admin",
  MEMBER = "member",
  SUPERADMIN = "superadmin"
}

export enum USER_ACHIEVEMENTS {
  TUTORIAL_COMPLETE = "tutorialComplete"
}

export enum TRS_SYSTEM {
  KEDERXL = "kederXL",
  HAKITEC750 = "hakitec750",
  HAKITEC450 = "hakitec450",
  UBIX780 = "ubix780",
  GIBSON = "gibson"
}

export enum DRAW_DIAGONAL_PATTERNS {
  CROSSDIAGONAL = "crossDiagonal",
  INWARDDIAGONAL = "inwardDiagonal",
  OUTWARDDIAGONAL = "outwardDiagonal",
  EVERYOTHER = "everyOther",
  EVERYOTHERREVERSE = "everyOtherReverse"
}
export enum TRS_STATUS {
  ABORTED = "aborted",
  DONE = "done",
  IN_PROGRESS = "in-progress",
  NOT_SENT = "not-sent",
  SENT = "sent"
}

export enum CALCULATION_MODES {
  NEW = "new",
  EDIT = "edit"
}

export enum TRS_ROOFTYPE {
  GABLE = "gable",
  SHED = "shed"
}

export enum CAMERA_POSITIONS {
  FRONT = "front",
  SIDE = "side"
}

export enum WIND_REFERENCESPEED {
  WS21 = 21,
  WS22 = 22,
  WS23 = 23,
  WS24 = 24,
  WS25 = 25,
  WS26 = 26,
  WS27 = 27,
  WS28 = 28,
  WS29 = 29,
  WS30 = 30,
  WS31 = 31
}

export enum TrsStatus {
  ABORTED = "aborted",
  DONE = "done",
  IN_PROGRESS = "in-progress",
  NOT_SENT = "not-sent",
  SENT = "sent"
}

import { StateCreator } from "zustand";
import { TutorialSlice } from "./tutorial.types";
import { TUTORIAL_STEP } from "shared/enums/tutorial";

const createTutorialSlice: StateCreator<
  TutorialSlice,
  [],
  [],
  TutorialSlice
> = (set) => ({
  tutorialActive: false,
  tutorialStep: TUTORIAL_STEP.GROUND,
  tutorialStepCompleted: undefined,
  tutorialActions: {
    setTutorialActive: (tutorialActive: boolean) => set({ tutorialActive }),
    setTutorialStep: (tutorialStep: TUTORIAL_STEP) => set({ tutorialStep }),
    setTutorialStepCompleted: (
      tutorialStepCompleted: TUTORIAL_STEP | undefined
    ) => set({ tutorialStepCompleted })
  }
});

export default createTutorialSlice;

import { ReactElement } from "react";
import ModalProps from "./Modal.types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Stack
} from "@mui/material";
import Button from "../../core/Button";
import { Close } from "@mui/icons-material";
import Breakpoints from "managers/ThemeManager/theme/breakpoints";

const Modal = (props: ModalProps): ReactElement => {
  const {
    commitButton,
    closeButton,
    fullScreenOnSmall = false,
    open,
    title,
    content,
    onTransitionExited,
    width = "sm",
    forceFullscreen = false
  } = props;

  const { belowSmallScreen } = Breakpoints();

  return (
    <Dialog
      open={open}
      maxWidth={width}
      fullWidth
      fullScreen={forceFullscreen || (belowSmallScreen && fullScreenOnSmall)}
      onTransitionExited={onTransitionExited}
      PaperProps={{
        elevation: 6
      }}
      onClose={closeButton?.onClick}
    >
      <DialogTitle
        id="alert-dialog-title"
        variant="h5"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          userSelect: "none"
        }}
      >
        <Box component="div" flex={1}>
          {title}
        </Box>
        {closeButton && (
          <IconButton aria-label="close" onClick={closeButton.onClick}>
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        {closeButton && (
          <Button
            autoFocus
            onClick={closeButton.onClick}
            variant={"text"}
            color={"inherit"}
          >
            {closeButton.label}
          </Button>
        )}
        {commitButton && (
          <Button
            autoFocus
            onClick={commitButton.onClick}
            variant={"contained"}
            color={commitButton.color ?? "primary"}
            disabled={commitButton.disabled}
          >
            {commitButton.label}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

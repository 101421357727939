import {
  AuthUser,
  AuthenticationService
} from "../authentication/authentication.types";
import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp
} from "firebase/app";
import {
  Auth,
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  signOut as signOutFirebase,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  confirmPasswordReset as confirmPasswordResetFirebase,
  verifyPasswordResetCode as verifyPasswordResetCodeFirebase,
  checkActionCode as checkActionCodeFirebase
} from "firebase/auth";

class FirebaseAuthentication implements AuthenticationService {
  private app: FirebaseApp;
  private auth: Auth;

  constructor(props: {
    config: FirebaseOptions & { functionsLink: string };
    isLocalDev?: boolean;
  }) {
    const { config, isLocalDev } = props;
    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.auth = getAuth(this.app);

    /** Setup local emulator */
    if (isLocalDev) this.setupLocalEmulator();
  }

  public signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password).then(
      (userCredential) => {
        const { user } = userCredential;

        return {
          emailVerfied: user.emailVerified,
          refreshToken: user.refreshToken,
          uid: user.uid
        };
      }
    );
  };

  public signInWithToken = (token: string) => {
    return signInWithCustomToken(this.auth, token);
  };

  public signOut = () => {
    return signOutFirebase(this.auth);
  };

  public onSessionUpdate = (onUpdate: (user: AuthUser | null) => void) => {
    return onAuthStateChanged(this.auth, (user) => {
      if (user) {
        onUpdate({
          emailVerfied: user.emailVerified,
          refreshToken: user.refreshToken,
          uid: user.uid
        });
      } else {
        onUpdate(null);
      }
    });
  };

  public sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmailFirebase(this.auth, email);
  };

  public changePassword = async (
    currentPassword: string,
    newPassword: string
  ) => {
    const { currentUser } = this.auth;
    if (currentUser && currentUser.email) {
      const creds = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );

      await reauthenticateWithCredential(currentUser, creds);
      const { currentUser: updatedUser } = this.auth;

      if (updatedUser) return updatePassword(updatedUser, newPassword);
    } else {
      return;
    }
  };

  public verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCodeFirebase(this.auth, code);
  }

  public confirmPasswordReset = (code: string, newPassword: string) => {
    return confirmPasswordResetFirebase(this.auth, code, newPassword);
  };

  public checkActionCode = (code: string) => {
    return checkActionCodeFirebase(this.auth, code);
  };

  public getToken = async () => {
    if (!this.auth.currentUser) return;

    return await this.auth.currentUser.getIdToken(true);
  };

  setupLocalEmulator = () => {
    connectAuthEmulator(
      this.auth,
      "http://0.0.0.0:9099",
      /** Hides the bottom white banner **/ {
        disableWarnings: true
      }
    );
  };
}

export default FirebaseAuthentication;

import { halfPi } from "math/constants";
import { minus, plus, round } from "math";
import {
  Anchor,
  BaseBoard,
  BasePlate,
  Box,
  BoxConsole,
  BoxFrame,
  DiagonalBrace,
  GuardRail,
  Ledger,
  Plank,
  Stairway,
  StairwayGuardRail,
  StairwayInnerGuardRail,
  ToeBoard
} from "shared/interfaces/firestore";
import { Vector3, Vector3Tuple } from "three";
import {
  componentsAnchors,
  componentsBaseplates,
  componentsConsoles,
  componentsDecks,
  componentsFrames,
  componentsOLedgers,
  componentsTopFrames,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsPassageFrames,
  componentsGuardRails,
  componentsSingleGuardRails,
  componentsScrews,
  componentsCouplers,
  componentsStairwayGuardrailsInternal,
  componentsToeBoards,
  componentsDiagonalBraces,
  componentsPlatforms,
  componentsULedgers,
  componentsConsoleBracketBraces
} from "../components";
import { genId } from "math/generators";
import {
  SCAFFOLD_DIAGONAL_PATTERN,
  SCAFFOLD_SUPPLIER
} from "shared/enums/scaffold";
import { Frame, FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { Console } from "world/core/Console/Console.types";
import { ReplacedComponent } from "suppliers/scaffold/scaffold.interface";
import { getSameStartPosAndRotLedgers } from "store/world/box/box.utils";
import {
  BASE_BOARD_HEIGHT,
  DEFAULT_FALL_PROTECTION_HEIGHT,
  DEFAULT_STAIR_HEIGHT,
  TOE_BOARD_OFFSET
} from "suppliers/scaffold/constants";
import {
  calcCenterPositionsAlongLine,
  getDiagonalHeight,
  splitConsoles,
  splitFrames
} from "suppliers/scaffold/scaffold.utils";
import { generateOptimalPlankConfiguration, getStair } from "./utils";

import { getEndPointFromStartPoint, roundVector } from "math/vectors";
import { half } from "math";
import { isComponentPartOfOtherBox } from "store/world/world.utils";
import useStoreWithUndo from "store/store";
import { componentsBaseBoards } from "suppliers/scaffold/components";
import { Screw } from "world/core/Screw/Screw.types";
import { Coupler } from "world/core/Coupler/Coupler.types";
const FRAME_SPIGOT_HEIGHT = 0.1;
export const generateFrames = (props: {
  frameLengths: {
    positions: {
      left: Vector3;
      right: Vector3;
    };
    frameLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  frameWidth: number;
  boxRotation: Box["rotation"];
  topVariant?: boolean;
  guardRailsOptions?: [boolean, boolean, boolean, boolean];
  standardPositions?: [Vector3, Vector3, Vector3, Vector3];
  passageBase?: boolean;
}) => {
  const {
    frameLengths,
    frameWidth,
    boxRotation,
    topVariant,
    guardRailsOptions,
    standardPositions,
    passageBase
  } = props;

  /** Creation of frames */
  const frames: Frame[] = [];
  const frameRotation = [
    boxRotation[0],
    boxRotation[1],
    plus(boxRotation[2], halfPi)
  ] as Vector3Tuple;

  frameLengths.forEach(({ positions, frameLength }) => {
    let currentYPos = plus(positions.left.y, frameLength.remainingLength);
    const { left, right } = positions;
    frameLength.partitions.forEach((length, idx) => {
      if (idx === frameLength.partitions.length - 1 && topVariant) {
        let matchingTopFrame;
        if (guardRailsOptions && guardRailsOptions[0] && standardPositions) {
          if (
            left.x === standardPositions[0].x &&
            left.z === standardPositions[0].z
          ) {
            matchingTopFrame = componentsTopFrames.find(
              (post) =>
                post.length === length &&
                post.width === frameWidth &&
                post.variant === FRAME_VARIANT.TOP_END
            );
          }
        }
        if (guardRailsOptions && guardRailsOptions[2] && standardPositions) {
          if (
            left.x === standardPositions[3].x &&
            left.z === standardPositions[3].z
          ) {
            matchingTopFrame = componentsTopFrames.find(
              (post) =>
                post.length === length &&
                post.width === frameWidth &&
                post.variant === FRAME_VARIANT.TOP_END
            );
          }
        }

        if (matchingTopFrame) {
          const frame: Frame = {
            id: genId(),
            position: [left.x, currentYPos, left.z],
            length,
            rotation: frameRotation,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingTopFrame.article_id,
            variant: FRAME_VARIANT.TOP_END,
            left: {
              pos: [left.x, currentYPos, left.z],
              splits: [],
              endPos: [left.x, currentYPos + length, left.z]
            },
            right: {
              pos: [right.x, currentYPos, right.z],
              splits: [],
              endPos: [right.x, currentYPos + length, right.z]
            }
          };
          frames.push(frame);

          return;
        }
        let matchingPost;

        matchingPost = componentsTopFrames.find(
          (post) =>
            post.length === length &&
            post.width === frameWidth &&
            post.variant === FRAME_VARIANT.TOP_INTERMEDIATE
        );

        if (matchingPost) {
          const frame: Frame = {
            id: genId(),
            position: [left.x, currentYPos, left.z],
            length,
            rotation: frameRotation,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingPost.article_id,
            variant: FRAME_VARIANT.TOP_INTERMEDIATE,
            left: {
              pos: [left.x, currentYPos, left.z],
              splits: [],
              endPos: [left.x, currentYPos + length, left.z]
            },
            right: {
              pos: [right.x, currentYPos, right.z],
              splits: [],
              endPos: [right.x, currentYPos + length, right.z]
            }
          };
          frames.push(frame);

          return;
        }
      }
      if (passageBase) {
        const matchingFrame = componentsPassageFrames.find(
          (frame) => frame.length === length && frame.width === frameWidth
        );
        if (matchingFrame) {
          const frame: Frame = {
            id: genId(),
            position: [left.x, currentYPos, left.z],
            left: {
              pos: [left.x, currentYPos, left.z],
              splits: [],
              endPos: [left.x, currentYPos + length, left.z]
            },
            right: {
              pos: [right.x, currentYPos, right.z],
              splits: [],
              endPos: [right.x, currentYPos + length, right.z]
            },
            top: {
              pos: [left.x, currentYPos + length - FRAME_SPIGOT_HEIGHT, left.z],
              splits: [],
              endPos: [
                right.x,
                currentYPos + length - FRAME_SPIGOT_HEIGHT,
                right.z
              ]
            },
            length,
            rotation: frameRotation,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingFrame.article_id,
            variant: FRAME_VARIANT.PASSAGE
          };
          frames.push(frame);
        }
      }
      const matchingFrame = componentsFrames.find(
        (frame) => frame.length === length && frame.width === frameWidth
      );

      if (matchingFrame) {
        const frame: Frame = {
          id: genId(),
          position: [left.x, currentYPos, left.z],
          left: {
            pos: [left.x, currentYPos, left.z],
            splits: [],
            endPos: [left.x, currentYPos + length, left.z]
          },
          right: {
            pos: [right.x, currentYPos, right.z],
            splits: [],
            endPos: [right.x, currentYPos + length, right.z]
          },
          top: {
            pos: [left.x, currentYPos + length - FRAME_SPIGOT_HEIGHT, left.z],
            splits: [],
            endPos: [
              right.x,
              currentYPos + length - FRAME_SPIGOT_HEIGHT,
              right.z
            ]
          },
          length,
          rotation: frameRotation,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingFrame.article_id
        };
        frames.push(frame);
      }
      currentYPos = plus(currentYPos, length);
    });
  });

  return { frames, replacedframes: [] };
};

export const generateFrameLedgers = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  framesToSplit: Frame[];
  boxId: string;
  skipBottomLedgers?: boolean;
}) => {
  const {
    standardPositions,
    boxRotation,
    depth,
    frameLevels,
    framesToSplit,
    boxId,
    width,
    skipBottomLedgers
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const ledgers: Ledger[] = [];
  const replacedLedgers: ReplacedComponent[] = [];

  const bayLengthDirectionRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const oLedger = componentsOLedgers.find((ledger) => ledger.length === depth);
  const uLedger = componentsULedgers.find((ledger) => ledger.length === width);

  const ledgersData = [
    {
      start: BL,
      end: FL,
      rotation: bayLengthDirectionRotation,
      component: oLedger
    },
    { start: FR, end: FL, rotation: boxRotation, component: uLedger },
    { start: BR, end: BL, rotation: boxRotation, component: uLedger }
  ];
  const ledgerLevels = [...frameLevels];
  if (!skipBottomLedgers) {
    const maxStandardYPos = Math.max(...standardPositions.map((p) => p.y), 0);
    const etraFrameHeight = round(maxStandardYPos + 0.15, 3);
    ledgerLevels.push({ height: etraFrameHeight });
  }

  ledgersData.forEach(({ start, end, rotation, component }) => {
    if (!component) return;
    const startPosition = start.toArray();
    const endPosition = end.toArray();
    let oldLedgerSplits: number[] = [];

    ledgerLevels.forEach(({ height, platform }) => {
      if (platform) return;

      const yPos = height;
      startPosition[1] = yPos;
      endPosition[1] = yPos;
      const newLedgerId = genId();
      oldLedgerSplits = [];
      const sameStartPosAndRotLedgers = getSameStartPosAndRotLedgers({
        position: startPosition,
        rotation
      });
      if (sameStartPosAndRotLedgers.length > 0) {
        const { graph } = useStoreWithUndo.getState();
        const sameStartPosLedger = sameStartPosAndRotLedgers[0];
        const isLedgerConnectedToOtherBox = isComponentPartOfOtherBox({
          graph,
          component: sameStartPosLedger,
          excludeBoxIds: [boxId]
        });

        /** Keep previous ledger  */
        if (
          sameStartPosLedger.length > component.length &&
          isLedgerConnectedToOtherBox
        ) {
          // important that we clone the ledger to avoid mutation
          ledgers.push({
            ...sameStartPosLedger,
            ...(sameStartPosLedger.splits && {
              splits: [...sameStartPosLedger.splits]
            })
          });
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: sameStartPosLedger.id
          });
          return;
          /** Remove previous ledger but keep their splits */
        } else {
          if (sameStartPosLedger.splits)
            oldLedgerSplits.push(...sameStartPosLedger.splits);
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: newLedgerId
          });
        }
      }
      ledgers.push({
        id: newLedgerId,
        position: [...startPosition],
        endPosition: [...endPosition],
        length: component.length,
        splits: oldLedgerSplits,
        rotation,
        supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
        componentId: component.article_id
      });
    });
  });

  /** Split frames with end and start positions*/
  splitFrames({
    framesToSplit,
    positions: ledgers
      .map((ledger) => [ledger.position, ledger.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { ledgers, replacedLedgers };
};
export const generateGuardRails = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  fallProtectionHeight: number;
  guardRailsOptions: [boolean, boolean, boolean, boolean];
  framesToSplit: Frame[];
  topVariant?: boolean;
}) => {
  const {
    boxRotation,
    depth,
    width,
    frameLevels: frames,
    fallProtectionHeight,
    guardRailsOptions,
    standardPositions,
    framesToSplit,
    topVariant
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const singleGuardRails: Ledger[] = [];
  const guardRails: GuardRail[] = [];

  /** Longitudinal Guard rails */
  const longitudinalGuardRailRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const shortGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === width
  );
  const longGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === depth
  );

  const shortSingleGuardRail = componentsSingleGuardRails.find(
    (ledger) => ledger.length === width
  );
  const longSingleGuardRail = componentsSingleGuardRails.find(
    (ledger) => ledger.length === depth
  );

  const guardRailData = [
    ...(guardRailsOptions[1]
      ? [
          {
            start: BR,
            end: FR,
            component: longGuardRail,
            singleGuardRailComponent: longSingleGuardRail,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            start: BL,
            end: FL,
            component: longGuardRail,
            singleGuardRailComponent: longSingleGuardRail,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            start: FR,
            end: FL,
            component: shortGuardRail,
            singleGuardRailComponent: shortSingleGuardRail,
            rotation: boxRotation
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            start: BR,
            end: BL,
            component: shortGuardRail,
            singleGuardRailComponent: shortSingleGuardRail,
            rotation: boxRotation
          }
        ]
      : [])
  ];

  guardRailData.forEach(
    ({ start, end, rotation, component, singleGuardRailComponent }) => {
      if (!component) return;
      frames.forEach(({ platform }, idx) => {
        if (!platform) return;
        const topStartPosition = start.toArray();
        const topEndPosition = end.toArray();
        const bottomStartPosition = start.toArray();
        const bottomEndPosition = end.toArray();

        if (idx === 0) {
          if (
            topVariant &&
            (start === standardPositions[1] || start === standardPositions[2])
          )
            return;
          let guardRailHeight = fallProtectionHeight;

          /** Add top ledger first then regular guard rails */
          if (fallProtectionHeight % 1 !== 0 && singleGuardRailComponent) {
            const yPos = plus(platform, guardRailHeight);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            const guardRailLedger: Ledger = {
              id: genId(),
              position: [...topStartPosition],
              endPosition: [...topEndPosition],
              length: singleGuardRailComponent.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
              componentId: singleGuardRailComponent.article_id
            };
            singleGuardRails.push(guardRailLedger);
            guardRailHeight = minus(guardRailHeight, 0.5);
          }
          for (let i = guardRailHeight; i > 0; i -= 1) {
            const yPos = plus(platform, i);
            const bottomYPos = minus(yPos, 0.5);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            bottomStartPosition[1] = bottomYPos;
            bottomEndPosition[1] = bottomYPos;

            const guardRail: GuardRail = {
              id: genId(),
              position: [...topStartPosition],
              top: {
                pos: [...topStartPosition],
                endPos: [...topEndPosition],
                splits: []
              },
              bottom: {
                pos: [...bottomStartPosition],
                endPos: [...bottomEndPosition],
                splits: []
              },
              length: component.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
              componentId: component.article_id,
              ...(component.variant && { variant: component.variant })
            };
            guardRails.push(guardRail);
          }
        } else {
          const yPos = plus(platform, DEFAULT_FALL_PROTECTION_HEIGHT);
          const bottomYPos = minus(yPos, 0.5);
          topStartPosition[1] = yPos;
          topEndPosition[1] = yPos;
          bottomStartPosition[1] = bottomYPos;
          bottomEndPosition[1] = bottomYPos;

          const guardRail: GuardRail = {
            id: genId(),
            position: topStartPosition,
            top: {
              pos: topStartPosition,
              endPos: topEndPosition,
              splits: []
            },
            bottom: {
              pos: bottomStartPosition,
              endPos: bottomEndPosition,
              splits: []
            },
            length: component.length,
            rotation,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: component.article_id,
            ...(component.variant && { variant: component.variant })
          };
          guardRails.push(guardRail);
        }
      });
    }
  );

  /** Split frames with end and start positions*/
  splitFrames({
    framesToSplit,
    positions: [
      ...guardRails
        .map((guardRail) => [guardRail.top.pos, guardRail.top.endPos])
        .flat(),
      ...singleGuardRails
        .map((singleGuardRail) => [
          singleGuardRail.position,
          singleGuardRail.endPosition ?? [0, 0, 0]
        ])
        .flat()
    ]
  });
  return { guardRails, ledgers: singleGuardRails };
};

export const generateFrameDeckPlanks = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  stair?: boolean;
  framesToSplit: Frame[];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frameLevels,
    standardPositions,
    stair,
    framesToSplit
  } = props;
  const plankSpacing = 0.01;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const planks: Plank[] = [];
  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;
  const allDeckComponents = [...componentsDecks, ...componentsPlatforms];

  const plankRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const startPlanksEndVector = stair
    ? BR.clone()
        .setY(0)
        .sub(
          BR.clone()
            .setY(0)
            .sub(BL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : BR.clone().setY(0);

  const endPlanksEndVector = stair
    ? FR.clone()
        .setY(0)
        .sub(
          FR.clone()
            .setY(0)
            .sub(FL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : FR.clone().setY(0);

  frameLevels.forEach(({ platform, plankingMaterial }) => {
    if (!platform) return;
    const optimalPlankConfiguration = generateOptimalPlankConfiguration({
      length: depth,
      width: minus(width, stair ? stairWidth : 0),
      plankType: plankingMaterial
    });

    const plankStartPositions = calcCenterPositionsAlongLine({
      start: BL.clone().setY(0),
      end: startPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });
    const plankEndPositions = calcCenterPositionsAlongLine({
      start: FL.clone().setY(0),
      end: endPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });

    plankStartPositions.forEach((plankPosition, idx) => {
      const plankStartPosition = plankPosition.clone().setY(platform);
      const plankEndPosition = plankEndPositions[idx].clone().setY(platform);

      const matchingPlank = allDeckComponents.find(
        (component) =>
          component.length === depth &&
          component.width === optimalPlankConfiguration[idx] &&
          component.material === plankingMaterial
      );
      if (matchingPlank) {
        const plank: Plank = {
          id: genId(),
          position: plankStartPosition.toArray(),
          endPosition: plankEndPosition.toArray(),
          length: depth,
          width: matchingPlank.width,
          rotation: plankRotation,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingPlank.article_id
        };
        planks.push(plank);
      }
    });
  });

  splitFrames({
    framesToSplit,
    positions: planks
      .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return planks;
};

export const generateToeBoards = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  guardRailsOptions: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    guardRailsOptions,
    standardPositions,
    frameLevels
  } = props;

  const toeBoards: ToeBoard[] = [];

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];

  const longitudinalRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const transverseRotation = boxRotation;

  const longitudinalToeBoard = componentsToeBoards.find(
    (component) => component.length === depth
  );
  const transverseToeBoard = componentsToeBoards.find(
    (component) => component.length === width
  );

  const toeBoardData = [
    ...(guardRailsOptions[1]
      ? [
          {
            position: BR,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            position: BL,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            position: FR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            position: BR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : [])
  ];

  toeBoardData.forEach(({ position, rotation, component }) => {
    if (!component) return;
    const pos = position.clone();
    frameLevels.forEach(({ platform }) => {
      if (!platform) return;
      const toeBoard: ToeBoard = {
        id: genId(),
        position: pos.setY(plus(platform, TOE_BOARD_OFFSET)).toArray(),
        width: component.width,
        length: component.length,
        rotation,
        supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
        componentId: component.article_id
      };

      toeBoards.push(toeBoard);
    });
  });

  return toeBoards;
};

export const generateDiagonalBraces = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  diagonalBraceOptions: [boolean, boolean, boolean, boolean];
  framesToSplit: Frame[];
  diagonalPattern?: SCAFFOLD_DIAGONAL_PATTERN;
}) => {
  const {
    boxRotation,
    depth,
    width,
    frameLevels,
    diagonalPattern,
    diagonalBraceOptions,
    standardPositions,
    framesToSplit
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const diagonalBraces: DiagonalBrace[] = [];
  if (!diagonalPattern) return diagonalBraces;

  const longDiagonalRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const shortDiagonalRotation = boxRotation;

  const diagonalBraceData = [
    ...(diagonalBraceOptions[1]
      ? [
          {
            start: BR,
            end: FR,
            rotation: longDiagonalRotation,
            length: depth
          }
        ]
      : []),
    ...(diagonalBraceOptions[3]
      ? [
          {
            start: BL,
            end: FL,
            rotation: longDiagonalRotation,
            length: depth
          }
        ]
      : []),
    ...(diagonalBraceOptions[0]
      ? [
          {
            start: FR,
            end: FL,
            rotation: shortDiagonalRotation,
            length: width
          }
        ]
      : []),
    ...(diagonalBraceOptions[2]
      ? [
          {
            start: BR,
            end: BL,
            rotation: shortDiagonalRotation,
            length: width
          }
        ]
      : [])
  ];
  const sortedFrameLevels = frameLevels.sort((a, b) => b.height - a.height);
  const bottomLockHeight = plus(standardPositions[0].y, 0.4);

  diagonalBraceData.forEach(({ start, end, rotation, length }) => {
    sortedFrameLevels.forEach(({ height }, idx) => {
      const diagonalHeight = getDiagonalHeight({
        currentFrameIdx: idx,
        sortedFrames: sortedFrameLevels,
        lowestCollarHeight: bottomLockHeight
      });
      if (!diagonalHeight) return;

      const startPosition = start.clone().setY(plus(start.y, height));
      const endPosition = end.clone().setY(plus(end.y, height));
      switch (diagonalPattern) {
        case SCAFFOLD_DIAGONAL_PATTERN.CROSSDIAGONAL:
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation,
              flipped: true
            })
          );
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.EVERYOTHER:
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation,
              flipped: idx % 2 === 0
            })
          );
          break;

        case SCAFFOLD_DIAGONAL_PATTERN.EVERYOTHERREVERSE:
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation,
              flipped: idx % 2 !== 0
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.INWARDDIAGONAL:
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation,
              flipped: true
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.OUTWARDDIAGONAL:
        default:
          diagonalBraces.push(
            ...generateDiagonalBrace({
              startPosition,
              endPosition,
              length,
              height: diagonalHeight,
              rotation
            })
          );
      }
    });
  });

  /** Split frames with end and start positions*/
  splitFrames({
    framesToSplit,
    positions: diagonalBraces
      .map((diagonalBrace) => [
        diagonalBrace.position,
        diagonalBrace.endPosition ?? [0, 0, 0]
      ])
      .flat()
  });

  return diagonalBraces;
};

export const generateConsoleDiagonalBracketBrace = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
  flipped?: boolean;
  length: number;
  rotation: Box["rotation"];
  height: number;
  width: number;
}) => {
  const { length, rotation, flipped, height, width } = props;
  const component = componentsConsoleBracketBraces.find(
    (diagonalBrace) => diagonalBrace.width === width
  );
  if (!component) return [];

  let endPosition = props.endPosition.clone();
  let startPosition = props.startPosition.clone();
  startPosition.setY(minus(startPosition.y, height));

  const diagonalBraceAngle = Math.atan(height / length);

  const diagonalBraceRotation = [
    rotation[0],
    flipped ? plus(rotation[1], Math.PI) : rotation[1],
    plus(rotation[2], diagonalBraceAngle)
  ];

  const diagonalBrace: DiagonalBrace = {
    id: genId(),
    position: startPosition.toArray(),
    endPosition: endPosition.toArray(),
    length: startPosition.distanceTo(endPosition),
    rotation: diagonalBraceRotation as Vector3Tuple,
    supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
    componentId: component.article_id
  };

  return [diagonalBrace];
};

export const generateDiagonalBrace = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
  flipped?: boolean;
  length: number;
  rotation: Box["rotation"];
  height: number;
}) => {
  const { length, rotation, height, flipped } = props;
  const component = componentsDiagonalBraces.find(
    (diagonalBrace) =>
      diagonalBrace.length === length && diagonalBrace.width === height
  );
  if (!component) return [];

  let endPosition = flipped
    ? props.startPosition.clone()
    : props.endPosition.clone();
  let startPosition = flipped
    ? props.endPosition.clone()
    : props.startPosition.clone();
  startPosition.setY(minus(startPosition.y, height));

  const diagonalBraceAngle = Math.atan(height / length);

  const diagonalBraceRotation = [
    rotation[0],
    flipped ? plus(rotation[1], Math.PI) : rotation[1],
    plus(rotation[2], diagonalBraceAngle)
  ];

  const diagonalBrace: DiagonalBrace = {
    id: genId(),
    position: startPosition.toArray(),
    endPosition: endPosition.toArray(),
    length: startPosition.distanceTo(endPosition),
    rotation: diagonalBraceRotation as Vector3Tuple,
    supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
    componentId: component.article_id
  };

  return [diagonalBrace];
};

export const generateBaseComponents = (props: {
  frameLengths: {
    positions: {
      left: Vector3;
      right: Vector3;
    };
    frameLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  rayHitsYPosition: [number, number, number, number];
}) => {
  const { frameLengths, boxRotation, boxOptions, rayHitsYPosition } = props;

  const baseBoards: BaseBoard[] = [];
  const basePlates: BasePlate[] = [];

  frameLengths.forEach(({ frameLength, positions }, index) => {
    if (!boxOptions || !boxOptions.grounded) return;
    const grounded = boxOptions.grounded[index];

    if (!grounded || !grounded.isGrounded) return;
    const leftIndex = index === 0 ? 0 : 3;
    const rightIndex = index === 0 ? 1 : 2;

    /** Calculate if we can bridge gap between frame left and right,
     * if not, we have to take the highest ray hit position  */
    const leftRayHitY = rayHitsYPosition[leftIndex];
    const rightRayHitY = rayHitsYPosition[rightIndex];
    const topRayHitPositionY =
      leftRayHitY > rightRayHitY ? leftRayHitY : rightRayHitY;

    const rayHitDiff = Math.abs(leftRayHitY - rightRayHitY);
    const longestBasePlate = componentsBaseplates.reduce((prev, current) =>
      prev.length > current.length ? prev : current
    );

    const basePlateLength = longestBasePlate ? longestBasePlate.length : 0;
    const isGapTooBig = rayHitDiff > basePlateLength;

    const leftPosition = positions.left
      .clone()
      .setY(isGapTooBig ? topRayHitPositionY : rayHitsYPosition[leftIndex]);
    const rightPosition = positions.right
      .clone()
      .setY(isGapTooBig ? topRayHitPositionY : rayHitsYPosition[rightIndex]);

    const startEndPositions = [
      {
        groundPos: leftPosition,
        frameStartPos: positions.left
          .clone()
          .setY(positions.left.y + frameLength.remainingLength)
      },
      {
        groundPos: rightPosition,
        frameStartPos: positions.right
          .clone()
          .setY(positions.right.y + frameLength.remainingLength)
      }
    ];
    const basePlateRotation = [
      boxRotation[0],
      boxRotation[1],
      plus(boxRotation[2], halfPi)
    ] as Vector3Tuple;

    const baseBoardRotation = boxRotation;

    startEndPositions.forEach(({ groundPos, frameStartPos }) => {
      const matchingBaseBoard = componentsBaseBoards[0];
      if (matchingBaseBoard) {
        const baseBoard: BaseBoard = {
          id: genId(),
          position: groundPos.toArray(),
          rotation: baseBoardRotation,
          length: matchingBaseBoard.length,
          width: matchingBaseBoard.width,
          componentId: matchingBaseBoard.article_id,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM
        };
        baseBoards.push(baseBoard);
      }
      const startPosition = groundPos
        .clone()
        .setY(plus(groundPos.y, BASE_BOARD_HEIGHT));
      const minBasePlateLength = startPosition
        .clone()
        .distanceTo(frameStartPos);
      const matchingBasePlates = componentsBaseplates.filter(
        (bp) => bp.length > minBasePlateLength
      );

      if (matchingBasePlates.length > 0) {
        const shortestBasePlate = matchingBasePlates.reduce((prev, current) =>
          prev.length < current.length ? prev : current
        );
        const basePlate: BasePlate = {
          id: genId(),
          position: startPosition.toArray(),
          endPosition: frameStartPos.clone().toArray(),
          rotation: basePlateRotation as Vector3Tuple,
          length: shortestBasePlate.length,
          spindleNutHeight: minBasePlateLength,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: shortestBasePlate.article_id,
          grounded: true
        };
        basePlates.push(basePlate);
      }
    });
  });

  return {
    baseBoards,
    basePlates
  };
};

const ANCHOR_HEIGHT_OFFSET = -0.4;
export const generateFrameAnchors = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  anchorOptions: [boolean, boolean, boolean, boolean];
  boxRotation: Box["rotation"];
  anchorLevels: number[];
  framesToSplit: Frame[];
}) => {
  const {
    standardPositions,
    boxRotation,
    anchorLevels,
    framesToSplit,
    anchorOptions
  } = props;

  const bayLengthDirectionRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const anchors: Anchor[] = [];
  const couplers: Coupler[] = [];
  const screws: Screw[] = [];

  const anchorSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
  }[] = [
    ...(anchorOptions[1]
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[3]
      ? [
          {
            left: FL,
            right: BL,
            rotation: boxRotation
          }
        ]
      : []),
    ...(anchorOptions[0]
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[2]
      ? [
          {
            left: BL,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : [])
  ];

  const matchingAnchor = componentsAnchors[0];
  const matchingCoupler = componentsCouplers[0];
  const matchingScrew = componentsScrews[0];

  if (matchingAnchor && matchingCoupler) {
    anchorSides.forEach(({ left, right, rotation }) => {
      anchorLevels.forEach((height) => {
        const leftCouplerStartPosition = [
          left.x,
          height + ANCHOR_HEIGHT_OFFSET,
          left.z
        ] as Vector3Tuple;
        const leftCouplerEndPosition = getEndPointFromStartPoint({
          startPosition: leftCouplerStartPosition,
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation
        });
        const leftAnchorStartPosition = leftCouplerEndPosition.toArray();

        const leftAnchorEndPosition = getEndPointFromStartPoint({
          startPosition: leftAnchorStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        const rightCouplerStartPosition = [
          right.x,
          height + ANCHOR_HEIGHT_OFFSET,
          right.z
        ] as Vector3Tuple;

        const rightCouplerEndPosition = getEndPointFromStartPoint({
          startPosition: rightCouplerStartPosition,
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation
        });
        const rightAnchorStartPosition = rightCouplerEndPosition.toArray();

        const rightAnchorEndPosition = getEndPointFromStartPoint({
          startPosition: rightAnchorStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        if (matchingScrew) {
          const rightScrewEndPosition = getEndPointFromStartPoint({
            startPosition: rightAnchorEndPosition.toArray(),
            length: matchingScrew.length,
            rotation
          });

          const leftScrewEndPosition = getEndPointFromStartPoint({
            startPosition: leftAnchorEndPosition.toArray(),
            length: matchingScrew.length,
            rotation
          });

          const leftScrew: Screw = {
            id: genId(),
            position: leftAnchorEndPosition.toArray(),
            endPosition: leftScrewEndPosition.toArray(),
            length: matchingScrew.length,
            rotation: rotation as Vector3Tuple,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingScrew.article_id
          };
          const rightScrew: Screw = {
            id: genId(),
            position: rightAnchorEndPosition.toArray(),
            endPosition: rightScrewEndPosition.toArray(),
            length: matchingScrew.length,
            rotation: rotation as Vector3Tuple,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingScrew.article_id
          };

          screws.push(leftScrew, rightScrew);
        }

        const leftCoupler: Coupler = {
          id: genId(),
          position: leftCouplerStartPosition,
          endPosition: leftCouplerEndPosition.toArray(),
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingCoupler.article_id
        };
        const rightCoupler: Coupler = {
          id: genId(),
          position: rightCouplerStartPosition,
          endPosition: rightCouplerEndPosition.toArray(),
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingCoupler.article_id
        };
        couplers.push(leftCoupler, rightCoupler);

        const leftAnchor: Anchor = {
          id: genId(),
          position: leftAnchorStartPosition,
          endPosition: leftAnchorEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingAnchor.article_id,
          grounded: true
        };
        const rightAnchor: Anchor = {
          id: genId(),
          position: rightAnchorStartPosition,
          endPosition: rightAnchorEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingAnchor.article_id,
          grounded: true
        };

        anchors.push(leftAnchor, rightAnchor);
      });
    });
  }

  /** Split frames with end and start positions*/
  splitFrames({
    framesToSplit,
    positions: anchors
      .map((anchor) => [anchor.position, anchor.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { anchors, couplers, screws };
};

export const generateConsoleComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  consoleLevels: BoxConsole[];
  consoleWidth?: number;
  consoleOptions?: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    consoleLevels,
    standardPositions,
    consoleWidth,
    consoleOptions
  } = props;

  const consoles: Console[] = [];
  const planks: Plank[] = [];
  const diagonalBraces: DiagonalBrace[] = [];

  /** Consoles or not */
  const [FC, RC, BC, LC] = consoleOptions ?? [false, false, false, false];

  if (!consoleWidth) return { consoles, planks, diagonalBraces };

  const consoleMatch = componentsConsoles.find(
    (component) => component.width === consoleWidth
  );

  if (!consoleMatch) return { consoles, planks, diagonalBraces };

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const bayLengthDir = FR.clone()
    .setY(0)
    .sub(BR.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const bayWidthDir = BR.clone()
    .setY(0)
    .sub(BL.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const consoleSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
    dirVector: Vector3;
    length: number;
  }[] = [
    ...(FC
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone(),
            length: width
          }
        ]
      : []),
    ...(BC
      ? [
          {
            left: BR,
            right: BL,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone().multiplyScalar(-1),
            length: width
          }
        ]
      : []),
    ...(LC
      ? [
          {
            left: BL,
            right: FL,
            rotation: boxRotation,
            dirVector: bayWidthDir.clone().multiplyScalar(-1),
            length: depth
          }
        ]
      : []),
    ...(RC
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayWidthDir.clone(),
            length: depth
          }
        ]
      : [])
  ];

  consoleSides.forEach(({ left, right, rotation, dirVector, length }) => {
    consoleLevels.forEach(({ height, plankingMaterial }) => {
      /** Consoles */
      [left, right].forEach((pos) => {
        const consoleEndPos = getEndPointFromStartPoint({
          startPosition: [pos.x, height, pos.z],
          length: consoleMatch.width,
          rotation
        });
        const console: Console = {
          id: genId(),
          position: pos.clone().setY(height).toArray(),
          length: consoleMatch.width,
          endPosition: consoleEndPos.toArray(),
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: consoleMatch.article_id,
          ...(consoleMatch.variant && { variant: consoleMatch.variant })
        };

        diagonalBraces.push(
          ...generateConsoleDiagonalBracketBrace({
            height: 2,
            startPosition: pos.clone().setY(height),
            endPosition: consoleEndPos,
            length: consoleMatch.width,
            rotation,
            width: consoleWidth
          })
        );

        consoles.push(console);
      });

      /** Console plank */
      const consolePlankRotation = [
        rotation[0],
        minus(rotation[1], halfPi),
        rotation[2]
      ] as Vector3Tuple;
      const optimalPlankConfiguration = generateOptimalPlankConfiguration({
        length,
        width: consoleWidth,
        plankType: plankingMaterial
      });
      const consolePlankPositions = calcCenterPositionsAlongLine({
        start: left.clone().setY(0),
        end: left.clone().setY(0).add(dirVector),
        partitions: optimalPlankConfiguration,
        spacing: 0.01
      });
      consolePlankPositions.forEach((plankPosition, idx) => {
        const matchingPlank = componentsDecks.find(
          (component) =>
            component.length === length &&
            component.width === optimalPlankConfiguration[idx] &&
            component.material === plankingMaterial
        );
        if (matchingPlank) {
          const plank: Plank = {
            id: genId(),
            position: plankPosition.clone().setY(height).toArray(),
            length: length,
            endPosition: getEndPointFromStartPoint({
              startPosition: [plankPosition.x, height, plankPosition.z],
              length,
              rotation: consolePlankRotation
            }).toArray(),
            width: matchingPlank.width,
            rotation: consolePlankRotation,
            supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
            componentId: matchingPlank.article_id
          };
          planks.push(plank);
        }
      });
    });
  });

  splitConsoles({
    consolesToSplit: consoles,
    positions: planks
      .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { consoles, planks, diagonalBraces };
};

export const generateStairComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frameLevels: BoxFrame[];
  stairwayGuardRail?: boolean;
  stairwayInnerGuardRail?: boolean;
}) => {
  const {
    boxRotation,
    depth,
    frameLevels,
    standardPositions,
    stairwayGuardRail,
    width
  } = props;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FL = standardPositions[0];

  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;

  /** Stairs */
  const dir = BR.clone().setY(0).sub(BL.clone().setY(0)).normalize();
  const stairways: Stairway[] = [];
  const stairwayGuardRails: StairwayGuardRail[] = [];
  const stairwayInnerGuardRails: StairwayInnerGuardRail[] = [];
  const bayLengthDir = FL.clone().setY(0).sub(BL.clone().setY(0)).normalize();

  const startVector = BR.clone()
    .setY(0)
    .sub(
      BR.clone()
        .setY(0)
        .sub(BL.clone().setY(0))
        .normalize()
        .multiplyScalar(stairWidth)
    );

  const stairPosition = calcCenterPositionsAlongLine({
    start: startVector,
    end: BR.clone().setY(0),
    partitions: [stairWidth],
    spacing: 0.01
  });

  const smallestAllowedBayWidth = Math.min(
    ...componentsFrames
      .filter((frame) => frame.width >= stairWidth)
      .map((frame) => frame.width)
  );
  const isSmallestBayWidth = width === smallestAllowedBayWidth;

  const sortedFrameLevels = frameLevels.sort((a, b) => b.height - a.height);

  sortedFrameLevels.forEach(({ height }, idx) => {
    const suitableStair = getStair({
      sortedFrames: sortedFrameLevels,
      currentFrameIdx: idx,
      lowestCollarHeight: 0,
      length: depth
    });
    if (!suitableStair) return;

    const stairHeight = suitableStair.height ?? DEFAULT_STAIR_HEIGHT;

    const matchingInternalStairwayGuardRail =
      componentsStairwayGuardrailsInternal.find(
        (component) => component.height === stairHeight
      );
    const matchingStairwayGuardRail = componentsStairwayGuardrails.find(
      (component) =>
        component.length === depth && component.height === stairHeight
    );

    const matchingGuardRailComponent = isSmallestBayWidth
      ? matchingStairwayGuardRail
      : matchingInternalStairwayGuardRail;

    stairPosition.forEach((stairPosition) => {
      const moddedStairStartPosition = stairPosition
        .clone()
        .setY(height - stairHeight)
        .add(bayLengthDir.clone().multiplyScalar(depth - suitableStair.length));
      const stairway: Stairway = {
        id: genId(),
        position: roundVector(moddedStairStartPosition).toArray(),
        length: suitableStair.length,
        height: stairHeight,
        width: suitableStair.width,
        rotation: boxRotation,
        supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
        componentId: suitableStair.article_id
      };
      stairways.push(stairway);

      if (matchingGuardRailComponent && stairwayGuardRail) {
        const startPos = isSmallestBayWidth
          ? BL.clone()
          : moddedStairStartPosition
              .clone()
              .sub(dir.clone().multiplyScalar(half(suitableStair.width)))
              .add(bayLengthDir.clone().multiplyScalar(half(depth)));
        const stairwayGuardRail: StairwayGuardRail = {
          id: genId(),
          position: roundVector(
            startPos.setY(height - stairHeight + 0.5)
          ).toArray(),
          length: matchingGuardRailComponent.length,
          height: matchingGuardRailComponent.height ?? DEFAULT_STAIR_HEIGHT,
          rotation: boxRotation,
          supplier: SCAFFOLD_SUPPLIER.MOSTOSTALRAM,
          componentId: matchingGuardRailComponent.article_id,
          ...(matchingGuardRailComponent.variant && {
            variant: matchingGuardRailComponent.variant
          })
        };
        stairwayGuardRails.push(stairwayGuardRail);
      }
    });
  });
  return { stairways, stairwayInnerGuardRails, stairwayGuardRails };
};

export const generatePassageComponents = (props: {
  frameLengths: {
    positions: {
      left: Vector3;
      right: Vector3;
    };
    frameLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxPosition: Box["position"];
  topOuterStandardPositions: [Vector3, Vector3];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  rayHitsYPosition: [number, number, number, number];
  baseWidth: number;
  depth: number;
}) => {
  const {
    frameLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition,
    baseWidth,
    standardPositions,
    depth,
    boxPosition
  } = props;

  const baseHeight = boxOptions?.baseHeight ?? 0;

  let startHeight = boxPosition[1];
  const worldBaseHeight = baseHeight + boxPosition[1];

  const frames: Frame[] = [];

  /** Generate base passage. Standards, base plates, base boards, base collars, "riddare", ledgers (reinforced), guard rails */
  const { baseBoards, basePlates } = generateBaseComponents({
    frameLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition
  });

  const bottomFrames = generateFrames({
    frameLengths,
    frameWidth: baseWidth,
    boxRotation,
    passageBase: true
  });
  frames.push(...bottomFrames.frames);

  /** Generate reinforced base ledgers */
  const { ledgers } = generateFrameLedgers({
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    frameLevels: [{ height: worldBaseHeight }],
    framesToSplit: [],
    boxId: ""
  });

  const maxStandardStartHeight = Math.max(...standardPositions.map((p) => p.y));
  startHeight = maxStandardStartHeight + 0.4;

  const guardRailFrameLevels: BoxFrame[] = [];
  for (let i = worldBaseHeight - 1; i >= startHeight; i -= 1.5) {
    const bottomGuardRailHeight = i;
    guardRailFrameLevels.push({
      height: bottomGuardRailHeight - DEFAULT_FALL_PROTECTION_HEIGHT,
      platform: bottomGuardRailHeight - DEFAULT_FALL_PROTECTION_HEIGHT
    });
  }

  /** Longitudinal Guard rails */
  const passageGuardRails = generateGuardRails({
    guardRailsOptions: [false, true, false, true],
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    fallProtectionHeight: DEFAULT_FALL_PROTECTION_HEIGHT,
    frameLevels: guardRailFrameLevels,
    framesToSplit: []
  });

  return {
    baseBoards,
    basePlates,
    frames,
    ledgers: [...ledgers, ...passageGuardRails.ledgers],
    guardRails: passageGuardRails.guardRails
  };
};

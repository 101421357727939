import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Coupler } from "world/core/Coupler/Coupler.types";
import { getGraphData } from "world/core/Coupler/Coupler.utils";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addCouplersToGraph = (props: {
  graph: Graph;
  couplers: Coupler[];
}) => {
  const { graph, couplers } = props;

  const couplersGraphData = couplers.map((coupler) => getGraphData(coupler));

  addComponentsToGraph({ graph, components: couplersGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeCouplersFromGraph = (props: {
  graph: Graph;
  couplers: Coupler[];
}) => {
  const { graph, couplers } = props;

  const couplersGraphData = couplers.map((coupler) => getGraphData(coupler));

  removeComponentsFromGraph({
    graph,
    components: couplersGraphData,
    force: true
  });
};

export const setCouplersInGraph = (props: {
  graph: Graph;
  newCouplers: Coupler[];
  oldCouplers: Coupler[];
}) => {
  const { graph, newCouplers, oldCouplers } = props;
  removeCouplersFromGraph({
    graph,
    couplers: oldCouplers
  });
  addCouplersToGraph({
    graph,
    couplers: newCouplers
  });
};

import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsBoxPassageSlice } from "./boxPassage.types";
import { SCAFFOLD_PLANKTYPE, SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { degToRad } from "three/src/math/MathUtils";
import { INITIAL_COMPONENT_VARIANT } from "shared/types/box";

/** Initial state */
const initialToolsBoxPassageState = {
  toolsBoxPassageBaseHeight: 4,
  toolsBoxPassageHeight: 10,
  toolsBoxPassageBaseWidth: 2.57,
  toolsBoxPassageWidth: 0.73,
  toolsBoxPassageDepth: 2.57,
  toolsBoxPassageConsoleWidth: 0.39,
  toolsBoxPassageFallProtectionHeight: 1,
  toolsBoxPassageFallProtectionNet: false,
  toolsBoxPassageInitialComponentVariant: INITIAL_COMPONENT_VARIANT.BASE_COLLAR,
  toolsBoxPassageTopVariant: null,
  toolsBoxPassageGuardRails: [true, false, true, true] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxPassageConsoles: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxPassageDiagonals: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxPassageAnchors: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxPassageDiagonalPattern: null,
  toolsBoxPassageFrames: [10, 8, 6],
  toolsBoxPassagePlatforms: [10, 8, 6],
  toolsBoxPassageConsoleLevels: [10, 8, 6],
  toolsBoxPassageAnchorLevels: [10, 8, 6],
  toolsBoxPassagePlankMaterial: SCAFFOLD_PLANKTYPE.STEEL,
  toolsBoxPassageDistanceToWall: 0.3,
  toolsBoxPassageDistanceToWallEnabled: true,
  toolsBoxPassageSupplier: SCAFFOLD_SUPPLIER.LAYHER,
  toolsBoxPassageRotationY: null,
  toolsBoxPassageMaxBoxes: 50,
  toolsBoxPassageRotationStep: degToRad(15)
};

const createToolsBoxPassageSlice: StateCreator<
  ToolsBoxPassageSlice,
  [],
  [],
  ToolsBoxPassageSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsBoxPassageState));

  /** Return state */
  return {
    ...initialToolsBoxPassageState,
    toolsBoxPassageActions: {
      setToolsBoxPassageHeight: (height) => {
        set({ toolsBoxPassageHeight: height });
      },
      setToolsBoxPassageBaseHeight: (height) => {
        set({ toolsBoxPassageBaseHeight: height });
      },
      setToolsBoxPassageDepth: (depth) => {
        set({ toolsBoxPassageDepth: depth });
      },
      setToolsBoxPassageWidth: (width) => {
        set({ toolsBoxPassageWidth: width });
      },
      setToolsBoxPassageBaseWidth: (width) => {
        set({ toolsBoxPassageBaseWidth: width });
      },
      setToolsBoxPassageGuardRails: (options) => {
        set({ toolsBoxPassageGuardRails: options });
      },
      setToolsBoxPassageFrames: (frames) => {
        set({ toolsBoxPassageFrames: frames });
      },
      setToolsBoxPassagePlatforms: (platforms) => {
        set({ toolsBoxPassagePlatforms: platforms });
      },
      setToolsBoxPassageInitialComponentVariant: (variant) => {
        set({ toolsBoxPassageInitialComponentVariant: variant });
      },
      setToolsBoxPassagePlankMaterial: (material) => {
        set({ toolsBoxPassagePlankMaterial: material });
      },
      setToolsBoxPassageConsoles: (options) => {
        set({ toolsBoxPassageConsoles: options });
      },
      setToolsBoxPassageConsoleWidth: (width) => {
        set({ toolsBoxPassageConsoleWidth: width });
      },
      setToolsBoxPassageFallProtectionHeight: (height) => {
        set({ toolsBoxPassageFallProtectionHeight: height });
      },
      setToolsBoxPassageFallProtectionNet: (net) => {
        set({ toolsBoxPassageFallProtectionNet: net });
      },
      setToolsBoxPassageTopVariant: (variant) => {
        set({ toolsBoxPassageTopVariant: variant });
      },
      setToolsBoxPassageRotationY: (rotation) => {
        set({ toolsBoxPassageRotationY: rotation });
      },
      setToolsBoxPassageDistanceToWall: (distance) => {
        set({ toolsBoxPassageDistanceToWall: distance });
      },
      setToolsBoxPassageConsoleLevels: (levels) => {
        set({ toolsBoxPassageConsoleLevels: levels });
      },
      setToolsBoxPassageAnchorLevels: (levels) => {
        set({ toolsBoxPassageAnchorLevels: levels });
      },
      setToolsBoxPassageSupplier: (supplier) => {
        set({ toolsBoxPassageSupplier: supplier });
      },
      setToolsBoxPassageDistanceToWallEnabled: (distanceToWallEnabled) => {
        set({ toolsBoxPassageDistanceToWallEnabled: distanceToWallEnabled });
      },
      setToolsBoxPassageDiagonalPattern: (pattern) => {
        set({ toolsBoxPassageDiagonalPattern: pattern });
      },
      setToolsBoxPassageDiagonals: (options) => {
        set({ toolsBoxPassageDiagonals: options });
      },
      setToolsBoxPassageAnchors: (options) => {
        set({ toolsBoxPassageAnchors: options });
      },
      setToolsBoxPassageMaxBoxes: (maxBoxes) => {
        set({ toolsBoxPassageMaxBoxes: maxBoxes });
      },
      setToolsBoxPassageRotationStep: (rotationStep) => {
        set({ toolsBoxPassageRotationStep: rotationStep });
      }
    }
  };
};

export default createToolsBoxPassageSlice;

import { Euler, Vector3, Vector3Tuple } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { BeamSpigot } from "./BeamSpigot.types";
import { plus } from "math";
const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();
export const getGraphData = (beamSpigot: BeamSpigot): GraphData => {
  const { length, position, rotation } = beamSpigot;
  const endPosition = [
    position[0],
    plus(position[1], length),
    position[2]
  ] as Vector3Tuple;

  return {
    startPosition: position,
    endPosition: endPosition,
    id: beamSpigot.id,
    type: ComponentType.BEAM_SPIGOT,
    metadata: {
      elementVector: ELEMENT_VECTOR.set(0, 0, 1)
        .applyEuler(EULER.fromArray(rotation))
        .toArray()
    }
  };
};

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "../scaffold.interface";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";

export const componentsAnchors: Component[] = [
  {
    width: 0,
    label_se: "Väggfäste",
    material: "",
    label_en: "Wall tie",
    weight: 3.4,
    length: 0.8,
    label_no: "Veggfeste",
    article_id: "75080"
  },
  {
    width: 0,
    label_se: "Väggfäste",
    material: "",
    label_en: "Wall tie",
    weight: 3.2,
    length: 0.6,
    label_no: "Veggfeste",
    article_id: "75060"
  },
  {
    width: 0,
    label_se: "Väggfäste",
    material: "",
    label_en: "Wall tie",
    weight: 2.8,
    length: 0.4,
    label_no: "Veggfeste",
    article_id: "75040"
  },
  {
    width: 0,
    label_se: "Väggfäste",
    material: "",
    label_en: "Wall tie",
    weight: 2.5,
    length: 0.3,
    label_no: "Veggfeste",
    article_id: "75030"
  }
];
export const componentsBaseplates: Component[] = [
  {
    length: 0.8,
    width: 0,
    label_se: "Bottenskruv",
    material: "",
    label_no: "Bunnskrue",
    article_id: "99980",
    label_en: "Bottom screw",
    weight: 4.5
  }
];
export const componentsConsoles: Component[] = [
  {
    width: 0.7,
    label_no: "Konsol 0,7",
    weight: 6.5,
    label_se: "Konsol 0,7",
    label_en: "Console 0,7",
    material: "",
    length: 0,
    article_id: "705070",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    width: 0.4,
    label_no: "Konsol 0,4",
    weight: 2.9,
    label_se: "Konsol 0,4",
    label_en: "Console 0,4",
    material: "",
    length: 0,
    article_id: "705040",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    width: 0.3,
    label_no: "Konsol 0,3",
    weight: 2.1,
    label_se: "Konsol 0,3",
    label_en: "Console 0,3",
    material: "",
    length: 0,
    article_id: "705030",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    width: 0.2,
    label_no: "Konsol 0,2",
    weight: 1.9,
    label_se: "Konsol 0,2",
    label_en: "Console 0,2",
    material: "",
    length: 0,
    article_id: "705020"
  }
];
export const componentsDecks: Component[] = [
  {
    article_id: "50300",
    width: 0.29,
    length: 3,
    label_en: "Steel plank for O-ledger 290mm wide 3m long",
    label_se: "Stålplank för O-bom 290mm bred 3m lång",
    label_no: "Stålplate for O-bom 290mm bred 3m lang",
    weight: 18.4,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50250",
    width: 0.29,
    length: 2.5,
    label_en: "Steel plank for O-ledger 290mm wide 2.5m long",
    label_se: "Stålplank för O-bom 290mm bred 2.5m lång",
    label_no: "Stålplate for O-bom 290mm bred 2.5m lang",
    weight: 16,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50200",
    width: 0.29,
    length: 2,
    label_en: "Steel plank for O-ledger 290mm wide 2m long",
    label_se: "Stålplank för O-bom 290mm bred 2m lång",
    label_no: "Stålplate for O-bom 290mm bred 2m lang",
    weight: 14,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50175",
    width: 0.29,
    length: 1.75,
    label_en: "Steel plank for O-ledger 290mm wide 1.75m long",
    label_se: "Stålplank för O-bom 290mm bred 1.75m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.75m lang",
    weight: 11,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50150",
    width: 0.29,
    length: 1.5,
    label_en: "Steel plank for O-ledger 290mm wide 1.5m long",
    label_se: "Stålplank för O-bom 290mm bred 1.5m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.5m lang",
    weight: 9.5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50125",
    width: 0.29,
    length: 1.25,
    label_en: "Steel plank for O-ledger 290mm wide 1.25m long",
    label_se: "Stålplank för O-bom 290mm bred 1.25m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.25m lang",
    weight: 8.5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50100",
    width: 0.29,
    length: 1,
    label_en: "Steel plank for O-ledger 290mm wide 1m long",
    label_se: "Stålplank för O-bom 290mm bred 1m lång",
    label_no: "Stålplate for O-bom 290mm bred 1m lang",
    weight: 7,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50700",
    width: 0.29,
    length: 0.7,
    label_en: "Steel plank for O-ledger 290mm wide 0.7m long",
    label_se: "Stålplank för O-bom 290mm bred 0.7m lång",
    label_no: "Stålplate for O-bom 290mm bred 0.7m lang",
    weight: 5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "Z-116300",
    width: 0.3,
    length: 3,
    label_en: "Aluminium plank for O-ledger 300mm wide 3m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 3m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 3m lang",
    weight: 12.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116250",
    width: 0.3,
    length: 2.5,
    label_en: "Aluminium plank for O-ledger 300mm wide 2.5m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 2.5m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 2.5m lang",
    weight: 9.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116200",
    width: 0.3,
    length: 2,
    label_en: "Aluminium plank for O-ledger 300mm wide 2m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 2m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 2m lang",
    weight: 8.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116175",
    width: 0.3,
    length: 1.75,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.75m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.75m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.75m lang",
    weight: 7.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116150",
    width: 0.3,
    length: 1.5,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.5m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.5m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.5m lang",
    weight: 6.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116125",
    width: 0.3,
    length: 1.25,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.25m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.25m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.25m lang",
    weight: 5.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116100",
    width: 0.3,
    length: 1,
    label_en: "Aluminium plank for O-ledger 300mm wide 1m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1m lang",
    weight: 4.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116100",
    width: 0.3,
    length: 0.7,
    label_en: "Aluminium plank for O-ledger 300mm wide 0.7m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 0.7m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 0.7m lang",
    weight: 3.4,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111300",
    width: 0.6,
    length: 3,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 3m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 3m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 3m lang",
    weight: 19.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111250",
    width: 0.6,
    length: 2.5,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 2.5m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 2.5m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 2.5m lang",
    weight: 16.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111200",
    width: 0.6,
    length: 2,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 2m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 2m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 2m lang",
    weight: 13.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111180",
    width: 0.6,
    length: 1.75,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.75m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.75m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.75m lang",
    weight: 12.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111150",
    width: 0.6,
    length: 1.5,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.5m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.5m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.5m lang",
    weight: 10.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111125",
    width: 0.6,
    length: 1.25,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.25m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.25m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.25m lang",
    weight: 8.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111100",
    width: 0.6,
    length: 1,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1m lang",
    weight: 7.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111070",
    width: 0.6,
    length: 0.7,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 0.7m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 0.7m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 0.7m lang",
    weight: 5.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116307",
    width: 0.32,
    length: 3.03,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 3.03m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 3.03m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 3.03m lang",
    weight: 13.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116257",
    width: 0.32,
    length: 2.53,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 2.53m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 2.53m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 2.53m lang",
    weight: 11.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116207",
    width: 0.32,
    length: 2.03,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 2.03m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 2.03m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 2.03m lang",
    weight: 9.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116157",
    width: 0.32,
    length: 1.53,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 1.53m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 1.53m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 1.53m lang",
    weight: 7.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116109",
    width: 0.32,
    length: 1.05,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 1.05m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 1.05m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 1.05m lang",
    weight: 5.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116073",
    width: 0.32,
    length: 0.69,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 0.69m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 0.69m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 0.69m lang",
    weight: 3.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z111307",
    width: 0.61,
    length: 3.03,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 3.03m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 3.03m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 3.03m lang",
    weight: 21.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116257",
    width: 0.61,
    length: 2.53,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 2.53m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 2.53m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 2.53m lang",
    weight: 17.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116207",
    width: 0.61,
    length: 2.03,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 2.03m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 2.03m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 2.03m lang",
    weight: 14.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116157",
    width: 0.61,
    length: 1.53,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 1.53m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 1.53m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 1.53m lang",
    weight: 11.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116109",
    width: 0.61,
    length: 1.05,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 1.05m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 1.05m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 1.05m lang",
    weight: 8.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116073",
    width: 0.61,
    length: 0.69,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 0.69m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 0.69m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 0.69m lang",
    weight: 6.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "50300",
    width: 0.29,
    length: 3,
    label_en: "Steel plank for O-ledger 290mm wide 3m long",
    label_se: "Stålplank för O-bom 290mm bred 3m lång",
    label_no: "Stålplate for O-bom 290mm bred 3m lang",
    weight: 18.4,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50250",
    width: 0.29,
    length: 2.5,
    label_en: "Steel plank for O-ledger 290mm wide 2.5m long",
    label_se: "Stålplank för O-bom 290mm bred 2.5m lång",
    label_no: "Stålplate for O-bom 290mm bred 2.5m lang",
    weight: 16,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50200",
    width: 0.29,
    length: 2,
    label_en: "Steel plank for O-ledger 290mm wide 2m long",
    label_se: "Stålplank för O-bom 290mm bred 2m lång",
    label_no: "Stålplate for O-bom 290mm bred 2m lang",
    weight: 14,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50175",
    width: 0.29,
    length: 1.75,
    label_en: "Steel plank for O-ledger 290mm wide 1.75m long",
    label_se: "Stålplank för O-bom 290mm bred 1.75m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.75m lang",
    weight: 11,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50150",
    width: 0.29,
    length: 1.5,
    label_en: "Steel plank for O-ledger 290mm wide 1.5m long",
    label_se: "Stålplank för O-bom 290mm bred 1.5m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.5m lang",
    weight: 9.5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50125",
    width: 0.29,
    length: 1.25,
    label_en: "Steel plank for O-ledger 290mm wide 1.25m long",
    label_se: "Stålplank för O-bom 290mm bred 1.25m lång",
    label_no: "Stålplate for O-bom 290mm bred 1.25m lang",
    weight: 8.5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50100",
    width: 0.29,
    length: 1,
    label_en: "Steel plank for O-ledger 290mm wide 1m long",
    label_se: "Stålplank för O-bom 290mm bred 1m lång",
    label_no: "Stålplate for O-bom 290mm bred 1m lang",
    weight: 7,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "50700",
    width: 0.29,
    length: 0.7,
    label_en: "Steel plank for O-ledger 290mm wide 0.7m long",
    label_se: "Stålplank för O-bom 290mm bred 0.7m lång",
    label_no: "Stålplate for O-bom 290mm bred 0.7m lang",
    weight: 5,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "Z-116300",
    width: 0.3,
    length: 3,
    label_en: "Aluminium plank for O-ledger 300mm wide 3m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 3m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 3m lang",
    weight: 12.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116250",
    width: 0.3,
    length: 2.5,
    label_en: "Aluminium plank for O-ledger 300mm wide 2.5m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 2.5m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 2.5m lang",
    weight: 9.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116200",
    width: 0.3,
    length: 2,
    label_en: "Aluminium plank for O-ledger 300mm wide 2m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 2m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 2m lang",
    weight: 8.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116175",
    width: 0.3,
    length: 1.75,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.75m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.75m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.75m lang",
    weight: 7.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116150",
    width: 0.3,
    length: 1.5,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.5m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.5m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.5m lang",
    weight: 6.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116125",
    width: 0.3,
    length: 1.25,
    label_en: "Aluminium plank for O-ledger 300mm wide 1.25m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1.25m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1.25m lang",
    weight: 5.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116100",
    width: 0.3,
    length: 1,
    label_en: "Aluminium plank for O-ledger 300mm wide 1m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 1m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 1m lang",
    weight: 4.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-116100",
    width: 0.3,
    length: 0.7,
    label_en: "Aluminium plank for O-ledger 300mm wide 0.7m long",
    label_se: "Aluminiumplank för O-bom 300mm bred 0.7m lång",
    label_no: "Aluminiumplate for O-bom 300mm bred 0.7m lang",
    weight: 3.4,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111300",
    width: 0.6,
    length: 3,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 3m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 3m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 3m lang",
    weight: 19.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111250",
    width: 0.6,
    length: 2.5,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 2.5m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 2.5m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 2.5m lang",
    weight: 16.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111200",
    width: 0.6,
    length: 2,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 2m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 2m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 2m lang",
    weight: 13.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111180",
    width: 0.6,
    length: 1.75,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.75m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.75m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.75m lang",
    weight: 12.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111150",
    width: 0.6,
    length: 1.5,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.5m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.5m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.5m lang",
    weight: 10.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111125",
    width: 0.6,
    length: 1.25,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1.25m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1.25m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1.25m lang",
    weight: 8.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111100",
    width: 0.6,
    length: 1,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 1m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 1m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 1m lang",
    weight: 7.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "Z-111070",
    width: 0.6,
    length: 0.7,
    label_en: "Aluminiumplatform for O-ledger 600mm wide 0.7m long",
    label_se: "Aluminiumplattform för O-bom 600mm bred 0.7m lång",
    label_no: "Aluminiumplattform for O-bom 600mm bred 0.7m lang",
    weight: 5.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116307",
    width: 0.32,
    length: 3.03,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 3.03m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 3.03m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 3.03m lang",
    weight: 13.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116257",
    width: 0.32,
    length: 2.53,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 2.53m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 2.53m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 2.53m lang",
    weight: 11.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116207",
    width: 0.32,
    length: 2.03,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 2.03m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 2.03m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 2.03m lang",
    weight: 9.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116157",
    width: 0.32,
    length: 1.53,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 1.53m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 1.53m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 1.53m lang",
    weight: 7.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116109",
    width: 0.32,
    length: 1.05,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 1.05m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 1.05m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 1.05m lang",
    weight: 5.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116073",
    width: 0.32,
    length: 0.69,
    label_en: "Aluminiumplatform for U-ledger 320mm wide 0.69m long",
    label_se: "Aluminiumplattform för U-bom 320mm bred 0.69m lång",
    label_no: "Aluminiumplattform for U-bom 320mm bred 0.69m lang",
    weight: 3.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z111307",
    width: 0.61,
    length: 3.03,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 3.03m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 3.03m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 3.03m lang",
    weight: 21.1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116257",
    width: 0.61,
    length: 2.53,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 2.53m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 2.53m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 2.53m lang",
    weight: 17.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116207",
    width: 0.61,
    length: 2.03,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 2.03m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 2.03m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 2.03m lang",
    weight: 14.8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116157",
    width: 0.61,
    length: 1.53,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 1.53m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 1.53m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 1.53m lang",
    weight: 11.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116109",
    width: 0.61,
    length: 1.05,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 1.05m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 1.05m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 1.05m lang",
    weight: 8.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    article_id: "54-Z-116073",
    width: 0.61,
    length: 0.69,
    label_en: "Aluminiumplatform for U-ledger 610mm wide 0.69m long",
    label_se: "Aluminiumplattform för U-bom 610mm bred 0.69m lång",
    label_no: "Aluminiumplattform for U-bom 610mm bred 0.69m lang",
    weight: 6.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  }
];
export const componentsDiagonalbraces: Component[] = [];

export const componentsGuardRails: Component[] = [
  {
    label_no: "Dubbelräcke 3m",
    label_en: "Double guardrail 3m",
    weight: 12.1,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 3m",
    article_id: "72300",
    length: 3,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 2.5m",
    label_en: "Double guardrail 2.5m",
    weight: 10.8,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 2.5m",
    article_id: "72250",
    length: 2.5,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 2m",
    label_en: "Double guardrail 2m",
    weight: 9.3,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 2m",
    article_id: "72200",
    length: 2,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 1.75m",
    label_en: "Double guardrail 1.75m",
    weight: 8.6,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 1.75m",
    article_id: "72175",
    length: 1.75,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 1.5m",
    label_en: "Double guardrail 1.5m",
    weight: 7.9,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 1.5m",
    article_id: "72150",
    length: 1.5,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 1.25m",
    label_en: "Double guardrail 1.25m",
    weight: 7.1,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 1.25m",
    article_id: "72125",
    length: 1.25,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 1m",
    label_en: "Double guardrail 1m",
    weight: 6.1,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 1m",
    article_id: "72100",
    length: 1,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Dubbelräcke 0.7m",
    label_en: "Double guardrail 0.7m",
    weight: 5.3,
    width: 0,
    material: "",
    label_se: "Dubbelräcke 0.7m",
    article_id: "72070",
    length: 0.7,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  }
];
export const componentsOLedgers: Component[] = [
  {
    width: 0,
    length: 3,
    label_se: "Horisontalstag 3m",
    label_no: "Ledger 3m",
    weight: 11.2,
    label_en: "Ledger 3m",
    article_id: "71300",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 2.5,
    label_se: "Horisontalstag 2.5m",
    label_no: "Ledger 2.5m",
    weight: 9.6,
    label_en: "Ledger 2.5m",
    article_id: "71250",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 2,
    label_se: "Horisontalstag 2m",
    label_no: "Ledger 2m",
    weight: 8.1,
    label_en: "Ledger 2m",
    article_id: "71200",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 1.75,
    label_se: "Horisontalstag 1.75m",
    label_no: "Ledger 1.75m",
    weight: 7.2,
    label_en: "Ledger 1.75m",
    article_id: "71175",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 1.5,
    label_se: "Horisontalstag 1.5m",
    label_no: "Ledger 1.5m",
    weight: 6.3,
    label_en: "Ledger 1.5m",
    article_id: "71150",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 1.25,
    label_se: "Horisontalstag 1.25m",
    label_no: "Ledger 1.25m",
    weight: 5.4,
    label_en: "Ledger 1.25m",
    article_id: "71125",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 1,
    label_se: "Horisontalstag 1m",
    label_no: "Ledger 1m",
    weight: 4.5,
    label_en: "Ledger 1m",
    article_id: "71100",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 0.7,
    label_se: "Horisontalstag 0.7m",
    label_no: "Ledger 0.7m",
    weight: 3.6,
    label_en: "Ledger 0.7m",
    article_id: "71070",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    length: 3,
    label_se: "Längdbalk/tvärbalk 3m",
    label_no: "Cross beam 3m",
    weight: 19.1,
    label_en: "Cross beam 3m",
    article_id: "73300",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    length: 2.5,
    label_se: "Längdbalk/tvärbalk 2.5m",
    label_no: "Cross beam 2.5m",
    weight: 16.8,
    label_en: "Cross beam 2.5m",
    article_id: "73250",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    length: 2,
    label_se: "Längdbalk/tvärbalk 2m",
    label_no: "Cross beam 2m",
    weight: 13.4,
    label_en: "Cross beam 2m",
    article_id: "73200",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    length: 1.75,
    label_se: "Längdbalk/tvärbalk 1.75m",
    label_no: "Cross beam 1.75m",
    weight: 12.8,
    label_en: "Cross beam 1.75m",
    article_id: "74175",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    length: 1.5,
    label_se: "Längdbalk/tvärbalk 1.5m",
    label_no: "Cross beam 1.5m",
    weight: 8.5,
    label_en: "Cross beam 1.5m",
    article_id: "74150",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    length: 1.25,
    label_se: "Längdbalk/tvärbalk 1.25m",
    label_no: "Cross beam 1.25m",
    weight: 7.5,
    label_en: "Cross beam 1.25m",
    article_id: "74125",
    material: "",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];
export const componentsScrews: Component[] = [
  {
    material: "",
    article_id: "-",
    label_no: "Forankringsløkke",
    label_en: "Ring screw",
    label_se: "Förankringsögla",
    weight: 0.16,
    length: 0.095,
    width: 0
  }
];
export const componentsStairs: Component[] = [
  {
    article_id: "76250",
    label_no: "Aluminiumtrapp 2,5m",
    width: 0.6,
    length: 2.5,
    height: 2,
    label_en: "ALUMINIUMstairway 2,5m",
    weight: 24,
    material: "",
    label_se: "Aluminiumtrappa 2,5m"
  }
];
export const componentsStartStairs: Component[] = [
  {
    article_id: "76150",
    label_no: "Aluminiumtrapp 1,5m",
    width: 0.6,
    length: 1.7,
    height: 1.5,
    label_en: "ALUMINIUMstairway 1,5m",
    weight: 14,
    material: "",
    label_se: "Aluminiumtrappa 1,5m"
  },
  {
    article_id: "76100",
    label_no: "Aluminiumtrapp 1m",
    width: 0.6,
    length: 1.5,
    height: 1,
    label_en: "ALUMINIUMstairway 1m",
    weight: 9,
    material: "",
    label_se: "Aluminiumtrappa 1m"
  },
  {
    article_id: "76050",
    label_no: "Aluminiumtrapp 0.5m",
    width: 0.6,
    length: 1.2,
    height: 0.5,
    label_en: "ALUMINIUMstairway 0.5m",
    weight: 6,
    material: "",
    label_se: "Aluminiumtrappa 0.5m"
  }
];
export const componentsStairwayGuardrails: Component[] = [
  {
    label_en: "Stairway guardrail 2,5m",
    width: 0,
    material: "",
    article_id: "66250",
    length: 2.5,
    weight: 12.5,
    label_no: "Trapperekkverk 2,5m",
    label_se: "Handledare 2,5m",
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];
export const componentsStandards: Component[] = [
  {
    article_id: "70400",
    length: 4,
    label_no: "Spire, 4m",
    width: 0,
    label_se: "Spira med hylsskarv, 4m",
    weight: 18.9,
    material: "",
    label_en: "Standard, 4m"
  },
  {
    article_id: "70300",
    length: 3,
    label_no: "Spire, 3m",
    width: 0,
    label_se: "Spira med hylsskarv, 3m",
    weight: 14.4,
    material: "",
    label_en: "Standard, 3m"
  },
  {
    article_id: "70200",
    length: 2,
    label_no: "Spire, 2m",
    width: 0,
    label_se: "Spira med hylsskarv, 2m",
    weight: 9.9,
    material: "",
    label_en: "Standard, 2m"
  },
  {
    article_id: "70100",
    length: 1,
    label_no: "Spire, 1m",
    width: 0,
    label_se: "Spira med hylsskarv, 1m",
    weight: 5.4,
    material: "",
    label_en: "Standard, 1m"
  },
  {
    article_id: "70050",
    length: 0.5,
    label_no: "Spire, 0.5m",
    width: 0,
    label_se: "Spira med hylsskarv, 0.5m",
    weight: 2.7,
    material: "",
    label_en: "Standard, 0.5m"
  }
];
export const componentsToeBoards: Component[] = [
  {
    label_en: "Toe Board 3.3m",
    width: 0.15,
    weight: 6,
    label_no: "Fotlist 3.3m",
    label_se: "Fotlist 3.3m",
    length: 3.3,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 3m",
    width: 0.15,
    weight: 6,
    label_no: "Fotlist 3m",
    label_se: "Fotlist 3m",
    length: 3,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 2.5m",
    width: 0.15,
    weight: 5,
    label_no: "Fotlist 2.5m",
    label_se: "Fotlist 2.5m",
    length: 2.5,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 2m",
    width: 0.15,
    weight: 4,
    label_no: "Fotlist 2m",
    label_se: "Fotlist 2m",
    length: 2,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 1.75m",
    width: 0.15,
    weight: 3.5,
    label_no: "Fotlist 1.75m",
    label_se: "Fotlist 1.75m",
    length: 1.75,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 1.5m",
    width: 0.15,
    weight: 3,
    label_no: "Fotlist 1.5m",
    label_se: "Fotlist 1.5m",
    length: 1.5,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 1.25m",
    width: 0.15,
    weight: 2.5,
    label_no: "Fotlist 1.25m",
    label_se: "Fotlist 1.25m",
    length: 1.25,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 1m",
    width: 0.15,
    weight: 2,
    label_no: "Fotlist 1m",
    label_se: "Fotlist 1m",
    length: 1,
    article_id: "80300",
    material: ""
  },
  {
    label_en: "Toe Board 0.7m",
    width: 0.15,
    weight: 0.7,
    label_no: "Fotlist 0.7m",
    label_se: "Fotlist 0.7m",
    length: 0.7,
    article_id: "80300",
    material: ""
  }
];
export const componentsBeamSpigots: Component[] = [
  {
    length: 0.5,
    width: 0,
    label_se: "Avväxlingsspira",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "",
    label_en: "Beam Spigot",
    weight: 3.5
  }
];
export const componentsCouplers: Array<Component> = [
  {
    label_no: "Stillas Kobling Fast",
    label_se: "Ställningskoppling Fast",
    article_id: "-",
    material: "",
    length: 0.07,
    label_en: "Scaffold coupler Stiff",
    weight: 1.7,
    width: 0
  }
];

import { half, minus, plus, round, strip } from "math";
import {
  Box,
  BoxComponentIds,
  StandardPosition
} from "shared/interfaces/firestore";
import useStoreWithUndo, { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { Euler, Vector3, Vector3Tuple } from "three";
import { StateCreator } from "zustand";
import {
  getBoxIdsConnectedToNode,
  removeComponentsFromGraph,
  toVectorStringRepresentation,
  updateSelectedObjectsPositionRotation
} from "../world.utils";
import { BoxSlice } from "./box.types";
import {
  addBoxToComponentConnections,
  addBoxesToGraph,
  deleteAndAddComponents,
  getBoxComponentsFromState,
  getComponentsGraphData,
  getScaffoldGroups,
  removeBoxComponentEdges,
  setBoxesInGraph
} from "./box.utils";
import { isVector3Tuple } from "validation/three";
import {
  rotatePointsAroundCenter,
  rotateStandardPositions
} from "suppliers/scaffold/scaffold.utils";
import { BoxComponents } from "./box.interface";
import { INITIAL_COMPONENT_VARIANT } from "shared/types/box";

/** Initial state */
const initialBoxState = {
  boxes: []
};

/** Slice creation */
const createBoxSlice: StateCreator<Store, [], [], BoxSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBoxState));

  /** Return state */
  return {
    ...initialBoxState,
    boxActions: {
      add: (boxes) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const strippedBoxes = boxes.map((box) => {
            return {
              ...box,
              height: strip(round(box.height, 3)),
              ...(box.options && {
                options: {
                  ...box.options,
                  ...(box.options.frames && {
                    frames: box.options.frames.map((frame) => ({
                      ...frame,
                      height: strip(round(frame.height, 3))
                    }))
                  })
                }
              })
            };
          });

          // const t1 = performance.now();
          const { addedComponents, deletedComponentIds } = addBoxesToGraph({
            graph: newGraph,
            boxes: strippedBoxes
          });

          // const t2 = performance.now();

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deletedComponentIds,
            addComponents: addedComponents,
            oldComponents: getBoxComponentsFromState()
          });
          console.log("finale", finalBoxComponents);

          return {
            graph: newGraph,
            boxes: [...state.boxes, ...strippedBoxes],
            ...finalBoxComponents
          };
        });
      },
      set: (boxes) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const { newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes: boxes,
            oldBoxes: state.boxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const { ledgers, baseBoards, baseCollars, basePlates, standards } =
            newComponents;

          return {
            graph: newGraph,
            boxes,
            standards,
            ledgers,
            basePlates,
            baseCollars,
            baseBoards
          };
        });
      },
      heightOperation: (
        ids: string[],
        operation: "add" | "subtract",
        value: number
      ) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const toUpdateBoxes = state.boxes.filter((box) =>
            ids.includes(box.id)
          );

          const updatedBoxes = toUpdateBoxes.map((box) => {
            const heightDiff = operation === "add" ? value : -value;
            const newHeight = plus(box.height, heightDiff);
            if (newHeight < 2) return box;

            return {
              ...box,
              height: newHeight,
              ...(box.options?.frames && {
                options: {
                  ...box.options,
                  frames: box.options.frames
                    .map((frame) => ({
                      ...frame,
                      height: frame.height + heightDiff,
                      ...(frame.platform && {
                        platform: frame.platform + heightDiff
                      })
                    }))
                    .filter(
                      (frame) =>
                        frame.height <= newHeight && frame.height >= 0.2
                    )
                }
              })
            };
          });

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes: updatedBoxes,
            oldBoxes: toUpdateBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });

          return {
            graph: newGraph,
            boxes: [
              ...state.boxes.filter((box) => !ids.includes(box.id)),
              ...updatedBoxes
            ],
            ...finalBoxComponents
          };
        });
      },
      setInitialComponentVariant: (
        ids: string[],
        initialComponentVariant?: INITIAL_COMPONENT_VARIANT
      ) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const toUpdateBoxes = state.boxes.filter((box) =>
            ids.includes(box.id)
          );

          const updatedBoxes = toUpdateBoxes.map((box) => {
            const { options } = box;
            if (options === undefined) return box;
            const {
              initialComponentVariant: oldInitialComponentVariant,
              ...restOptions
            } = options;

            return {
              ...box,
              options: {
                ...restOptions,
                ...(initialComponentVariant && { initialComponentVariant })
              }
            };
          });

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes: updatedBoxes,
            oldBoxes: toUpdateBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });

          return {
            graph: newGraph,
            boxes: [
              ...state.boxes.filter((box) => !ids.includes(box.id)),
              ...updatedBoxes
            ],
            ...finalBoxComponents
          };
        });
      },
      update: (ids, data) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const toUpdateBoxes = state.boxes.filter((box) =>
            ids.includes(box.id)
          );
          const updatedBoxes = toUpdateBoxes.map((box) => {
            const cleanData = Object.entries(data).reduce(
              (acc, [key, value]) => {
                if (isVector3Tuple(value)) {
                  const boxKeyValue = box[key] as Vector3Tuple;

                  acc[key] = value.map((v, idx) =>
                    isNaN(v) ? boxKeyValue[idx] : v
                  ) as Vector3Tuple;
                } else {
                  acc[key] = value;
                }

                return acc;
              },
              {} as Partial<Box>
            );
            const positionDiff = data.position
              ? [
                  isNaN(data.position[0])
                    ? 0
                    : minus(data.position[0], box.position[0]),

                  isNaN(data.position[1])
                    ? 0
                    : minus(data.position[1], box.position[1]),

                  isNaN(data.position[2])
                    ? 0
                    : minus(data.position[2], box.position[2])
                ]
              : [0, 0, 0];
            /** Rotate standard positions if any */
            if (data.rotation && box.options?.standardPositions) {
              const rotation = [
                isNaN(data.rotation[0]) ? 0 : data.rotation[0],
                isNaN(data.rotation[1]) ? 0 : data.rotation[1],
                isNaN(data.rotation[2]) ? 0 : data.rotation[2]
              ];
              const rotationDiff = [
                rotation[0] - box.rotation[0],
                rotation[1] - box.rotation[1],
                rotation[2] - box.rotation[2]
              ];
              box.options.standardPositions = rotatePointsAroundCenter({
                points: box.options.standardPositions.map(
                  ({ position }) => new Vector3(...position)
                ),
                rotation: new Euler(...rotationDiff),
                center: new Vector3(...box.position)
              }).map((pos) => ({ position: pos.toArray() })) as [
                StandardPosition,
                StandardPosition,
                StandardPosition,
                StandardPosition
              ];
            }

            /** Move standard positions if any */
            if (box.options?.standardPositions) {
              box.options.standardPositions = box.options.standardPositions.map(
                (standardPosition) => ({
                  position: [
                    plus(standardPosition.position[0], positionDiff[0]),
                    plus(standardPosition.position[1], positionDiff[1]),
                    plus(standardPosition.position[2], positionDiff[2])
                  ]
                })
              ) as [
                StandardPosition,
                StandardPosition,
                StandardPosition,
                StandardPosition
              ];
            }

            if (cleanData.options) {
              return {
                ...box,
                ...cleanData,
                options: {
                  ...box.options,
                  ...cleanData.options
                }
              };
            }

            return {
              ...box,
              ...cleanData
            };
          });

          const { newComponents, deleteComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes: updatedBoxes,
            oldBoxes: toUpdateBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });
          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });
          console.log("finalBoxComponents", finalBoxComponents);
          return {
            graph: newGraph,
            boxes: [
              ...state.boxes.filter((box) => !ids.includes(box.id)),
              ...updatedBoxes
            ],
            ...finalBoxComponents,
            worldSelectedObjects: updateSelectedObjectsPositionRotation(
              state.worldSelectedObjects,
              ids,
              data
            )
          };
        });
      },
      remove: (ids) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const removedComponentIds = new Set<string>();
          ids.forEach((id) => {
            const componentsToRemove = removeBoxComponentEdges({
              graph: newGraph,
              boxIds: [id]
            });
            const componentsGraphData = getComponentsGraphData({
              beamSpigots: state.beamSpigots.filter((beamSpigot) =>
                componentsToRemove.includes(beamSpigot.id)
              ),
              standards: state.standards.filter((standard) =>
                componentsToRemove.includes(standard.id)
              ),
              ledgers: state.ledgers.filter((ledger) =>
                componentsToRemove.includes(ledger.id)
              ),
              basePlates: state.basePlates.filter((basePlate) =>
                componentsToRemove.includes(basePlate.id)
              ),
              baseCollars: state.baseCollars.filter((baseCollar) =>
                componentsToRemove.includes(baseCollar.id)
              ),
              baseBoards: state.baseBoards.filter((baseBoard) =>
                componentsToRemove.includes(baseBoard.id)
              ),
              planks: state.planks.filter((plank) =>
                componentsToRemove.includes(plank.id)
              ),
              toeBoards: state.toeBoards.filter((toeBoard) =>
                componentsToRemove.includes(toeBoard.id)
              ),
              consoles: state.consoles.filter((console) =>
                componentsToRemove.includes(console.id)
              ),
              stairways: state.stairways.filter((stairway) =>
                componentsToRemove.includes(stairway.id)
              ),
              stairwayGuardRails: state.stairwayGuardRails.filter((guardRail) =>
                componentsToRemove.includes(guardRail.id)
              ),
              stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
                (innerGuardRail) =>
                  componentsToRemove.includes(innerGuardRail.id)
              ),
              diagonalBraces: state.diagonalBraces.filter((diagonalBrace) =>
                componentsToRemove.includes(diagonalBrace.id)
              ),
              anchors: state.anchors.filter((anchor) =>
                componentsToRemove.includes(anchor.id)
              ),
              frames: state.frames.filter((frame) =>
                componentsToRemove.includes(frame.id)
              ),
              guardRails: state.guardRails.filter((guardRail) =>
                componentsToRemove.includes(guardRail.id)
              ),
              liftOffPreventers: state.liftOffPreventers.filter(
                (liftOffPreventer) =>
                  componentsToRemove.includes(liftOffPreventer.id)
              ),
              couplers: state.couplers.filter((coupler) =>
                componentsToRemove.includes(coupler.id)
              ),
              screws: state.screws.filter((screw) =>
                componentsToRemove.includes(screw.id)
              )
            });

            const boxRemovedComponents = removeComponentsFromGraph({
              graph: newGraph,
              components: [Object.values(componentsGraphData).flat()].flat()
            });
            boxRemovedComponents.forEach((cId) => removedComponentIds.add(cId));
          });

          const removedComponents = Array.from(removedComponentIds);

          return {
            graph: newGraph,
            boxes: state.boxes.filter((box) => !ids.includes(box.id)),
            standards: state.standards.filter(
              (standard) => !removedComponents.includes(standard.id)
            ),
            frames: state.frames.filter(
              (frame) => !removedComponents.includes(frame.id)
            ),
            ledgers: state.ledgers.filter(
              (ledger) => !removedComponents.includes(ledger.id)
            ),
            basePlates: state.basePlates.filter(
              (basePlate) => !removedComponents.includes(basePlate.id)
            ),
            baseCollars: state.baseCollars.filter(
              (baseCollar) => !removedComponents.includes(baseCollar.id)
            ),
            baseBoards: state.baseBoards.filter(
              (baseBoard) => !removedComponents.includes(baseBoard.id)
            ),
            toeBoards: state.toeBoards.filter(
              (toeBoard) => !removedComponents.includes(toeBoard.id)
            ),
            anchors: state.anchors.filter(
              (anchor) => !removedComponents.includes(anchor.id)
            ),
            consoles: state.consoles.filter(
              (console) => !removedComponents.includes(console.id)
            ),
            planks: state.planks.filter(
              (plank) => !removedComponents.includes(plank.id)
            ),
            stairways: state.stairways.filter(
              (stairway) => !removedComponents.includes(stairway.id)
            ),
            stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
              (stairwayInnerGuardRail) =>
                !removedComponents.includes(stairwayInnerGuardRail.id)
            ),
            stairwayGuardRails: state.stairwayGuardRails.filter(
              (stairwayGuardRail) =>
                !removedComponents.includes(stairwayGuardRail.id)
            ),
            beamSpigots: state.beamSpigots.filter(
              (beamSpigot) => !removedComponents.includes(beamSpigot.id)
            ),
            diagonalBraces: state.diagonalBraces.filter(
              (diagonalBrace) => !removedComponents.includes(diagonalBrace.id)
            ),
            guardRails: state.guardRails.filter(
              (guardRail) => !removedComponents.includes(guardRail.id)
            ),
            liftOffPreventers: state.liftOffPreventers.filter(
              (liftOffPreventer) =>
                !removedComponents.includes(liftOffPreventer.id)
            ),
            couplers: state.couplers.filter(
              (coupler) => !removedComponents.includes(coupler.id)
            ),
            screws: state.screws.filter(
              (screw) => !removedComponents.includes(screw.id)
            )
          };
        });
      },
      changeDimensions: (
        ids: string[],
        dimensions: { depth?: number; width?: number; height?: number }
      ) => {
        set((state) => {
          const { depth, width, height } = dimensions;

          const isSingleBoxDepthUpdate = depth && ids.length === 1;
          let affectedGroup:
            | {
                id: string;
                boxIds: string[];
              }
            | undefined;
          const moveVector: Vector3 = new Vector3();

          if (isSingleBoxDepthUpdate) {
            const scaffoldGroups = getScaffoldGroups({
              graph: state.graph,
              boxes: [...state.boxes]
            });
            affectedGroup = scaffoldGroups.find((group) =>
              group.boxIds.includes(ids[0])
            );
          }

          const newGraph = state.graph.copy();
          const oldBoxes = state.boxes.filter((box) => ids.includes(box.id));

          const newBoxes = oldBoxes.map((box) => {
            const depthDiff = depth ? minus(depth, box.depth) : 0;
            const widthDiff = width ? width - box.width : 0;

            const newDepth = depth ? depth : box.depth;
            const newWidth = width ? width : box.width;
            const newHeight = height ? height : box.height;

            const boxPosition = new Vector3(...box.position);

            if (box.options?.standardPositions) {
              const standardPositions = [
                new Vector3().fromArray(
                  box.options.standardPositions[2].position
                ),
                new Vector3().fromArray(
                  box.options.standardPositions[3].position
                ),
                new Vector3().fromArray(
                  box.options.standardPositions[1].position
                ),
                new Vector3().fromArray(
                  box.options.standardPositions[0].position
                )
              ] as [Vector3, Vector3, Vector3, Vector3];
              const BR = standardPositions[2];
              const BL = standardPositions[3];
              const FR = standardPositions[1];
              const FL = standardPositions[0];

              const connectedStandards: Vector3[] = [];

              standardPositions.forEach((standard) => {
                const connectedBoxesIds = getBoxIdsConnectedToNode(
                  newGraph,
                  toVectorStringRepresentation(standard.toArray())
                ).filter((id) => id !== box.id);
                if (connectedBoxesIds.length) {
                  connectedStandards.push(standard);
                }
              });

              const bayLengthDirection = FR.clone().sub(BR.clone());
              const bayLengthDirectionNormalized = bayLengthDirection
                .clone()
                .normalize();
              const bayWidthDirection = FR.clone().sub(FL.clone());
              const bayWidthDirectionNormalized = bayWidthDirection
                .clone()
                .normalize();

              if (
                connectedStandards.includes(BR) ||
                connectedStandards.includes(FR)
              ) {
                bayWidthDirectionNormalized.multiplyScalar(-1);
              }

              const halfBayWidthDiff = bayWidthDirectionNormalized
                .clone()
                .multiplyScalar(half(widthDiff));
              const bayWidthDiff = bayWidthDirectionNormalized
                .clone()
                .multiplyScalar(widthDiff);
              const halfbayLengthDiff = bayLengthDirectionNormalized
                .clone()
                .multiplyScalar(half(depthDiff));

              const bayLengthDiff = bayLengthDirectionNormalized
                .clone()
                .multiplyScalar(depthDiff);

              moveVector.copy(bayLengthDiff);

              if (
                connectedStandards.includes(BR) ||
                connectedStandards.includes(FR)
              ) {
                if (widthDiff !== 0) {
                  boxPosition.add(halfBayWidthDiff);
                  FL.add(bayWidthDiff.clone()).toArray();
                  BL.add(bayWidthDiff.clone()).toArray();
                }
              } else {
                if (widthDiff !== 0) {
                  boxPosition.add(halfBayWidthDiff);
                  FR.add(bayWidthDiff.clone()).toArray();
                  BR.add(bayWidthDiff.clone()).toArray();
                }
              }

              if (depthDiff !== 0) {
                boxPosition.add(halfbayLengthDiff.clone());
                FR.add(bayLengthDiff.clone()).toArray();
                FL.add(bayLengthDiff.clone()).toArray();
              }
              box.options.standardPositions = [
                { position: standardPositions[3].toArray() },
                { position: standardPositions[2].toArray() },
                { position: standardPositions[0].toArray() },
                { position: standardPositions[1].toArray() }
              ];
            }

            const newBox: Box = {
              ...box,
              depth: newDepth,
              width: newWidth,
              height: newHeight,
              position: boxPosition.toArray()
            };

            if (newBox.options?.frames) {
              newBox.options.frames = newBox.options.frames.filter(
                (frame) => frame.height <= newHeight
              );
            }

            return newBox;
          });

          const currentBoxComponents = { ...state } as BoxComponents;

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes,
            oldBoxes,
            currentBoxComponents
          });

          const oldComponents = { ...state } as BoxComponents;

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents
          });

          const newStateBoxes = state.boxes.map((box) => {
            const newBox = newBoxes.find((newBox) => newBox.id === box.id);
            return newBox ? newBox : box;
          });

          /** If single bay depth update, move all affected bays in same scaffold group,
           * then generate new components
           */
          if (isSingleBoxDepthUpdate && affectedGroup) {
            const newScaffoldGroups = getScaffoldGroups({
              boxes: [...newStateBoxes],
              graph: newGraph
            });

            const newScaffoldGroup = newScaffoldGroups.find((group) =>
              group.boxIds.includes(ids[0])
            );

            const baysIdsToBeMoved = affectedGroup.boxIds.filter(
              (id) => !newScaffoldGroup?.boxIds.includes(id)
            );
            const baysToBeMoved = newStateBoxes.filter((box) =>
              baysIdsToBeMoved.includes(box.id)
            );
            /** Move standard positions and bay position with delta move vector */
            const newPositionBays = baysToBeMoved.map((box) => {
              const boxPosition = new Vector3(...box.position).add(moveVector);

              if (box.options?.standardPositions) {
                box.options.standardPositions =
                  box.options.standardPositions.map((standardPosition) => ({
                    position: new Vector3(...standardPosition.position)
                      .add(moveVector)
                      .toArray()
                  })) as [
                    StandardPosition,
                    StandardPosition,
                    StandardPosition,
                    StandardPosition
                  ];
              }

              const newBox: Box = {
                ...box,
                position: boxPosition.toArray()
              };
              return newBox;
            });

            /** Generate new components after move */
            const {
              deleteComponents: afterMoveDeleteComponents,
              newComponents: afterMoveNewComponents
            } = setBoxesInGraph({
              graph: newGraph,
              newBoxes: newPositionBays,
              oldBoxes: baysToBeMoved,
              currentBoxComponents: finalBoxComponents
            });

            const finalComponentesAfterMove = deleteAndAddComponents({
              deleteIds: afterMoveDeleteComponents,
              addComponents: afterMoveNewComponents,
              oldComponents: finalBoxComponents
            });

            const finalNewBoxes = [...newBoxes, ...newPositionBays];
            const afterMoveNewStateBoxes = state.boxes.map((box) => {
              const newBox = finalNewBoxes.find(
                (newBox) => newBox.id === box.id
              );
              return newBox ? newBox : box;
            });

            return {
              graph: newGraph,
              boxes: afterMoveNewStateBoxes,
              ...finalComponentesAfterMove
            };
          }

          return {
            graph: newGraph,
            boxes: newStateBoxes,
            ...finalBoxComponents
          };
        });
      },
      addPosition: (ids: string[], position: Vector3Tuple) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const oldBoxes = state.boxes.filter((box) => ids.includes(box.id));

          const newBoxes = oldBoxes.map((box) => {
            const newBox: Box = {
              ...box,
              position: [
                plus(box.position[0], position[0]),
                plus(box.position[1], position[1]),
                plus(box.position[2], position[2])
              ]
            };
            if (newBox.options?.standardPositions) {
              newBox.options.standardPositions =
                newBox.options.standardPositions.map((standardPosition) => ({
                  position: [
                    plus(standardPosition.position[0], position[0]),
                    plus(standardPosition.position[1], position[1]),
                    plus(standardPosition.position[2], position[2])
                  ]
                })) as [
                  StandardPosition,
                  StandardPosition,
                  StandardPosition,
                  StandardPosition
                ];
            }
            return newBox;
          });

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes,
            oldBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });

          return {
            graph: newGraph,
            boxes: state.boxes.map((box) => {
              const newBox = newBoxes.find((newBox) => newBox.id === box.id);
              return newBox ? newBox : box;
            }),
            ...finalBoxComponents
          };
        });
      },
      setPosition: (
        ids: string[],
        position: { x?: number; y?: number; z?: number }
      ) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const oldBoxes = state.boxes.filter((box) => ids.includes(box.id));

          const newBoxes = oldBoxes.map((box) => {
            const positionDiff = [
              position.x ? minus(box.position[0], position.x) : 0,
              position.y ? minus(box.position[1], position.y) : 0,
              position.z ? minus(box.position[2], position.z) : 0
            ];
            const newBox: Box = {
              ...box,
              position: [
                position.x ? position.x : box.position[0],
                position.y ? position.y : box.position[1],
                position.z ? position.z : box.position[2]
              ]
            };

            if (newBox.options?.standardPositions) {
              newBox.options.standardPositions =
                newBox.options.standardPositions.map((standardPosition) => ({
                  position: [
                    plus(standardPosition.position[0], positionDiff[0]),
                    plus(standardPosition.position[1], positionDiff[1]),
                    plus(standardPosition.position[2], positionDiff[2])
                  ] as Vector3Tuple
                })) as [
                  StandardPosition,
                  StandardPosition,
                  StandardPosition,
                  StandardPosition
                ];
            }

            return newBox;
          });

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes,
            oldBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });

          return {
            graph: newGraph,
            boxes: state.boxes.map((box) => {
              const newBox = newBoxes.find((newBox) => newBox.id === box.id);
              return newBox ? newBox : box;
            }),
            ...finalBoxComponents
          };
        });
      },
      setRotationY: (ids: string[], rotationY: number) => {
        set((state) => {
          const newGraph = state.graph.copy();
          const oldBoxes = state.boxes.filter((box) => ids.includes(box.id));

          const newBoxes = oldBoxes.map((box) => {
            const newBox: Box = {
              ...box,
              rotation: [box.rotation[0], rotationY, box.rotation[2]]
            };
            return newBox;
          });

          const { deleteComponents, newComponents } = setBoxesInGraph({
            graph: newGraph,
            newBoxes,
            oldBoxes,
            currentBoxComponents: getBoxComponentsFromState()
          });

          const finalBoxComponents = deleteAndAddComponents({
            deleteIds: deleteComponents,
            addComponents: newComponents,
            oldComponents: getBoxComponentsFromState()
          });

          return {
            graph: newGraph,
            boxes: state.boxes.map((box) => {
              const newBox = newBoxes.find((newBox) => newBox.id === box.id);
              return newBox ? newBox : box;
            }),
            ...finalBoxComponents
          };
        });
      },
      addBoxesComponentConnections: (boxesComponents: BoxComponentIds[]) => {
        set((state) => {
          const newGraph = state.graph.copy();
          boxesComponents.forEach((boxComponents) => {
            addBoxToComponentConnections(boxComponents, newGraph);
          });

          return {
            graph: newGraph
          };
        });
      }
    }
  };
};

export const applyBoxDimensions = (
  dimensions: {
    id: string;
    position: Vector3Tuple;
    rotation: Vector3Tuple;
  }[]
) => {
  const { graph, boxes } = useStoreWithUndo.getState();

  const ids = dimensions.map((p) => p.id);

  const newGraph = graph.copy();
  const oldBoxes = boxes.filter((box) => ids.includes(box.id));

  const updatedBoxes = oldBoxes.map((oldBox) => {
    const newDimensions = dimensions.find((p) => p.id === oldBox.id);
    if (!newDimensions) return oldBox;

    const { position: newPosition, rotation: newRotation } = newDimensions;

    let rotatedStandardPositions: Vector3[] = [];
    if (oldBox.options?.standardPositions) {
      rotatedStandardPositions = rotateStandardPositions({
        standardPositions: oldBox.options.standardPositions.map(
          ({ position }) => new Vector3(...position)
        ),
        oldRotation: new Euler(...oldBox.rotation),
        newRotation: new Euler(...newRotation),
        oldCenter: new Vector3(...oldBox.position),
        newCenter: new Vector3(...newPosition)
      });
    }

    const newBox: Box = {
      ...oldBox,
      options: {
        ...oldBox.options,
        standardPositions: rotatedStandardPositions.map((pos) => ({
          position: pos.toArray()
        })) as [
          StandardPosition,
          StandardPosition,
          StandardPosition,
          StandardPosition
        ]
      },
      position: newPosition,
      rotation: newRotation
    };

    return newBox;
  });

  const currentcomponents = getBoxComponentsFromState();

  const { deleteComponents, newComponents } = setBoxesInGraph({
    graph: newGraph,
    newBoxes: updatedBoxes,
    oldBoxes,
    currentBoxComponents: currentcomponents
  });

  const finalBoxComponents = deleteAndAddComponents({
    deleteIds: deleteComponents,
    addComponents: newComponents,
    oldComponents: currentcomponents
  });

  return {
    graph: newGraph,
    boxes: boxes.map((box) => {
      const newBox = updatedBoxes.find((newBox) => newBox.id === box.id);
      return newBox ? newBox : box;
    }),
    ...finalBoxComponents
  };
};

export default createBoxSlice;

import { ReactElement } from "react";
import TooltipProps from "./Tooltip.types";
import { Tooltip as MUITooltip } from "@mui/material";

const Tooltip = (props: TooltipProps): ReactElement => {
  const { children, placement, text } = props;

  return (
    <MUITooltip
      title={text}
      placement={placement}
      arrow
      enterDelay={300}
      enterNextDelay={300}
      slotProps={{ tooltip: { sx: { userSelect: "none" } } }}
    >
      {children}
    </MUITooltip>
  );
};

export default Tooltip;

import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Euler, Vector3 } from "three";
import { Plank } from "./Plank.types";
import { getEndPointFromStartPoint } from "math/vectors";

export const getGraphData = (plank: Plank): GraphData => {
  const { position, rotation, length, endPosition: inputEndPosition } = plank;
  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: plank.id,
    type: ComponentType.PLANK,
    metadata: {
      elementVector: new Vector3(0, 0, 1)
        .applyEuler(new Euler().fromArray(rotation))
        .toArray()
    }
  };
};

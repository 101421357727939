import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Ledger } from "./Ledger.types";
import { getEndPointFromStartPoint } from "math/vectors";
const START_VECTOR = new Vector3();
const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();
export const getGraphData = (ledger: Ledger): GraphData => {
  const { length, position, rotation, endPosition: inputEndPosition } = ledger;
  START_VECTOR.set(...position);
  EULER.set(...rotation);
  ELEMENT_VECTOR.set(0, 0, 1);

  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: ledger.id,
    type: ComponentType.LEDGER,
    metadata: {
      elementVector: ELEMENT_VECTOR.applyEuler(EULER).toArray()
    }
  };
};

export const getGraphSplitData = (ledger: Ledger): GraphData[] => {
  const { length, position, rotation, splits } = ledger;

  if (!splits) return [];

  const newGraphData: GraphData[] = [];
  const mergedLengths = [...splits, length].sort((a, b) => a - b);
  const vectorPosition = new Vector3(...position);

  let startPosition = vectorPosition.clone();
  for (let i = 0; i < mergedLengths.length; i++) {
    const length = mergedLengths[i];

    const endPosition = getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    });

    newGraphData.push({
      startPosition: startPosition.toArray(),
      endPosition: endPosition.toArray(),
      id: ledger.id,
      type: ComponentType.LEDGER,
      metadata: {
        elementVector: new Vector3(0, 0, 1)
          .applyEuler(new Euler().fromArray(rotation))
          .toArray()
      }
    });

    startPosition = endPosition;
  }

  return newGraphData;
};

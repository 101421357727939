import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsGroundSlice } from "./ground.types";

/** Initial state */
const initialToolsGroundState = {
  toolsGroundHeight: 2,

};

const createToolsGroundSlice: StateCreator<
  ToolsGroundSlice,
  [],
  [],
  ToolsGroundSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsGroundState));

  /** Return state */
  return {
    ...initialToolsGroundState,
    toolsGroundActions: {
      setToolsGroundHeight: (height) => {
        set({ toolsGroundHeight: height });
      },
     
    }
  };
};

export default createToolsGroundSlice;

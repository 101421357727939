import { divide, times } from "math";
import { halfPi } from "math/constants";
import { Point, Polygon } from "shared/interfaces/firestore";
import { Box3, Euler, Vector2, Vector3 } from "three";
import * as ClipperLib from "clipper-lib";

export const specialInflatePolygonPositions = (props: {
  closedPositions: Vector3[];
  distance: number;
}) => {
  const { closedPositions, distance } = props;

  // Clipper lib uses integer values
  const decToIntScale = 1e6;

  const clipperPath: ClipperLib.IntPoint[] = closedPositions.map((pos) => ({
    X: Math.round(times(pos.x, decToIntScale)),
    Y: Math.round(times(pos.z, decToIntScale))
  }));

  // Miter limit (10m) is the threshold for starting to round the corners
  const offsetOperation = new ClipperLib.ClipperOffset(10);

  offsetOperation.AddPath(
    clipperPath,
    ClipperLib.JoinType.jtMiter,
    ClipperLib.EndType.etClosedPolygon
  );

  const offsetPaths: ClipperLib.IntPoint[][] = [];
  offsetOperation.Execute(offsetPaths, times(distance, decToIntScale));

  if (offsetPaths.length === 0) {
    return [];
  }

  const offsetPath = offsetPaths[0];

  const newPositions: Vector3[] = offsetPath.map(
    (pt) =>
      new Vector3(divide(pt.X, decToIntScale), 0, divide(pt.Y, decToIntScale))
  );

  // Close polygon if not closed
  if (!newPositions[0].equals(newPositions[newPositions.length - 1])) {
    newPositions.push(newPositions[0]);
  }

  return newPositions;
};

export const createOffsetedVectors = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
  offset: number;
}): { startPosition: Vector3; endPosition: Vector3 } => {
  const { startPosition, endPosition, offset } = props;
  const newStartPosition = startPosition.clone();
  const newEndPosition = endPosition.clone();

  const endBaseVector = newEndPosition.clone().sub(newStartPosition);

  const normalisedEndBaseVector = endBaseVector.clone().normalize();
  const perpendicularVector = normalisedEndBaseVector
    .clone()
    .cross(new Vector3(0, 1, 0));

  const halfDistanceOffset = perpendicularVector.clone().multiplyScalar(offset);

  newStartPosition.sub(halfDistanceOffset);
  newEndPosition.sub(halfDistanceOffset);

  return { startPosition: newStartPosition, endPosition: newEndPosition };
};

export const calculateAngleBetweenVectors = (props: {
  startPointVector: Vector3;
  endPointVector: Vector3;
  startRotationY?: number;
}) => {
  const { startPointVector, endPointVector, startRotationY } = props;

  let rawAngle = Math.atan2(
    startPointVector.x - endPointVector.x,
    startPointVector.z - endPointVector.z
  );

  let angle = rawAngle;
  if (rawAngle < 0) {
    angle = rawAngle + 2 * Math.PI;
  }

  let newAngle = angle;
  if (startRotationY) {
    newAngle = angle - startRotationY;
  }

  let finalAngle = newAngle;
  if (newAngle < 0) {
    finalAngle = newAngle + 2 * Math.PI;
  }

  return finalAngle;
};

export const calculateForwardDragDistance = (props: {
  startPoint: Vector2;
  endPoint: Vector2;
  startRotationY: number;
}) => {
  const { startPoint, endPoint, startRotationY } = props;

  const dragVector = endPoint.clone().sub(startPoint);

  /** Undo the startRotationY rotation */
  const dragVectorRotated = dragVector
    .clone()
    .rotateAround(new Vector2(), -startRotationY);

  if (dragVectorRotated.x < 0) return 0;

  return dragVectorRotated.x;
};

export const calculateBackwardDragDistance = (props: {
  startPoint: Vector2;
  endPoint: Vector2;
  startRotationY: number;
}) => {
  const { startPoint, endPoint, startRotationY } = props;

  const dragVector = endPoint.clone().sub(startPoint);

  /** Undo the startRotationY rotation */
  const dragVectorRotated = dragVector
    .clone()
    .rotateAround(new Vector2(), -startRotationY);

  if (dragVectorRotated.x > 0) return 0;

  return Math.abs(dragVectorRotated.x);
};

export const calculateDirectedAngleBetweenVectors = (props: {
  startPointVector: Vector3;
  middlePointVector: Vector3;
  endPointVector: Vector3;
  skipPositive?: boolean;
}) => {
  const { startPointVector, middlePointVector, endPointVector, skipPositive } =
    props;

  const startToMiddleVector = startPointVector.clone().sub(middlePointVector);
  const endToMiddleVector = endPointVector.clone().sub(middlePointVector);

  let angle =
    Math.atan2(startToMiddleVector.z, startToMiddleVector.x) -
    Math.atan2(endToMiddleVector.z, endToMiddleVector.x);

  if (skipPositive) return angle;

  if (angle < 0) angle += 2 * Math.PI;

  return angle;
};

export const createVector3FromPoint = (point: Vector2, yPos?: number) => {
  const y = yPos || 0;
  return new Vector3(point.x, y, point.y);
};

export const getPolygonSnapToSide = (props: {
  points: Polygon["points"];
  height: Polygon["height"];
  position: Polygon["position"];
  rotation: Polygon["rotation"];
}) => {
  const boxes: {
    startPoint: Point;
    endPoint: Point;
    position: Vector3;
    length: number;
    width: number;
    rotation: Euler;
    height: number;
    polygonPosition: Vector3;
    polygonRotation: Euler;
  }[] = [];
  const { points, height, position, rotation } = props;
  points.forEach((point, idx) => {
    const startPoint = idx > 0 ? points[idx - 1] : points[points.length - 1];
    const endPoint = point;

    const { startPosition, endPosition } = createOffsetedVectors({
      startPosition: new Vector3(...startPoint.position).setY(0),
      endPosition: new Vector3(...endPoint.position).setY(0),
      offset: 0.01
    });

    const planeLength = startPosition.clone().sub(endPosition.clone()).length();

    const midPlanePosition = startPosition
      .clone()
      .add(endPosition.clone().sub(startPosition.clone()).multiplyScalar(0.5));

    const endBaseVector = endPosition.clone().sub(startPosition);

    const angle = calculateDirectedAngleBetweenVectors({
      startPointVector: new Vector3(0, 0, 1),
      middlePointVector: new Vector3(0, 0, 0),
      endPointVector: endBaseVector
    });

    boxes.push({
      startPoint,
      length: planeLength,
      width: 2,
      position: midPlanePosition,
      endPoint,
      rotation: new Euler(-Math.PI / 2, 0, angle),
      height,
      polygonPosition: new Vector3(...position),
      polygonRotation: new Euler().fromArray(rotation)
    });
  });

  return boxes;
};

export const getDistanceBetweenPoints = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
}) => {
  const { startPosition, endPosition } = props;

  const planeLength = startPosition.clone().sub(endPosition.clone()).length();

  const midPlanePosition = startPosition
    .clone()
    .add(endPosition.clone().sub(startPosition.clone()).multiplyScalar(0.5));
  const endBaseVector = endPosition.clone().sub(startPosition);

  const angle = calculateDirectedAngleBetweenVectors({
    startPointVector: new Vector3(0, 0, 1),
    middlePointVector: new Vector3(0, 0, 0),
    endPointVector: endBaseVector
  });

  return {
    length: planeLength,
    position: midPlanePosition,
    rotation: new Euler(-halfPi, 0, angle + halfPi)
  };
};

export const getCylinderCornerPoints = (bbox: Box3) => {
  const bboxCenter = bbox.getCenter(new Vector3());
  const bboxSize = bbox.getSize(new Vector3());

  const cornerPoints: Point[] = [
    {
      position: [
        bboxCenter.x - bboxSize.x / 2,
        0,
        bboxCenter.z - bboxSize.z / 2
      ],
      id: "1"
    },
    {
      position: [
        bboxCenter.x + bboxSize.x / 2,
        0,
        bboxCenter.z - bboxSize.z / 2
      ],
      id: "2"
    },
    {
      position: [
        bboxCenter.x + bboxSize.x / 2,
        0,
        bboxCenter.z + bboxSize.z / 2
      ],
      id: "3"
    },
    {
      position: [
        bboxCenter.x - bboxSize.x / 2,
        0,
        bboxCenter.z + bboxSize.z / 2
      ],
      id: "4"
    }
  ];

  return cornerPoints;
};

import * as NP from "number-precision";

/** Disable boundary checking for the NP library */
NP.enableBoundaryChecking(false);

/** Rounds a value precisly
 *
 * @param {number} value value to round
 * @returns {number} rounded value
 * @example round(0.123456) // 0.123
 */
const round = (value: number, decimals: number) => NP.round(value, decimals);

/** Substracts values precisly
 *
 * @param {...*} values values to subtract
 * @returns {number} substraction result
 * @example minus(0.1, 0.2) // -0.1
 */
const minus = (...values: number[]) => {
  const value = NP.minus(...values);
  return value;
  // return round(value, config.decimals);
};

/** Addition of values precisly
 *
 * @param {...*} values values to add
 * @returns {number} addition result
 * @example plus(0.1, 0.2) // 0.3
 */
const plus = (...values: number[]) => {
  const value = NP.plus(...values);
  return value;
  // return round(value, config.decimals);
};

/** Division of values precisly
 *
 * If any of the values is 0, NaN or null, returns 0
 * @param {...*} values values to divide
 * @returns {number} division result
 * @example divide(0.1, 0.2) // 0.5
 */
const divide = (...values: number[]) => {
  if (values.some((x) => x === 0 || isNaN(x) || x === null)) {
    return 0;
  } else {
    const value = NP.divide(...values);
    return value;
    // return round(value, config.decimals);
  }
};

/** Multiplication of values precisly
 *
 * @param {...*} values values to multiply
 * @returns {number} multiplication result
 * @example times(0.1, 0.2) // 0.02
 */
const times = (...values: number[]) => {
  const value = NP.times(...values);
  return value;
  // return round(value, config.decimals);
};

/** Half of value precisly
 *
 * @param {number} value value to half
 * @returns {number} half of value
 *  @example half(0.1) // 0.05
 */
const half = (value: number) => {
  return divide(value, 2);
};

/** Double of value precisly
 *
 * @param {number} value value to double
 * @returns {number} double of value
 * @example double(0.1) // 0.2
 */
const double = (value: number) => {
  return times(value, 2);
};

/** Clamps a value between a minimum and maximum value
 *
 * @param {number} value value to clamp
 * @param {number} min minimum value
 * @param {number} max maximum value
 * @returns {number} clamped value
 * @example clamp(0.1, 0.2, 0.3) // 0.2
 */
const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

/** Round a number to the nearest value
 * @param {number} value value to round
 * @param {number} fraction fraction to round to
 * @returns {number} rounded value
 * @example toNearest(0.0005, 0.25) // 0.25
 */
const toNearest = (value: number, fraction: number) =>
  times(Math.ceil(divide(value, fraction)), fraction);

const strip = (value: number) => {
  return NP.strip(value);
};

/** Export */
export {
  minus,
  plus,
  divide,
  times,
  round,
  half,
  double,
  clamp,
  toNearest,
  strip
};

import Graph from "graphology";
import { getGraphData } from "world/core/BaseCollar/BaseCollar.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { BaseCollar } from "world/core/BaseCollar/BaseCollar.types";

export const addBaseCollarsToGraph = (props: {
  graph: Graph;
  baseCollars: BaseCollar[];
}) => {
  const { graph, baseCollars } = props;
  const baseCollarsGraphData = baseCollars.map((baseCollar) =>
    getGraphData(baseCollar)
  );
  addComponentsToGraph({ graph, components: baseCollarsGraphData });
};

export const removeBaseCollarsFromGraph = (props: {
  graph: Graph;
  baseCollars: BaseCollar[];
}) => {
  const { graph, baseCollars } = props;
  const baseCollarsGraphData = baseCollars.map((baseCollar) =>
    getGraphData(baseCollar)
  );
  removeComponentsFromGraph({
    graph,
    components: baseCollarsGraphData,
    force: true
  });
};

export const setBaseCollarsInGraph = (props: {
  graph: Graph;
  newBaseCollars: BaseCollar[];
  oldBaseCollars: BaseCollar[];
}) => {
  const { graph, newBaseCollars, oldBaseCollars } = props;
  removeBaseCollarsFromGraph({ graph, baseCollars: oldBaseCollars });
  addBaseCollarsToGraph({ graph, baseCollars: newBaseCollars });
};

import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { StairwaySlice } from "./stairway.interface";
import {
  addStairwaysToGraph,
  removeStairwaysFromGraph,
  setStairwaysInGraph
} from "./stairway.utils";

/** Initial state */
const initialStairwayState = {
  stairways: []
};

/** Slice creation */
const createStairwaySlice: StateCreator<Store, [], [], StairwaySlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialStairwayState));

  /** Return state */
  return {
    ...initialStairwayState,
    addStairways: (stairways) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addStairwaysToGraph({ graph: newGraph, stairways });

        return {
          stairways: [...state.stairways, ...stairways],
          graph: newGraph
        };
      });
    },
    setStairways: (stairways) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setStairwaysInGraph({
          graph: newGraph,
          newStairways: stairways,
          oldStairways: [...state.stairways]
        });

        return {
          stairways,
          graph: newGraph
        };
      });
    },
    removeStairways: (ids) => {
      set((state) => {
        const removeStairways = [...state.stairways];
        const newGraph = state.graph.copy();
        removeStairwaysFromGraph({
          graph: newGraph,
          stairways: removeStairways
        });
        return {
          stairways: state.stairways.filter(
            (stairway) => !ids.includes(stairway.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createStairwaySlice;

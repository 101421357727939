import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Stairway } from "./Stairway.types";
import { roundVector } from "suppliers/scaffold/scaffold.utils";

export const getGraphData = (stairway: Stairway): GraphData => {
  const { length, position, rotation } = stairway;
  let startPosition = new Vector3(...position);

  const endPosition = new Vector3(1, 0, 0)
    .applyEuler(new Euler(...rotation))
    .multiplyScalar(length)
    .add(startPosition);

  return {
    startPosition: position,
    endPosition: roundVector(endPosition).toArray(),
    id: stairway.id,
    type: ComponentType.STAIRWAY
  };
};

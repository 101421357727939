import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import se from "./se/translation.json";
import en from "./en/translation.json";
import da from "./da/translation.json";
import no from "./no/translation.json";
import de from "./de/translation.json";

import { Language } from "./translation.types";
import { APP_LANGUAGECODE } from "enums/language";

const translations: {
  [key in APP_LANGUAGECODE | "default"]: Language;
} = {
  [APP_LANGUAGECODE.EN]: en,
  [APP_LANGUAGECODE.SE]: se,
  [APP_LANGUAGECODE.NO]: no,
  [APP_LANGUAGECODE.DA]: da,
  [APP_LANGUAGECODE.DE]: de,
  default: en
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    supportedLngs: ["en", "se", "da", "no", "de"],
    load: "languageOnly",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: translations.en
      },
      se: {
        translation: translations.se
      },
      no: {
        translation: translations.no
      },
      da: {
        translation: translations.da
      },
      de: {
        translation: translations.de
      }
    }
  });

export default i18n;

import { halfPi } from "math/constants";
import {
  Anchor,
  BaseBoard,
  BaseCollar,
  BasePlate,
  Box,
  BoxConsole,
  BoxFrame,
  Plank,
  Standard
} from "shared/interfaces/firestore";
import useStoreWithUndo from "store/store";
import {
  getSameStartPosAndRotLedgers,
  getSameXZPosBaseComponents,
  getSameXZPosStandards
} from "store/world/box/box.utils";
import {
  getBoxesConnectedToNode,
  isComponentPartOfOtherBox,
  toVectorStringRepresentation
} from "store/world/world.utils";
import { ReplacedComponent } from "suppliers/scaffold/scaffold.interface";
import { Vector3, Vector3Tuple } from "three";
import {
  componentsAnchors,
  componentsBaseplates,
  componentsBeamSpigots,
  componentsConsoles,
  componentsCouplers,
  componentsDecks,
  componentsGuardRails,
  componentsOLedgers,
  componentsScrews,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsStandards,
  componentsToeBoards
} from "../components";
import { genId } from "math/generators";
import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import {
  getEndPointFromStartPoint,
  isPointOnLineLimited,
  roundVector
} from "math/vectors";
import {
  BASE_BOARD_HEIGHT,
  DEFAULT_FALL_PROTECTION_HEIGHT,
  DEFAULT_STAIR_HEIGHT,
  MIN_STANDARD_HEIGHT,
  TOE_BOARD_OFFSET
} from "suppliers/scaffold/constants";
import { Ledger } from "world/core/Ledger/Ledger.types";
import { GuardRail } from "world/core/GuardRail/GuardRail.types";
import { Console } from "world/core/Console/Console.types";

import {
  calcCenterPositionsAlongLine,
  calcLengthPartitions,
  getBoxStandardTopHeightFromXZPosition,
  splitLedgers,
  splitStandards
} from "suppliers/scaffold/scaffold.utils";
import { ToeBoard } from "world/core/ToeBoard/ToeBoard.types";
import { BeamSpigot } from "world/core/BeamSpigot/BeamSpigot.types";
import { Stairway } from "world/core/Stairway/Stairway.types";
import { StairwayGuardRail } from "world/core/StairwayGuardRail/StairwayGuardRail.types";
import { StairwayInnerGuardRail } from "world/core/StairwayInnerGuardRail/StairwayInnerGuardRail.types";
import { half, minus, plus } from "math";
import Graph from "graphology";
import { componentsBaseBoards } from "suppliers/scaffold/components";
import { Coupler } from "world/core/Coupler/Coupler.types";
import { Screw } from "world/core/Screw/Screw.types";
import { generateOptimalPlankConfiguration, getStair } from "./utils";
import { INITIAL_COMPONENT_VARIANT } from "shared/types/box";

export interface StandardLength {
  pos: Vector3;
  standardLength: {
    partitions: number[];
    remainingLength: number;
    skipBottomComponents?: boolean;
  };
  initialComponentVariant?: INITIAL_COMPONENT_VARIANT;
}

export const generateStandards = (props: {
  standardLengths: StandardLength[];
  boxRotation: Box["rotation"];
  boxId: string;
  allBoxes?: Box[];
  preStateStandards?: Standard[];
  availableStandardLengths?: number[];
  isTopOuterPassageStandard?: boolean;
  isBottomOuterPassageStandard?: boolean;
  preStateCommitGraph: Graph;
}) => {
  const {
    standardLengths,
    boxRotation,
    boxId,
    allBoxes,
    preStateStandards,
    availableStandardLengths,
    isTopOuterPassageStandard,
    isBottomOuterPassageStandard,
    preStateCommitGraph
  } = props;

  /** Creation of standards */
  const standards: Standard[] = [];
  const replacedStandards: ReplacedComponent[] = [];
  const standardRotation = [
    boxRotation[0],
    boxRotation[1],
    plus(boxRotation[2], halfPi)
  ] as Vector3Tuple;

  standardLengths.forEach(({ pos, standardLength }) => {
    const oldStandardSplits: number[] = [];
    /** State standards */
    const sameXZStateStandards = getSameXZPosStandards({
      x: pos.x,
      z: pos.z,
      skipInitialStandardType: true
    });

    /** Pre state standards */
    const sameXZPreStateStandards = getSameXZPosStandards({
      x: pos.x,
      z: pos.z,
      standards: preStateStandards,
      skipInitialStandardType: true
    });

    const currStandardsLength = standardLength.partitions.reduce(
      (a, b) => a + b,
      0
    );

    /** Guard against standards already existing in state */
    if (sameXZStateStandards.length > 0) {
      const { boxes, graph } = useStoreWithUndo.getState();
      const connectedBoxes = getBoxesConnectedToNode({
        graph,
        pos: toVectorStringRepresentation(sameXZStateStandards[0].position),
        boxes: allBoxes ?? boxes,
        excludeBoxIds: [boxId]
      });

      const prevStandardsLength = sameXZStateStandards.reduce(
        (a, b) => a + b.length,
        0
      );

      const boxHeights = connectedBoxes.map((box) => ({
        standardLength: minus(
          getBoxStandardTopHeightFromXZPosition({
            box,
            xzPosition: [pos.x, pos.z],
            isTopOuterPassageStandard,
            isBottomOuterPassageStandard
          }),
          MIN_STANDARD_HEIGHT
        ),
        topHeight: getBoxStandardTopHeightFromXZPosition({
          box,
          xzPosition: [pos.x, pos.z],
          isTopOuterPassageStandard,
          isBottomOuterPassageStandard
        }),
        box: box
      }));

      const maxConnectedBox = boxHeights.sort(
        (a, b) => b.topHeight - a.topHeight
      )[0];

      const maxConnectedBoxesTopHeight = maxConnectedBox?.standardLength ?? 0;

      const maxConnectedBoxesStandard = calcLengthPartitions(
        plus(maxConnectedBoxesTopHeight, 0.1),
        availableStandardLengths ?? []
      );

      const maxConnectedBoxesStandardLength =
        maxConnectedBoxesStandard.partitions.reduce((a, b) => a + b, 0);

      if (
        connectedBoxes.length > 0 &&
        maxConnectedBoxesStandardLength > currStandardsLength &&
        maxConnectedBoxesStandardLength === prevStandardsLength
      ) {
        /** Copy and replace existing standards */

        const standardLengthToReachTopHeight = calcLengthPartitions(
          minus(plus(maxConnectedBox.topHeight, 0.1), MIN_STANDARD_HEIGHT),
          availableStandardLengths ?? []
        );
        replacedStandards.push(
          ...sameXZStateStandards.map((standard) => ({
            id: standard.id,
            replacedById: ""
          }))
        );

        sameXZStateStandards.forEach((standard) => {
          standard.splits?.forEach((split) => {
            oldStandardSplits.push(plus(split, standard.position[1]));
          });
        });

        standardLength.remainingLength =
          standardLengthToReachTopHeight.remainingLength;
        standardLength.partitions = [
          ...standardLengthToReachTopHeight.partitions
        ];
      } else if (
        connectedBoxes.length > 0 &&
        maxConnectedBoxesStandardLength > currStandardsLength &&
        maxConnectedBoxesStandardLength < prevStandardsLength
      ) {
        /** Remove previous standards but keep their partitions, calculate required standard height and keep old and new partitions.
         *  Add new standards to previous boxes. */
        const standardLengthToReachTopHeight = calcLengthPartitions(
          minus(plus(maxConnectedBox.topHeight, 0.1), MIN_STANDARD_HEIGHT),
          availableStandardLengths ?? []
        );
        standardLength.partitions = [
          ...standardLengthToReachTopHeight.partitions
        ];

        sameXZStateStandards.forEach((standard) => {
          standard.splits?.forEach((split) => {
            oldStandardSplits.push(plus(split, standard.position[1]));
          });
        });

        replacedStandards.push(
          ...sameXZStateStandards.map((standard) => ({
            id: standard.id,
            replacedById: ""
          }))
        );
      } else {
        /** Remove previous standards but keep their partitions, add new standards with old and new partitions.
         *  Add new standards to previous boxes */
        sameXZStateStandards.forEach((standard) => {
          standard.splits?.forEach((split) => {
            oldStandardSplits.push(plus(split, standard.position[1]));
          });
        });

        replacedStandards.push(
          ...sameXZStateStandards.map((standard) => ({
            id: standard.id,
            replacedById: ""
          }))
        );
      }
    }

    /** Guard agains standards being generated by drag action but not yet in state */
    if (sameXZPreStateStandards.length > 0) {
      /** Remove previous standards but keep their partitions, add new standards with old and new partitions.
       *  Add new standards to previous boxes */
      const { boxes } = useStoreWithUndo.getState();
      sameXZPreStateStandards.forEach((standard) => {
        standard.splits?.forEach((split) => {
          oldStandardSplits.push(plus(split, standard.position[1]));
        });
      });

      replacedStandards.push(
        ...sameXZPreStateStandards.map((standard) => ({
          id: standard.id,
          replacedById: ""
        }))
      );

      const connectedBoxes = getBoxesConnectedToNode({
        graph: preStateCommitGraph,
        pos: toVectorStringRepresentation(sameXZPreStateStandards[0].position),
        boxes: allBoxes ?? boxes,
        excludeBoxIds: [boxId]
      });

      const boxHeights = connectedBoxes.map((box) => ({
        standardLength: minus(
          getBoxStandardTopHeightFromXZPosition({
            box,
            xzPosition: [pos.x, pos.z],
            isTopOuterPassageStandard,
            isBottomOuterPassageStandard
          }),
          MIN_STANDARD_HEIGHT
        ),
        topHeight: getBoxStandardTopHeightFromXZPosition({
          box,
          xzPosition: [pos.x, pos.z],
          isTopOuterPassageStandard,
          isBottomOuterPassageStandard
        }),
        box: box
      }));

      const maxConnectedBox = boxHeights.sort(
        (a, b) => b.topHeight - a.topHeight
      )[0];

      const maxConnectedBoxesTopHeight = maxConnectedBox?.standardLength ?? 0;

      const maxConnectedBoxesStandard = calcLengthPartitions(
        plus(maxConnectedBoxesTopHeight, 0.1),
        availableStandardLengths ?? []
      );

      const maxConnectedBoxesStandardLength =
        maxConnectedBoxesStandard.partitions.reduce((a, b) => a + b, 0);

      /** If prev standards have a greater length then current partitions must be updated */
      if (
        connectedBoxes.length > 0 &&
        currStandardsLength < maxConnectedBoxesStandardLength
      ) {
        const standardLengthToReachTopHeight = calcLengthPartitions(
          minus(plus(maxConnectedBox.topHeight, 0.1), MIN_STANDARD_HEIGHT),
          availableStandardLengths ?? []
        );
        standardLength.partitions = [
          ...standardLengthToReachTopHeight.partitions
        ];
      }
    }

    let currentYPos = plus(pos.y, standardLength.remainingLength);

    standardLength.partitions.forEach((length, idx) => {
      const matchingStandard = componentsStandards.find(
        (standard) => standard.length === length
      );
      if (matchingStandard) {
        const isLastStandard = idx === standardLength.partitions.length - 1;
        const standard: Standard = {
          id: genId(),
          position: [pos.x, currentYPos, pos.z],
          length,
          rotation: standardRotation,
          noSpigot: isLastStandard,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingStandard.article_id
        };

        const standardStart = pos.clone().setY(currentYPos);
        const standardEnd = pos.clone().setY(plus(currentYPos, length));
        oldStandardSplits.forEach((splitHeight) => {
          const splitPos = pos.clone().setY(splitHeight);

          const isPrevSplitOnStandard = isPointOnLineLimited({
            pointA: standardStart,
            pointB: standardEnd,
            pointToCheck: splitPos
          });

          if (isPrevSplitOnStandard) {
            const distance = standardStart.distanceTo(splitPos);
            if (standard.splits) {
              if (!standard.splits.includes(distance)) {
                standard.splits.push(distance);
              }
            } else {
              standard.splits = [distance];
            }
          }
        });

        standards.push(standard);
      }
      currentYPos = plus(currentYPos, length);
    });
  });
  return { standards, replacedStandards };
};

export const generateBaseComponents = (props: {
  standardLengths: StandardLength[];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  preStateBaseComponents?: {
    baseBoards: BaseBoard[];
    baseCollars: BaseCollar[];
    basePlates: BasePlate[];
    standards: Standard[];
  };
  rayHitsYPosition: [number, number, number, number];
}) => {
  const {
    standardLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition,
    preStateBaseComponents
  } = props;

  const baseBoards: BaseBoard[] = [];
  const basePlates: BasePlate[] = [];

  const replacedComponents: ReplacedComponent[] = [];

  standardLengths.forEach(
    ({ standardLength, pos: standardStartPos }, index) => {
      if (!boxOptions || !boxOptions.grounded) return;
      const grounded = boxOptions.grounded[index];

      if (!grounded || !grounded.isGrounded) return;

      const sameXZPosBaseComponents = getSameXZPosBaseComponents({
        x: standardStartPos.x,
        z: standardStartPos.z
      });
      const totalSameXZPosBaseComponents =
        sameXZPosBaseComponents.baseBoards.length +
        sameXZPosBaseComponents.basePlates.length +
        sameXZPosBaseComponents.baseCollars.length +
        sameXZPosBaseComponents.standards.length;

      if (totalSameXZPosBaseComponents > 0) {
        if (standardLength.skipBottomComponents) {
          /** Copy and replace same xz state base components */
          replacedComponents.push(
            ...sameXZPosBaseComponents.baseBoards.map((baseBoard) => ({
              id: baseBoard.id,
              replacedById: ""
            })),
            ...sameXZPosBaseComponents.basePlates.map((basePlate) => ({
              id: basePlate.id,
              replacedById: ""
            }))
          );
          baseBoards.push(
            ...sameXZPosBaseComponents.baseBoards.map((baseBoard) => ({
              ...baseBoard,
              id: genId()
            }))
          );
          basePlates.push(
            ...sameXZPosBaseComponents.basePlates.map((basePlate) => ({
              ...basePlate,
              id: genId()
            }))
          );
          return;
        } else {
          /** Replace sate xz state base components */
          replacedComponents.push(
            ...sameXZPosBaseComponents.baseBoards.map((baseBoard) => ({
              id: baseBoard.id,
              replacedById: ""
            })),
            ...sameXZPosBaseComponents.basePlates.map((basePlate) => ({
              id: basePlate.id,
              replacedById: ""
            }))
          );
        }
      }

      const sameXZPosPreStateBaseComponents = getSameXZPosBaseComponents({
        x: standardStartPos.x,
        z: standardStartPos.z,
        baseComponents: preStateBaseComponents
      });

      const totSameXZPosPreStateBaseComponents =
        sameXZPosPreStateBaseComponents.baseBoards.length +
        sameXZPosPreStateBaseComponents.basePlates.length +
        sameXZPosPreStateBaseComponents.baseCollars.length +
        sameXZPosPreStateBaseComponents.standards.length;

      if (totSameXZPosPreStateBaseComponents > 0) {
        if (standardLength.skipBottomComponents) {
          /** Copy and replace same xz state base components */
          replacedComponents.push(
            ...sameXZPosPreStateBaseComponents.baseBoards.map((baseBoard) => ({
              id: baseBoard.id,
              replacedById: ""
            })),
            ...sameXZPosPreStateBaseComponents.basePlates.map((basePlate) => ({
              id: basePlate.id,
              replacedById: ""
            }))
          );

          baseBoards.push(
            ...sameXZPosPreStateBaseComponents.baseBoards.map((baseBoard) => ({
              ...baseBoard,
              id: genId()
            }))
          );
          basePlates.push(
            ...sameXZPosPreStateBaseComponents.basePlates.map((basePlate) => ({
              ...basePlate,
              id: genId()
            }))
          );
          return;
        } else {
          /** Replace sate xz state base components */
          replacedComponents.push(
            ...sameXZPosPreStateBaseComponents.baseBoards.map((baseBoard) => ({
              id: baseBoard.id,
              replacedById: ""
            })),
            ...sameXZPosPreStateBaseComponents.basePlates.map((basePlate) => ({
              id: basePlate.id,
              replacedById: ""
            }))
          );
        }
      }

      const position = standardStartPos.clone().setY(rayHitsYPosition[index]);
      let latestStartPoint = standardStartPos
        .clone()
        .setY(plus(standardStartPos.y, standardLength.remainingLength));

      const basePlateRotation = [
        boxRotation[0],
        boxRotation[1],
        plus(boxRotation[2], halfPi)
      ] as Vector3Tuple;

      const baseBoardRotation = boxRotation;

      const matchingBaseBoard = componentsBaseBoards[0];
      if (matchingBaseBoard) {
        const baseBoard: BaseBoard = {
          id: genId(),
          position: position.toArray(),
          rotation: baseBoardRotation,
          length: matchingBaseBoard.length,
          width: matchingBaseBoard.width,
          componentId: matchingBaseBoard.article_id,
          supplier: SCAFFOLD_SUPPLIER.SUPER9
        };
        baseBoards.push(baseBoard);
      }

      const matchingBasePlate = componentsBaseplates[0];
      if (matchingBasePlate) {
        const startPosition = position
          .clone()
          .setY(plus(position.y, BASE_BOARD_HEIGHT));
        const basePlate: BasePlate = {
          id: genId(),
          position: startPosition.toArray(),
          endPosition: latestStartPoint.clone().toArray(),
          rotation: basePlateRotation as Vector3Tuple,
          length: startPosition.clone().distanceTo(latestStartPoint),
          spindleNutHeight: startPosition.clone().distanceTo(latestStartPoint),
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingBasePlate.article_id,
          grounded: true
        };
        latestStartPoint = startPosition.clone();
        basePlates.push(basePlate);
      }
    }
  );

  return {
    baseBoards,
    basePlates,
    replacedComponents
  };
};

const GUARD_RAIL_HEIGHT = 0.5;
export const generateFrameLedgers = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  standardsToSplit: Standard[];
  preStateLedgers?: Ledger[];
  skipBottomLedgers?: boolean;
  boxId: string;
}) => {
  const {
    standardPositions,
    boxRotation,
    depth,
    width,
    frames,
    standardsToSplit,
    preStateLedgers,
    skipBottomLedgers,
    boxId
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const topYPos = Math.max(BR.y, BL.y, FR.y, FL.y);

  const ledgers: Ledger[] = [];
  const guardRails: GuardRail[] = [];
  const replacedLedgers: ReplacedComponent[] = [];

  const bayLengthDirectionRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const uLedger = componentsOLedgers.find((ledger) => ledger.length === width);
  const oLedger = componentsOLedgers.find((ledger) => ledger.length === depth);
  const guardRail = componentsGuardRails.find(
    (guardRail) => guardRail.length === depth
  );

  const ledgersData = [
    {
      start: BR,
      end: FR,
      rotation: bayLengthDirectionRotation,
      component: oLedger
    },
    {
      start: BL,
      end: FL,
      rotation: bayLengthDirectionRotation,
      component: oLedger,
      optComponent: guardRail // use guardrail if no plank
    },
    { start: FR, end: FL, rotation: boxRotation, component: uLedger },
    { start: BR, end: BL, rotation: boxRotation, component: uLedger }
  ];
  const extraFrameHeght = topYPos + 0.4;
  const ledgerLevels = !frames
    .map(({ height }) => height)
    .includes(extraFrameHeght)
    ? [{ height: topYPos + 0.4 }, ...frames]
    : frames;

  if (skipBottomLedgers) {
    ledgerLevels.shift();
  }

  ledgersData.forEach(({ start, end, rotation, component, optComponent }) => {
    if (!component) return;
    const startPosition = start.toArray();
    const endPosition = end.toArray();
    let oldLedgerSplits: number[] = [];

    ledgerLevels.forEach(({ height, platform }) => {
      const yPos = height;
      startPosition[1] = yPos;
      endPosition[1] = yPos;
      const newLedgerId = genId();
      oldLedgerSplits = [];
      const sameStartPosAndRotLedgers = getSameStartPosAndRotLedgers({
        position: startPosition,
        rotation
      });
      if (sameStartPosAndRotLedgers.length > 0) {
        const { graph } = useStoreWithUndo.getState();
        const sameStartPosLedger = sameStartPosAndRotLedgers[0];
        const isLedgerConnectedToOtherBox = isComponentPartOfOtherBox({
          graph,
          component: sameStartPosLedger,
          excludeBoxIds: [boxId]
        });

        /** Keep previous ledger  */
        if (
          sameStartPosLedger.length > component.length &&
          isLedgerConnectedToOtherBox
        ) {
          // important that we clone the ledger to avoid mutation
          ledgers.push({
            ...sameStartPosLedger,
            ...(sameStartPosLedger.splits && {
              splits: [...sameStartPosLedger.splits]
            }),
            id: genId()
          });
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: sameStartPosLedger.id
          });
          return;
          /** Remove previous ledger but keep their splits */
        } else {
          if (sameStartPosLedger.splits)
            oldLedgerSplits.push(...sameStartPosLedger.splits);
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: newLedgerId
          });
        }
      }
      const sameStartPosAndRotPreStateLedgers = getSameStartPosAndRotLedgers({
        position: startPosition,
        rotation,
        ledgers: preStateLedgers
      });

      if (sameStartPosAndRotPreStateLedgers.length > 0) {
        const sameStartPosLedger = sameStartPosAndRotPreStateLedgers[0];
        /** Keep previous ledger  */
        if (sameStartPosLedger.length > component.length) {
          // important that we clone the ledger to avoid mutation
          ledgers.push({
            ...sameStartPosLedger,
            ...(sameStartPosLedger.splits && {
              splits: [...sameStartPosLedger.splits]
            }),
            id: genId()
          });
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: sameStartPosLedger.id
          });
          return;
          /** Remove previous ledger but keep their splits */
        } else {
          if (sameStartPosLedger.splits)
            oldLedgerSplits.push(...sameStartPosLedger.splits);
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: newLedgerId
          });
        }
      }

      if (!platform && optComponent) {
        const topStartPosition: Vector3Tuple = [
          startPosition[0],
          startPosition[1] + GUARD_RAIL_HEIGHT,
          startPosition[2]
        ];
        const topEndPosition: Vector3Tuple = [
          endPosition[0],
          endPosition[1] + GUARD_RAIL_HEIGHT,
          endPosition[2]
        ];
        guardRails.push({
          id: newLedgerId,
          position: [...topStartPosition],
          top: {
            pos: [...topStartPosition],
            endPos: [...topEndPosition],
            splits: oldLedgerSplits
          },
          bottom: {
            pos: [...startPosition],
            endPos: [...endPosition],
            splits: []
          },
          length: optComponent.length,
          rotation,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: optComponent.article_id,
          ...(optComponent.variant && { variant: optComponent.variant })
        });
      } else {
        ledgers.push({
          id: newLedgerId,
          position: [...startPosition],
          endPosition: [...endPosition],
          length: component.length,
          splits: oldLedgerSplits,
          rotation,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: component.article_id
        });
      }
    });
  });

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: ledgers
      .map((ledger) => [ledger.position, ledger.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { ledgers, replacedLedgers, guardRails };
};

export const generateGuardRails = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  fallProtectionHeight: number;
  guardRailsOptions: [boolean, boolean, boolean, boolean];
  standardsToSplit: Standard[];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    fallProtectionHeight,
    guardRailsOptions,
    standardPositions,
    standardsToSplit
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const guardRails: GuardRail[] = [];
  const ledgers: Ledger[] = [];

  /** Longitudinal Guard rails */
  const longitudinalGuardRailRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const shortGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === width
  );
  const longGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === depth
  );
  const shortLedger = componentsOLedgers.find(
    (ledger) => ledger.length === width
  );
  const longLedger = componentsOLedgers.find(
    (ledger) => ledger.length === depth
  );

  const guardRailData = [
    ...(guardRailsOptions[1]
      ? [
          {
            start: BR,
            end: FR,
            component: longGuardRail,
            ledgerComponent: longLedger,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            start: BL,
            end: FL,
            component: longGuardRail,
            ledgerComponent: longLedger,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            start: FR,
            end: FL,
            component: shortGuardRail,
            ledgerComponent: shortLedger,
            rotation: boxRotation
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            start: BR,
            end: BL,
            component: shortGuardRail,
            ledgerComponent: shortLedger,
            rotation: boxRotation
          }
        ]
      : [])
  ];

  guardRailData.forEach(
    ({ start, end, rotation, component, ledgerComponent }) => {
      if (!component) return;
      frames.forEach(({ platform }, idx) => {
        if (!platform) return;

        const topStartPosition = start.toArray();
        const topEndPosition = end.toArray();
        const bottomStartPosition = start.toArray();
        const bottomEndPosition = end.toArray();

        if (idx === 0) {
          let guardRailHeight = fallProtectionHeight;

          /** Add top ledger first then regular guard rails */
          if (fallProtectionHeight % 1 !== 0 && ledgerComponent) {
            const yPos = plus(platform, guardRailHeight);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            const guardRailLedger: Ledger = {
              id: genId(),
              position: [...topStartPosition],
              endPosition: [...topEndPosition],
              length: ledgerComponent.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.SUPER9,
              componentId: ledgerComponent.article_id
            };
            ledgers.push(guardRailLedger);
            guardRailHeight = minus(guardRailHeight, 0.5);
          }
          for (let i = guardRailHeight; i > 0; i -= 1) {
            const yPos = plus(platform, i);
            const bottomYPos = minus(yPos, 0.5);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            bottomStartPosition[1] = bottomYPos;
            bottomEndPosition[1] = bottomYPos;

            const guardRail: GuardRail = {
              id: genId(),
              position: [...topStartPosition],
              top: {
                pos: [...topStartPosition],
                endPos: [...topEndPosition],
                splits: []
              },
              bottom: {
                pos: [...bottomStartPosition],
                endPos: [...bottomEndPosition],
                splits: []
              },
              length: component.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.SUPER9,
              componentId: component.article_id,
              ...(component.variant && { variant: component.variant })
            };
            guardRails.push(guardRail);
          }
        } else {
          const yPos = plus(platform, DEFAULT_FALL_PROTECTION_HEIGHT);
          const bottomYPos = minus(yPos, 0.5);
          topStartPosition[1] = yPos;
          topEndPosition[1] = yPos;
          bottomStartPosition[1] = bottomYPos;
          bottomEndPosition[1] = bottomYPos;

          const guardRail: GuardRail = {
            id: genId(),
            position: topStartPosition,
            top: {
              pos: topStartPosition,
              endPos: topEndPosition,
              splits: []
            },
            bottom: {
              pos: bottomStartPosition,
              endPos: bottomEndPosition,
              splits: []
            },
            length: component.length,
            rotation,
            supplier: SCAFFOLD_SUPPLIER.SUPER9,
            componentId: component.article_id,
            ...(component.variant && { variant: component.variant })
          };
          guardRails.push(guardRail);
        }
      });
    }
  );

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: [
      ...guardRails
        .map((guardRail) => [guardRail.top.pos, guardRail.top.endPos])
        .flat(),
      ...ledgers
        .map((ledger) => [ledger.position, ledger.endPosition ?? [0, 0, 0]])
        .flat()
    ]
  });
  return { guardRails, ledgers };
};

export const generateFrameDeckPlanks = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  stair?: boolean;
  ledgersToSplit: Ledger[];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    standardPositions,
    stair,
    ledgersToSplit
  } = props;
  const plankSpacing = 0.01;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const planks: Plank[] = [];
  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;

  const plankRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const startPlanksEndVector = stair
    ? BR.clone()
        .setY(0)
        .sub(
          BR.clone()
            .setY(0)
            .sub(BL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : BR.clone().setY(0);

  const endPlanksEndVector = stair
    ? FR.clone()
        .setY(0)
        .sub(
          FR.clone()
            .setY(0)
            .sub(FL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : FR.clone().setY(0);

  frames.forEach(({ platform, plankingMaterial }) => {
    if (!platform) return;
    const optimalPlankConfiguration = generateOptimalPlankConfiguration({
      length: depth,
      width: minus(width, stair ? stairWidth : 0),
      plankType: plankingMaterial
    });

    const plankStartPositions = calcCenterPositionsAlongLine({
      start: BL.clone().setY(0),
      end: startPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });
    const plankEndPositions = calcCenterPositionsAlongLine({
      start: FL.clone().setY(0),
      end: endPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });

    plankStartPositions.forEach((plankPosition, idx) => {
      const plankStartPosition = plankPosition.clone().setY(platform);
      const plankEndPosition = plankEndPositions[idx].clone().setY(platform);

      const matchingPlank = componentsDecks.find(
        (component) =>
          component.length === depth &&
          component.width === optimalPlankConfiguration[idx] &&
          component.material === plankingMaterial
      );
      if (matchingPlank) {
        const plank: Plank = {
          id: genId(),
          position: plankStartPosition.toArray(),
          endPosition: plankEndPosition.toArray(),
          length: depth,
          width: matchingPlank.width,
          rotation: plankRotation,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingPlank.article_id
        };
        planks.push(plank);
      }
    });
  });

  splitLedgers({
    ledgersToSplit,
    positions: planks
      .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return planks;
};

export const generateToeBoards = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  guardRailsOptions: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    guardRailsOptions,
    standardPositions,
    frames
  } = props;

  const toeBoards: ToeBoard[] = [];

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];

  const longitudinalRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const transverseRotation = boxRotation;

  const longitudinalToeBoard = componentsToeBoards.find(
    (component) => component.length === depth
  );
  const transverseToeBoard = componentsToeBoards.find(
    (component) => component.length === width
  );

  const toeBoardData = [
    ...(guardRailsOptions[1]
      ? [
          {
            position: BR,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            position: BL,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            position: FR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            position: BR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : [])
  ];

  toeBoardData.forEach(({ position, rotation, component }) => {
    if (!component) return;
    const pos = position.clone();
    frames.forEach(({ platform }) => {
      if (!platform) return;
      const toeBoard: ToeBoard = {
        id: genId(),
        position: pos.setY(plus(platform, TOE_BOARD_OFFSET)).toArray(),
        width: component.width,
        length: component.length,
        rotation,
        supplier: SCAFFOLD_SUPPLIER.SUPER9,
        componentId: component.article_id
      };

      toeBoards.push(toeBoard);
    });
  });

  return toeBoards;
};

const ANCHOR_HEIGHT_OFFSET = -0.4;
export const generateFrameAnchors = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  anchorOptions: [boolean, boolean, boolean, boolean];
  boxRotation: Box["rotation"];
  anchorLevels: number[];
  standardsToSplit: Standard[];
}) => {
  const {
    standardPositions,
    boxRotation,
    anchorLevels,
    standardsToSplit,
    anchorOptions
  } = props;

  const bayLengthDirectionRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const anchors: Anchor[] = [];
  const couplers: Coupler[] = [];
  const screws: Screw[] = [];

  const anchorSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
  }[] = [
    ...(anchorOptions[1]
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[3]
      ? [
          {
            left: FL,
            right: BL,
            rotation: boxRotation
          }
        ]
      : []),
    ...(anchorOptions[0]
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[2]
      ? [
          {
            left: BL,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : [])
  ];

  const matchingAnchor = componentsAnchors[0];
  const matchingCoupler = componentsCouplers[0];
  const matchingScrew = componentsScrews[0];

  if (matchingAnchor && matchingCoupler) {
    anchorSides.forEach(({ left, right, rotation }) => {
      anchorLevels.forEach((height) => {
        const leftCouplerStartPosition = [
          left.x,
          height + ANCHOR_HEIGHT_OFFSET,
          left.z
        ] as Vector3Tuple;
        const leftCouplerEndPosition = getEndPointFromStartPoint({
          startPosition: leftCouplerStartPosition,
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation
        });
        const leftAnchorStartPosition = leftCouplerEndPosition.toArray();

        const leftAnchorEndPosition = getEndPointFromStartPoint({
          startPosition: leftAnchorStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        const rightCouplerStartPosition = [
          right.x,
          height + ANCHOR_HEIGHT_OFFSET,
          right.z
        ] as Vector3Tuple;

        const rightCouplerEndPosition = getEndPointFromStartPoint({
          startPosition: rightCouplerStartPosition,
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation
        });
        const rightAnchorStartPosition = rightCouplerEndPosition.toArray();

        const rightAnchorEndPosition = getEndPointFromStartPoint({
          startPosition: rightAnchorStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        if (matchingScrew) {
          const rightScrewEndPosition = getEndPointFromStartPoint({
            startPosition: rightAnchorEndPosition.toArray(),
            length: matchingScrew.length,
            rotation
          });

          const leftScrewEndPosition = getEndPointFromStartPoint({
            startPosition: leftAnchorEndPosition.toArray(),
            length: matchingScrew.length,
            rotation
          });

          const leftScrew: Screw = {
            id: genId(),
            position: leftAnchorEndPosition.toArray(),
            endPosition: leftScrewEndPosition.toArray(),
            length: matchingScrew.length,
            rotation: rotation as Vector3Tuple,
            supplier: SCAFFOLD_SUPPLIER.SUPER9,
            componentId: matchingScrew.article_id
          };
          const rightScrew: Screw = {
            id: genId(),
            position: rightAnchorEndPosition.toArray(),
            endPosition: rightScrewEndPosition.toArray(),
            length: matchingScrew.length,
            rotation: rotation as Vector3Tuple,
            supplier: SCAFFOLD_SUPPLIER.SUPER9,
            componentId: matchingScrew.article_id
          };

          screws.push(leftScrew, rightScrew);
        }

        const leftCoupler: Coupler = {
          id: genId(),
          position: leftCouplerStartPosition,
          endPosition: leftCouplerEndPosition.toArray(),
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingCoupler.article_id
        };
        const rightCoupler: Coupler = {
          id: genId(),
          position: rightCouplerStartPosition,
          endPosition: rightCouplerEndPosition.toArray(),
          length: matchingCoupler.length,
          rotation: bayLengthDirectionRotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingCoupler.article_id
        };
        couplers.push(leftCoupler, rightCoupler);

        const leftAnchor: Anchor = {
          id: genId(),
          position: leftAnchorStartPosition,
          endPosition: leftAnchorEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingAnchor.article_id,
          grounded: true
        };
        const rightAnchor: Anchor = {
          id: genId(),
          position: rightAnchorStartPosition,
          endPosition: rightAnchorEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingAnchor.article_id,
          grounded: true
        };

        anchors.push(leftAnchor, rightAnchor);
      });
    });
  }

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: couplers
      .map((coupler) => [coupler.position, coupler.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { anchors, couplers, screws };
};

export const generateConsoleComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  consoleLevels: BoxConsole[];
  consoleWidth?: number;
  consoleOptions?: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    consoleLevels,
    standardPositions,
    consoleWidth,
    consoleOptions
  } = props;

  const consoles: Console[] = [];
  const planks: Plank[] = [];

  /** Consoles or not */
  const [FC, RC, BC, LC] = consoleOptions ?? [false, false, false, false];

  if (!consoleWidth) return { consoles, planks };

  const consoleMatch = componentsConsoles.find(
    (component) => component.width === consoleWidth
  );

  if (!consoleMatch) return { consoles, planks };

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const bayLengthDir = FR.clone()
    .setY(0)
    .sub(BR.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const bayWidthDir = BR.clone()
    .setY(0)
    .sub(BL.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const consoleSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
    dirVector: Vector3;
    length: number;
  }[] = [
    ...(FC
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone(),
            length: width
          }
        ]
      : []),
    ...(BC
      ? [
          {
            left: BR,
            right: BL,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone().multiplyScalar(-1),
            length: width
          }
        ]
      : []),
    ...(LC
      ? [
          {
            left: BL,
            right: FL,
            rotation: boxRotation,
            dirVector: bayWidthDir.clone().multiplyScalar(-1),
            length: depth
          }
        ]
      : []),
    ...(RC
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayWidthDir.clone(),
            length: depth
          }
        ]
      : [])
  ];

  consoleSides.forEach(({ left, right, rotation, dirVector, length }) => {
    consoleLevels.forEach(({ height, plankingMaterial }) => {
      /** Consoles */
      [left, right].forEach((pos) => {
        const console: Console = {
          id: genId(),
          position: pos.clone().setY(height).toArray(),
          length: consoleMatch.width,
          endPosition: getEndPointFromStartPoint({
            startPosition: [pos.x, height, pos.z],
            length: consoleMatch.width,
            rotation
          }).toArray(),
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: consoleMatch.article_id,
          ...(consoleMatch.variant && { variant: consoleMatch.variant })
        };
        consoles.push(console);
      });

      /** Console plank */
      const consolePlankRotation = [
        rotation[0],
        minus(rotation[1], halfPi),
        rotation[2]
      ] as Vector3Tuple;
      const optimalPlankConfiguration = generateOptimalPlankConfiguration({
        length,
        width: consoleWidth,
        plankType: plankingMaterial
      });
      const consolePlankPositions = calcCenterPositionsAlongLine({
        start: left.clone().setY(0),
        end: left.clone().setY(0).add(dirVector),
        partitions: optimalPlankConfiguration,
        spacing: 0.01
      });
      consolePlankPositions.forEach((plankPosition, idx) => {
        const matchingPlank = componentsDecks.find(
          (component) =>
            component.length === length &&
            component.width === optimalPlankConfiguration[idx] &&
            component.material === plankingMaterial
        );
        if (matchingPlank) {
          const plank: Plank = {
            id: genId(),
            position: plankPosition.clone().setY(height).toArray(),
            length: length,
            endPosition: getEndPointFromStartPoint({
              startPosition: [plankPosition.x, height, plankPosition.z],
              length,
              rotation: consolePlankRotation
            }).toArray(),
            width: matchingPlank.width,
            rotation: consolePlankRotation,
            supplier: SCAFFOLD_SUPPLIER.SUPER9,
            componentId: matchingPlank.article_id
          };
          planks.push(plank);
        }
      });
    });
  });

  //   splitConsoles({
  //     consolesToSplit: consoles,
  //     positions: planks
  //       .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
  //       .flat()
  //   });

  return { consoles, planks };
};

export const generatePassageComponents = (props: {
  baseStandardLengths: StandardLength[];
  boxPosition: Box["position"];
  topOuterStandardPositions: [Vector3, Vector3];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  rayHitsYPosition: [number, number, number, number];
  baseWidth: number;
  depth: number;
  availableStandardLengths?: number[];
  preStateCommitGraph: Graph;
  preStateLedgers?: Ledger[];
  preStateBasePlates?: BasePlate[];
  preStateBaseBoards?: BaseBoard[];
  preStateStandards?: Standard[];
}) => {
  const {
    baseStandardLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition,
    topOuterStandardPositions: topStandardPositions,
    baseWidth,
    depth,
    boxPosition,
    availableStandardLengths,
    preStateCommitGraph,
    preStateBaseBoards,
    preStateBasePlates,
    preStateLedgers,
    preStateStandards
  } = props;

  const baseHeight = boxOptions?.baseHeight ?? 0;
  const standardPositions = baseStandardLengths.map((s) => s.pos.clone()) as [
    Vector3,
    Vector3,
    Vector3,
    Vector3
  ];

  let startHeight = boxPosition[1];
  const worldBaseHeight = baseHeight + boxPosition[1];

  const standards: Standard[] = [];
  const beamSpigots: BeamSpigot[] = [];
  const replacedComponents: ReplacedComponent[] = [];

  /** Generate base passage. Standards, base plates, base boards, base collars, "riddare", ledgers (reinforced), guard rails */
  const {
    baseBoards,
    basePlates,
    replacedComponents: replacedBaseComponents
  } = generateBaseComponents({
    standardLengths: baseStandardLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition,
    preStateBaseComponents: {
      baseBoards: preStateBaseBoards ?? [],
      baseCollars: [],
      basePlates: preStateBasePlates ?? [],
      standards: preStateStandards ?? []
    }
  });

  if (baseStandardLengths.length >= 4) {
    const outerStandardLengths = [
      baseStandardLengths[0],
      baseStandardLengths[3]
    ];
    const outerStandards = generateStandards({
      standardLengths: outerStandardLengths,
      boxRotation,
      boxId: "",
      availableStandardLengths,
      preStateCommitGraph,
      preStateStandards,
      isBottomOuterPassageStandard: true
    });
    replacedComponents.push(...outerStandards.replacedStandards);
    standards.push(...outerStandards.standards);
  }

  /** Generate reinforced base ledgers */
  const { ledgers, guardRails, replacedLedgers } = generateFrameLedgers({
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    frames: [{ height: worldBaseHeight, platform: worldBaseHeight }],
    standardsToSplit: [],
    boxId: "",
    preStateLedgers,
    skipBottomLedgers: true
  });
  replacedComponents.push(...replacedLedgers);

  const maxStandardStartHeight = Math.max(...standardPositions.map((p) => p.y));
  startHeight = maxStandardStartHeight + 0.4;

  const guardRailFrameLevels: BoxFrame[] = [];
  for (let i = worldBaseHeight - 2; i >= startHeight; i -= 1.5) {
    const bottomGuardRailHeight = i;
    guardRailFrameLevels.push({
      height: bottomGuardRailHeight,
      platform: bottomGuardRailHeight
    });
  }

  /** Longitudinal Guard rails */
  const passageGuardRails = generateGuardRails({
    guardRailsOptions: [false, true, false, true],
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    fallProtectionHeight: DEFAULT_FALL_PROTECTION_HEIGHT,
    frames: guardRailFrameLevels,
    standardsToSplit: []
  });

  if (topStandardPositions.length >= 2) {
    const outerStandards = [
      topStandardPositions[0].clone().setY(boxPosition[1]),
      topStandardPositions[1].clone().setY(boxPosition[1])
    ] as [Vector3, Vector3];

    const beamSpigotsOuter = generateBeamSpigots({
      standardPositions: outerStandards,
      baseHeight: boxOptions?.baseHeight ?? 0,
      boxRotation,
      ledgersToSplit: ledgers
    });
    beamSpigots.push(...beamSpigotsOuter);
  }

  return {
    baseBoards,
    basePlates,
    standards,
    beamSpigots,
    ledgers: [...ledgers, ...passageGuardRails.ledgers],
    guardRails: [...guardRails, ...passageGuardRails.guardRails],
    replacedComponents: [...replacedComponents, ...replacedBaseComponents]
  };
};

export const generateBeamSpigots = (props: {
  standardPositions: [Vector3, Vector3];
  baseHeight: number;
  boxRotation: Box["rotation"];
  ledgersToSplit: Ledger[];
}) => {
  const { standardPositions, boxRotation, baseHeight } = props;

  const beamSpigotRotation = [
    boxRotation[0],
    boxRotation[1],
    plus(boxRotation[2], halfPi)
  ] as Vector3Tuple;

  const beamSpigots: BeamSpigot[] = [];
  const beamSpigotComponent = componentsBeamSpigots[0];
  if (!beamSpigotComponent) return beamSpigots;

  standardPositions.forEach((pos) => {
    const beamSpigotPos = pos.clone().setY(pos.y + baseHeight);
    const beamSpigot: BeamSpigot = {
      id: genId(),
      position: beamSpigotPos.toArray(),
      length: beamSpigotComponent.length,
      rotation: beamSpigotRotation,
      supplier: SCAFFOLD_SUPPLIER.SUPER9,
      componentId: beamSpigotComponent.article_id
    };
    beamSpigots.push(beamSpigot);
  });

  return beamSpigots;
};

export const generateStairComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  stairwayGuardRail?: boolean;
  stairwayInnerGuardRail?: boolean;
}) => {
  const {
    boxRotation,
    depth,
    frames,
    standardPositions,
    stairwayGuardRail,
    width
  } = props;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FL = standardPositions[0];

  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;

  /** Stairs */
  const dir = BR.clone().setY(0).sub(BL.clone().setY(0)).normalize();
  const stairways: Stairway[] = [];
  const stairwayGuardRails: StairwayGuardRail[] = [];
  const stairwayInnerGuardRails: StairwayInnerGuardRail[] = [];
  const bayLengthDir = FL.clone().setY(0).sub(BL.clone().setY(0)).normalize();

  const startVector = BR.clone()
    .setY(0)
    .sub(
      BR.clone()
        .setY(0)
        .sub(BL.clone().setY(0))
        .normalize()
        .multiplyScalar(stairWidth)
    );
  const stairPosition = calcCenterPositionsAlongLine({
    start: startVector,
    end: BR.clone().setY(0),
    partitions: [stairWidth],
    spacing: 0.01
  });

  const smallestAllowedBayWidth = Math.min(
    ...componentsOLedgers
      .filter((ledger) => ledger.length >= stairWidth)
      .map((ledger) => ledger.length)
  );
  const isSmallestBayWidth = width === smallestAllowedBayWidth;

  const sortedFrames = frames.sort((a, b) => b.height - a.height);

  sortedFrames.forEach(({ height }, idx) => {
    const suitableStair = getStair({
      sortedFrames,
      currentFrameIdx: idx,
      lowestCollarHeight: 0,
      length: depth
    });
    if (!suitableStair) return;

    const stairHeight = suitableStair.height ?? DEFAULT_STAIR_HEIGHT;

    const matchingStairwayGuardRail = componentsStairwayGuardrails.find(
      (component) =>
        component.length === depth && component.height === stairHeight
    );

    const matchingGuardRailComponent = isSmallestBayWidth
      ? matchingStairwayGuardRail
      : undefined;

    stairPosition.forEach((stairPosition) => {
      const moddedStairStartPosition = stairPosition
        .clone()
        .setY(height - stairHeight)
        .add(bayLengthDir.clone().multiplyScalar(depth - suitableStair.length));
      const stairway: Stairway = {
        id: genId(),
        position: roundVector(moddedStairStartPosition).toArray(),
        length: suitableStair.length,
        height: stairHeight,
        width: suitableStair.width,
        rotation: boxRotation,
        supplier: SCAFFOLD_SUPPLIER.SUPER9,
        componentId: suitableStair.article_id
      };
      stairways.push(stairway);

      if (matchingGuardRailComponent && stairwayGuardRail) {
        const startPos = isSmallestBayWidth
          ? BL.clone()
          : moddedStairStartPosition
              .clone()
              .sub(dir.clone().multiplyScalar(half(suitableStair.width)))
              .add(bayLengthDir.clone().multiplyScalar(half(depth)));
        const stairwayGuardRail: StairwayGuardRail = {
          id: genId(),
          position: roundVector(
            startPos.setY(height - stairHeight + 0.5)
          ).toArray(),
          length: matchingGuardRailComponent.length,
          height: matchingGuardRailComponent.height ?? DEFAULT_STAIR_HEIGHT,
          rotation: boxRotation,
          supplier: SCAFFOLD_SUPPLIER.SUPER9,
          componentId: matchingGuardRailComponent.article_id,
          ...(matchingGuardRailComponent.variant && {
            variant: matchingGuardRailComponent.variant
          })
        };
        stairwayGuardRails.push(stairwayGuardRail);
      }
    });
  });
  return { stairways, stairwayInnerGuardRails, stairwayGuardRails };
};

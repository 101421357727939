import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "shared/enums/scaffold";

export const BAYWIDTHS = {
  MM730: 0.73,
  MM1090: 1.09,
  MM1400: 1.4,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
};

export const LOADINGBAYWIDTHS = {
  MM730: 0.73,
  MM1090: 1.09,
  MM1400: 1.4,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
};

export const BAYLENGTHS = {
  MM150: 0.15,
  MM390: 0.39,
  MM450: 0.45,
  MM730: 0.73,
  MM1090: 1.09,
  MM1400: 1.4,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
  MM4140: 4.14,
};

export const LOADINGBAYLENGTHS = {
  MM730: 0.73,
  MM1090: 1.09,
  MM1400: 1.4,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
  MM4140: 4.14,
};

export const PLANKWIDTHS = {
  MM190: 0.19,
  MM320: 0.32,
  MM600: 0.6,
};

export const CONSOLEWIDTHS = {
  MM280: 0.28,
  MM390: 0.39,
  MM590: 0.59,
  MM730: 0.73,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const STAIRWIDTHS = {
  MM730: 0.73,
};

export const STAIRLENGTHS = {
  MM2570: 2.57,
  MM3070: 3.07,
};
export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINIUM: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  ROBUST: SCAFFOLD_PLANKTYPE.ROBUST,
  WOOD: SCAFFOLD_PLANKTYPE.WOOD,
};

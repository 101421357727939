export enum TUTORIAL_STEP {
  GROUND = "ground",
  HOUSE = "house",
  ROOF = "roof",
  SCAFFOLD = "scaffold",
  CORNER = "corner",
  REAL_RENDER = "realRender",
  COMPONENTS = "components",
  COMPLETE = "complete"
}

export enum GETTING_STARTED_STEP {
  LANGUAGE = "language",
  SCAFFOLD_SETTINGS = "scaffoldsettings",
  TUTORIAL = "tutorial"
}

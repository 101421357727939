import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Euler, Vector3 } from "three";
import { BaseBoard } from "./BaseBoard.types";
import { roundVector } from "suppliers/scaffold/scaffold.utils";

const BoardHeight = 0.045;
export const getGraphData = (basePlate: BaseBoard): GraphData => {
  const { position, rotation } = basePlate;
  let startPosition = new Vector3(...position);

  const endPosition = new Vector3(1, 0, 0)
    .applyEuler(new Euler(...rotation))
    .multiplyScalar(BoardHeight)
    .add(startPosition);

  return {
    startPosition: position,
    endPosition: roundVector(endPosition).toArray(),
    id: basePlate.id,
    type: ComponentType.BASE_BOARD
  };
};

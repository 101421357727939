import { StateCreator } from "zustand";
import { ByteTransferJobSlice } from "./byteTransferJob.types";

const createByteTransferJobSlice: StateCreator<
  ByteTransferJobSlice,
  [],
  [],
  ByteTransferJobSlice
> = (set) => ({
  byteTransferJobs: [],
  byteTransferJobActions: {
    add: (byteTransferJobs) =>
      set((state) => {
        return {
          byteTransferJobs: [...state.byteTransferJobs, ...byteTransferJobs]
        };
      }),
    remove: (ids) =>
      set((state) => {
        return {
          byteTransferJobs: state.byteTransferJobs.filter(
            (job) => !ids.includes(job.id)
          )
        };
      })
  }
});

export default createByteTransferJobSlice;

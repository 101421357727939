import { ThreeEvent } from "@react-three/fiber";
import { Box } from "shared/interfaces/firestore";
import { ColorRepresentation, Group, Vector3Tuple } from "three";
import { CoreProps } from "world/generic/types";

export enum BoxFace {
  FRONT = "front",
  BACK = "back",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom"
}

export interface BoxRef {
  group: Group | null;
  meshActions: {
    setDimensions: (props: {
      width: number;
      height: number;
      depth: number;
    }) => void;
    setPosition: (position: Vector3Tuple) => void;
    setColor: (color: ColorRepresentation) => void;
    clearColor: () => void;
  };
}

interface BoxProps
  extends Omit<
    CoreProps,
    "onPointerMove" | "onClick" | "onPointerDown" | "onPointerUp"
  > {
  width?: number;
  height?: number;
  depth?: number;
  anchor?: { x: number; y: number; z: number };
  onPointerMove?: (props: {
    event: ThreeEvent<PointerEvent>;
    face?: BoxFace;
    box?: Box;
  }) => void;
  onClick?: (props: {
    event: ThreeEvent<MouseEvent>;
    face?: BoxFace;
    box?: Box;
  }) => void;
  onPointerDown?: (props: {
    event: ThreeEvent<PointerEvent>;
    face?: BoxFace;
    box?: Box;
  }) => void;
  onPointerUp?: (props: {
    event: ThreeEvent<PointerEvent>;
    face?: BoxFace;
    box?: Box;
  }) => void;
  showAnchor?: boolean;
  showEdges?: boolean;
  clearMaterial?: boolean;
  hideFaces?: BoxFace[];
  originBox?: Box;
  hideEdges?: boolean;
  renderOrder?: number;
  frameVariant?: boolean;
}

export default BoxProps;

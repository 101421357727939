import { PaletteMode } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import ThemeProps from "./Theme.types";
import lightPalette from "./palettes/lightPalette";
import darkPalette from "./palettes/darkPalette";

declare module "@mui/material/styles" {
  interface Theme {
    background: {
      world: string;
      action: string;
      paperSecondary: string;
    };
  }
  interface ThemeOptions {
    background?: {
      world?: string;
      action?: string;
      paperSecondary?: string;
    };
  }
}

declare module "@mui/material/styles" {
  interface Palette extends PaletteOptions {
    primaryVariant: Palette["primary"];
    secondaryVariant: Palette["secondary"];
    world: {
      axes: {
        x: string;
        y: string;
        z: string;
      };
      box: {
        edge: string;
        main: string;
        hovered: string;
        selected: string;
      };
      boxDetails: {
        edge: string;
        main: string;
        hovered: string;
        selected: string;
      };
      edges: string;
      grid: string;
      ground: {
        edge: string;
        main: string;
        hovered: string;
        selected: string;
      };
      houseBody: {
        edge: string;
        main: string;
        hovered: string;
        selected: string;
      };
      placeholder: {
        main: string;
        mainContrast: string;
        snapped: string;
        snappedContrast: string;
      };
      realComponent: {
        main: string;
        hovered: string;
        selected: string;
      };
      roof: {
        main: string;
      };
    };
    trs: {
      bay: {
        main: string;
        selected: string;
        details: string;
        preview: string;
      };
      clothing: string;
      roof: { support: string; main: string };
    };
  }
  interface PaletteOptions {
    primaryVariant?: PaletteOptions["primary"];
    secondaryVariant?: PaletteOptions["secondary"];
    world?: {
      axes?: {
        x?: string;
        y?: string;
        z?: string;
      };
      box?: {
        edge?: string;
        main?: string;
        hovered?: string;
        selected?: string;
      };
      boxDetails?: {
        edge?: string;
        main?: string;
        hovered?: string;
        selected?: string;
      };
      edges?: string;
      grid?: string;
      ground?: {
        edge?: string;
        main?: string;
        hovered?: string;
        selected?: string;
      };
      houseBody?: {
        edge?: string;
        main?: string;
        hovered?: string;
        selected?: string;
      };
      placeholder?: {
        main?: string;
        mainContrast?: string;
        snapped?: string;
        snappedContrast?: string;
      };
      realComponent?: {
        main?: string;
        hovered?: string;
        selected?: string;
      };
      roof?: {
        main?: string;
      };
    };
    trs?: {
      bay?: {
        main?: string;
        selected?: string;
        details?: string;
        preview?: string;
      };
      clothing?: string;
      roof?: { support?: string; main?: string };
    };
  }
}

const Theme = (props: ThemeProps) => {
  const { mode = "dark" } = props;

  const palette = mode === "dark" ? darkPalette : lightPalette;
  const getColorMode = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...palette,
      contrastThreshold: 4.5,
      tonalOffset: 0.1,
      grey: {
        50: "hsl(219, 15%, 98%)",
        100: "hsl(219, 15%, 96%)",
        200: "hsl(219, 15%, 93%)",
        300: "hsl(219, 15%, 88%)",
        400: "hsl(219, 15%, 74%)",
        500: "hsl(219, 15%, 62%)",
        600: "hsl(219, 15%, 46%)",
        700: "hsl(219, 15%, 38%)",
        800: "hsl(219, 15%, 26%)",
        900: "hsl(219, 10%, 13%)",
        A100: "hsl(219, 15%, 96%)",
        A200: "hsl(219, 15%, 93%)",
        A400: "hsl(219, 15%, 74%)",
        A700: "hsl(219, 15%, 38%)"
      }
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            backgroundColor: palette.background.action
          }
        }
      },
      MuiCssBaseline: {
        styleOverrides: `
        *::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }
        *::-webkit-scrollbar-track {
          background: none;
        }
        *::-webkit-scrollbar-thumb {
          background-color: ${"hsl(219, 10%, 62%)"};
          border-radius: 2px;
        }
        *::-webkit-scrollbar-corner {
          background: none;
        }
      `
      }
    }
  });

  let theme = createTheme(getColorMode(mode));

  theme = responsiveFontSizes(theme);

  theme.typography.subtitle1 = {
    fontSize: "1rem",
    fontWeight: 600
  };

  theme.typography.subtitle2 = {
    fontSize: "0.875rem",
    textTransform: "uppercase",
    fontWeight: 400,
    letterSpacing: "0.04em"
  };

  theme.typography.h6 = {
    fontSize: "1.125rem",
    fontWeight: 600,
    letterSpacing: "0.01em"
  };

  return theme;
};

export default Theme;

import { StateCreator } from "zustand";
import { GuardRailSlice } from "./guardRail.interface";
import { Store } from "store/store.types";
import {
  addGuardRailsToGraph,
  removeGuardRailsFromGraph,
  setGuardRailsInGraph
} from "./guardRail.utils";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialGuardRailState = {
  guardRails: []
};

/** Slice creation */
const createGuardRailSlice: StateCreator<Store, [], [], GuardRailSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialGuardRailState));

  /** Return state */
  return {
    ...initialGuardRailState,
    addGuardRails: (guardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addGuardRailsToGraph({ graph: newGraph, guardRails });
        return {
          guardRail: [...state.guardRails, ...guardRails],
          graph: newGraph
        };
      });
    },
    setGuardRails: (newGuardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setGuardRailsInGraph({
          graph: newGraph,
          newGuardRails,
          oldGuardRails: [...state.guardRails]
        });

        return {
          guardRail: newGuardRails,
          graph: newGraph
        };
      });
    },
    removeGuardRails: (ids) => {
      set((state) => {
        const removeGuardRails = state.guardRails.filter((guardRail) =>
          ids.includes(guardRail.id)
        );

        const newGraph = state.graph.copy();
        removeGuardRailsFromGraph({
          graph: newGraph,
          guardRails: removeGuardRails
        });

        return {
          guardRail: state.guardRails.filter(
            (guardRail) => !ids.includes(guardRail.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createGuardRailSlice;

import { FIREBASE_PROJECTID } from "./enums";
import { FirebaseOptionsWithFunctions } from "./firebase.types";

export const prodConfig: FirebaseOptionsWithFunctions = {
  apiKey: "AIzaSyBqFey2ebIkd1aB19XdmCAS1K21UTHUTsE",
  authDomain: "scaffcalc-mikado.firebaseapp.com",
  projectId: FIREBASE_PROJECTID.SCAFFCALC_PROD,
  storageBucket: "scaffcalc-mikado.appspot.com",
  messagingSenderId: "590066036263",
  appId: "1:590066036263:web:cc8d49aee05dba8486a802",
  measurementId: "G-0T5G30GN93",
  functionsLink: "https://europe-west3-scaffcalc-mikado.cloudfunctions.net",
  femLink: ""
};

export const devConfig: FirebaseOptionsWithFunctions = {
  apiKey: "AIzaSyCfVOLi8psQKYzAbwhFoneUTY_JW0Qip2o",
  authDomain: "scaffcalc-mikado-dev.firebaseapp.com",
  projectId: FIREBASE_PROJECTID.SCAFFCALC_DEV,
  storageBucket: "scaffcalc-mikado-dev.appspot.com",
  messagingSenderId: "401864137187",
  appId: "1:401864137187:web:8cbccf9c5a6e78d4c528d6",
  measurementId: "G-MLCVW2RNNF",
  functionsLink: "https://europe-west3-scaffcalc-mikado-dev.cloudfunctions.net",
  femLink: ""
};

export const localConfig: FirebaseOptionsWithFunctions = {
  ...devConfig,
  functionsLink: "http://localhost:5001/scaffcalc-mikado-dev/europe-west3",
  femLink: "http://localhost:9090"
};

export const getConfig = (projectId?: FIREBASE_PROJECTID) => {
  if (projectId === FIREBASE_PROJECTID.SCAFFCALC_PROD) {
    return prodConfig;
  } else if (projectId === FIREBASE_PROJECTID.SCAFFCALC_DEV) {
    return devConfig;
  } else if (projectId === FIREBASE_PROJECTID.LOCAL) {
    return localConfig;
  } else {
    return devConfig;
  }
};

export default getConfig;

import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { getGraphData } from "world/core/BaseBoard/BaseBoard.utils";
import { BaseBoard } from "world/core/BaseBoard/BaseBoard.types";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addBaseBoardsToGraph = (props: {
  graph: Graph;
  baseBoards: BaseBoard[];
}) => {
  const { graph, baseBoards } = props;

  const baseBoardsGraphData = baseBoards.map((basePlate) =>
    getGraphData(basePlate)
  );

  addComponentsToGraph({ graph, components: baseBoardsGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeBaseBoardsFromGraph = (props: {
  graph: Graph;
  baseBoards: BaseBoard[];
}) => {
  const { graph, baseBoards } = props;

  const baseBoardsGraphData = baseBoards.map((basePlate) =>
    getGraphData(basePlate)
  );

  removeComponentsFromGraph({
    graph,
    components: baseBoardsGraphData,
    force: true
  });
};

export const setBaseBoardsInGraph = (props: {
  graph: Graph;
  newBaseBoards: BaseBoard[];
  oldBaseBoards: BaseBoard[];
}) => {
  const { graph, newBaseBoards, oldBaseBoards } = props;
  removeBaseBoardsFromGraph({ graph, baseBoards: oldBaseBoards });
  addBaseBoardsToGraph({ graph, baseBoards: newBaseBoards });
};

import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsAutoBoxSlice } from "./autoBox.types";
import { SCAFFOLD_PLANKTYPE, SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { getSupplierClass } from "pages/TrsPage/TrsPage.options";
import { INITIAL_COMPONENT_VARIANT } from "shared/types/box";

/** Initial state */
const initialToolsAutoBoxState = {
  toolsAutoBoxesAcceptAll: false,
  toolsAutoBoxBayHeightEnabled: false,
  toolsAutoBoxConsoleWidth: 0.39,
  toolsAutoBoxDiagonalPattern: null,
  toolsAutoBoxDistanceToWall: 0.3,
  toolsAutoBoxesLength: 0,
  toolsAutoBoxFallProtectionHeight: 1,
  toolsAutoBoxFallProtectionNet: false,
  toolsAutoBoxFramePattern: "second",
  toolsAutoBoxConsoleLevelPattern: "second",
  toolsAutoBoxInitialComponentVariant: INITIAL_COMPONENT_VARIANT.BASE_COLLAR,
  toolsAutoBoxAnchorLevelPattern: "second",
  toolsAutoBoxFrames: [10, 8, 6, 4, 2],
  toolsAutoBoxConsoleLevels: [10, 8, 6, 4, 2],
  toolsAutoBoxAnchorLevels: [10, 8, 6, 4, 2],
  toolsAutoBoxHeight: 10,
  toolsAutoBoxPlankMaterial: SCAFFOLD_PLANKTYPE.STEEL,
  toolsAutoBoxPlatformPattern: "second",
  toolsAutoBoxPlatforms: [10, 8, 6, 4, 2],
  toolsAutoBoxSupplier: SCAFFOLD_SUPPLIER.LAYHER,
  toolsAutoBoxTopVariant: null,
  toolsAutoBoxWidth: 0.73,
  toolsAutoBoxDepths: Object.values(
    getSupplierClass(SCAFFOLD_SUPPLIER.LAYHER)?.bayLengths || []
  ),
  toolsAutoBoxAllSameHeight: true,
  toolsAutoBoxGuardRails: [false, false, false, true] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsAutoBoxConsoles: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsAutoBoxDiagonals: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsAutoBoxAnchors: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ]
};

const createToolsAutoBoxSlice: StateCreator<
  ToolsAutoBoxSlice,
  [],
  [],
  ToolsAutoBoxSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsAutoBoxState));

  /** Return state */
  return {
    ...initialToolsAutoBoxState,
    toolsAutoBoxActions: {
      setToolsAutoBoxHeight: (height) => {
        set({ toolsAutoBoxHeight: height });
      },
      setToolsAutoBoxDepths: (depths) => {
        set({ toolsAutoBoxDepths: depths });
      },
      setToolsAutoBoxWidth: (width) => {
        set({ toolsAutoBoxWidth: width });
      },
      setToolsAutoBoxInitialComponentVariant: (variant) => {
        set({ toolsAutoBoxInitialComponentVariant: variant });
      },
      setToolsAutoBoxGuardRails: (options) => {
        set({ toolsAutoBoxGuardRails: options });
      },
      setToolsAutoBoxFrames: (frames) => {
        set({ toolsAutoBoxFrames: frames });
      },
      setToolsAutoBoxPlatforms: (platforms) => {
        set({ toolsAutoBoxPlatforms: platforms });
      },
      setToolsAutoBoxPlankMaterial: (material) => {
        set({ toolsAutoBoxPlankMaterial: material });
      },
      setToolsAutoBoxConsoleLevels: (levels) => {
        set({ toolsAutoBoxConsoleLevels: levels });
      },
      setToolsAutoBoxAnchorLevelPattern: (pattern) => {
        set({ toolsAutoBoxAnchorLevelPattern: pattern });
      },
      setToolsAutoBoxAnchorLevels: (levels) => {
        set({ toolsAutoBoxAnchorLevels: levels });
      },
      setToolsAutoBoxConsoleLevelPattern: (pattern) => {
        set({ toolsAutoBoxConsoleLevelPattern: pattern });
      },
      setToolsAutoBoxConsoles: (options) => {
        set({ toolsAutoBoxConsoles: options });
      },
      setToolsAutoBoxConsoleWidth: (width) => {
        set({ toolsAutoBoxConsoleWidth: width });
      },
      setToolsAutoBoxFallProtectionHeight: (height) => {
        set({ toolsAutoBoxFallProtectionHeight: height });
      },
      setToolsAutoBoxFallProtectionNet: (net) => {
        set({ toolsAutoBoxFallProtectionNet: net });
      },
      setToolsAutoBoxTopVariant: (variant) => {
        set({ toolsAutoBoxTopVariant: variant });
      },
      setToolsAutoBoxDistanceToWall: (distance) => {
        set({ toolsAutoBoxDistanceToWall: distance });
      },
      setToolsAutoBoxSupplier: (supplier) => {
        set({ toolsAutoBoxSupplier: supplier });
      },
      setToolsAutoBoxAnchors: (options) => {
        set({ toolsAutoBoxAnchors: options });
      },
      setToolsAutoBoxBayHeightEnabled: (bayHeightEnabled: boolean) => {
        set({ toolsAutoBoxBayHeightEnabled: bayHeightEnabled });
      },
      setToolsAutoBoxDiagonals: (options) => {
        set({ toolsAutoBoxDiagonals: options });
      },
      setToolsAutoBoxDiagonalPattern: (pattern) => {
        set({ toolsAutoBoxDiagonalPattern: pattern });
      },
      setToolsAutoBoxesAcceptAll: (accept) => {
        set({ toolsAutoBoxesAcceptAll: accept });
      },
      setToolsAutoBoxesLength: (length) => {
        set({ toolsAutoBoxesLength: length });
      },
      setToolsAutoBoxAllSameHeight: (allSameHeight) => {
        set({ toolsAutoBoxAllSameHeight: allSameHeight });
      },
      setToolsAutoBoxFramePattern: (pattern) => {
        set({ toolsAutoBoxFramePattern: pattern });
      },
      setToolsAutoBoxPlatformPattern: (pattern) => {
        set({ toolsAutoBoxPlatformPattern: pattern });
      }
    }
  };
};

export default createToolsAutoBoxSlice;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsCouplers: Component[] = [
  {
    label_no: "forankringskobling",
    label_se: "Förankringskoppling",
    article_id: "xxxx.xxx",
    material: "",
    length: 0.1,
    label_en: "Anchoring coupling",
    weight: 1.3,
    width: 0
  }
];

export const componentsConsoles: Component[] = [
  {
    label_se: "Konsol 0.4m",
    width: 0,
    label_en: "Console 0.4",
    article_id: "SF MPP 155 00",
    weight: 3.4,
    length: 0.4,
    material: "",
    label_no: "Konsol 0.4m"
  },
  {
    label_se: "Konsol 0.75m",
    width: 0,
    label_en: "Console 0.75",
    article_id: "SF MPP 155 01",
    weight: 6,
    length: 0.75,
    material: "",
    label_no: "Konsol 0.75m"
  }
];

export const componentsAnchors: Component[] = [
  {
    width: 0,
    label_se: "Förankringsrör",
    material: "",
    label_en: "Wall tie",
    weight: 1,
    length: 0,
    label_no: "Rörforankring",
    article_id: ""
  }
];

export const componentsScrews: Component[] = [
  {
    label_en: "Ring screw",
    article_id: "4009.096",
    weight: 1.6,
    material: "",
    length: 0.095,
    label_no: "Forankringsløkke",
    width: 0.012,
    label_se: "Förankringsögla"
  }
];

export const componentsULedgers: Component[] = [
  {
    length: 0.4,
    label_no: "Inplankningsbom 0.4m",
    weight: 2,
    article_id: "SF MPP 090 00",
    width: 0,
    material: "",
    label_se: "Inplankningsbom 0.4m",
    label_en: "Inplankningsbom 0.4m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    length: 1.1,
    weight: 5.7,
    article_id: "SF MPP 090 02",
    label_en: "Inplankningsbom 1.1m",
    material: "",
    width: 0,
    label_no: "Inplankningsbom 1.1m",
    label_se: "Inplankningsbom 1.1m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    length: 0.74,
    label_en: "Inplankningsbom 0.74m",
    label_se: "Inplankningsbom 0.74m",
    label_no: "Inplankningsbom 0.74m",
    material: "",
    article_id: "SF MPP 090 01",
    weight: 3.2,
    width: 0,
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    label_en: "Inplankningsbom 1.4m",
    article_id: "SF MPP 090 03",
    weight: 7.2,
    label_no: "Inplankningsbom 1.4m",
    label_se: "Inplankningsbom 1.4m",
    length: 1.4,
    material: "",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    label_se: "Avlastningsbom 1.5m",
    material: "",
    width: 0,
    label_no: "Avlastningsbom 1.5m",
    article_id: "SF MPP 095 00",
    length: 1.5,
    label_en: "Avlastningsbom 1.5m",
    weight: 9.4,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_en: "Avlastningsbom 2m",
    length: 2,
    article_id: "SF MPP 095 01",
    label_no: "Avlastningsbom 2m",
    weight: 14,
    label_se: "Avlastningsbom 2m",
    material: "",
    width: 0,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    material: "",
    label_en: "Avlastningsbom 2.5m",
    label_se: "Avlastningsbom 2.5m",
    length: 2.5,
    article_id: "SF MPP 095 02",
    weight: 16.8,
    width: 0,
    label_no: "Avlastningsbom 2.5m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_en: "Avlastningsbom 3m",
    width: 0,
    article_id: "SF MPP 095 03",
    material: "",
    length: 3,
    label_no: "Avlastningsbom 3m",
    weight: 20.1,
    label_se: "Avlastningsbom 3m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsBridgingBeams: Component[] = [
  {
    label_se: "Indäckningsbalk SL 7.5m",
    length: 7.5,
    material: "",
    width: 0,
    label_en: "Indäckningsbalk SL 7.5m",
    label_no: "Indäckningsbalk SL 7.5m",
    weight: 84.4,
    article_id: "SF MPP 160 03",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 4,
    weight: 45.9,
    label_en: "Indäckningsbalk SL 4m",
    article_id: "SF MPP 160 00",
    width: 0,
    material: "",
    label_se: "Indäckningsbalk SL 4m",
    label_no: "Indäckningsbalk SL 4m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "SF MPP 160 01",
    length: 5,
    material: "",
    label_no: "Indäckningsbalk SL 5m",
    label_se: "Indäckningsbalk SL 5m",
    weight: 56.8,
    label_en: "Indäckningsbalk SL 5m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    weight: 67.7,
    label_no: "Indäckningsbalk SL 6m",
    length: 6,
    article_id: "SF MPP 160 02",
    label_en: "Indäckningsbalk SL 6m",
    material: "",
    label_se: "Indäckningsbalk SL 6m",
    width: 0,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsOLedgers: Component[] = [
  {
    length: 1.4,
    width: 0,
    label_en: "Horisontalstag 1.4m",
    article_id: "SF MPP 025 07",
    material: "",
    weight: 5.6,
    label_no: "Horisontalstag 1.4m",
    label_se: "Horisontalstag 1.4m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_no: "Horisontalstag 0.25m",
    length: 0.25,
    label_en: "Horisontalstag 0.25m",
    label_se: "Horisontalstag 0.25m",
    width: 0,
    material: "",
    weight: 1.5,
    article_id: "SF MPP 025 00",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "Horisontalstag 0.74",
    width: 0,
    weight: 3.3,
    length: 0.74,
    label_no: "Horisontalstag 0.74",
    label_se: "Horisontalstag 0.74",
    article_id: "SF MPP 025 03",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    material: "",
    article_id: "SF MPP 025 04",
    label_se: "Horisontalstag 0.75",
    length: 0.75,
    label_en: "Horisontalstag 0.75",
    weight: 3.3,
    label_no: "Horisontalstag 0.75",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    length: 4,
    material: "",
    label_no: "Horisontalstag 4m",
    width: 0,
    weight: 15,
    label_se: "Horisontalstag 4m",
    label_en: "Horisontalstag 4m",
    article_id: "SF MPP 025 12",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    weight: 4.2,
    article_id: "SF MPP 025 05",
    label_no: "Horisontalstag 1m",
    label_se: "Horisontalstag 1m",
    width: 0,
    material: "",
    label_en: "Horisontalstag 1m",
    length: 1,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    article_id: "SF MPP 025 06",
    label_se: "Horisontalstag 1.1m",
    weight: 4.4,
    length: 1.1,
    label_no: "Horisontalstag 1.1m",
    material: "",
    label_en: "Horisontalstag 1.1m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    article_id: "SF MPP 025 10",
    label_en: "Horisontalstag 2.5m",
    label_no: "Horisontalstag 2.5m",
    label_se: "Horisontalstag 2.5m",
    length: 2.5,
    weight: 9.6,
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    weight: 11.4,
    material: "",
    label_en: "Horisontalstag 3m",
    label_se: "Horisontalstag 3m",
    length: 3,
    label_no: "Horisontalstag 3m",
    width: 0,
    article_id: "SF MPP 025 11",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    length: 1.5,
    width: 0,
    weight: 6,
    material: "",
    article_id: "SF MPP 025 08",
    label_se: "Horisontalstag 1.5m",
    label_en: "Horisontalstag 1.5m",
    label_no: "Horisontalstag 1.5m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_se: "Horisontalstag 0.4m",
    weight: 2.1,
    material: "",
    width: 0,
    label_en: "Horisontalstag 0.4m",
    article_id: "SF MPP 025 01",
    length: 0.4,
    label_no: "Horisontalstag 0.4m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    material: "",
    width: 0,
    weight: 2.4,
    label_en: "Horisontalstag 0.5m",
    label_se: "Horisontalstag 0.5m",
    label_no: "Horisontalstag 0.5m",
    length: 0.5,
    article_id: "SF MPP 025 02",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    weight: 7.8,
    material: "",
    article_id: "SF MPP 025 09",
    label_en: "Horisontalstag 2m",
    label_se: "Horisontalstag 2m",
    label_no: "Horisontalstag 2m",
    length: 2,
    width: 0,
    variant: LEDGER_VARIANT.O_LEDGER
  }
];

export const componentsToeBoards: Component[] = [
  {
    length: 0.74,
    label_se: "Fotlist 0.74m",
    label_no: "Fotlist 0.74m",
    weight: 1.6,
    material: "",
    width: 0.15,
    article_id: "SF MPP 195 05",
    label_en: "Fotlist 0.74m"
  },
  {
    length: 0.75,
    label_se: "Fotlist 0.75m",
    label_no: "Fotlist 0.75m",
    weight: 1.6,
    material: "",
    width: 0.15,
    article_id: "SF MPP 195 05",
    label_en: "Fotlist 0.75m"
  },
  {
    length: 1,
    label_se: "Fotlist 1m",
    label_no: "Fotlist 1m",
    weight: 2.1,
    material: "",
    width: 0.15,
    article_id: "SF MPP 195 04",
    label_en: "Fotlist 1m"
  },
  {
    label_en: "Fotlist 2.5m",
    length: 2.5,
    width: 0.15,
    weight: 4.9,
    label_se: "Fotlist 2.5m",
    material: "",
    label_no: "Fotlist 2.5m",
    article_id: "SF MPP 195 01"
  },
  {
    article_id: "SF MPP 195 00",
    weight: 5.8,
    length: 3,
    material: "",
    width: 0.15,
    label_se: "Fotlist 3m",
    label_no: "Fotlist 3m",
    label_en: "Fotlist 3m"
  },
  {
    label_se: "Fotlist 1.5m",
    width: 0.15,
    label_en: "Fotlist 1.5m",
    material: "",
    article_id: "SF MPP 195 03",
    length: 1.5,
    label_no: "Fotlist 1.5m",
    weight: 3
  }
];

export const componentsDecks: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Stålplank 34, 1.5m",
    width: 0.34,
    article_id: "SF MPP 205 03",
    label_no: "Stålplank 34, 1.5m",
    weight: 12.2,
    length: 1.5,
    label_se: "Stålplank 34, 1.5m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Stålplank 34, 2m",
    width: 0.34,
    article_id: "SF MPP 205 03",
    label_no: "Stålplank 34, 2m",
    weight: 17.3,
    length: 2,
    label_se: "Stålplank 34, 2m"
  },
  {
    weight: 18.1,
    label_no: "Träplank 2m",
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.WOOD,
    label_en: "Träplank 2m",
    article_id: "SF MPP 180 02",
    label_se: "Träplank 2m",
    length: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.WOOD,
    label_en: "Träplank 3m",
    length: 3,
    weight: 25.8,
    article_id: "SF MPP 180 00",
    width: 0.32,
    label_no: "Träplank 3m",
    label_se: "Träplank 3m"
  },
  {
    label_no: "Stålplank 32, 1.5m",
    width: 0.32,
    label_se: "Stålplank 32, 1.5m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Stålplank 32, 1.5m",
    length: 1.5,
    weight: 13.6,
    article_id: "SF MPP 140 03"
  },
  {
    weight: 22.3,
    length: 3,
    article_id: "SF MPP 205 00",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Stålplank 34, 3m",
    label_se: "Stålplank 34, 3m",
    width: 0.34,
    label_no: "Stålplank 34, 3m"
  },
  {
    label_se: "Stålplank 32, 1m",
    article_id: "SF MPP 140 05",
    length: 1,
    width: 0,
    weight: 9.9,
    label_no: "Stålplank 32, 1m",
    label_en: "Stålplank 32, 1m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    width: 0.32,
    label_en: "Aluminiumplank 2.5m",
    weight: 13.7,
    article_id: "SF MPP 135 01",
    label_se: "Aluminiumplank 2.5m",
    length: 2.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluminiumplank 2.5m"
  },
  {
    length: 2,
    label_en: "Aluminiumplank 2m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "SF MPP 135 02",
    weight: 11.6,
    width: 0.32,
    label_no: "Aluminiumplank 2m",
    label_se: "Aluminiumplank 2m"
  },
  {
    label_no: "Stålplank 32, 1.1m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.32,
    length: 1.1,
    weight: 10.3,
    label_en: "Stålplank 32, 1.1m",
    article_id: "SF MPP 140 04",
    label_se: "Stålplank 32, 1.1m"
  },
  {
    width: 0.32,
    label_en: "Stålplank 32, 2m",
    weight: 17.3,
    label_no: "Stålplank 32, 2m",
    length: 2,
    article_id: "SF MPP 140 02",
    label_se: "Stålplank 32, 2m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    weight: 8.1,
    label_no: "Stålplank 32, 0.75m",
    label_en: "Stålplank 32, 0.75m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.32,
    length: 0.75,
    label_se: "Stålplank 32, 0.75m",
    article_id: "SF MPP 140 06"
  },
  {
    width: 0.34,
    length: 2,
    label_no: "Stålplank 34, 2m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 34, 2m",
    article_id: "SF MPP 205 02",
    label_en: "Stålplank 34, 2m",
    weight: 15.6
  },
  {
    width: 0.34,
    length: 1,
    weight: 8.8,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 34, 1m",
    article_id: "SF MPP 205 04",
    label_en: "Stålplank 34, 1m",
    label_no: "Stålplank 34, 1m"
  },
  {
    label_se: "Träplank 1.5m",
    label_en: "Träplank 1.5m",
    label_no: "Träplank 1.5m",
    article_id: "SF MPP 180 03",
    width: 0.32,
    weight: 14.3,
    length: 1.5,
    material: SCAFFOLD_PLANKTYPE.WOOD
  },
  {
    label_en: "Aluminiumplank 1.5m",
    length: 1.5,
    label_se: "Aluminiumplank 1.5m",
    width: 0.32,
    weight: 9.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluminiumplank 1.5m",
    article_id: "SF MPP 135 03"
  },
  {
    label_en: "Stålplank 34, 2.5m",
    label_no: "Stålplank 34, 2.5m",
    length: 2.5,
    article_id: "SF MPP 205 01",
    label_se: "Stålplank 34, 2.5m",
    weight: 19,
    width: 0.34,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    article_id: "SF MPP 140 00",
    width: 0.32,
    weight: 24.6,
    label_en: "Stålplank 32, 3m",
    label_se: "Stålplank 32, 3m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank 32, 3m",
    length: 3
  },
  {
    width: 0.32,
    article_id: "SF MPP 140 01",
    label_no: "Stålplank 32, 2.5m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 2.5,
    weight: 21,
    label_se: "Stålplank 32, 2.5m",
    label_en: "Stålplank 32, 2.5m"
  },
  {
    weight: 15.7,
    label_en: "Aluminiumplank 3m",
    label_se: "Aluminiumplank 3m",
    length: 3,
    article_id: "SF MPP 135 00",
    width: 0.32,
    label_no: "Aluminiumplank 3m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    length: 2.5,
    weight: 22,
    label_no: "Träplank 2.5m",
    article_id: "SF MPP 180 01",
    material: SCAFFOLD_PLANKTYPE.WOOD,
    label_se: "Träplank 2.5m",
    label_en: "Träplank 2.5m",
    width: 0.32
  },
  {
    length: 0.75,
    label_se: "Stålplank 34, 0.75m",
    label_no: "Stålplank 34, 0.75m",
    width: 0.34,
    label_en: "Stålplank 34, 0.75m",
    weight: 7.1,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "SF MPP 205 05"
  }
];

export const componentsBaseCollars: Component[] = [
  {
    article_id: "SF MPP 020 00",
    weight: 2.1,
    width: 0,
    material: "",
    length: 0.33,
    label_en: "Base collar",
    label_no: "Startkrans",
    label_se: "Startkrans"
  }
];

export const componentsBaseplates: Component[] = [
  {
    length: 0.5,
    width: 0,
    label_se: "Bottenskruv ",
    material: "",
    label_no: "Bunnskrue ",
    article_id: "",
    label_en: "Bottom screw ",
    weight: 5.3
  }
];

export const componentsLiftOffPreventers: Component[] = [];

export const componentsStandards: Component[] = [
  {
    label_no: "Vertikalspire 2.5m",
    length: 2.5,
    label_en: "Vertikalspira 2.5m",
    article_id: "SF MPP 010 04",
    width: 0,
    label_se: "Vertikalspira 2.5m",
    material: "",
    weight: 12.1
  },
  {
    label_en: "Vertikalspira 3m",
    label_se: "Vertikalspira 3m",
    material: "",
    label_no: "Vertikalspira 3m",
    width: 0,
    length: 3,
    article_id: "SF MPP 010 05",
    weight: 14.3
  },
  {
    label_no: "Vertikalspire 2m",
    width: 0,
    weight: 9.8,
    label_se: "Vertikalspira 2m",
    label_en: "Vertical Standard 2m",
    material: "",
    article_id: "SF MPP 010 03",
    length: 2
  },
  {
    length: 0.5,
    weight: 3.2,
    label_en: "Verical Standard 0.5m",
    material: "",
    width: 0,
    label_se: "Vertikalspira 0.5m",
    article_id: "SF MPP 010 00",
    label_no: "Vertikalspire 0.5m"
  },
  {
    weight: 7.6,
    width: 0,
    label_en: "Vertical Standard 1.5m",
    length: 1.5,
    label_se: "Vertikalspira 1.5m",
    article_id: "SF MPP 010 02",
    label_no: "Vertikalspire 1.5m",
    material: ""
  },
  {
    material: "",
    length: 4,
    weight: 18.7,
    article_id: "SF MPP 010 06",
    label_se: "Vertikalspira 4m",
    width: 0,
    label_en: "Vertikalspira 4m",
    label_no: "Vertikalspira 4m"
  },
  {
    width: 0,
    label_en: "Vertical Standard 1m",
    material: "",
    label_se: "Vertikalspira 1m",
    weight: 5.4,
    label_no: "Vertikalspire 1m",
    length: 1,
    article_id: "SF MPP 010 01"
  }
];

export const componentsStartStandards: Component[] = [
  {
    label_no: "Startspire 1.16m",
    length: 1.16,
    label_en: "Start Standard 1.16m",
    article_id: "SF MPP 015 00",
    width: 0,
    label_se: "Startspira 1.16m",
    material: "",
    weight: 6.4
  },
  {
    label_no: "Startspire 2.16m",
    length: 2.16,
    label_en: "Start Standard 2.16m",
    article_id: "SF MPP 015 01",
    width: 0,
    label_se: "Startspira 2.16m",
    material: "",
    weight: 10.8
  },
  {
    label_no: "Startspire 3.16m",
    length: 3.16,
    label_en: "Start Standard 3.16m",
    article_id: "SF MPP 015 02",
    width: 0,
    label_se: "Startspira 3.16m",
    material: "",
    weight: 15.3
  },
  {
    label_no: "Startspire 4.16m",
    length: 4.16,
    label_en: "Start Standard 4.16m",
    article_id: "SF MPP 015 03",
    width: 0,
    label_se: "Startspira 4.16m",
    material: "",
    weight: 19.7
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    length: 2.5,
    label_no: "Handledare",
    label_en: "Handledare",
    article_id: "SF MPP 120 00",
    weight: 12.5,
    label_se: "Handledare",
    material: "",
    width: 0,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER,
    height: 2
  }
];

export const componentsStairs: Component[] = [
  {
    label_en: "Aluminiumtrappa",
    weight: 24,
    length: 2.5,
    width: 0.64,
    label_no: "Aluminiumtrappa",
    article_id: "SF MPP 175 00",
    label_se: "Aluminiumtrappa",
    material: "",
    height: 2
  }
];

export const componentsDiagonalBraces: Component[] = [
  /** 2m diagonal braces */
  {
    label_se: "Vertikaldiagonalstag 1.4x2m",
    label_en: "Vertikaldiagonalstag 1.4x2m",
    article_id: "SF MPP 050 03",
    width: 0,
    weight: 8.7,
    label_no: "Vertikaldiagonalstag 1.4x2m",
    material: "",
    length: 1.4,
    height: 2
  },
  {
    length: 2,
    label_no: "Vertikaldiagonalstag 2x2m",
    weight: 9.8,
    width: 0,
    material: "",
    label_en: "Vertikaldiagonalstag 2x2m",
    label_se: "Vertikaldiagonalstag 2x2m",
    article_id: "SF MPP 050 05",
    height: 2
  },
  {
    width: 0,
    label_se: "Vertikaldiagonalstag 2.5x2m",
    material: "",
    weight: 10.9,
    label_en: "Vertikaldiagonalstag 2.5x2m",
    article_id: "SF MPP 050 06",
    label_no: "Vertikaldiagonalstag 2.5x2m",
    length: 2.5,
    height: 2
  },
  {
    article_id: "SF MPP 050 07",
    length: 3,
    label_en: "Vertikaldiagonalstag 3x2m",
    weight: 12.1,
    width: 0,
    label_no: "Vertikaldiagonalstag 3x2m",
    material: "",
    label_se: "Vertikaldiagonalstag 3x2m",
    height: 2
  },
  {
    width: 0,
    material: "",
    label_en: "Vertikaldiagonalstag 0.74x2m",
    label_no: "Vertikaldiagonalstag 0.74x2m",
    weight: 8,
    length: 0.74,
    label_se: "Vertikaldiagonalstag 0.74x2m",
    article_id: "SF MPP 050 00",
    height: 2
  },
  {
    width: 0,
    material: "",
    label_en: "Vertikaldiagonalstag 0.75x2m",
    label_no: "Vertikaldiagonalstag 0.75x2m",
    weight: 8,
    length: 0.75,
    label_se: "Vertikaldiagonalstag 0.75x2m",
    article_id: "SF MPP 050 00",
    height: 2
  },
  {
    label_se: "Vertikaldiagonalstag 1.1x2m",
    width: 0,
    length: 1.1,
    weight: 8.3,
    article_id: "SF MPP 050 02",
    material: "",
    label_no: "Vertikaldiagonalstag 1.1x2m",
    label_en: "Vertikaldiagonalstag 1.1x2m",
    height: 2
  },
  {
    label_en: "Vertikaldiagonalstag 1x2m",
    length: 1,
    label_no: "Vertikaldiagonalstag 1x2m",
    label_se: "Vertikaldiagonalstag 1x2m",
    article_id: "SF MPP 050 01",
    material: "",
    width: 0,
    weight: 8.2,
    height: 2
  },
  {
    label_se: "Vertikaldiagonalstag 1.5x2m",
    width: 0,
    label_en: "Vertikaldiagonalstag 1.5x2m",
    article_id: "SF MPP 050 04",
    weight: 8.9,
    length: 1.5,
    material: "",
    label_no: "Vertikaldiagonalstag 1.5x2m",
    height: 2
  },

  /** 1.5m diagonal braces */
  {
    label_se: "Vertikaldiagonalstag 1.5x1.5m",
    width: 0,
    label_en: "Vertical diagonal brace 1.5x1.5m",
    article_id: "SF MPP 050 08",
    weight: 7.7,
    length: 1.5,
    material: "",
    label_no: "Vertikal diagonalstag 1.5x1.5m",
    height: 1.5
  },
  {
    label_se: "Vertikaldiagonalstag 2x1.5m",
    width: 0,
    label_en: "Vertical diagonal brace 2x1.5m",
    article_id: "SF MPP 050 09",
    weight: 8.8,
    length: 2,
    material: "",
    label_no: "Vertikal diagonalstag 2x1.5m",
    height: 1.5
  },
  {
    label_se: "Vertikaldiagonalstag21.5x1.5m",
    width: 0,
    label_en: "Vertical diagonal brace 2.5x1.5m",
    article_id: "SF MPP 050 10",
    weight: 10,
    length: 2.5,
    material: "",
    label_no: "Vertikal diagonalstag 2.5x1.5m",
    height: 1.5
  },
  {
    label_se: "Vertikaldiagonalstag 3x1.5m",
    width: 0,
    label_en: "Vertical diagonal brace 3x1.5m",
    article_id: "SF MPP 050 11",
    weight: 11.3,
    length: 3,
    material: "",
    label_no: "Vertikal diagonalstag 3x1.5m",
    height: 1.5
  },
  /** 1m diagonal braces */
  {
    label_se: "Vertikaldiagonalstag 3x1m",
    width: 0,
    label_en: "Vertical diagonal brace 3x1m",
    article_id: "SF MPP 050 15",
    weight: 10.7,
    length: 3,
    material: "",
    label_no: "Vertikal diagonalstag 3x1m",
    height: 1
  },
  {
    label_se: "Vertikaldiagonalstag 2.5x1m",
    width: 0,
    label_en: "Vertical diagonal brace 2.5x1m",
    article_id: "SF MPP 050 14",
    weight: 9.3,
    length: 2.5,
    material: "",
    label_no: "Vertikal diagonalstag 2.5x1m",
    height: 1
  },
  {
    label_se: "Vertikaldiagonalstag 2x1m",
    width: 0,
    label_en: "Vertical diagonal brace 2x1m",
    article_id: "SF MPP 050 13",
    weight: 8,
    length: 2,
    material: "",
    label_no: "Vertikal diagonalstag 2x1m",
    height: 1
  },
  {
    label_se: "Vertikaldiagonalstag 1.5x1m",
    width: 0,
    label_en: "Vertical diagonal brace 1.5x1m",
    article_id: "SF MPP 050 12",
    weight: 6.7,
    length: 1.5,
    material: "",
    label_no: "Vertikal diagonalstag 1.5x1m",
    height: 1
  }
];
export const componentsBeamSpigots: Component[] = [
  {
    length: 0.3,
    width: 0,
    label_se: "Skarvtapp U-balk",
    material: "",
    label_no: "Bjelkespiss U-bjelke",
    article_id: "SF MPP 100 00",
    label_en: "Spigot for U-section",
    weight: 1.7
  }
];

import { StateCreator } from "zustand";
import { CompanySlice } from "./company.types";

const createCompanySlice: StateCreator<CompanySlice, [], [], CompanySlice> = (
  set
) => ({
  companyName: "",
  companyId: "",
  companyUsers: [],
  companyLogoUrl: undefined,
  companyQuotaLimit: 0,
  companyQuotaRenewalDays: 0,
  companyQuotaStartDate: new Date(),
  companyQuotaEndDate: new Date(),
  companyQuotaUsage: 0,
  companyActions: {
    setCompanyName: (name) => set({ companyName: name }),
    setCompanyId: (id) => set({ companyId: id }),
    setCompanyUsers: (users) => set({ companyUsers: users }),
    setCompanyLogoUrl: (url) => set({ companyLogoUrl: url }),
    setCompanyQuotaEndDate: (date) => set({ companyQuotaEndDate: date }),
    setCompanyQuotaLimit: (limit) => set({ companyQuotaLimit: limit }),
    setCompanyQuotaRenewalDays: (days) =>
      set({ companyQuotaRenewalDays: days }),
    setCompanyQuotaStartDate: (date) => set({ companyQuotaStartDate: date }),
    setCompanyQuotaUsage: (usage) => set({ companyQuotaUsage: usage })
  }
});

export default createCompanySlice;

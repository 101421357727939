import { StateCreator } from "zustand";
import { TrsSlice } from "./trs.types";

const createTrsSlice: StateCreator<TrsSlice, [], [], TrsSlice> = (set) => ({
  trsViewMode: "front",
  trsToolMode: "select",
  trsActions: {
    setTrsViewMode: (trsViewMode: "front" | "side") => set({ trsViewMode }),
    setTrsToolMode: (trsToolMode: "select" | "bays" | "anchors") =>
      set({ trsToolMode })
  }
});

export default createTrsSlice;

import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { StairwayGuardRailSlice } from "./stairwayGuardrail.interface";
import {
  addStairwayGuardRailsToGraph,
  removeStairwayGuardRailsFromGraph,
  setStairwayGuardRailsInGraph
} from "./stairwayGuardRails.utils";

/** Initial state */
const initialStairwayGuardRailState = {
  stairwayGuardRails: []
};

/** Slice creation */
const createStairwayGuardRailSlice: StateCreator<
  Store,
  [],
  [],
  StairwayGuardRailSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialStairwayGuardRailState));

  /** Return state */
  return {
    ...initialStairwayGuardRailState,
    addStairwayGuardRails: (stairwayGuardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addStairwayGuardRailsToGraph({
          graph: newGraph,
          stairwayGuardRails
        });

        return {
          stairwayGuardRails: [
            ...state.stairwayGuardRails,
            ...stairwayGuardRails
          ],
          graph: newGraph
        };
      });
    },
    setStairwayGuardRails: (stairwayGuardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setStairwayGuardRailsInGraph({
          graph: newGraph,
          newStairwayGuardRails: stairwayGuardRails,
          oldStairwayGuardRails: [...state.stairwayGuardRails]
        });

        return {
          stairwayGuardRails,
          graph: newGraph
        };
      });
    },
    removeStairwayGuardRails: (ids) => {
      set((state) => {
        const removeStairwayGuardRails = [...state.stairwayGuardRails];
        const newGraph = state.graph.copy();
        removeStairwayGuardRailsFromGraph({
          graph: newGraph,
          stairwayGuardRails: removeStairwayGuardRails
        });
        return {
          stairwayGuardRails: state.stairwayGuardRails.filter(
            (stairwayGuardRail) => !ids.includes(stairwayGuardRail.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createStairwayGuardRailSlice;

import useStoreWithUndo, { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { IfcModelWithIndexSignature, IfcSlice } from "./ifc.interface";
import { isVector3Tuple } from "validation/three";
import { Vector3Tuple } from "three";
import { updateSelectedObjectsPositionRotation } from "../world.utils";

/** Initial state */
const initialIfcState = {
  activeIfcModels: [],
  ifcModels: [],
  ifcModelPlaceholders: []
};

/** Slice creation */
const createIfcSlice: StateCreator<Store, [], [], IfcSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialIfcState));

  /** Return state */
  return {
    ...initialIfcState,
    ifcActions: {
      add: (groups) =>
        set((state) => ({
          ifcModels: [...state.ifcModels, ...groups]
        })),
      set: (ifcModels) => set({ ifcModels }),
      setPlaceholders: (ifcModelPlaceholders) => set({ ifcModelPlaceholders }),
      update: (ids, data) => {
        set((state) => {
          return {
            ifcModels: state.ifcModels.map((ifcModel) => {
              if (!ids.includes(ifcModel.id)) return ifcModel;

              const cleanData = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  if (isVector3Tuple(value)) {
                    const blueprintKeyValue = ifcModel[key] as Vector3Tuple;

                    acc[key] = value.map((v, idx) =>
                      isNaN(v) ? blueprintKeyValue[idx] : v
                    ) as Vector3Tuple;
                  } else {
                    acc[key] = value;
                  }

                  return acc;
                },
                {} as Partial<IfcModelWithIndexSignature>
              );
              return {
                ...ifcModel,
                ...cleanData
              };
            }),
            worldSelectedObjects: updateSelectedObjectsPositionRotation(
              state.worldSelectedObjects,
              ids,
              data
            )
          };
        });
      },
      deletePlaceholders: (ids) =>
        set((state) => ({
          ifcModelPlaceholders: state.ifcModelPlaceholders.filter(
            (g) => !ids.includes(g.id)
          )
        })),
      remove: (group) =>
        set((state) => ({
          ifcModels: state.ifcModels.filter(
            (g) => g.fragments.id !== group.fragments.id
          )
        })),
      deleteAll: () => set({ ifcModels: [], activeIfcModels: [] })
    }
  };
};

export const applyIfcDimensions = (
  dimensions: {
    id: string;
    position: Vector3Tuple;
    rotation: Vector3Tuple;
  }[]
) => {
  const state = useStoreWithUndo.getState();

  const ids = dimensions.map((p) => p.id);

  return state.ifcModels.map((ifc) => {
    if (!ids.includes(ifc.id)) return ifc;

    const dimensionObject = dimensions.find((p) => p.id === ifc.id);

    return {
      ...ifc,
      position: dimensionObject?.position ?? ifc.position,
      rotation: dimensionObject?.rotation ?? ifc.rotation
    };
  });
};

export default createIfcSlice;

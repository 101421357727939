import { StateCreator } from "zustand";
import { StandardSlice } from "./standard.interface";
import { Store } from "store/store.types";
import {
  addStandardsToGraph,
  removeStandardsFromGraph,
  setStandardsInGraph
} from "./standard.utils";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialStandardState = {
  standards: []
};

/** Slice creation */
const createStandardSlice: StateCreator<Store, [], [], StandardSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialStandardState));

  /** Return state */
  return {
    ...initialStandardState,
    addStandards: (standards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addStandardsToGraph({ graph: newGraph, standards });
        return {
          standards: [...state.standards, ...standards],
          graph: newGraph
        };
      });
    },
    setStandards: (newStandards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setStandardsInGraph({
          graph: newGraph,
          newStandards,
          oldStandards: [...state.standards]
        });

        return {
          standards: newStandards,
          graph: newGraph
        };
      });
    },
    removeStandards: (ids) => {
      set((state) => {
        const removeStandards = state.standards.filter((standard) =>
          ids.includes(standard.id)
        );

        const newGraph = state.graph.copy();
        removeStandardsFromGraph({
          graph: newGraph,
          standards: removeStandards
        });

        return {
          standards: state.standards.filter(
            (standard) => !ids.includes(standard.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createStandardSlice;

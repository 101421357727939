import { CoreProps } from "world/generic/types";
import StandardLockProps from "world/core/StandardLock/StandardLock.types";
import { Vector3Tuple } from "three";
import { CoreComponentProps } from "world/World.types";

interface FrameProps extends CoreProps {
  length?: number;
  noSpigot?: boolean;
  lockVariant?: StandardLockProps["variant"];
  selected?: boolean;
  hovered?: boolean;
}

export default FrameProps;

export interface Frame extends CoreComponentProps {
  length: number;
  noSpigot?: boolean;
  left: {
    pos: Vector3Tuple;
    endPos: Vector3Tuple;
    splits: number[];
  };
  right: {
    pos: Vector3Tuple;
    endPos: Vector3Tuple;
    splits: number[];
  };
  top?: {
    pos: Vector3Tuple;
    endPos: Vector3Tuple;
    splits: number[];
  };
}

export enum FRAME_VARIANT {
  FRAME = "FRAME",
  TOP_END = "TOP_END",
  TOP_INTERMEDIATE = "TOP_INTERMEDIATE",
  PASSAGE = "PASSAGE"
}

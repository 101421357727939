import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { getGraphData } from "world/core/ToeBoard/ToeBoard.utils";
import { ToeBoard } from "world/core/ToeBoard/ToeBoard.types";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addToeBoardsToGraph = (props: {
  graph: Graph;
  toeBoards: ToeBoard[];
}) => {
  const { graph, toeBoards } = props;

  const toeBoardsGraphData = toeBoards.map((plank) => getGraphData(plank));

  addComponentsToGraph({ graph, components: toeBoardsGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeToeBoardsFromGraph = (props: {
  graph: Graph;
  toeBoards: ToeBoard[];
}) => {
  const { graph, toeBoards } = props;

  const toeBoardsGraphData = toeBoards.map((plank) => getGraphData(plank));

  removeComponentsFromGraph({
    graph,
    components: toeBoardsGraphData,
    force: true
  });
};

export const setToeBoardsInGraph = (props: {
  graph: Graph;
  newToeBoards: ToeBoard[];
  oldToeBoards: ToeBoard[];
}) => {
  const { graph, newToeBoards, oldToeBoards } = props;
  removeToeBoardsFromGraph({ graph, toeBoards: oldToeBoards });
  addToeBoardsToGraph({ graph, toeBoards: newToeBoards });
};

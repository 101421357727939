import { AnalyticsServiceProvider, AppFeatures } from "./analytics.enums";
import { AnalyticsService } from "./analytics.interface";
import { initAnalyticsService } from "./analytics.utils";

class Analytics {
  private analyticsService: AnalyticsService;

  public constructor(serviceProvider = AnalyticsServiceProvider.FIREBASE) {
    this.analyticsService = initAnalyticsService(serviceProvider);
  }

  public logFeatureUsage = (data: {
    companyId: string;
    userId: string;
    featureName: AppFeatures;
  }) => {
    this.analyticsService.logEvent({
      event: "feature_usage",
      data: data
    });
  };
}

/** Initialize a Analytics object */
const analytics = new Analytics();
export { analytics };

import { getGraphData } from "world/core/StairwayInnerGuardRail/StairwayInnerGuardRail.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { StairwayInnerGuardRail } from "world/core/StairwayInnerGuardRail/StairwayInnerGuardRail.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding stairwayInnerGuardRails to the graph.
 * @param props
 */
export const addStairwayInnerGuardRailsToGraph = (props: {
  graph: Graph;
  stairwayInnerGuardRails: StairwayInnerGuardRail[];
}) => {
  const { graph, stairwayInnerGuardRails } = props;

  const stairwaysGraphData = stairwayInnerGuardRails.map(
    (stairwayInnerGuardRail) => getGraphData(stairwayInnerGuardRail)
  );
  addComponentsToGraph({ graph, components: stairwaysGraphData });
};

/**
 * Mutates the graph by removing stairwayInnerGuardRails from the graph.
 * @param props
 */
export const removeStairwayInnerGuardRailsFromGraph = (props: {
  graph: Graph;
  stairwayInnerGuardRails: StairwayInnerGuardRail[];
}) => {
  const { graph, stairwayInnerGuardRails } = props;

  const stairwaysGraphData = stairwayInnerGuardRails.map(
    (stairwayInnerGuardRail) => getGraphData(stairwayInnerGuardRail)
  );
  removeComponentsFromGraph({
    graph,
    components: stairwaysGraphData,
    force: true
  });
};

export const setStairwayInnerGuardRailsInGraph = (props: {
  graph: Graph;
  newStairwayInnerGuardRails: StairwayInnerGuardRail[];
  oldStairwayInnerGuardRails: StairwayInnerGuardRail[];
}) => {
  const { graph, newStairwayInnerGuardRails, oldStairwayInnerGuardRails } =
    props;
  removeStairwayInnerGuardRailsFromGraph({
    graph,
    stairwayInnerGuardRails: oldStairwayInnerGuardRails
  });
  addStairwayInnerGuardRailsToGraph({
    graph,
    stairwayInnerGuardRails: newStairwayInnerGuardRails
  });
};

import useStoreWithUndo from "store/store";

export const useLightsAmbient = () =>
  useStoreWithUndo((state) => state.lightsAmbient);
export const useLightsDirectional = () =>
  useStoreWithUndo((state) => state.lightsDirectional);
export const useLightsHemisphere = () =>
  useStoreWithUndo((state) => state.lightsHemisphere);
export const useLightsPoint = () =>
  useStoreWithUndo((state) => state.lightsPoint);
export const useLightsSpot = () =>
  useStoreWithUndo((state) => state.lightsSpot);

export const useLightActions = () =>
  useStoreWithUndo((state) => state.lightActions);

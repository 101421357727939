import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { BasePlateSlice } from "./basePlate.interface";
import {
  addBasePlatesToGraph,
  removeBasePlatesFromGraph,
  setBasePlatesInGraph
} from "./basePlate.utils";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialBasePlateState = {
  basePlates: []
};

/** Slice creation */
const createBasePlateSlice: StateCreator<Store, [], [], BasePlateSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBasePlateState));

  /** Return state */
  return {
    ...initialBasePlateState,
    addBasePlates: (basePlates) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addBasePlatesToGraph({ graph: newGraph, basePlates });

        return {
          graph: newGraph,
          basePlates: [...state.basePlates, ...basePlates]
        };
      });
    },
    setBasePlates: (newBasePlates) => {
      set((state) => {
        const newGraph = state.graph.copy();

        setBasePlatesInGraph({
          graph: newGraph,
          newBasePlates,
          oldBasePlates: [...state.basePlates]
        });

        return {
          graph: newGraph,
          basePlates: newBasePlates
        };
      });
    },
    removeBasePlates: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeBasePlatesFromGraph({
          graph: newGraph,
          basePlates: [...state.basePlates]
        });
        return {
          graph: newGraph,
          basePlates: state.basePlates.filter(
            (basePlate) => !ids.includes(basePlate.id)
          )
        };
      });
    }
  };
};

export default createBasePlateSlice;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsConsoles: Component[] = [
  {
    width: 0.39,
    label_no: "RINGSCAFF Konsol 0,39m (U)",
    weight: 3.8,
    label_se: "RINGSCAFF Konsol 0,39m (U)",
    label_en: "Console 0,39m (U)",
    material: "",
    length: 0,
    article_id: "E04RS0449",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    width: 0.73,
    label_no: "RINGSCAFF Konsol 0,73m (U)",
    weight: 6.8,
    label_se: "RINGSCAFF Konsol 0,73m (U)",
    label_en: "Console 0,73m (U)",
    material: "",
    length: 0,
    article_id: "E04RS0417",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    width: 0.73,
    label_no: "RINGSCAFF Konsol 1,09m (U)",
    weight: 11.5,
    label_se: "RINGSCAFF Konsol 1,09m (U)",
    label_en: "Console 1,09m (U)",
    material: "",
    length: 0,
    article_id: "E04RS0270",
    variant: CONSOLE_VARIANT.SPIGOT
  }
];

export const componentsAnchors: Component[] = [
  {
    width: 0,
    label_se: "RINGSCAFF Förankringsrör 1,0m",
    material: "",
    label_en: "Anchor tube",
    weight: 1,
    length: 1,
    label_no: "Rörforankring",
    article_id: "E04AA0095"
  }
];

export const componentsScrews: Component[] = [
  {
    label_en: "Ring screw",
    article_id: "00291",
    weight: 1.6,
    material: "",
    length: 0.12,
    label_no: "Forankringsløkke",
    width: 0.012,
    label_se: "Förankringsögla"
  }
];

export const componentsULedgers: Component[] = [
  {
    label_se: "RINGSCAFF U-Tvärbom 0,73m",
    label_no: "RINGSCAFF U-Tverrstang 0,73",
    label_en: "RINGSCAFF U-shaped ledger 0,73",
    length: 0.73,
    weight: 3.4,
    material: "",
    width: 0,
    article_id: "E04RS0378",
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    label_se: "RINGSCAFF U-Tvärbom (forstärkt)m 1,09",
    label_no: "RINGSCAFF U-Tverrstang (forsterkt) 1,09",
    label_en: "RINGSCAFF U-shaped ledger (reinforced) 1,09",
    length: 1.09,
    weight: 6.2,
    material: "",
    width: 0,
    article_id: "E04RS0379",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    label_se: "RINGSCAFF U-Tvärbom (forstärkt)m 1,4",
    label_no: "RINGSCAFF U-Tverrstang (forsterkt) 1,4 ",
    label_en: "RINGSCAFF U-shaped ledger (reinforced) 1,4",
    length: 1.4,
    weight: 7.9,
    material: "",
    width: 0,
    article_id: "E04RS0380",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    label_se: "RINGSCAFF Dubbel U-Tvärbom 1,57m",
    label_no: "RINGSCAFF Dubbel U-Tverrstang 1,57",
    label_en: "RINGSCAFF Double U-shaped ledger 1,57",
    length: 1.57,
    weight: 10.1,
    material: "",
    width: 0,
    article_id: "E04RS0392",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_se: "RINGSCAFF Dubbel U-Tvärbom 2,07m",
    label_no: "RINGSCAFF Dubbel U-Tverrstang 2,07",
    label_en: "RINGSCAFF Double U-shaped ledger 2,07",
    length: 2.07,
    weight: 12.7,
    material: "",
    width: 0,
    article_id: "E04RS0395",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_se: "RINGSCAFF Dubbel U-Tvärbom 2,57m",
    label_no: "RINGSCAFF Dubbel U-Tverrstang 2,57",
    label_en: "RINGSCAFF Double U-shaped ledger 2,57",
    length: 2.57,
    weight: 15.8,
    material: "",
    width: 0,
    article_id: "E04RS0397",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_se: "RINGSCAFF Dubbel U-Tvärbom 3,07m",
    label_no: "RINGSCAFF Dubbel U-Tverrstang 3,07",
    label_en: "RINGSCAFF Double U-shaped ledger 3,07",
    length: 3.07,
    weight: 18.4,
    material: "",
    width: 0,
    article_id: "E04RS0399",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsOLedgers: Component[] = [
  {
    label_no: "Dobbel kil-kobling",
    label_se: "Dubbel kil-koppling",
    article_id: "E04RS1337",
    material: "",
    length: 0.15,
    label_en: "Wedge head coupler, double cast",
    weight: 1.1,
    width: 0
  },
  {
    width: 0,
    article_id: "E04RS0011",
    length: 0.73,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 0,73m",
    label_no: "O-Tverrstang 0,73",
    label_en: "O-Ledger 0,73",
    weight: 3.5
  },
  {
    width: 0,
    article_id: "E04RS0033",
    length: 1.09,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 1,09m",
    label_no: "O-Tverrstang 1,09",
    label_en: "O-Ledger 1,09",
    weight: 4.1
  },
  {
    width: 0,
    article_id: "E04RS0047",
    length: 1.4,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 1,4m",
    label_no: "O-Tverrstang 1,4",
    label_en: "O-Ledger 1,4",
    weight: 5.7
  },
  {
    width: 0,
    article_id: "E04RS0058",
    length: 1.57,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 1,57m",
    label_no: "O-Tverrstang 1,57",
    label_en: "O-Ledger 1,57",
    weight: 6.4
  },
  {
    width: 0,
    article_id: "E04RS0074",
    length: 2.07,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 2,07m",
    label_no: "O-Tverrstang 2,07",
    label_en: "O-Ledger 2,07",
    weight: 8.4
  },
  {
    width: 0,
    article_id: "E04RS0086",
    length: 2.57,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 2,57m",
    label_no: "O-Tverrstang 2,57",
    label_en: "O-Ledger 2,57",
    weight: 10
  },
  {
    width: 0,
    article_id: "E04RS0099",
    length: 3.07,
    material: "",
    label_se: "RINGSCAFF Horisontalstag 3,07m",
    label_no: "O-Tverrstang 3,07",
    label_en: "O-Ledger 3,07",
    weight: 11.7
  }
];

export const componentsToeBoards: Component[] = [
  {
    article_id: "E04RS0101",
    weight: 10.3,
    label_se: "RINGSCAFF Sparklist 3,07m trä",
    label_en: "Toe Board 3,07m",
    width: 0.15,
    material: "",
    length: 3.07,
    label_no: "RINGSCAFF Fotlist 3,07m trä"
  },
  {
    article_id: "E04RS0091",
    weight: 8.8,
    label_se: "RINGSCAFF Sparklist 2,57m trä",
    label_en: "Toe Board 2,57m",
    width: 0.15,
    material: "",
    length: 2.57,
    label_no: "RINGSCAFF Fotlist 2,57m trä"
  },
  {
    article_id: "E04RS0076",
    weight: 7.2,
    label_se: "RINGSCAFF Sparklist 2,07m trä",
    label_en: "Toe Board 2,07m",
    width: 0.15,
    material: "",
    length: 2.07,
    label_no: "RINGSCAFF Fotlist 2,07m trä"
  },
  {
    article_id: "E04RS0064",
    weight: 5.5,
    label_se: "RINGSCAFF Sparklist 1,57m trä",
    label_en: "Toe Board 1,57m",
    width: 0.15,
    material: "",
    length: 1.57,
    label_no: "RINGSCAFF Fotlist 1,57m trä"
  },
  {
    article_id: "E04RS0054",
    weight: 4.9,
    label_se: "RINGSCAFF Sparklist 1,4m trä",
    label_en: "Toe Board 1,4m",
    width: 0.15,
    material: "",
    length: 1.4,
    label_no: "RINGSCAFF Fotlist 1,4m trä"
  },
  {
    article_id: "E04RS0037",
    weight: 3.9,
    label_se: "RINGSCAFF Sparklist 1,09m trä",
    label_en: "Toe Board 1,09m",
    width: 0.15,
    material: "",
    length: 1.09,
    label_no: "RINGSCAFF Fotlist 1,09m trä"
  },
  {
    article_id: "E04RS0016",
    weight: 2.8,
    label_se: "RINGSCAFF Sparklist 0,73m trä",
    label_en: "Toe Board 0,73m",
    width: 0.15,
    material: "",
    length: 0.73,
    label_no: "RINGSCAFF Fotlist 0,73m trä"
  }
];

export const componentsDecks: Component[] = [
  {
    width: 0.19,
    label_en: "Steel plank 0,19x0,73m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0908",
    label_se: "RINGSCAFF Stålplank 0,19x0,73m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x0,73m (U) MOD.T",
    weight: 4.8,
    length: 0.73
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19x1,09m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0909",
    label_se: "RINGSCAFF Stålplank 0,19x1,09m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x1,09m (U) MOD.T",
    weight: 7.2,
    length: 1.09
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19x1,57m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0911",
    label_se: "RINGSCAFF Stålplank 0,19x1,57m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x1,57m (U) MOD.T",
    weight: 10.3,
    length: 1.57
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19x2,07m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0912",
    label_se: "RINGSCAFF Stålplank 0,19x2,07m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x2,07m (U) MOD.T",
    weight: 13.6,
    length: 2.07
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19x2,57m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0913",
    label_se: "RINGSCAFF Stålplank 0,19x2,57m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x2,57m (U) MOD.T",
    weight: 16.9,
    length: 2.57
  },
  {
    width: 0.19,
    label_en: "Steel plank 0,19x3,07m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0914",
    label_se: "RINGSCAFF Stålplank 0,19x3,07m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,19x3,07m (U) MOD.T",
    weight: 20.2,
    length: 3.07
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x0,73m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0941",
    label_se: "RINGSCAFF Stålplank 0,32x0,73m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x0,73m (U) MOD.T",
    weight: 5.9,
    length: 0.73
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x1,09m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0887",
    label_se: "RINGSCAFF Stålplank 0,32x1,09m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x1,09m (U) MOD.T",
    weight: 9.7,
    length: 1.09
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x1,57m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0942",
    label_se: "RINGSCAFF Stålplank 0,32x1,57m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x1,57m (U) MOD.T",
    weight: 11.3,
    length: 1.57
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x2,07m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0888",
    label_se: "RINGSCAFF Stålplank 0,32x2,07m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x2,07m (U) MOD.T",
    weight: 15.6,
    length: 2.07
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x2,57m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0943",
    label_se: "RINGSCAFF Stålplank 0,32x2,57m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x2,57m (U) MOD.T",
    weight: 18.7,
    length: 2.57
  },
  {
    width: 0.32,
    label_en: "Steel plank 0,32x3,07m (U) MOD.T",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "E04RS0944",
    label_se: "RINGSCAFF Stålplank 0,32x3,07m (U) MOD.T",
    label_no: "RINGSCAFF Stålplanke 0,32x3,07m (U) MOD.T",
    weight: 21.5,
    length: 3.07
  }
];

export const componentsBaseCollars: Component[] = [
  {
    article_id: "E04RS0002",
    weight: 1.5,
    width: 0,
    material: "",
    length: 0.26,
    label_en: "Base collar",
    label_no: "RINGSCAFF Bunnkrave",
    label_se: "RINGSCAFF Bottenkrage"
  }
  // {
  //   article_id: "E04RS0580",
  //   weight: 2.5,
  //   width: 0,
  //   material: "",
  //   length: 0.43,
  //   label_en: "Base collar with drill hole",
  //   label_no: "RINGSCAFF Bunnkrave med hull",
  //   label_se: "RINGSCAFF Bottenkrage med hål"
  // }
];

export const componentsBaseplates: Component[] = [
  {
    length: 0.78,
    width: 0,
    label_se: "RINGSCAFF Bottenskruv 780",
    material: "",
    label_no: "RINGSCAFF Bunnskrue 780",
    article_id: "E02RS0002",
    label_en: "RINGSCAFF Base jack 780",
    weight: 4.8
  }
];

export const componentsLiftOffPreventers: Component[] = [
  {
    width: 0,
    weight: 1.3,
    material: "",
    article_id: "E04RS0594",
    label_no: "Retainer for steel deck 0.73m",
    label_se: "Inplankningslås 0.73m (U)",
    label_en: "Retainer for steel deck 0.73m",
    length: 0.73
  },
  {
    width: 0,
    weight: 1.8,
    material: "",
    article_id: "E04RS0595",
    label_no: "Retainer for steel deck 1.09m",
    label_se: "Inplankningslås 1.09m (U)",
    label_en: "Retainer for steel deck 1.09m",
    length: 1.09
  },
  {
    width: 0,
    weight: 3.0,
    material: "",
    article_id: "E04RS0597",
    label_no: "Retainer for steel deck 1,57m",
    label_se: "Inplankningslås 1,57m (U)",
    label_en: "Retainer for steel deck 1,57m",
    length: 1.57
  },
  {
    width: 0,
    weight: 7.5,
    material: "",
    article_id: "E04RS0598",
    label_no: "Retainer for steel deck 2.07m",
    label_se: "Inplankningslås 2.07m (U)",
    label_en: "Retainer for steel deck 2.07m",
    length: 2.07
  },
  {
    width: 0,
    weight: 8.9,
    material: "",
    article_id: "E04RS0599",
    label_no: "Retainer for steel deck 2.57m",
    label_se: "Inplankningslås 2.57m (U)",
    label_en: "Retainer for steel deck 2.57m",
    length: 2.57
  },
  {
    width: 0,
    weight: 11.9,
    material: "",
    article_id: "E04RS0600",
    label_no: "Retainer for steel deck 3.07m",
    label_se: "Inplankningslås 3.07m (U)",
    label_en: "Retainer for steel deck 3.07m",
    length: 3.07
  }
];

export const componentsGuardRails: Component[] = [
  {
    width: 0,
    article_id: "E04RS0011",
    material: "",
    weight: 3.5,
    label_se: "RINGSCAFF Horistontalstag 0,73m",
    length: 0.73,
    label_no: "RINGSCAFF Horistontalstag 0,73m",
    label_en: "Tubular ledger 0,73 "
  },
  {
    width: 0,
    article_id: "E04RS0033",
    material: "",
    weight: 4.1,
    label_se: "RINGSCAFF Horistontalstag 1,09m",
    length: 1.09,
    label_no: "RINGSCAFF Horistontalstag 1,09m",
    label_en: "Tubular ledger 1,09 "
  },
  {
    width: 0,
    article_id: "E04RS0047",
    material: "",
    weight: 3.5,
    label_se: "RINGSCAFF Horistontalstag 1,4m",
    length: 1.4,
    label_no: "RINGSCAFF Horistontalstag 1,4m",
    label_en: "Tubular ledger 1,4 "
  },
  {
    width: 0,
    article_id: "E04RS0058",
    material: "",
    weight: 6.4,
    label_se: "RINGSCAFF Horistontalstag 1,57m",
    length: 1.57,
    label_no: "RINGSCAFF Horistontalstag 1,57m",
    label_en: "Tubular ledger 1,57 "
  },
  {
    width: 0,
    article_id: "E04RS0074",
    material: "",
    weight: 8.4,
    label_se: "RINGSCAFF Horistontalstag 2,07m",
    length: 2.07,
    label_no: "RINGSCAFF Horistontalstag 2,07m",
    label_en: "Tubular ledger 2,07 "
  },
  {
    width: 0,
    article_id: "E04RS0086",
    material: "",
    weight: 10,
    label_se: "RINGSCAFF Horistontalstag 2,57m",
    length: 2.57,
    label_no: "RINGSCAFF Horistontalstag 2,57m",
    label_en: "Tubular ledger 2,57 "
  },
  {
    width: 0,
    article_id: "E04RS0099",
    material: "",
    weight: 10,
    label_se: "RINGSCAFF Horistontalstag 3,07m",
    length: 3.07,
    label_no: "RINGSCAFF Horistontalstag 3,07m",
    label_en: "Tubular ledger 3,07 "
  }
];

export const componentsStandards: Component[] = [
  {
    article_id: "E04RS0005",
    label_en: "Standard, 0.5m",
    length: 0.5,
    weight: 3,
    label_se: "RINGSCAFF Spira,  0,5m",
    material: "",
    label_no: "RINGSCAFF Spire,  0,5m",
    width: 0
  },
  {
    article_id: "E04RS0030",
    label_en: "Standard, 1m",
    length: 1,
    weight: 5.4,
    label_se: "RINGSCAFF Spira 1,0m",
    material: "",
    label_no: "RINGSCAFF Spire 1,0m",
    width: 0
  },
  {
    article_id: "E04RS0055",
    label_en: "Standard, 1,5m",
    length: 1.5,
    weight: 7.7,
    label_se: "RINGSCAFF Spira,  1,5m",
    material: "",
    label_no: "RINGSCAFF Spire,  1,5m",
    width: 0
  },
  {
    article_id: "E04RS0071",
    label_en: "Standard, 2m",
    length: 2,
    weight: 10,
    label_se: "RINGSCAFF Spira 2,0m",
    material: "",
    label_no: "RINGSCAFF Spire 2,0m",
    width: 0
  },
  {
    article_id: "E04RS0225",
    label_en: "Standard, 2,5m",
    length: 2.5,
    weight: 12.4,
    label_se: "RINGSCAFF Spira,  2,5m",
    material: "",
    label_no: "RINGSCAFF Spire,  2,5m",
    width: 0
  },
  {
    article_id: "E04RS0096",
    label_en: "Standard, 3m",
    length: 3,
    weight: 14.8,
    label_se: "RINGSCAFF Spira 3,0m",
    material: "",
    label_no: "RINGSCAFF Spire 3,0m",
    width: 0
  },
  {
    article_id: "E04RS0107",
    label_en: "Standard, 4m",
    length: 4,
    weight: 10.3,
    label_se: "RINGSCAFF Spira 4,0m",
    material: "",
    label_no: "RINGSCAFF Spire 4,0m",
    width: 0
  }
];

export const componentsStartStandards: Component[] = [
  {
    article_id: "E04RS0107",
    label_en: "Starter Standard, 2.2m",
    length: 2.2,
    weight: 11.2,
    label_se: "Startspira 2.2m",
    material: "",
    label_no: "Startspire 2.2m",
    width: 0
  }
];

export const componentsCouplers: Component[] = [
  {
    label_no: "RINGSCAFF Fackverkskopling",
    label_se: "RINGSCAFF Fackverkskoppling",
    article_id: "E04RS0559",
    material: "",
    length: 0.1,
    label_en: "Lattice girder coupler",
    weight: 1.5,
    width: 0
  }
];

export const componentsStairwayGuardrailPosts: Component[] = [
  {
    weight: 8.7,
    label_en: "Guardrail post",
    width: 0,
    length: 1.7,
    label_no: "Rekkverkstolpe",
    label_se: "Räckesstolpe",
    material: "",
    article_id: "E04RS0501"
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    label_se: "RINGSCAFF Yttre Trappräcke dubbelt 2,57m",
    weight: 13.8,
    label_no: "RINGSCAFF Yttre Trappräcke dobbelt 2,57m",
    article_id: "E04RS0572",
    material: "",
    label_en: "Outer stairway handrail 2,57m",
    length: 2.57,
    width: 0,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    label_se: "Trappräcke innvendig",
    weight: 10.9,
    label_no: "Trappräcke innvendig",
    article_id: "E04RS0573",
    material: "",
    label_en: "Inner stairway handrail",
    length: 2.15,
    width: 0,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsStairs: Component[] = [
  /** Stair 2m height */
  {
    width: 0.64,
    height: 2,
    label_en: "Platform stairway 2,57x2m",
    article_id: "E04RS0589",
    length: 2.57,
    label_se: "RINGSCAFF Aluminium trappa 2,57x2m (U)",
    weight: 23,
    material: "",
    label_no: "RINGSCAFF Aluminium trapp 2,57x2m (U)"
  }
];
export const componentsStartStairs: Component[] = [
  /** Stair 1m height */
  {
    width: 0.6,
    height: 1,
    label_en: "Platform stairway 1m",
    article_id: "00981",
    length: 1.2,
    label_se: "RINGSCAFF Aluminium trappa 1m",
    weight: 12,
    material: "",
    label_no: "RINGSCAFF Aluminium trapp 1m"
  }
];

export const componentsDiagonalBraces: Component[] = [
  /** Diagonal brace 2m height */
  {
    label_en: "Diagonal Brace 3,07x2m",
    label_no: "RINGSCAFF Diagonalstag 3,07x2m",
    material: "",
    length: 3.07,
    weight: 11,
    width: 2,
    article_id: "E04RS0102",
    label_se: "RINGSCAFF Diagonalstag 3,07x2m"
  },
  {
    label_en: "Diagonal Brace 2,57x2m",
    label_no: "RINGSCAFF Diagonalstag 2,57x2m",
    material: "",
    length: 2.57,
    weight: 10,
    width: 2,
    article_id: "E04RS0092",
    label_se: "RINGSCAFF Diagonalstag 2,57x2m"
  },
  {
    label_en: "Diagonal Brace 2,07x2m",
    label_no: "RINGSCAFF Diagonalstag 2,07x2m",
    material: "",
    length: 2.07,
    weight: 9,
    width: 2,
    article_id: "E04RS0077",
    label_se: "RINGSCAFF Diagonalstag 2,07x2m"
  },
  {
    label_en: "Diagonal Brace 1,57x2m",
    label_no: "RINGSCAFF Diagonalstag 1,57x2m",
    material: "",
    length: 1.57,
    weight: 8.1,
    width: 2,
    article_id: "E04RS0065",
    label_se: "RINGSCAFF Diagonalstag 1,57x2m"
  },
  {
    label_en: "Diagonal Brace 1,4x2m",
    label_no: "RINGSCAFF Diagonalstag 1,4x2m",
    material: "",
    length: 1.4,
    weight: 8.2,
    width: 2,
    article_id: "E04RS0051",
    label_se: "RINGSCAFF Diagonalstag 1,4x2m"
  },
  {
    label_en: "Diagonal Brace 1,09x2m",
    label_no: "RINGSCAFF Diagonalstag 1,09x2m",
    material: "",
    length: 1.09,
    weight: 7.5,
    width: 2,
    article_id: "E04RS0038",
    label_se: "RINGSCAFF Diagonalstag 1,09x2m"
  },
  {
    label_en: "Diagonal Brace 0,73x2m",
    label_no: "RINGSCAFF Diagonalstag 0,73x2m",
    material: "",
    length: 0.73,
    weight: 7.2,
    width: 2,
    article_id: "E04RS0017",
    label_se: "RINGSCAFF Diagonalstag 0,73x2m"
  },
  /** Diagonal brace 1.5m height */
  {
    label_en: "Diagonal Brace 1.09x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.09x1.5m",
    material: "",
    length: 1.09,
    weight: 6.8,
    width: 1.5,
    article_id: "E04RS0334",
    label_se: "RINGSCAFF Diagonalstag 1.09x1.5m"
  },
  {
    label_en: "Diagonal Brace 1.4x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.4x1.5m",
    material: "",
    length: 1.4,
    weight: 7.2,
    width: 1.5,
    article_id: "E04RS0320",
    label_se: "RINGSCAFF Diagonalstag 1.4x1.5m"
  },
  {
    label_en: "Diagonal Brace 1.57x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.57x1.5m",
    material: "",
    length: 1.57,
    weight: 7.6,
    width: 1.5,
    article_id: "E04RS0335",
    label_se: "RINGSCAFF Diagonalstag 1.57x1.5m"
  },
  {
    label_en: "Diagonal Brace 2.07x1.5m",
    label_no: "RINGSCAFF Diagonalstag 2.07x1.5m",
    material: "",
    length: 2.07,
    weight: 8.2,
    width: 1.5,
    article_id: "E04RS0336",
    label_se: "RINGSCAFF Diagonalstag 2.07x1.5m"
  },
  {
    label_en: "Diagonal Brace 2.57x1.5m",
    label_no: "RINGSCAFF Diagonalstag 2.57x1.5m",
    material: "",
    length: 2.57,
    weight: 9.6,
    width: 1.5,
    article_id: "E04RS0337",
    label_se: "RINGSCAFF Diagonalstag 2.57x1.5m"
  },
  {
    label_en: "Diagonal Brace 3.07x1.5m",
    label_no: "RINGSCAFF Diagonalstag 3.07x1.5m",
    material: "",
    length: 3.07,
    weight: 10.5,
    width: 1.5,
    article_id: "E04RS0338",
    label_se: "RINGSCAFF Diagonalstag 3.07x1.5m"
  },
  /** Diagonal brace 1m height */
  {
    label_en: "Diagonal Brace 1.09x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.09x1.5m",
    material: "",
    length: 1.09,
    weight: 4.9,
    width: 1,
    article_id: "E04RS0328",
    label_se: "RINGSCAFF Diagonalstag 1.09x1.5m"
  },
  {
    label_en: "Diagonal Brace 1.4x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.4x1.5m",
    material: "",
    length: 1.4,
    weight: 5.5,
    width: 1,
    article_id: "E04RS0329",
    label_se: "RINGSCAFF Diagonalstag 1.4x1.5m"
  },
  {
    label_en: "Diagonal Brace 1.57x1.5m",
    label_no: "RINGSCAFF Diagonalstag 1.57x1.5m",
    material: "",
    length: 1.57,
    weight: 5.9,
    width: 1,
    article_id: "E04RS0330",
    label_se: "RINGSCAFF Diagonalstag 1.57x1.5m"
  },
  {
    label_en: "Diagonal Brace 2.07x1.5m",
    label_no: "RINGSCAFF Diagonalstag 2.07x1.5m",
    material: "",
    length: 2.07,
    weight: 7.1,
    width: 1,
    article_id: "E04RS0331",
    label_se: "RINGSCAFF Diagonalstag 2.07x1.5m"
  },
  {
    label_en: "Diagonal Brace 2.57x1.5m",
    label_no: "RINGSCAFF Diagonalstag 2.57x1.5m",
    material: "",
    length: 2.57,
    weight: 8.2,
    width: 1,
    article_id: "E04RS0332",
    label_se: "RINGSCAFF Diagonalstag 2.57x1.5m"
  },
  {
    label_en: "Diagonal Brace 3.07x1.5m",
    label_no: "RINGSCAFF Diagonalstag 3.07x1.5m",
    material: "",
    length: 3.07,
    weight: 9.8,
    width: 1,
    article_id: "E04RS0331",
    label_se: "RINGSCAFF Diagonalstag 3.07x1.5m"
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    width: 0,
    article_id: "00470",
    material: "",
    weight: 1.8,
    length: 0.2,
    label_en: "Tube connector with semi-coupler",
    label_no: "RINGSCAFF Spiretapp med halvkobling",
    label_se: "RINGSCAFF Spirtapp med halvkoppling"
  },
  {
    width: 0,
    article_id: "00470",
    material: "",
    weight: 0.97,
    length: 0.2,
    label_en: "Tube connector universal",
    label_no: "RINGSCAFF Spiretapp universal",
    label_se: "RINGSCAFF Spirtapp universal"
  }
];

export const componentsStairwayGuardrailsWideInner: Component[] = [
  {
    label_se: "Aluminium trappräcke",
    weight: 3,
    label_no: "Aluminium trappræling",
    article_id: "01050",
    material: "",
    label_en: "Aluminium stair guardrail",
    length: 0.7,
    width: 0
  }
];

import { StateCreator } from "zustand";
import { FrameSlice } from "./frame.interface";
import { Store } from "store/store.types";
import {
  addFramesToGraph,
  removeFramesFromGraph,
  setFramesInGraph
} from "./frame.utils";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialFrameState = {
  frames: []
};

/** Slice creation */
const createFrameSlice: StateCreator<Store, [], [], FrameSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialFrameState));

  /** Return state */
  return {
    ...initialFrameState,
    addFrames: (frames) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addFramesToGraph({ graph: newGraph, frames });
        return {
          frame: [...state.frames, ...frames],
          graph: newGraph
        };
      });
    },
    setFrames: (newFrames) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setFramesInGraph({
          graph: newGraph,
          newFrames,
          oldFrames: [...state.frames]
        });

        return {
          frame: newFrames,
          graph: newGraph
        };
      });
    },
    removeFrames: (ids) => {
      set((state) => {
        const removeFrames = state.frames.filter((frame) =>
          ids.includes(frame.id)
        );

        const newGraph = state.graph.copy();
        removeFramesFromGraph({
          graph: newGraph,
          frames: removeFrames
        });

        return {
          frame: state.frames.filter((frame) => !ids.includes(frame.id)),
          graph: newGraph
        };
      });
    }
  };
};

export default createFrameSlice;

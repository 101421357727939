import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { GraphSlice } from "./graph.interface";
import Graph from "graphology";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialGraphState = {
  graph: new Graph({ type: "undirected" }),
  graphShow: false
};

/** Slice creation */
const createGraphSlice: StateCreator<Store, [], [], GraphSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialGraphState));

  /** Return state */
  return {
    ...initialGraphState,
    graphActions: {
      set: (graph) => {
        set({ graph });
      },
      clear: () => {
        set({ graph: new Graph({ type: "undirected" }) });
      },
      setShow: (graphShow) => {
        set({ graphShow });
      },
      toggleShow: () => {
        set((state) => ({ graphShow: !state.graphShow }));
      }
    }
  };
};

export default createGraphSlice;

import Component from "./scaffold.interface";

export const componentsBaseBoards: Component[] = [
  /**  I Sverige är torr-rådensiteten som medeltal för trä 400 kg/m  */
  {
    label_no: "UnderPallning 20x24cm",
    material: "",
    weight: 0.85,
    label_se: "Underpallning 20x24cm",
    label_en: "Base board 20x24cm",
    length: 0.2,
    article_id: "base_board_20x24",
    width: 0.24
  },
  {
    label_no: "UnderPallning 30x24cm",
    material: "",
    weight: 1.3,
    label_se: "Underpallning 30x24cm",
    label_en: "Base board 30x24cm",
    length: 0.3,
    article_id: "base_board_20x24",
    width: 0.24
  }
];

import { FIREBASE_PROJECTID } from "services/firebase/enums";
import { ApiServiceProvider } from "./api.types";
import getConfig from "services/firebase/config";
import FirebaseFunctions from "services/firebase/firebase.functions";

export const initApiService = (serviceProvider: ApiServiceProvider) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (serviceProvider === ApiServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseFunctions({
      config: firebaseConfig
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseFunctions({
    config: firebaseConfig
  });
};

import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { Supplier } from "./scaffold.interface";
import AllroundModul from "./layher/allroundModul/allroundModul";
import SpeedyScaf from "./layher/speedyScaf/speedyScaf";
import Universal from "./haki/universal/universal";
import SystemScaffold from "./pluseight/systemScaffold/systemScaffold";
import UpFlex from "./peri/upFlex/upFlex";
import RingScaff from "./scafomrux/ringscaff/ringScaff";
import Super9 from "./super9/super9";
import PerfectContur from "./plettac/perfectContur/perfectContur";
import Ramscaff from "./solideq/ramscaff/ramscaff";
import Mostostal from "./solideq/mostostal/mostostal";
import Aluscaff from "./solideq/aluscaff/aluscaff";

export const getScaffoldClass = (
  supplier: SCAFFOLD_SUPPLIER
): Supplier | null => {
  if (supplier === SCAFFOLD_SUPPLIER.LAYHER) {
    return new AllroundModul();
  } else if (supplier === SCAFFOLD_SUPPLIER.HAKI) {
    return new Universal();
  } else if (supplier === SCAFFOLD_SUPPLIER.SPEEDYSCAF) {
    return new SpeedyScaf();
  } else if (supplier === SCAFFOLD_SUPPLIER.PLUSEIGHT) {
    return new SystemScaffold();
  } else if (supplier === SCAFFOLD_SUPPLIER.UPFLEX) {
    return new UpFlex();
  } else if (supplier === SCAFFOLD_SUPPLIER.SCAFOMRUX) {
    return new RingScaff();
  } else if (supplier === SCAFFOLD_SUPPLIER.SUPER9) {
    return new Super9();
  } else if (supplier === SCAFFOLD_SUPPLIER.PLETTAC) {
    return new PerfectContur();
  } else if (supplier === SCAFFOLD_SUPPLIER.RAMSCAFF) {
    return new Ramscaff();
  } else if (supplier === SCAFFOLD_SUPPLIER.MOSTOSTALRAM) {
    return new Mostostal();
  } else if (supplier === SCAFFOLD_SUPPLIER.ALUSCAFF) {
    return new Aluscaff();
  }
  return null;
};

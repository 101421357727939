import { ReactElement } from "react";
import { Backdrop, Stack } from "@mui/material";

import LoadingOverlayProps from "./LoadingOverlay.types";
import { Outlet } from "react-router-dom";
import { useLoading, useLoadingText } from "store/selectors/loading";
import Loading from "ui/core/Loading";
import Text from "ui/core/Text";

const LoadingOverlay = (props: LoadingOverlayProps): ReactElement => {
  let isLoading = useLoading();
  let loadingText = useLoadingText();

  return (
    <>
      <Backdrop open={isLoading}>
        <Stack alignItems="center" justifyContent="center" gap={2}>
          <Loading />
          {loadingText && <Text variant="body1">{loadingText}</Text>}
        </Stack>
      </Backdrop>
      <Outlet />
    </>
  );
};

export default LoadingOverlay;

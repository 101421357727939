import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { GuardRail } from "world/core/GuardRail/GuardRail.types";
import { getGraphData } from "world/core/GuardRail/GuardRail.utils";

/**
 * Mutates the graph by adding guardRails to the graph.
 * @param props
 */
export const addGuardRailsToGraph = (props: {
  graph: Graph;
  guardRails: GuardRail[];
}) => {
  const { graph, guardRails } = props;

  const guardRailsGraphData = guardRails
    .map((guardRail) => {
      return getGraphData(guardRail);
    })
    .flat();
  addComponentsToGraph({ graph, components: guardRailsGraphData });
};

/**
 * Mutates the graph by removing guardRails from the graph.
 * @param props
 */
export const removeGuardRailsFromGraph = (props: {
  graph: Graph;
  guardRails: GuardRail[];
}) => {
  const { graph, guardRails } = props;

  const guardRailsGraphData = guardRails
    .map((guardRail) => {
      return getGraphData(guardRail);
    })
    .flat();

  removeComponentsFromGraph({
    graph,
    components: guardRailsGraphData,
    force: true
  });
};

export const setGuardRailsInGraph = (props: {
  graph: Graph;
  newGuardRails: GuardRail[];
  oldGuardRails: GuardRail[];
}) => {
  const { graph, newGuardRails, oldGuardRails } = props;
  removeGuardRailsFromGraph({ graph, guardRails: oldGuardRails });
  addGuardRailsToGraph({ graph, guardRails: newGuardRails });
};

import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { LedgerSlice } from "./ledger.interface";
import {
  addLedgersToGraph,
  removeLedgersFromGraph,
  setLedgersInGraph
} from "./ledger.utils";
import { Ledger } from "world/core/Ledger/Ledger.types";
import { Supplier } from "suppliers/scaffold/scaffold.interface";

/** Initial state */
const initialLedgerState = {
  ledgers: []
};

/** Slice creation */
const createLedgerSlice: StateCreator<Store, [], [], LedgerSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialLedgerState));

  /** Return state */
  return {
    ...initialLedgerState,
    ledgerActions: {
      add: (ledgers) => {
        set((state) => {
          const newGraph = state.graph.copy();
          addLedgersToGraph({ graph: newGraph, ledgers });

          return {
            ledgers: [...state.ledgers, ...ledgers],
            graph: newGraph
          };
        });
      },
      set: (ledgers) => {
        set((state) => {
          const newGraph = state.graph.copy();
          setLedgersInGraph({
            graph: newGraph,
            newLedgers: ledgers,
            oldLedgers: [...state.ledgers]
          });

          return {
            ledgers,
            graph: newGraph
          };
        });
      },
      remove: (ids) => {
        set((state) => {
          const removeLedgers = [...state.ledgers];
          const newGraph = state.graph.copy();
          removeLedgersFromGraph({ graph: newGraph, ledgers: removeLedgers });
          return {
            ledgers: state.ledgers.filter((ledger) => !ids.includes(ledger.id)),
            graph: newGraph
          };
        });
      },
      replace: (
        currentArticleId: string,
        selectedIds: string[],
        ledgers: Ledger[],
        supplierClass: Supplier
      ) => {
        set((state) => {
          if (ledgers.length === 0) return state;

          const newGraph = state.graph.copy();
          const notRemovedLedgers = state.ledgers.filter(
            (ledger) => !selectedIds.includes(ledger.id)
          );
          const removedLedgers = state.ledgers.filter((ledger) =>
            selectedIds.includes(ledger.id)
          );
          removeLedgersFromGraph({ graph: newGraph, ledgers: removedLedgers });
          const ledgerAlternatives = supplierClass.getLedgerAlternatives({
            length: removedLedgers[0].length
          });
          const chosenComponent = ledgerAlternatives.find(
            (ledger) => ledger.article_id === currentArticleId
          );

          if (!chosenComponent) return state;
          const newLedgers: Ledger[] = removedLedgers.map((ledger) => {
            const { variant, ...rest } = ledger;
            return {
              ...rest,
              componentId: chosenComponent.article_id,
              ...(chosenComponent.variant && { variant: chosenComponent.variant})
            }
          });
          addLedgersToGraph({ graph: newGraph, ledgers: newLedgers });

          return {
            ledgers: [...notRemovedLedgers, ...newLedgers],
            graph: newGraph
          };
        });
      }
    }
  };
};

export default createLedgerSlice;

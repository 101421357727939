import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { LiftOffPreventerSlice } from "./liftOffPreventer.interface";
import { sliceResetFns } from "store/store";
import {
  addLiftOffPreventersToGraph,
  removeLiftOffPreventersFromGraph,
  setLiftOffPreventersInGraph
} from "./liftOffPreventer.utils";

/** Initial state */
const initialPlankState = {
  liftOffPreventers: []
};

/** Slice creation */
const createLiftOffPreventerSlice: StateCreator<
  Store,
  [],
  [],
  LiftOffPreventerSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialPlankState));

  /** Return state */
  return {
    ...initialPlankState,
    addLiftOffPreventers: (liftOffPreventers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addLiftOffPreventersToGraph({ graph: newGraph, liftOffPreventers });
        return {
          graph: newGraph,
          liftOffPreventers: [...state.liftOffPreventers, ...liftOffPreventers]
        };
      });
    },
    setLiftOffPreventers: (liftOffPreventers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setLiftOffPreventersInGraph({
          graph: newGraph,
          newLiftOffPreventers: liftOffPreventers,
          oldLiftOffPreventers: [...state.liftOffPreventers]
        });

        return {
          graph: newGraph,
          liftOffPreventers
        };
      });
    },
    removeLiftOffPreventers: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeLiftOffPreventersFromGraph({
          graph: newGraph,
          liftOffPreventers: [...state.liftOffPreventers]
        });

        return {
          graph: newGraph,
          liftOffPreventers: state.liftOffPreventers.filter(
            (liftOffPreventer) => !ids.includes(liftOffPreventer.id)
          )
        };
      });
    }
  };
};

export default createLiftOffPreventerSlice;

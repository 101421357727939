import { getGraphData } from "world/core/StairwayGuardRail/StairwayGuardRail.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { StairwayGuardRail } from "world/core/StairwayGuardRail/StairwayGuardRail.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding stairwayGuardRails to the graph.
 * @param props
 */
export const addStairwayGuardRailsToGraph = (props: {
  graph: Graph;
  stairwayGuardRails: StairwayGuardRail[];
}) => {
  const { graph, stairwayGuardRails } = props;

  const stairwaysGraphData = stairwayGuardRails.map((stairwayGuardRail) =>
    getGraphData(stairwayGuardRail)
  );
  addComponentsToGraph({ graph, components: stairwaysGraphData });
};

/**
 * Mutates the graph by removing stairwayGuardRails from the graph.
 * @param props
 */
export const removeStairwayGuardRailsFromGraph = (props: {
  graph: Graph;
  stairwayGuardRails: StairwayGuardRail[];
}) => {
  const { graph, stairwayGuardRails } = props;

  const stairwaysGraphData = stairwayGuardRails.map((stairwayGuardRail) =>
    getGraphData(stairwayGuardRail)
  );
  removeComponentsFromGraph({
    graph,
    components: stairwaysGraphData,
    force: true
  });
};

export const setStairwayGuardRailsInGraph = (props: {
  graph: Graph;
  newStairwayGuardRails: StairwayGuardRail[];
  oldStairwayGuardRails: StairwayGuardRail[];
}) => {
  const { graph, newStairwayGuardRails, oldStairwayGuardRails } = props;
  removeStairwayGuardRailsFromGraph({
    graph,
    stairwayGuardRails: oldStairwayGuardRails
  });
  addStairwayGuardRailsToGraph({
    graph,
    stairwayGuardRails: newStairwayGuardRails
  });
};

import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { HelpersSlice } from "./helpers.types";

/** Initial state */
const initialHelpersState = {
  helperAxes: [],
  helperGrids: []
};

/** Slice creation */
const createHelpersSlice: StateCreator<Store, [], [], HelpersSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialHelpersState));

  /** Return state */
  return {
    ...initialHelpersState,

    /** Helper actions */
    helperActions: {
      addAxes: (axes) =>
        set((state) => ({ helperAxes: [...state.helperAxes, ...axes] })),
      removeAxis: (ids) =>
        set((state) => ({
          helperAxes: state.helperAxes.filter((axis) => !ids.includes(axis.id))
        })),
      setAxes: (axes) => set({ helperAxes: axes }),
      addGrid: (grids) =>
        set((state) => ({ helperGrids: [...state.helperGrids, ...grids] })),
      removeGrid: (ids) =>
        set((state) => ({
          helperGrids: state.helperGrids.filter(
            (grid) => !ids.includes(grid.id)
          )
        })),
      setGrids: (grids) => set({ helperGrids: grids })
    }
  };
};

export default createHelpersSlice;

import { Euler, Vector3 } from "three";
import { Frame, FRAME_VARIANT } from "./Frame.types";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { plus } from "math";

const INIT_LOCK_TOP_OFFSET = 0.1;
const DELTA_LOCK_HEIGHT = 0.5;

const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();

/** Calculate lock positions from top-down */
export const calcLockHeights = (length: number) => {
  const lockPositions: number[] = [];

  /** Early return if lenght is less than INIT_LOCK_TOP_OFFSET */
  if (length < INIT_LOCK_TOP_OFFSET) return lockPositions;

  /** Calcualte remaining length */
  let remainingLength = length - INIT_LOCK_TOP_OFFSET;

  /** Push init top height*/
  lockPositions.push(remainingLength);

  /** Continue to push more heights if possible */
  let lockCount = 1;
  while (remainingLength > DELTA_LOCK_HEIGHT) {
    remainingLength -= DELTA_LOCK_HEIGHT;
    lockPositions.push(
      length - INIT_LOCK_TOP_OFFSET - lockCount * DELTA_LOCK_HEIGHT
    );
    lockCount++;
  }

  return lockPositions;
};

export const getGraphData = (frame: Frame): GraphData[] => {
  const { rotation, left, right, top, variant } = frame;

  const data = [
    {
      startPosition: left.pos,
      endPosition: left.endPos,
      id: frame.id,
      type: ComponentType.FRAME,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    }
  ];
  if (variant !== FRAME_VARIANT.TOP_INTERMEDIATE)
    data.push({
      startPosition: right.pos,
      endPosition: right.endPos,
      id: frame.id,
      type: ComponentType.FRAME,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    });
  if (top)
    data.push({
      startPosition: top.pos,
      endPosition: top.endPos,
      id: frame.id,
      type: ComponentType.FRAME,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    });
  if (
    variant === FRAME_VARIANT.TOP_END ||
    variant === FRAME_VARIANT.TOP_INTERMEDIATE
  )
    data.push({
      startPosition: left.pos,
      endPosition: right.pos,
      id: frame.id,
      type: ComponentType.FRAME,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    });

  if (variant === FRAME_VARIANT.TOP_END) {
    data.push(
      {
        startPosition: [left.pos[0], plus(left.pos[1], 0.4), left.pos[2]],
        endPosition: [right.pos[0], plus(right.pos[1], 0.4), right.pos[2]],
        id: frame.id,
        type: ComponentType.FRAME,
        metadata: {
          elementVector: ELEMENT_VECTOR.set(0, 0, 1)
            .applyEuler(EULER.fromArray(rotation))
            .toArray()
        }
      },
      {
        startPosition: [left.pos[0], plus(left.pos[1], 0.9), left.pos[2]],
        endPosition: [right.pos[0], plus(right.pos[1], 0.9), right.pos[2]],
        id: frame.id,
        type: ComponentType.FRAME,
        metadata: {
          elementVector: ELEMENT_VECTOR.set(0, 0, 1)
            .applyEuler(EULER.fromArray(rotation))
            .toArray()
        }
      }
    );
  }

  return data;
};

// export const getGraphSplitData = (frame: Frame): GraphData[] => {
//   const { length, position, rotation } = frame;

//   if (!splits) return [];

//   const newGraphData: GraphData[] = [];
//   const mergedLengths = [...splits, length].sort((a, b) => a - b);

//   let startPosition = position;
//   for (let i = 0; i < mergedLengths.length; i++) {
//     const length = mergedLengths[i];

//     const endPosition = [
//       position[0],
//       plus(position[1], length),
//       position[2]
//     ] as Vector3Tuple;

//     newGraphData.push({
//       startPosition: startPosition,
//       endPosition: endPosition,
//       id: frame.id,
//       type: ComponentType.STANDARD,
//       metadata: {
//         elementVector: ELEMENT_VECTOR.set(0, 0, 1)
//           .applyEuler(EULER.fromArray(rotation))
//           .toArray()
//       }
//     });

//     startPosition = endPosition;
//   }

//   return newGraphData;
// };

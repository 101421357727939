import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { BaseBoardSlice } from "./baseBoard.interface";
import { sliceResetFns } from "store/store";
import {
  addBaseBoardsToGraph,
  removeBaseBoardsFromGraph,
  setBaseBoardsInGraph
} from "./baseBoard.utils";

/** Initial state */
const initialBaseBoardState = {
  baseBoards: []
};

/** Slice creation */
const createBaseBoardSlice: StateCreator<Store, [], [], BaseBoardSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBaseBoardState));

  /** Return state */
  return {
    ...initialBaseBoardState,
    addBaseBoards: (baseBoards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addBaseBoardsToGraph({ graph: newGraph, baseBoards });
        return {
          graph: newGraph,
          baseBoards: [...state.baseBoards, ...baseBoards]
        };
      });
    },
    setBaseBoards: (baseBoards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setBaseBoardsInGraph({
          graph: newGraph,
          newBaseBoards: baseBoards,
          oldBaseBoards: [...state.baseBoards]
        });

        return {
          graph: newGraph,
          baseBoards
        };
      });
    },
    removeBaseBoards: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeBaseBoardsFromGraph({
          graph: newGraph,
          baseBoards: [...state.baseBoards]
        });

        return {
          graph: newGraph,
          baseBoards: state.baseBoards.filter(
            (baseBoard) => !ids.includes(baseBoard.id)
          )
        };
      });
    }
  };
};

export default createBaseBoardSlice;

import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { ScrewSlice } from "./screw.interface";
import { sliceResetFns } from "store/store";
import {
  addScrewsToGraph,
  removeScrewsFromGraph,
  setScrewsInGraph
} from "./screw.utils";

/** Initial state */
const initialScrewState = {
  screws: []
};

/** Slice creation */
const createScrewSlice: StateCreator<Store, [], [], ScrewSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialScrewState));

  /** Return state */
  return {
    ...initialScrewState,
    addScrews: (screws) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addScrewsToGraph({ graph: newGraph, screws });
        return {
          graph: newGraph,
          screws: [...state.screws, ...screws]
        };
      });
    },
    setScrews: (screws) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setScrewsInGraph({
          graph: newGraph,
          newScrews: screws,
          oldScrews: [...state.screws]
        });

        return {
          graph: newGraph,
          screws
        };
      });
    },
    removeScrews: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeScrewsFromGraph({
          graph: newGraph,
          screws: [...state.screws]
        });

        return {
          graph: newGraph,
          screws: state.screws.filter((screw) => !ids.includes(screw.id))
        };
      });
    }
  };
};

export default createScrewSlice;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import {
  componentsDecks,
  componentsStairs,
  componentsStartStairs
} from "../components";
import { calculateOptimalSegmentLengthCombination } from "math/optimizers";
import { BoxFrame } from "shared/interfaces/firestore";
import { calcLengthPartitions } from "suppliers/scaffold/scaffold.utils";
import {
  MIN_STANDARD_HEIGHT,
  SPIGOT_LENGTH
} from "suppliers/scaffold/constants";
import { minus, plus } from "math";

export const generateOptimalPlankConfiguration = (props: {
  length: number;
  width: number;
  plankType?: SCAFFOLD_PLANKTYPE;
}) => {
  const { length, width, plankType } = props;
  if (!plankType) return [];
  const decks = componentsDecks.filter((deck) => deck.material === plankType);

  const correctLengthDecks = decks.filter((deck) => deck.length === length);

  if (correctLengthDecks.length === 0) {
    return [];
  }

  const uniqueWidths = [
    ...new Set(correctLengthDecks.map((deck) => deck.width))
  ];

  return calculateOptimalSegmentLengthCombination({
    lengthSegments: uniqueWidths,
    targetLength: width
  });
};

export const getStair = (props: {
  sortedFrames: BoxFrame[];
  currentFrameIdx: number;
  lowestCollarHeight: number;
  length: number;
}) => {
  const { sortedFrames, currentFrameIdx, lowestCollarHeight, length } = props;
  const stairComponents = [
    ...componentsStartStairs,
    ...componentsStairs.filter((stair) => stair.length === length)
  ];
  let lowerFrameHeight = lowestCollarHeight;
  const currentFrame = sortedFrames[currentFrameIdx];
  if (!currentFrame) return;

  if (currentFrameIdx < sortedFrames.length - 1) {
    lowerFrameHeight = sortedFrames[currentFrameIdx + 1]?.height;
  }

  const heightBetweenFrames = currentFrame.height - lowerFrameHeight;
  const suitableStairs = stairComponents.filter(
    (stair) => stair.height && stair.height <= heightBetweenFrames
  );

  return suitableStairs.sort((a, b) => (b.height ?? 0) - (a.height ?? 0))[0];
};

/** Get valid anchor with height adjusted to world position */
export const getValidAnchorLevels = (props: {
  boxPosition: [number, number, number];
  anchorLevels?: number[];
  height: number;
  standardLengths: number[];
}) => {
  const { boxPosition, anchorLevels, height, standardLengths } = props;

  if (!anchorLevels) return [];
  const worldAnchorLevels = anchorLevels.map((anchorLevel) =>
    plus(anchorLevel, boxPosition[1])
  );
  /** Top deck height */
  const topDeckHeight = plus(boxPosition[1], height, SPIGOT_LENGTH);

  /** Frames or not: requires pre calculation to be made:
   * - Top standard length
   * - Standard start height
   */
  const topStandardLength = calcLengthPartitions(
    minus(plus(topDeckHeight, SPIGOT_LENGTH), MIN_STANDARD_HEIGHT),
    standardLengths
  );

  return worldAnchorLevels.filter(
    (anchorLevel) => anchorLevel > topStandardLength.remainingLength
  );
};

/** Get valid consoles with height adjusted to world position */
export const getValidConsoleLevels = (props: {
  boxPosition: [number, number, number];
  consoleLevels?: BoxFrame[];
  height: number;
  standardLengths: number[];
}) => {
  const { boxPosition, consoleLevels, height, standardLengths } = props;

  if (!consoleLevels) return [];
  const worldConsoleLevels = consoleLevels.map((consoleLevel) => ({
    ...consoleLevel,
    height: plus(consoleLevel.height, boxPosition[1])
  }));
  /** Top deck height */
  const topDeckHeight = plus(boxPosition[1], height, SPIGOT_LENGTH);

  /** Frames or not: requires pre calculation to be made:
   * - Top standard length
   * - Standard start height
   */
  const topStandardLength = calcLengthPartitions(
    minus(plus(topDeckHeight, SPIGOT_LENGTH), MIN_STANDARD_HEIGHT),
    standardLengths
  );

  return worldConsoleLevels.filter(
    (consoleLevel) => consoleLevel.height > topStandardLength.remainingLength
  );
};

/** Get valid frames with height adjusted to world position */
export const getValidFrames = (props: {
  boxPosition: [number, number, number];
  frames?: BoxFrame[];
  height: number;
  standardLengths: number[];
}) => {
  const { boxPosition, frames, height, standardLengths } = props;

  if (!frames) return [];
  const worldFrames = frames.map((frame) => ({
    ...frame,
    ...(frame.platform && {
      platform: plus(frame.platform, boxPosition[1])
    }),
    height: plus(frame.height, boxPosition[1])
  }));
  /** Top deck height */
  const topDeckHeight = plus(boxPosition[1], height, SPIGOT_LENGTH);

  /** Frames or not: requires pre calculation to be made:
   * - Top standard length
   * - Standard start height
   */
  const topStandardLength = calcLengthPartitions(
    minus(plus(topDeckHeight, SPIGOT_LENGTH), MIN_STANDARD_HEIGHT),
    standardLengths
  );

  return worldFrames.filter(
    (frame) => frame.height > topStandardLength.remainingLength
  );
};

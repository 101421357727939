import { round, times } from "math";
import { Point } from "shared/interfaces/firestore";
import { Box2, Euler, Vector2, Vector3 } from "three";
import { calculateDirectedAngleBetweenVectors } from "world/manager/HouseBodyManager/HouseBodyManager.utils";

export const getCylinderDimensions = (points: Point[]) => {
  if (points.length < 3)
    return {
      width: 0,
      length: 0,
      rotation: new Euler(),
      perpendicularVector: undefined,
      startVector: undefined
    };

  const newPoints = [...points];

  const box = new Box2().setFromPoints(
    newPoints.map((x) => new Vector2(x.position[0], x.position[2]))
  );

  const startPoint = newPoints[0].position;

  const perpendicularPointIdx = round((newPoints.length - 1) / 4, 0);
  const perpendicularPoint = newPoints[perpendicularPointIdx].position;

  const boxCenter = box.getCenter(new Vector2());

  const startVector = new Vector2(startPoint[0], startPoint[2]);
  const perpVector = new Vector2(perpendicularPoint[0], perpendicularPoint[2]);
  const width = round(times(boxCenter.distanceTo(startVector), 2), 2);
  const length = round(
    times(
      new Vector2(perpendicularPoint[0], perpendicularPoint[2]).distanceTo(
        boxCenter
      ),
      2
    ),
    2
  );

  const rotVec = startVector.clone().sub(boxCenter).normalize();
  const rotation = calculateDirectedAngleBetweenVectors({
    startPointVector: new Vector3(rotVec.x, 0, rotVec.y),
    middlePointVector: new Vector3(),
    endPointVector: new Vector3(-1, 0, 0)
  });

  return {
    width,
    length,
    rotation: new Euler(0, -rotation, 0),
    startVector,
    perpendicularVector: perpVector
  };
};

import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { ToeBoardSlice } from "./toeBoard.interface";
import { sliceResetFns } from "store/store";
import {
  addToeBoardsToGraph,
  removeToeBoardsFromGraph,
  setToeBoardsInGraph
} from "./toeBoard.utils";

/** Initial state */
const initialToeBoardState = {
  toeBoards: []
};

/** Slice creation */
const createToeBoardSlice: StateCreator<Store, [], [], ToeBoardSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToeBoardState));

  /** Return state */
  return {
    ...initialToeBoardState,
    addToeBoards: (toeBoards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addToeBoardsToGraph({ graph: newGraph, toeBoards });
        return {
          graph: newGraph,
          toeBoards: [...state.toeBoards, ...toeBoards]
        };
      });
    },
    setToeBoards: (toeBoards) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setToeBoardsInGraph({
          graph: newGraph,
          newToeBoards: toeBoards,
          oldToeBoards: [...state.toeBoards]
        });

        return {
          graph: newGraph,
          toeBoards
        };
      });
    },
    removeToeBoards: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeToeBoardsFromGraph({
          graph: newGraph,
          toeBoards: [...state.toeBoards]
        });

        return {
          graph: newGraph,
          toeBoards: state.toeBoards.filter((plank) => !ids.includes(plank.id))
        };
      });
    }
  };
};

export default createToeBoardSlice;

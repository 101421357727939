import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Euler, Vector3 } from "three";
import { ToeBoard } from "./ToeBoard.types";
import { roundVector } from "suppliers/scaffold/scaffold.utils";

const plankHeight = 0.045;
export const getGraphData = (plank: ToeBoard): GraphData => {
  const { position, rotation } = plank;
  let startPosition = new Vector3(...position);

  const endPosition = new Vector3(1, 0, 0)
    .applyEuler(new Euler(...rotation))
    .multiplyScalar(plankHeight)
    .add(startPosition);

  return {
    startPosition: position,
    endPosition: roundVector(endPosition).toArray(),
    id: plank.id,
    type: ComponentType.PLANK
  };
};

import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { LiftOffPreventer } from "world/core/LiftOffPreventer/LiftOffPreventer.types";
import { getGraphData } from "world/core/LiftOffPreventer/LiftOffPreventer.utils";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addLiftOffPreventersToGraph = (props: {
  graph: Graph;
  liftOffPreventers: LiftOffPreventer[];
}) => {
  const { graph, liftOffPreventers } = props;

  const liftOffPreventersGraphData = liftOffPreventers.map((liftOffPreventer) =>
    getGraphData(liftOffPreventer)
  );

  addComponentsToGraph({ graph, components: liftOffPreventersGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeLiftOffPreventersFromGraph = (props: {
  graph: Graph;
  liftOffPreventers: LiftOffPreventer[];
}) => {
  const { graph, liftOffPreventers } = props;

  const liftOffPreventersGraphData = liftOffPreventers.map((liftOffPreventer) =>
    getGraphData(liftOffPreventer)
  );

  removeComponentsFromGraph({
    graph,
    components: liftOffPreventersGraphData,
    force: true
  });
};

export const setLiftOffPreventersInGraph = (props: {
  graph: Graph;
  newLiftOffPreventers: LiftOffPreventer[];
  oldLiftOffPreventers: LiftOffPreventer[];
}) => {
  const { graph, newLiftOffPreventers, oldLiftOffPreventers } = props;
  removeLiftOffPreventersFromGraph({
    graph,
    liftOffPreventers: oldLiftOffPreventers
  });
  addLiftOffPreventersToGraph({
    graph,
    liftOffPreventers: newLiftOffPreventers
  });
};

import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { GuardRail } from "./GuardRail.types";

const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();

export const getGraphData = (guardRail: GuardRail): GraphData[] => {
  const { rotation, top: left, bottom: right } = guardRail;

  return [
    {
      startPosition: left.pos,
      endPosition: left.endPos,
      id: guardRail.id,
      type: ComponentType.GUARD_RAIL,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    },
    {
      startPosition: right.pos,
      endPosition: right.endPos,
      id: guardRail.id,
      type: ComponentType.GUARD_RAIL,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    }
  ];
};

import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Screw } from "world/core/Screw/Screw.types";
import { getGraphData } from "world/core/Screw/Screw.utils";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addScrewsToGraph = (props: { graph: Graph; screws: Screw[] }) => {
  const { graph, screws } = props;

  const screwsGraphData = screws.map((screw) => getGraphData(screw));

  addComponentsToGraph({ graph, components: screwsGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeScrewsFromGraph = (props: {
  graph: Graph;
  screws: Screw[];
}) => {
  const { graph, screws } = props;

  const screwsGraphData = screws.map((screw) => getGraphData(screw));

  removeComponentsFromGraph({
    graph,
    components: screwsGraphData,
    force: true
  });
};

export const setScrewsInGraph = (props: {
  graph: Graph;
  newScrews: Screw[];
  oldScrews: Screw[];
}) => {
  const { graph, newScrews, oldScrews } = props;
  removeScrewsFromGraph({
    graph,
    screws: oldScrews
  });
  addScrewsToGraph({
    graph,
    screws: newScrews
  });
};

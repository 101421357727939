import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "shared/enums/scaffold";

export const BAYWIDTHS = {
  MM730: 0.73,
};
export const LOADINGBAYWIDTHS = {};

export const BAYLENGTHS = {
  MM700: 0.7,
  MM730: 0.73,
  MM1070: 1.07,
  MM1570: 1.57,
  MM2070: 2.07,
  MM2570: 2.57,
  MM3070: 3.07,
};

export const LOADINGBAYLENGTHS = {};

export const PLANKWIDTHS = {
  MM320: 0.32,
  MM610: 0.61,
};

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
};

export const CONSOLEWIDTHS = {
  MM360: 0.36,
  MM730: 0.73,
};

export const LOADINGBAYLOADCLASSES = {};

export const STAIRWIDTHS = {
  MM730: 0.73,
};
export const STAIRLENGTHS = {
  MM2570: 2.57,
  MM3070: 3.07,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
};

export const PLANKTYPES = {
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  ALUMINIUM: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  COMPOSITE: SCAFFOLD_PLANKTYPE.COMPOSITE,
  PLYWOOD: SCAFFOLD_PLANKTYPE.PLYWOOD,
};

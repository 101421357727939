import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsHouseSlice } from "./house.types";
import { Point } from "shared/interfaces/firestore";
import { genId } from "math/generators";

/** Initial state */
const initialToolsHouseState = {
  toolsHouseHeight: 10,
  toolsHouseEditPoint: { position: [0,0,0], id: genId()} as Point,
  toolsHousePointsLength: 0,
};

const createToolsHouseSlice: StateCreator<
  ToolsHouseSlice,
  [],
  [],
  ToolsHouseSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsHouseState));

  /** Return state */
  return {
    ...initialToolsHouseState,
    toolsHouseActions: {
      setToolsHouseHeight: (height) => {
        set({ toolsHouseHeight: height });
      },
      setToolsHouseEditPoint: (point) => {
        set({ toolsHouseEditPoint: point });
      },
      setToolsHousePointsLength: (length) => {
        set({ toolsHousePointsLength: length });
      },
    }
  };
};

export default createToolsHouseSlice;

import {
  SCAFFOLD_PLANKORIENTATION,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_STANDARDLENGTH,
} from "shared/enums/scaffold";

export const BAYWIDTHS = {
  MM700: 0.7,
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1550: 1.55,
  MM1655: 1.655,
  MM1964: 1.964,
  MM1955: 1.955,
  MM2500: 2.5,
  MM2550: 2.55,
  MM3050: 3.05,
};

export const LOADINGBAYWIDTHS = {
  MM700: 0.7,
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1550: 1.55,
  MM1655: 1.655,
  MM1964: 1.964,
  MM2050: 2.05,
  MM2500: 2.5,
  MM2550: 2.55,
  MM3050: 3.05,
};

export const BAYLENGTHS = {
  MM700: 0.7,
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1550: 1.55,
  MM1655: 1.655,
  MM1964: 1.964,
  MM2050: 2.05,
  MM2500: 2.5,
  MM2550: 2.55,
  MM3050: 3.05,
};

export const LOADINGBAYLENGTHS = {
  MM700: 0.7,
  MM770: 0.77,
  MM1050: 1.05,
  MM1250: 1.25,
  MM1550: 1.55,
  MM1655: 1.655,
  MM1964: 1.964,
  MM2050: 2.05,
  MM2500: 2.5,
  MM2550: 2.55,
  MM3050: 3.05,
};

export const PLANKWIDTHS = {
  MM230: 0.23,
  MM320: 0.32,
  MM400: 0.4,
  MM485: 0.485,
  MM490: 0.49,
  MM495: 0.495,
  MM600: 0.6,
};

export const PLANKORIENTATIONS = {
  LONGITUDINAL: SCAFFOLD_PLANKORIENTATION.LONGITUDINAL,
  TRANSVERSE: SCAFFOLD_PLANKORIENTATION.TRANSVERSE,
};

export const CONSOLEWIDTHS = {
  MM230: 0.23,
  MM300: 0.3,
  MM400: 0.4,
  MM460: 0.46,
  MM600: 0.6,
};

export const LOADINGBAYLOADCLASSES = {
  LOADCLASS1: 1,
  LOADCLASS2: 2,
  LOADCLASS3: 3,
  LOADCLASS4: 4,
  LOADCLASS5: 5,
  LOADCLASS6: 6,
};

export const STAIRWIDTHS = {
  MM700: 0.7,
  MM1250: 1.25,
};

export const STAIRLENGTHS = {
  MM2500: 2.5,
  MM2550: 2.55,
  MM3050: 3.05,
};

export const STANDARDLENGTHS = {
  M2: SCAFFOLD_STANDARDLENGTH.M2,
  M3: SCAFFOLD_STANDARDLENGTH.M3,
  M4: SCAFFOLD_STANDARDLENGTH.M4,
};

export const PLANKTYPES = {
  ALUMINIUM: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  ALUMINIUM_DUCKBOARD: SCAFFOLD_PLANKTYPE.ALUMINIUM_DUCKBOARD,
  COMPOSITE_DUCKBOARD: SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD,
  HOOKPLANE: SCAFFOLD_PLANKTYPE.HOOKPLANE,
  STEEL_DUCKBOARD: SCAFFOLD_PLANKTYPE.STEEL_DUCKBOARD,
  STEEL: SCAFFOLD_PLANKTYPE.STEEL,
  WOOD_DUCKBOARD: SCAFFOLD_PLANKTYPE.WOOD_DUCKBOARD,
  WOOD_SOUTH_DUCKBOARD: SCAFFOLD_PLANKTYPE.WOOD_SOUTH_DUCKBOARD,
};

export const getQuotaPeriodStartDate = (
  quotaStartDate: Date,
  quotaRenewalDays: number | string
) => {
  const quotaRenewalDaysNumber = Number(quotaRenewalDays);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const diffFromStart = today.getTime() - quotaStartDate.getTime();

  const daysFromStart = Math.ceil(diffFromStart / (1000 * 60 * 60 * 24));
  const daysIntoPeriod = daysFromStart % quotaRenewalDaysNumber;
  const currentQuotaPeriodStart = new Date(
    today.getTime() - daysIntoPeriod * (1000 * 60 * 60 * 24)
  );
  return currentQuotaPeriodStart;
};

export const getQuotaPeriodEndDate = (
  quotaStartDate: Date,
  quotaRenewalDays: number | string,
  currentQuotaPeriodStartDate?: Date
) => {
  const quotaRenewalDaysNumber = Number(quotaRenewalDays);
  let quotaPeriodStartDate = currentQuotaPeriodStartDate;

  if (!quotaPeriodStartDate) {
    quotaPeriodStartDate = getQuotaPeriodStartDate(
      quotaStartDate,
      quotaRenewalDays
    );
  }

  const currentQuotaPeriodEnd = new Date(quotaPeriodStartDate);
  currentQuotaPeriodEnd.setDate(
    currentQuotaPeriodEnd.getDate() + quotaRenewalDaysNumber
  );
  return currentQuotaPeriodEnd;
};

import { getGraphData } from "world/core/DiagonalBrace/DiagonalBrace.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { DiagonalBrace } from "world/core/DiagonalBrace/DiagonalBrace.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding diagonalBraces to the graph.
 * @param props
 */
export const addDiagonalBracesToGraph = (props: {
  graph: Graph;
  diagonalBraces: DiagonalBrace[];
}) => {
  const { graph, diagonalBraces } = props;

  const diagonalBracesGraphData = diagonalBraces.map((stairway) =>
    getGraphData(stairway)
  );
  addComponentsToGraph({ graph, components: diagonalBracesGraphData });
};

/**
 * Mutates the graph by removing diagonalBraces from the graph.
 * @param props
 */
export const removeDiagonalBracesFromGraph = (props: {
  graph: Graph;
  diagonalBraces: DiagonalBrace[];
}) => {
  const { graph, diagonalBraces } = props;

  const diagonalBracesGraphData = diagonalBraces.map((stairway) =>
    getGraphData(stairway)
  );
  removeComponentsFromGraph({
    graph,
    components: diagonalBracesGraphData,
    force: true
  });
};

export const setDiagonalBracesInGraph = (props: {
  graph: Graph;
  newDiagonalBraces: DiagonalBrace[];
  oldDiagonalBraces: DiagonalBrace[];
}) => {
  const { graph, newDiagonalBraces, oldDiagonalBraces } = props;
  removeDiagonalBracesFromGraph({ graph, diagonalBraces: oldDiagonalBraces });
  addDiagonalBracesToGraph({ graph, diagonalBraces: newDiagonalBraces });
};

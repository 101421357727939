import { Palette } from "@mui/material";

const CornerMode1 = (props: { palette: Palette }) => {
  const { palette } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="11"
        y="43"
        width="20"
        height="8"
        transform="rotate(-90 11 43)"
        fill={palette.world.box.main}
        fillOpacity="0.6"
      />
      <rect
        x="20.5"
        y="15.5"
        width="19"
        height="7"
        fill={palette.world.placeholder.main}
        fillOpacity="0.2"
        stroke={palette.world.placeholder.main}
      />
      <rect
        x="26.7452"
        y="19.683"
        width="19"
        height="7"
        transform="rotate(60 26.7452 19.683)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <rect
        x="13.683"
        y="19.1375"
        width="19"
        height="7"
        transform="rotate(-60 13.683 19.1375)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <circle cx="19" cy="23" r="2.5" fill={palette.world.axes.x} />
    </svg>
  );
};

export default CornerMode1;

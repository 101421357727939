const darkPalette = {
  background: {
    default: "hsl(219, 35%, 10%)",
    paper: "hsl(219, 40%, 12%)",
    action: "hsl(219, 39%, 20%)",
    world: "hsl(219, 20%, 16%)",
    paperSecondary: "hsl(309, 60%, 70%)"
  },
  primary: {
    main: "hsl(204, 80%, 70%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  primaryVariant: {
    main: "hsl(204, 70%, 50%)",
    dark: "hsl(204, 70%, 42.5%)",
    light: "hsl(204, 70%, 55%)",
    contrastText: "#ffffff"
  },
  secondary: {
    main: "hsl(309, 60%, 70%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  secondaryVariant: {
    main: "hsl(309, 60%, 60%)",
    dark: "hsl(309, 60%, 51%)",
    light: "hsl(309, 60%, 64%)",
    contrastText: "#ffffff"
  },
  success: {
    main: "hsl(123, 38%, 57%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  warning: {
    main: "hsl(36, 100%, 57%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  error: {
    main: "hsl(5, 100%, 66%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  info: {
    main: "hsl(203, 40%, 80%)",
    contrastText: "hsla(219, 38%, 7%, 0.87)"
  },
  world: {
    axes: {
      x: "hsl(309, 70%, 60%)",
      y: "hsl(240, 70%, 60%)",
      z: "hsl(180, 60%, 50%)"
    },
    box: {
      edge: "hsl(204, 40%, 50%)",
      main: "hsl(204, 90%, 70%)",
      hovered: "hsl(204, 95%, 73%)",
      selected: "hsl(204, 100%, 60%)"
    },
    boxDetails: {
      edge: "hsl(204, 50%, 65%)",
      main: "hsl(204, 60%, 55%)",
      hovered: "hsl(204, 65%, 58%)",
      selected: "hsl(204, 70%, 45%)"
    },
    edges: "hsl(204, 80%, 80%)",
    grid: "hsl(218, 30%, 70%)",
    ground: {
      edge: "hsl(123, 5%, 48%)",
      main: "hsl(123, 5%, 40%)",
      hovered: "hsl(160, 6%, 41%)",
      selected: "hsl(176, 7%, 42%)"
    },
    houseBody: {
      edge: "hsl(219, 10%, 53%)",
      main: "hsl(219, 10%, 45%)",
      hovered: "hsl(217, 12%, 47%)",
      selected: "hsl(215, 14%, 49%)"
    },
    placeholder: {
      main: "hsl(123, 35%, 60%)",
      mainContrast: "hsl(130, 35%, 40%)",
      snapped: "hsl(123, 30%, 50%)",
      snappedContrast: "hsl(130, 30%, 30%)"
    },
    realComponent: {
      main: "hsl(204, 0%, 70%)",
      hovered: "hsl(204, 60%, 70%)",
      selected: "hsl(204, 90%, 50%)"
    },
    roof: {
      main: "hsl(219, 10%, 40%)"
    }
  },
  trs: {
    bay: {
      main: "hsl(240, 15%, 50%)",
      selected: "hsl(240, 30%, 60%)",
      details: "hsl(309, 100%, 80%)",
      preview: "hsl(219, 15%, 40%)"
    },
    clothing: "hsl(180, 60%, 80%)",
    roof: {
      main: "hsl(219, 30%, 60%)",
      support: "hsl(240, 10%, 50%)"
    }
  }
};
export default darkPalette;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsFrames: Component[] = [
  {
    /*1a-c*/
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 9.3,
    length: 0.66,
    width: 0.73,
    article_id: "1700.066",
    label_en: "Steel frame. 0.66 x 0.73 m",
    label_se: "Stålram. 0.66 x 0.73 m",
    label_no: "Stålramme. 0.66 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 11.9,
    length: 1,
    width: 0.73,
    article_id: "1700.101",
    label_en: "Steel frame. 1.00 x 0.73 m",
    label_se: "Stålram. 1.00 x 0.73 m",
    label_no: "Stålramme. 1.00 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 15.8,
    length: 1.5,
    width: 0.73,
    article_id: "1700.150",
    label_en: "Steel frame. 1.50 x 0.73 m",
    label_se: "Stålram. 1.50 x 0.73 m",
    label_no: "Stålramme. 1.50 x 0.73 m"
  },
  {
    /*2a-c*/
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 11.5,
    length: 0.66,
    width: 1.09,
    article_id: "1780.066",
    label_en: "Steel frame. 0.66 x 1.09 m",
    label_se: "Stålram. 0.66 x 1.09 m",
    label_no: "Stålramme. 0.66 x 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 13.8,
    length: 1,
    width: 1.09,
    article_id: "1780.100",
    label_en: "Steel frame. 1.00 x 1.09 m",
    label_se: "Stålram. 1.00 x 1.09 m",
    label_no: "Stålramme. 1.00 x 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 14.9,
    length: 1.5,
    width: 1.09,
    article_id: "1780.150",
    label_en: "Steel frame. 1.50 x 1.09 m",
    label_se: "Stålram. 1.50 x 1.09 m",
    label_no: "Stålramme. 1.50 x 1.09 m"
  },
  {
    /*3a-c*/
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4.1,
    length: 0.66,
    width: 0.73,
    article_id: "1714.066",
    label_en: "Steel frame. 0.66 x 0.73 m",
    label_se: "Stålram. 0.66 x 0.73 m",
    label_no: "Stålramme. 0.66 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.2,
    length: 1.0,
    width: 0.73,
    article_id: "1714.101",
    label_en: "Steel frame. 1.00 x 0.73 m",
    label_se: "Stålram. 1.00 x 0.73 m",
    label_no: "Stålramme. 1.00 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.7,
    length: 1.5,
    width: 0.73,
    article_id: "1714.150",
    label_en: "Steel frame. 1.50 x 0.73 m",
    label_se: "Stålram. 1.50 x 0.73 m",
    label_no: "Stålramme. 1.50 x 0.73 m"
  },
  {
    /* page 13 */
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 18.8,
    length: 2.0,
    width: 0.73,
    article_id: "1700.200",
    label_en: "Steel frame. 2.00 x 0.73 m",
    label_se: "Stålram. 2.00 x 0.73 m",
    label_no: "Stålramme. 2.00 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 19.6,
    length: 2,
    width: 0.73,
    article_id: "1710.200",
    label_en: "Steel frame. 2.00 x 0.73 m",
    label_se: "Stålram. 2.00 x 0.73 m",
    label_no: "Stålramme. 2.00 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 21.5,
    length: 2,
    width: 1.09,
    article_id: "1780.200",
    label_en: "Steel frame. 2.00 x 1.09 m",
    label_se: "Stålram. 2.00 x 1.09 m",
    label_no: "Stålramme. 2.00 x 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 22.3,
    length: 2.0,
    width: 1.09,
    article_id: "1785.200",
    label_en: "Steel frame. 1.50 x 0.73 m",
    label_se: "Stålram. 1.50 x 0.73 m",
    label_no: "Stålramme. 1.50 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 8.6,
    length: 2,
    width: 0.73,
    article_id: "1714.200",
    label_en: "Aluminium frame. 2.00 x 0.73 m",
    label_se: "Aluminiumram. 2.00 x 0.73 m",
    label_no: "Aluminiumramme. 2.00 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 28.4,
    length: 2.2,
    width: 1.09,
    article_id: "1779.109",
    label_en: "Gantry frame. 2.20 x 1.09 m",
    label_se: "Genomgångsram. 2.20 x 1.09 m",
    label_no: "Gjennomgang ramme. 2.20 x 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 17.3,
    length: 2.0,
    width: 0.36,
    article_id: "1717.200",
    label_en: "Steel frame. 2.00 x 0.36 m",
    label_se: "Stålram. 2.00 x 0.36 m",
    label_no: "Stålramme. 1.50 x 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 22.7,
    length: 2,
    width: 0.73,
    article_id: "1718.200",
    label_en: "Steel frame. 2.00 x 0.73 m",
    label_se: "Stålram. 2.00 x 0.73 m",
    label_no: "Stålramme. 2.00 x 0.73 m"
  }
];
export const componentsPassageFrames: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 28.4,
    length: 2.2,
    width: 1.09,
    article_id: "1779.109",
    label_en: "Gantry frame. 2.20 x 1.09 m",
    label_se: "Genomgångsram. 2.20 x 1.09 m",
    label_no: "Gjennomgang. 2.20 x 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 31.2,
    length: 2.2,
    width: 1.5,
    article_id: "1779.150",
    label_en: "Gantry frame. 2.20 x 1.50 m",
    label_se: "Genomgångsram. 2.20 x 1.50 m",
    label_no: "Gjennomgang. 2.20 x 1.50 m"
  }
];

export const componentsTopFrames: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.5,
    length: 1,
    width: 0.73,
    article_id: "1719.073",
    label_en: "Speedy intermediate frame, Steel 0.73 m",
    label_se: "Räckesstolpe med plattformslås, Stål 0.73 m",
    label_no: "Rekkverksstolpe med plattformlås, Stål 0.73 m",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8.5,
    length: 1,
    width: 1.09,
    article_id: "1719.109",
    label_en: "Speedy intermediate frame, Steel 1.09 m",
    label_se: "Räckesstolpe med plattformslås, Stål 1.09 m",
    label_no: "Rekkverksstolpe med plattformlås, Stål 1.09 m",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 2.7,
    length: 1,
    width: 0.73,
    article_id: "1769.073",
    label_en: "Speedy intermediate frame, Aluminium 0.73 m",
    label_se: "Räckesstolpe med plattformslås, Aluminium 0.73 m",
    label_no: "Rekkverksstolpe med plattformlås, Aluminium 0.73 m",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 13.3,
    length: 1,
    width: 0.73,
    article_id: "1722.073",
    label_en: "Speedy top end frame, Steel 0.73 m",
    label_se: "Gavelräckesram, Stål 0.73 m",
    label_no: "Ramme på gavl, Stål 0.73 m",
    variant: FRAME_VARIANT.TOP_END
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 14.9,
    length: 1,
    width: 1.08,
    article_id: "1722.109",
    label_en: "Speedy top end frame, Steel 1.09 m",
    label_se: "Gavelräckesram, Stål 1.09 m",
    label_no: "Ramme på gavl, Stål 1.09 m",
    variant: FRAME_VARIANT.TOP_END
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.6,
    length: 1,
    width: 0.73,
    article_id: "1770.073",
    label_en: "Speedy top end frame, Aluminium 0.73 m",
    label_se: "Gavelräckesram, Aluminium 0.73 m",
    label_no: "Ramme på gavl, Aluminium 0.73 m",
    variant: FRAME_VARIANT.TOP_END
  }
];

export const componentsULedgers: Component[] = [
  /*5a-b*/
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 2.2,
    length: 0.73,
    width: 0,
    article_id: "1750.073",
    label_en: "U-base beam. 0.73 m",
    label_se: "U-tvärstång. 0.73 m" /*change name*/,
    label_no: "U-tverstang. 0.73 m",
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.2,
    length: 1.09,
    width: 0,
    article_id: "1751.109",
    label_en: "U-base beam. 1.09 m",
    label_se: "U-tvärstång. 1.09 m",
    label_no: "U-tverrstang . 1.09 m",
    variant: LEDGER_VARIANT.U_LEDGER
  }
];

export const componentsStarterTransoms: Component[] = [
  /*4a-b*/
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.8,
    length: 0.73,
    width: 0,
    height: 0.1,
    article_id: "1751.073",
    label_en: "Starter U-ledger beam. 0.73 m",
    label_se: "Starter U-tvärstång. 0.73 m",
    label_no: "Starter U-tverstang. 0.73 m",
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    material: "",
    weight: 5.1,
    length: 1.09,
    width: 0,
    height: 0.1,
    article_id: "1751.109",
    label_en: "Starter U-ledger beam. 1.09 m",
    label_se: "Starter U-tvärstång. 1.09 m",
    label_no: "Starter U-tverrstang . 1.09 m",
    variant: LEDGER_VARIANT.U_LEDGER
  }
];

export const componentsOLedgers: Component[] = [
  {
    /* p.25 */
    material: "",
    weight: 6.9,
    length: 2.07,
    width: 0,
    article_id: "1727.207",
    label_en: "Horisontal brace 2.07 m",
    label_se: "Horisontalstag 2.07 m",
    label_no: "Horisontalstag 2.07 m"
  },
  {
    material: "",
    weight: 8.6,
    length: 2.57,
    width: 0,
    article_id: "1727.257",
    label_en: "Horisontal brace 2.57 m",
    label_se: "Horisontalstag 2.57 m",
    label_no: "Horisontalstag 2.57 m"
  },
  {
    material: "",
    weight: 10.4,
    length: 3.07,
    width: 0,
    article_id: "1727.307",
    label_en: "Horisontal brace 3.07 m",
    label_se: "Horisontalstag 3.07 m",
    label_no: "Horisontalstag 3.07 m"
  }
];

export const componentsDecks: Component[] = [
  /* p.19 item 7 */
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.5,
    length: 1.0,
    width: 0.3,
    article_id: "3880.100",
    label_en: "Steel plank 1.00 x 0.30m",
    label_se: "Stålplank 1.00 x 0.30m",
    label_no: "Stålplanke 1.00 x 0.30m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 10.3,
    length: 1.5,
    width: 0.3,
    article_id: "3880.150",
    label_en: "Steel plank 1.50 x 0.30m",
    label_se: "Stålplank 1.50 x 0.30m",
    label_no: "Stålplanke 1.50 x 0.30m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 12.8,
    length: 2.0,
    width: 0.3,
    article_id: "3880.200",
    label_en: "Steel plank 2.00 x 0.30m",
    label_se: "Stålplank 2.00 x 0.30m",
    label_no: "Stålplanke 2.00 x 0.30m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 15.3,
    length: 2.5,
    width: 0.3,
    article_id: "3880.250",
    label_en: "Steel plank 2.50 x 0.30m",
    label_se: "Stålplank 2.50 x 0.30m",
    label_no: "Stålplanke 2.50 x 0.30m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 11.8,
    length: 2.5,
    width: 0.2,
    article_id: "3878.250",
    label_en: "Steel plank 2.50 x 0.20m",
    label_se: "Stålplank 2.50 x 0.20m",
    label_no: "Stålplanke 2.50 x 0.20m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 9.5,
    length: 2.0,
    width: 0.2,
    article_id: "3878.200",
    label_en: "Steel plank 2.00 x 0.20m",
    label_se: "Stålplank 2.00 x 0.20m",
    label_no: "Stålplanke 2.00 x 0.20m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 7.2,
    length: 1.5,
    width: 0.2,
    article_id: "3878.150",
    label_en: "Steel plank 1.50 x 0.20m",
    label_se: "Stålplank 1.50 x 0.20m",
    label_no: "Stålplanke 1.50 x 0.20m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4.8,
    length: 1.0,
    width: 0.2,
    article_id: "3878.100",
    label_en: "Steel plank 1.00 x 0.20m",
    label_se: "Stålplank 1.00 x 0.20m",
    label_no: "Stålplanke 1.00 x 0.20m"
  },
  {
    /* 1 p.15 */
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.6,
    length: 0.73,
    width: 0.32,
    article_id: "3883.073",
    label_en: "Steel plank. 0.73 x 0.32m - U LW",
    label_se: "Stålplank. 0.73 x 0.32m - U LV",
    label_no: "Stålplanke. 0.73 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 7.7,
    length: 1.09,
    width: 0.32,
    article_id: "3883.109",
    label_en: "Steel plank. 1.09 x 0.32m - U LW",
    label_se: "Stålplank. 1.09 x 0.32m - U LV",
    label_no: "Stålplanke. 1.09 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 10.5,
    length: 1.57,
    width: 0.32,
    article_id: "3883.157",
    label_en: "Steel plank. 1.57 x 0.32m - U LW",
    label_se: "Stålplank. 1.57 x 0.32m - U LV",
    label_no: "Stålplanke. 1.57 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 13.4,
    length: 2.07,
    width: 0.32,
    article_id: "3883.207",
    label_en: "Steel plank. 2.07 x 0.32m - U LW",
    label_se: "Stålplank. 2.07 x 0.32m - U LV",
    label_no: "Stålplanke. 2.07 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 16.4,
    length: 2.57,
    width: 0.32,
    article_id: "3883.257",
    label_en: "Steel plank. 2.57 x 0.32m - U LW",
    label_se: "Stålplank. 2.57 x 0.32m - U LV",
    label_no: "Stålplanke. 2.57 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 19.3,
    length: 3.07,
    width: 0.32,
    article_id: "3883.307",
    label_en: "Steel plank. 3.07 x 0.32m - U LW",
    label_se: "Stålplank. 3.07 x 0.32m - U LV",
    label_no: "Stålplanke. 3.07 x 0.32m - U L"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 25.6,
    length: 4.14,
    width: 0.32,
    article_id: "3883.414",
    label_en: "Steel plank. 4.14 x 0.32m - U LW",
    label_se: "Stålplank. 4.14 x 0.32m - U LV",
    label_no: "Stålplanke. 4.14 x 0.32m - U L"
  },
  {
    /* 2 */
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6,
    length: 0.73,
    width: 0.32,
    article_id: "3812.073",
    label_en: "Steel plank. 0.73 x 0.32m - U T4",
    label_se: "Stålplank. 0.73 x 0.32m - U T4",
    label_no: "Stålplanke. 0.73 x 0.322m - U T4"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8.3,
    length: 1.09,
    width: 0.32,
    article_id: "3812.109",
    label_en: "Steel plank. 1.09 x 0.32m - U T4",
    label_se: "Stålplank. 1.09 x 0.32m - U T4",
    label_no: "Stålplanke. 1.09 x 0.32m - U T4"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 11.6,
    length: 1.57,
    width: 0.32,
    article_id: "3812.157",
    label_en: "Steel plank. 1.57 x 0.32m - U T4",
    label_se: "Stålplank. 1.57 x 0.32m - U T4",
    label_no: "Stålplanke. 1.57 x 0.32m - U T4"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 14.9,
    length: 2.07,
    width: 0.32,
    article_id: "3812.207",
    label_en: "Steel plank. 2.07 x 0.32m - U T4",
    label_se: "Stålplank. 2.07 x 0.32m - U T4",
    label_no: "Stålplanke. 2.07 x 0.32m - U T4"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 18.2,
    length: 2.57,
    width: 0.32,
    article_id: "3812.257",
    label_en: "Steel plank. 2.57 x 0.32m - U T4",
    label_se: "Stålplank. 2.57 x 0.32m - U T4",
    label_no: "Stålplanke. 2.57 x 0.32m - U T4"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 21.5,
    length: 3.07,
    width: 0.32,
    article_id: "3812.307",
    label_en: "Steel plank. 3.07 x 0.32m - U T4",
    label_se: "Stålplank. 3.07 x 0.32m - U T4",
    label_no: "Stålplanke. 3.07 x 0.32m - U T4"
  },
  /* 3 p.15 */
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.1,
    length: 0.73,
    width: 0.19,
    article_id: "3801.073",
    label_en: "Steel plank. 0.73 x 0.19m - U",
    label_se: "Stålplank. 0.73 x 0.19m - U",
    label_no: "Stålplanke. 0.73 x 0.19m - U"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.4,
    length: 1.09,
    width: 0.19,
    article_id: "3801.109",
    label_en: "Steel plank. 1.09 x 0.19m - U",
    label_se: "Stålplank. 1.09 x 0.19m - U",
    label_no: "Stålplanke. 1.09 x 0.19m - U"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8.5,
    length: 1.57,
    width: 0.19,
    article_id: "3801.157",
    label_en: "Steel plank. 1.57 x 0.19m - U",
    label_se: "Stålplank. 1.57 x 0.19m - U",
    label_no: "Stålplanke. 1.57 x 0.19m - U"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 10.2,
    length: 2.07,
    width: 0.19,
    article_id: "3801.207",
    label_en: "Steel plank. 2.07 x 0.19m - U",
    label_se: "Stålplank. 2.07 x 0.19m - U",
    label_no: "Stålplanke. 2.07 x 0.19m - U"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 13.2,
    length: 2.57,
    width: 0.19,
    article_id: "3801.257",
    label_en: "Steel plank. 2.57 x 0.19m - U",
    label_se: "Stålplank. 2.57 x 0.19m - U",
    label_no: "Stålplanke. 2.57 x 0.19m - U"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 15.3,
    length: 3.07,
    width: 0.19,
    article_id: "3801.307",
    label_en: "Steel plank. 3.07 x 0.19m - U",
    label_se: "Stålplank. 3.07 x 0.19m - U",
    label_no: "Stålplanke. 3.07 x 0.19m - U"
  },
  {
    /* 4 p.15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 7,
    length: 0.73,
    width: 0.61,
    article_id: "3866.073",
    label_en: "Alu Plank. 0.73 x 0.61m - U-Xtra-N",
    label_se: "AluPlank. 0.73 x 0.61m - U-Xtra-N",
    label_no: "AluPlanke. 0.73 x 0.61m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 9.5,
    length: 1.09,
    width: 0.61,
    article_id: "3866.109",
    label_en: "Alu Plank. 1.09 x 0.61m - U-Xtra-N",
    label_se: "AluPlank. 1.09 x 0.61m - U-Xtra-N",
    label_no: "AluPlanke. 1.09 x 0.61m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 13,
    length: 1.57,
    width: 0.61,
    article_id: "3866.157",
    label_en: "Alu Plank. 1.57 x 0.61m - U-Xtra-N",
    label_se: "AluPlank. 1.57 x 0.61m - U-Xtra-N",
    label_no: "AluPlanke. 1.57 x 0.61m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 16.2,
    length: 2.07,
    width: 0.61,
    article_id: "3866.207",
    label_en: "Alu Plank. 2.07 x 0.61m - U-Xtra-N",
    label_se: "AluPlank. 2.07 x 0.61m - U-Xtra-N",
    label_no: "AluPlanke. 2.07 x 0.61m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 19.0,
    length: 2.57,
    width: 0.61,
    article_id: "3866.257",
    label_en: "Alu Plank. 2.57 x 0.61m - U-Xtra-N",
    label_se: "AluPlank. 2.57 x 0.61m - U-Xtra-N",
    label_no: "AluPlanke. 2.57 x 0.61m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 23.5,
    length: 3.07,
    width: 0.61,
    article_id: "3866.307",
    label_en: "Plank. 3.07 x 0.61m - U-Xtra-N",
    label_se: "Plank. 3.07 x 0.61m - U-Xtra-N",
    label_no: "Planke. 3.07 x 0.61m - U-Xtra-N"
  },
  {
    /* 5 p.15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 8.5,
    length: 1.57,
    width: 0.32,
    article_id: "3877.157",
    label_en: "Plank. 1.57 x 0.32m - U-Xtra-N",
    label_se: "Plank. 1.57 x 0.32m - U-Xtra-N",
    label_no: "Planke. 1.57 x 0.32m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 10.7,
    length: 2.07,
    width: 0.32,
    article_id: "3877.207",
    label_en: "Plank. 2.07 x 0.32m - U-Xtra-N",
    label_se: "Plank. 2.07 x 0.32m - U-Xtra-N",
    label_no: "Planke. 2.07 x 0.32m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 13,
    length: 2.57,
    width: 0.32,
    article_id: "3877.257",
    label_en: "Plank. 2.57 x 0.32m - U-Xtra-N",
    label_se: "Plank. 2.57 x 0.32m - U-Xtra-N",
    label_no: "Planke. 2.57 x 0.32m - U-Xtra-N"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLASTIC,
    weight: 15.2,
    length: 3.07,
    width: 0.32,
    article_id: "3877.307",
    label_en: "Plank. 3.07 x 0.32m - U-Xtra-N",
    label_se: "Plank. 3.07 x 0.32m - U-Xtra-N",
    label_no: "Planke. 3.07 x 0.32m - U-Xtra-N"
  },
  {
    /* 6 p15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 6.7,
    length: 0.73,
    width: 0.61,
    article_id: "3898.073",
    label_en: "Plank. 0.73 x 0.61m - U T21",
    label_se: "Plank. 0.73 x 0.61m - U T21",
    label_no: "Planke. 0.73 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 9,
    length: 1.09,
    width: 0.61,
    article_id: "3898.109",
    label_en: "Plank. 1.09 x 0.61m - U T21",
    label_se: "Plank. 1.09 x 0.61m - U T21",
    label_no: "Planke. 1.09 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 11,
    length: 1.4,
    width: 0.61,
    article_id: "3898.140",
    label_en: "Plank. 1.4 x 0.61m - U T21",
    label_se: "Plank. 1.4 x 0.61m - U T21",
    label_no: "Planke. 1.4 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 12.1,
    length: 1.57,
    width: 0.61,
    article_id: "3898.157",
    label_en: "Plank. 1.57 x 0.61m - U T21",
    label_se: "Plank. 1.57 x 0.61m - U T21",
    label_no: "Planke. 1.57 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 15.3,
    length: 2.07,
    width: 0.61,
    article_id: "3898.207",
    label_en: "Plank. 2.07 x 0.61m - U T21",
    label_se: "Plank. 2.07 x 0.61m - U T21",
    label_no: "Planke. 2.07 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 18.5,
    length: 2.57,
    width: 0.61,
    article_id: "3898.257",
    label_en: "Plank. 2.57 x 0.61m - U T21",
    label_se: "Plank. 2.57 x 0.61m - U T21",
    label_no: "Planke. 2.57 x 0.61m - U T21"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 21.7,
    length: 3.07,
    width: 0.61,
    article_id: "3898.307",
    label_en: "Plank. 3.07 x 0.61m - U T21",
    label_se: "Plank. 3.07 x 0.61m - U T21",
    label_no: "Planke. 3.07 x 0.61m - U T21"
  },
  {
    /* 7 p15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 7.4,
    length: 1.57,
    width: 0.32,
    article_id: "3856.157",
    label_en: "Plank. 1.57 x 0.32m - U T9",
    label_se: "Plank. 1.57 x 0.32m - U T9",
    label_no: "Planke. 1.57 x 0.32m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 9.2,
    length: 2.07,
    width: 0.32,
    article_id: "3856.207",
    label_en: "Plank. 2.07 x 0.32m - U T9",
    label_se: "Plank. 2.07 x 0.32m - U T9",
    label_no: "Planke. 2.07 x 0.32m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 11,
    length: 2.57,
    width: 0.32,
    article_id: "3856.257",
    label_en: "Plank. 2.57 x 0.32m - U T9",
    label_se: "Plank. 2.57 x 0.32m - U T9",
    label_no: "Planke. 2.57 x 0.32m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 13.3,
    length: 3.07,
    width: 0.32,
    article_id: "3856.307",
    label_en: "Plank. 3.07 x 0.32m - U T9",
    label_se: "Plank. 3.07 x 0.32m - U T9",
    label_no: "Planke. 3.07 x 0.32m - U T9"
  },
  {
    /* 8 p15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 5.6,
    length: 1.57,
    width: 0.19,
    article_id: "3857.157",
    label_en: "Plank. 1.57 x 0.19m - U T9",
    label_se: "Plank. 1.57 x 0.19m - U T9",
    label_no: "Planke. 1.57 x 0.19m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 7.2,
    length: 2.07,
    width: 0.19,
    article_id: "3857.207",
    label_en: "Plank. 2.07 x 0.19m - U T9",
    label_se: "Plank. 2.07 x 0.19m - U T9",
    label_no: "Planke. 2.07 x 0.19m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 8.7,
    length: 2.57,
    width: 0.19,
    article_id: "3857.257",
    label_en: "Plank. 2.57 x 0.19m - U T9",
    label_se: "Plank. 2.57 x 0.19m - U T9",
    label_no: "Planke. 2.57 x 0.19m - U T9"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_STEEL,
    weight: 10.2,
    length: 3.07,
    width: 0.19,
    article_id: "3857.307",
    label_en: "Plank. 3.07 x 0.19m - U T9",
    label_se: "Plank. 3.07 x 0.19m - U T9",
    label_no: "Planke. 3.07 x 0.19m - U T9"
  },
  {
    /* 9 p15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.1,
    length: 0.73,
    width: 0.32,
    article_id: "3803.073",
    label_en: "Alu Plank. 0.73 x 0.32m - U perforated",
    label_se: "AluPlank. 0.73 x 0.32m - U perforated",
    label_no: "AluPlanke. 0.73 x 0.32m - U perforated,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.4,
    length: 1.09,
    width: 0.32,
    article_id: "3803.109",
    label_en: "Alu Plank. 1.09 x 0.32m - U perforated",
    label_se: "AluPlank. 1.09 x 0.32m - U perforated",
    label_no: "AluPlanke. 1.09 x 0.32m - U perforated,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.5,
    length: 1.57,
    width: 0.32,
    article_id: "3803.157",
    label_en: "Alu Plank. 1.57 x 0.32m - U perforated",
    label_se: "AluPlank. 1.57 x 0.32m - U perforated",
    label_no: "AluPlanke. 1.57 x 0.32m - U perforated,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 8,
    length: 2.07,
    width: 0.32,
    article_id: "3803.207",
    label_en: "Alu Plank. 2.07 x 0.32m - U perforated",
    label_se: "AluPlank. 2.07 x 0.32m - U perforated",
    label_no: "AluPlanke. 2.07 x 0.32m - U perforated,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 10.0,
    length: 2.57,
    width: 0.32,
    article_id: "3803.257",
    label_en: "Alu Plank. 2.57 x 0.32m - U perforated",
    label_se: "AluPlank. 2.57 x 0.32m - U perforated",
    label_no: "AluPlanke. 2.57 x 0.32m - U perforated,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 11.5,
    length: 3.07,
    width: 0.32,
    article_id: "3803.307",
    label_en: "Alu Plank. 3.07 x 0.32m - U perforated",
    label_se: "AluPlank. 3.07 x 0.32m - U perforated",
    label_no: "AluPlanke. 3.07 x 0.32m - U perforated,"
  },
  {
    /* 10 p15 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 13.1,
    length: 0.73,
    width: 0.61,
    article_id: "3835.157",
    label_en: "Plank. 1.57 x 0.61m - U robust",
    label_se: "Plank. 1.57 x 0.61m - U robust",
    label_no: "Planke. 1.57 x 0.61m - U robust,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 16.4,
    length: 2.07,
    width: 0.61,
    article_id: "3835.207",
    label_en: "Plank. 2.07 x 0.61m - U robust",
    label_se: "Plank. 2.07 x 0.61m - U robust",
    label_no: "Planke. 2.07 x 0.61m - U robust,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 19.3,
    length: 2.57,
    width: 0.61,
    article_id: "3835.257",
    label_en: "Plank. 2.57 x 0.61m - U robust",
    label_se: "Plank. 2.57 x 0.61m - U robust",
    label_no: "Planke. 2.57 x 0.61m - U robust,"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 24.2,
    length: 3.07,
    width: 0.61,
    article_id: "3835.307",
    label_en: "Plank. 3.07 x 0.61m - U robust",
    label_se: "Plank. 3.07 x 0.61m - U robust",
    label_no: "Planke. 3.07 x 0.61m - U robust,"
  },
  {
    /* p.17 6-9 */
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 14.2,
    length: 1.57,
    width: 0.61,
    article_id: "3858.157",
    label_en: "Access deck U-robust hatch 1.57 x 0.61m",
    label_se: "Däck med lucka U-robust hatch 1.57 x 0.61m",
    label_no: "Dekk med luke U-robust hatch 1.57 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 17.2,
    length: 2.07,
    width: 0.61,
    article_id: "3858.207",
    label_en: "Access deck U-robust hatch 2.07 x 0.61m",
    label_se: "Däck med lucka U-robust hatch 2.07 x 0.61m",
    label_no: "Dekk med luke U-robust hatch 2.07 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 17.6,
    length: 2.07,
    width: 0.61,
    article_id: "3875.207",
    label_en: "Access deck U-Alu 2.07 x 0.61m",
    label_se: "Däck med lucka U-Alu 2.07 x 0.61m",
    label_no: "Dekk med luke U-Alu 2.07 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 15.1,
    length: 1.57,
    width: 0.61,
    article_id: "3851.157",
    label_en: "Acess deck U-Alu 1.57 x 0.61m",
    label_se: "Däck med lucka U-Alu 1.57 x 0.61m",
    label_no: "Dekk med luke U-Alu 1.57 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 17.0,
    length: 2.07,
    width: 0.61,
    article_id: "3851.207",
    label_en: "Access deck U-Alu 2.07 x 0.61m",
    label_se: "Däck med lucka U-Alu 2.07 x 0.61m",
    label_no: "Dekk med luke U-Alu 2.07 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 38.0,
    length: 2.57,
    width: 0.64,
    article_id: "3813.257",
    label_en: "Access deck U-hatch 2.57 x 0.64m",
    label_se: "Däck med lucka U-hatch 2.57 x 0.64m",
    label_no: "Dekk med luke U-hatch 2.57 x 0.64m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 28.9,
    length: 2.07,
    width: 0.64,
    article_id: "3813.207",
    label_en: "Access deck U-hatch 2.07 x 0.64m",
    label_se: "Däck med lucka U-hatch 2.07 x 0.64m",
    label_no: "Dekk med luke U-hatch 2.07 x 0.64m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 24.5,
    length: 3.07,
    width: 0.61,
    article_id: "3851.307",
    label_en: "Access deck U-Alu 3.07 x 0.61m",
    label_se: "Däck med lucka U-Alu 3.07 x 0.61m",
    label_no: "Dekk med luke U-Alu 3.07 x 0.61m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 20.0,
    length: 2.57,
    width: 0.61,
    article_id: "3851.257",
    label_en: "Access deck U-Alu 2.57 x 0.61m",
    label_se: "Däck med lucka U-Alu 2.57 x 0.61m",
    label_no: "Dekk med luke U-Alu 2.57 x 0.61m"
  }
];

export const componentsStairDecks: Component[] = [
  {
    material: "",
    weight: 25.4,
    length: 2.57,
    width: 0.61,
    article_id: "3869.257",
    label_en: "Platform with ladder U-Xtra-N 2.57 x 0.61m",
    label_se: "Plattform med stege U-Xtra-N 2.57 x 0.61m",
    label_no: "Plattform med stige U-Xtra-N 2.57 x 0.61m"
  },
  {
    material: "",
    weight: 29.5,
    length: 3.07,
    width: 0.61,
    article_id: "3869.307",
    label_en: "Platform with ladder U-Xtra-N 3.07 x 0.61m",
    label_se: "Plattform med stege U-Xtra-N 3.07 x 0.61m",
    label_no: "Plattform med stige U-Xtra-N 3.07 x 0.61m"
  },
  {
    material: "",
    weight: 24.0,
    length: 2.57,
    width: 0.61,
    article_id: "3852.257",
    label_en: "Platform with ladder U-Alu 2.57 x 0.61m",
    label_se: "Plattform med stege U-Alu  2.57 x 0.61m",
    label_no: "Plattform med stige U-Alu 2.57 x 0.61m"
  },
  {
    material: "",
    weight: 28.0,
    length: 3.07,
    width: 0.61,
    article_id: "3852.307",
    label_en: "Platform with ladder U-Alu 3.07 x 0.61m",
    label_se: "Plattform med stege U-Alu 3.07 x 0.61m",
    label_no: "Plattform med stige U-Alu 3.07 x 0.61m"
  },
  {
    material: "",
    weight: 24.0,
    length: 2.57,
    width: 0.61,
    article_id: "3838.257",
    label_en: "Platform with ladder U-robust 2.57 x 0.61m",
    label_se: "Plattform med stege U-robust  2.57 x 0.61m",
    label_no: "Plattform med stige U-robust 2.57 x 0.61m"
  },
  {
    material: "",
    weight: 27.4,
    length: 3.07,
    width: 0.61,
    article_id: "3838.307",
    label_en: "Platform with ladder U-robust 3.07 x 0.61m",
    label_se: "Plattform med stege U-robust 3.07 x 0.61m",
    label_no: "Plattform med stige U-robust 3.07 x 0.61m"
  },
  {
    material: "",
    weight: 25.2,
    length: 2.57,
    width: 0.61,
    article_id: "3859.257",
    label_en: "Platform with ladder U-robust 2.57 x 0.61m",
    label_se: "Plattform med stege U-robust 2.57 x 0.61m",
    label_no: "Plattform med stige U-robust 2.57 x 0.61m"
  },
  {
    material: "",
    weight: 28.4,
    length: 3.07,
    width: 0.61,
    article_id: "3859.307",
    label_en: "Platform with ladder U-robust 3.07 x 0.61m",
    label_se: "Plattform med stege U-robust 3.07 x 0.61m",
    label_no: "Plattform med stige U-robust 3.07 x 0.61m"
  },
  {
    material: "",
    weight: 25.0,
    length: 2.57,
    width: 0.61,
    article_id: "3875.257",
    label_en: "Platform with ladder U-Alu 2.57 x 0.61m",
    label_se: "Plattform med stege U-Alu 2.57 x 0.61m",
    label_no: "Plattform med stige U-Alu 2.57 x 0.61m"
  },
  {
    material: "",
    weight: 29.0,
    length: 3.07,
    width: 0.61,
    article_id: "3875.307",
    label_en: "Platform with ladder U-Alu 3.07 x 0.61m",
    label_se: "Plattform med stege U-Alu 3.07 x 0.61m",
    label_no: "Plattform med stige U-Alu 3.07 x 0.61m"
  }
];

export const componentsStairHatches: Component[] = [
  {
    material: "",
    weight: 7.6,
    length: 2.15,
    width: 0.35,
    article_id: "4009.007",
    label_en: "Access ladder T19 2.15 x 0.35m",
    label_se: "Stege 2.15 x 0.35m",
    label_no: "Stige 2.15 x 0.35m"
  }
];

export const componentsSingleGuardRails: Component[] = [
  {
    /* p.23 */
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 1.6,
    length: 0.73,
    width: 0,
    article_id: "1724.073",
    label_en: "Single guardrail 0.73 m",
    label_se: "Enkelräcke 0.73 m",
    label_no: "Enkelt rekkverk 0.73 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 2.0,
    length: 1.09,
    width: 0,
    article_id: "1724.109",
    label_en: "Single guardrail 1.09 m",
    label_se: "Enkelräcke 1.09 m",
    label_no: "Enkelt rekkverk 1.09 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 2.9,
    length: 1.57,
    width: 0,
    article_id: "1725.157",
    label_en: "Single guardrail 1.57 m",
    label_se: "Enkelräcke 1.57 m",
    label_no: "Enkelt rekkverk 1.57 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.8,
    length: 2.07,
    width: 0,
    article_id: "1725.207",
    label_en: "Single guardrail 2.07 m",
    label_se: "Enkelträcke 2.07 m",
    label_no: "Enkelt rekkverk 2.07 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4.7,
    length: 2.57,
    width: 0,
    article_id: "1725.257",
    label_en: "Single guardrail 2.57 m",
    label_se: "Enkelräcke 2.57 m",
    label_no: "Enkelt rekkverk 2.57 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.6,
    length: 3.07,
    width: 0,
    article_id: "1725.307",
    label_en: "Single guardrail 3.07 m",
    label_se: "Enkelräcke 3.07 m",
    label_no: "Enkelt rekkverk 3.07 m"
  }
];

export const componentsGuardRails: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.5,
    length: 1.57,
    width: 0.5,
    article_id: "1728.157",
    label_en: "Double guardrail steel 1.57 x 0.50m",
    label_se: "Dubbelräcke stål 1.57 x 0.50m",
    label_no: "Dubbelt stål rekkverk 1.57 x 0.50m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4.6,
    length: 2.07,
    width: 0.5,
    article_id: "1728.207",
    label_en: "Double guardrail steel 2.07 x 0.50m",
    label_se: "Dubbelräcke stål 2.07 x 0.50m",
    label_no: "Dubbelt stål rekkverk 2.07 x 0.50m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.8,
    length: 2.57,
    width: 0.5,
    article_id: "1728.257",
    label_en: "Double guardrail steel 2.57 x 0.50m",
    label_se: "Dubbelräcke stål 2.57 x 0.50m",
    label_no: "Dubbelt stål reckverk 2.57 x 0.50m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.7,
    length: 3.07,
    width: 0.5,
    article_id: "1728.307",
    label_en: "Double guardrail steel 3.07 x 0.50m",
    label_se: "Dubbelräcke stål 3.07 x 0.50m",
    label_no: "Dubbelt stål rekkverk 3.07 x 0.50m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.5,
    length: 1.57,
    width: 0.5,
    article_id: "1732.157",
    label_en: "Double guardrail aluminium 1.57 x 0.50m",
    label_se: "Dubbelräcke aluminium 1.57 x 0.50m",
    label_no: "Dubbelt rekkverk aluminium 1.57 x 0.50m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.6,
    length: 2.07,
    width: 0.5,
    article_id: "1732.207",
    label_en: "Double guardrail aluminium 2.07 x 0.50m",
    label_se: "Dubbelräcke aluminium 2.07 x 0.50m",
    label_no: "Dubbelt rekkverk aluminium 2.07 x 0.50m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.8,
    length: 2.57,
    width: 0.5,
    article_id: "1732.257",
    label_en: "Double guardrail aluminium 2.57 x 0.50m",
    label_se: "Dubellräcke aluminium 2.57 x 0.50m",
    label_no: "Dubbelt reckverk aluminium 2.57 x 0.50m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.7,
    length: 3.07,
    width: 0.5,
    article_id: "1732.307",
    label_en: "Double guardrail aluminium 3.07 x 0.50m",
    label_se: "Dubbelräcke aluminium 3.07 x 0.50m",
    label_no: "Dubbelt rekkverk aluminium 3.07 x 0.50m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.6,
    length: 0.73,
    width: 1.0,
    article_id: "1770.073",
    label_en: "Gabel rail Alu 1.00 x 0.73m",
    label_se: "Gavelräcke Alu 1.00 x 0.73m",
    label_no: "Gavlrekkverk Alu 1.00 x 0.73m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 14.9,
    length: 1.09,
    width: 1.0,
    article_id: "1722.109",
    label_en: "Gabel rail of steel 1.00 x 1.09m",
    label_se: "Gavelräcke av stål 1.00 x 1.09m",
    label_no: "Gavlrekkverk av stål 1.00 x 1.09m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 2.2,
    length: 0.73,
    width: 0,
    article_id: "1725.073",
    label_en: "Gabel rail 0.73m",
    label_se: "Gavelräcke 0.73m",
    label_no: "Gavlrekkverk 0.73m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 3.5,
    length: 1.09,
    width: 0,
    article_id: "1725.109",
    label_en: "Gabel rail 1.09m",
    label_se: "Gavelräcke 1.09m",
    label_no: "Gavlrekkverk 1.09m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 4.4,
    length: 0.73,
    width: 0,
    article_id: "1728.719",
    label_en: "Double gabel rail ws19 0.73m",
    label_se: "Dubbelt gavelräcke ws19 0.73m",
    label_no: "Dobbelt gavlrekkverk ws19 0.73m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: "",
    weight: 4.4,
    length: 0.73,
    width: 0,
    article_id: "1728.722",
    label_en: "Double gabel rail ws22 0.73m",
    label_se: "Dubbelt gavelräcke ws22 0.73m",
    label_no: "Dobbelt gavlrekkverk ws22 0.73m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: "",
    weight: 5.6,
    length: 1.09,
    width: 0,
    article_id: "1728.119",
    label_en: "Double gabel rail ws19 1.09m",
    label_se: "Dubbelt gavelräcke ws19 1.09m",
    label_no: "Dobbelt gavlrekkverk ws19 1.09m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: "",
    weight: 5.6,
    length: 1.09,
    width: 0,
    article_id: "1728.122",
    label_en: "Double gabel rail ws22 1.09m",
    label_se: "Dubbelt gavelräcke ws22 1.09m",
    label_no: "Dobbelt gavlrekkverk ws22 1.09m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  }
];

export const componentsToeBoards: Component[] = [
  {
    /* p.25 item 1 */
    material: "",
    weight: 1.6,
    length: 0.73,
    width: 0.15,
    article_id: "1756.073",
    label_en: "Toe board 0.73 x 0.15m",
    label_se: "Sparklist 0.73 x 0.15m",
    label_no: "Fotlist 0.73 x 0.15m"
  },
  {
    material: "",
    weight: 2.4,
    length: 1.09,
    width: 0.15,
    article_id: "1756.109",
    label_en: "Toe board 1.09 x 0.15m",
    label_se: "Sparklist 1.09 x 0.15m",
    label_no: "Fotlist 1.09 x 0.15m"
  },
  {
    material: "",
    weight: 3.1,
    length: 1.57,
    width: 0.15,
    article_id: "1757.157",
    label_en: "Toe board 1.57 x 0.15m",
    label_se: "Sparklist 1.57 x 0.15m",
    label_no: "Fotlist 1.57 x 0.15m"
  },
  {
    material: "",
    weight: 4.7,
    length: 2.07,
    width: 0.15,
    article_id: "1757.207'",
    label_en: "Toe board 2.07 x 0.15m",
    label_se: "Sparklist 2.07 x 0.15m",
    label_no: "Fotlist 2.07 x 0.15m"
  },
  {
    material: "",
    weight: 5.6,
    length: 2.57,
    width: 0.15,
    article_id: "1757.257",
    label_en: "Toe board 2.57 x 0.15m",
    label_se: "Sparklist 2.57 x 0.15m",
    label_no: "Fotlist 2.57 x 0.15m"
  },
  {
    material: "",
    weight: 6.8,
    length: 3.07,
    width: 0.15,
    article_id: "1757.307",
    label_en: "Toe board 3.07 x 0.15m",
    label_se: "Sparklist 3.07 x 0.15m",
    label_no: "Fotlist 3.07 x 0.15m"
  },
  {
    material: "",
    weight: 10.3,
    length: 4.14,
    width: 0.15,
    article_id: "1757.414",
    label_en: "Toe board 4.14 x 0.15m",
    label_se: "Sparklist 4.14 x 0.15m",
    label_no: "Fotlist 4.14 x 0.15m"
  }
];

export const componentsDiagonalBraces: Component[] = [
  {
    /* p.25 */
    material: "",
    weight: 6.5,
    length: 1.57,
    width: 2,
    article_id: "1736.157",
    label_en: "Diagonal brace with 2 half-couplers 1.57x2m",
    label_se: "Diagonalstag med 2 halvkopplingar 1.57x2m",
    label_no: "Diagonalstag med 2 halvkopplinger 1.57x2m"
  },
  {
    material: "",
    weight: 7.0,
    length: 2.07,
    width: 2,
    article_id: "1736.207",
    label_en: "Diagonal brace (with wedge) 2.07x2m",
    label_se: "Diagonalstag (med kil) 2.07x2m",
    label_no: "Diagonalstag (med kile) 2.07x2m"
  },
  {
    material: "",
    weight: 7.8,
    length: 2.57,
    width: 2,
    article_id: "1736.257",
    label_en: "Diagonal brace (with wedge) 2.57x2m",
    label_se: "Diagonalstag (med kil) 2.57x2m",
    label_no: "Diagonalstag (med kile) 2.57x2m"
  },
  {
    material: "",
    weight: 8.3,
    length: 3.07,
    width: 2,
    article_id: "1736.307",
    label_en: "Diagonal brace (with wedge) 3.07x2m",
    label_se: "Diagonalstag (med kil) 3.07x2m",
    label_no: "Diagonalstag (med kile) 3.07x2m"
  },
  {
    material: "",
    weight: 7.3,
    length: 2.57,
    width: 1.5,
    article_id: "1737.257",
    label_en: "Diagonal brace (with wedge) 2.57x1.5m",
    label_se: "Diagonalstag (med kil) 2.57x1.5m",
    label_no: "Diagonalstag (med kile) 2.57x1.5m"
  }
];

export const componentsConsoles: Component[] = [
  {
    material: "",
    weight: 7.0,
    length: 0,
    width: 0.73,
    article_id: "1744.073",
    label_en: "Console bracket with clamp 0.73 m",
    label_se: "Konsol med klämma 0.73 m",
    label_no: "Konsoll med klemme 0.73 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 8.8,
    length: 0,
    width: 0.73,
    article_id: "1745.719",
    label_en: "Console bracket with clamp (reinforced) 0.73 m",
    label_se: "Konsol med klämma (förstärkt) 0.73 m",
    label_no: "Konsoll med klemme (forsterket) 0.73 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 9.6,
    length: 0,
    width: 1.09,
    article_id: "1745.119",
    label_en: "Console bracket with clamp 1.09 m",
    label_se: "Konsol med klämma 1.09 m",
    label_no: "Konsoll med klemme 1.09 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 1.6,
    length: 0,
    width: 0.36,
    article_id: "1746.036",
    label_en: "Plug-in console 0.36 m",
    label_se: "Plug-in konsol 0.36 m",
    label_no: "Plug-in konsoll 0.36 m"
  },
  {
    material: "",
    weight: 3.5,
    length: 0,
    width: 0.36,
    article_id: "1745.319",
    label_en: "Console with clamp 0.36 m",
    label_se: "Konsol med klämma 0.36 m",
    label_no: "Konsoll med klemme 0.36 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: "",
    weight: 5.8,
    length: 0,
    width: 0.5,
    article_id: "1744.519",
    label_en: "Console with clamp 0.50 m",
    label_se: "Konsol med klämma 0.50 m",
    label_no: "Konsoll med klemme 0.50 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 6.4,
    length: 0,
    width: 0.73,
    article_id: "1744.719",
    label_en: "Console with clamp 0.73 m",
    label_se: "Konsol med klämma 0.73 m",
    label_no: "Konsoll med klemme 0.73 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 5.46,
    length: 0,
    width: 0.5,
    article_id: "1746.500",
    label_en: "Combi-bracket 0.50 m",
    label_se: "Kombi-konsol 0.50 m",
    label_no: "Kombi-konsoll 0.50 m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    weight: 1.3,
    length: 0,
    width: 0.22,
    article_id: "1746.022",
    label_en: "Plug-in console with clamp 0.22 m",
    label_se: "Plug-in konsol med klämma 0.22 m",
    label_no: "Plug-in konoll med klemme 0.22 m"
  },
  {
    material: "",
    weight: 2.8,
    length: 0,
    width: 0.22,
    article_id: "1744.019",
    label_en: "Console 0.22 m",
    label_se: "Konsol 0.22 m",
    label_no: "Konsoll 0.22 m"
  },
  {
    material: "",
    weight: 3.3,
    length: 0,
    width: 0.36,
    article_id: "1743.319",
    label_en: "Console 0.36 m",
    label_se: "Konsol 0.36 m",
    label_no: "Konsoll 0.36 m"
  },
  {
    material: "",
    weight: 4.8,
    length: 0,
    width: 0.36,
    article_id: "1746.319",
    label_en: "Combi consol 0.36 m",
    label_se: "Kombi konsol 0.36 m",
    label_no: "Kombi konsoll 0.36 m"
  }
];

export const componentsConsoleBracketBraces: Component[] = [
  {
    article_id: "1740.177",
    label_se: "Konsol diagonal med 2 halvkopplingar, 0.73m",
    label_no: "Konsoll diagonal med 2 halvkoplinger, 0.73m",
    label_en: "Section Brace with 2 half couplers, 0.73m",
    material: "",
    weight: 6,
    length: 1.8,
    height: 2,
    width: 0.73
  },
  {
    article_id: "1740.195",
    label_no: "Konsoll diagonal med 2 halvkoplinger, 1.09m",
    label_se: "Konsol diagonal med 2 halvkopplingar, 1.09m",
    label_en: "Section Brace with 2 half couplers, 1.09m",
    material: "",
    weight: 6.4,
    length: 1.95,
    height: 2,
    width: 1.09
  }
];
export const componentsAnchors: Component[] = [
  {
    material: "",
    weight: 2.8,
    length: 0.69,
    width: 0,
    article_id: "1755.069",
    label_en: "Wall anchor 0.69 m",
    label_se: "Väggförankring 0.69 m",
    label_no: "Veggforankring 0.69 m"
  },
  {
    material: "",
    weight: 1.6,
    length: 0.38,
    width: 0,
    article_id: "1754.038",
    label_en: "Wall anchor 0.38 m",
    label_se: "Väggförankring 0.38 m",
    label_no: "Veggforankring 0.38 m"
  },
  {
    material: "",
    weight: 2.8,
    length: 0.69,
    width: 0,
    article_id: "1754.069",
    label_en: "Wall anchor 0.69 m",
    label_se: "Väggförankring 0.69 m",
    label_no: "Veggforankring 0.69 m"
  },
  {
    material: "",
    weight: 3.7,
    length: 0.95,
    width: 0,
    article_id: "1754.095",
    label_en: "Wall anchor 0.95 m",
    label_se: "Väggförankring 0.95 m",
    label_no: "Veggforankring 0.95 m"
  },
  {
    material: "",
    weight: 5.7,
    length: 1.45,
    width: 0,
    article_id: "1754.145",
    label_en: "Wall anchor 1.45 m",
    label_se: "Väggförankring 1.45 m",
    label_no: "Veggforankring 1.45 m"
  },
  {
    material: "",
    weight: 5.8,
    length: 1.75,
    width: 0,
    article_id: "1754.175",
    label_en: "Wall anchor 1.75 m",
    label_se: "Väggförankring 1.75 m",
    label_no: "Veggforankring 1.75 m"
  }
];

export const componentsRingScrews: Component[] = [
  {
    /* p.31 item 7 */
    label_en: "Ring screw 95 mm",
    article_id: "4009.097",
    weight: 1.6,
    material: "",
    length: 0.095,
    label_no: "Forankringsløkke 95 mm",
    width: 0.012,
    label_se: "Förankringsögla 95 mm"
  },
  {
    label_en: "Ring screw 120 mm",
    article_id: "4009.122",
    weight: 1.8,
    material: "",
    length: 0.12,
    label_no: "Forankringsløkke 120 mm",
    width: 0.012,
    label_se: "Förankringsögla 120 mm"
  },
  {
    label_en: "Ring screw 190 mm",
    article_id: "4009.192",
    weight: 2.5,
    material: "",
    length: 0.19,
    label_no: "Forankringsløkke 190 mm",
    width: 0.012,
    label_se: "Förankringsögla 190 mm"
  },
  {
    label_en: "Ring screw 230 mm",
    article_id: "4009.232",
    weight: 3,
    material: "",
    length: 0.23,
    label_no: "Forankringsløkke 230 mm",
    width: 0.012,
    label_se: "Förankringsögla 230 mm"
  },
  {
    label_en: "Ring screw 300 mm",
    article_id: "4009.302",
    weight: 3.5,
    material: "",
    length: 0.3,
    label_no: "Forankringsløkke 300 mm",
    width: 0.012,
    label_se: "Förankringsögla 300 mm"
  },
  {
    label_en: "Ring screw 350 mm",
    article_id: "4009.352",
    weight: 5,
    material: "",
    length: 0.35,
    label_no: "Forankringsløkke 350 mm",
    width: 0.012,
    label_se: "Förankringsögla 350 mm"
  }
];

export const componentsStairs: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 27.0,
    length: 2.57,
    width: 0.64,
    height: 2,
    article_id: "1755.257",
    label_en: "Scaffold stair aluminium 2.57 x 0.64m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.64m",
    label_no: "Stillasstige aluminium 2.57 x 0.64m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 32.0,
    length: 3.07,
    width: 0.64,
    height: 2,
    article_id: "1755.307",
    label_en: "Scaffold stair aluminium 3.07 x 0.64m",
    label_se: "Ställningstrappa i aluminium 3.07 x 0.64m",
    label_no: "Stillasstige aluminium 3.07 x 0.64m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 37.0,
    length: 2.57,
    width: 0.94,
    article_id: "1755.258",
    label_en: "Scaffold stair aluminium 2.57 x 0.94m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.94m",
    label_no: "Stillasstige aluminium 2.57 x 0.94m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 21.9,
    length: 2.57,
    width: 0.64,
    article_id: "1753.257",
    label_en: "Scaffold stair aluminium 2.57 x 0.64m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.64m",
    label_no: "Stillasstige aluminium 2.57 x 0.64m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 26.3,
    length: 3.07,
    width: 0.64,
    article_id: "1753.307",
    label_en: "Scaffold stair aluminium 3.07 x 0.64m",
    label_se: "Ställningstrappa i aluminium 3.07 x 0.64m",
    label_no: "Stillasstige aluminium 3.07 x 0.64m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 21.5,
    length: 2.57,
    width: 0.64,
    article_id: "1753.251",
    label_en: "Scaffold stair aluminium 2.57 x 0.64m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.64m",
    label_no: "Stillasstige aluminium 2.57 x 0.64m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 33.7,
    length: 2.57,
    width: 0.94,
    article_id: "1753.258",
    label_en: "Scaffold stair aluminium 2.57 x 0.94m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.94m",
    label_no: "Stillasstige aluminium 2.57 x 0.94m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 40.1,
    length: 3.07,
    width: 0.94,
    article_id: "1753.308",
    label_en: "Scaffold stair aluminium 3.07 x 0.94m",
    label_se: "Ställningstrappa i aluminium 3.07 x 0.94m",
    label_no: "Stillasstige aluminium 3.07 x 0.94m",
    height: 2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 36.6,
    length: 2.57,
    width: 0.94,
    article_id: "1753.252",
    label_en: "Scaffold stair aluminium 2.57 x 0.94m",
    label_se: "Ställningstrappa i aluminium 2.57 x 0.94m",
    label_no: "Stillasstige aluminium 2.57 x 0.94m",
    height: 2
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    material: "",
    weight: 9.9,
    length: 0.9,
    height: 1.7,
    width: 0,
    article_id: "1752.009",
    label_en: "Initial stairway guardrail 0.90 x 1.70m",
    label_se: "Trappräcke 0.90 x 1.70m",
    label_no: "Trapperekkverk 0.90 x 1.70m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    material: "",
    weight: 16.1,
    length: 2.57,
    width: 0,
    height: 2,
    article_id: "1752.257",
    label_en: "Stair guardrail  2.57 x 2.00m",
    label_se: "Trappräcke 2.57 x 2.00m",
    label_no: "Trapperekkverk 2.57 x 2.00m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    material: "",
    weight: 17.6,
    length: 3.07,
    width: 0,
    height: 2,
    article_id: "1752.307",
    label_en: "Stair guardrail 3.07 x 2.00m",
    label_se: "Trappräcke 3.07 x 2.00m",
    label_no: "Trapperekkverk 3.07 x 2.00m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    material: "",
    weight: 14.6,
    length: 2.57,
    width: 0,
    height: 1.5,
    article_id: "1752.003",
    label_en: "Stair guardrail 2.57 x 1.50m",
    label_se: "Trappräcke 2.57 x 1.50m",
    label_no: "Trapperekkverk 2.57 x 1.50m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsStartStairs: Component[] = [
  {
    width: 0.64,
    height: 1,
    label_en: "Starting stairway 1m",
    article_id: "1753.003",
    length: 1.4,
    label_se: "Start Trappa 1m",
    weight: 11.5,
    material: "",
    label_no: "Start Trapp 1m"
  },
  {
    width: 0.64,
    height: 1.2,
    label_en: "Starting stairway 1.2m",
    article_id: "1753.002",
    length: 1.4,
    label_se: "Start Trappa 1.2m",
    weight: 13.5,
    material: "",
    label_no: "Start Trapp 1.2m"
  },
  {
    width: 0.64,
    height: 1.7,
    label_en: "Starting stairway 1.2m",
    article_id: "1753.004",
    length: 1.4,
    label_se: "Start Trappa 1.2m",
    weight: 18.3,
    material: "",
    label_no: "Start Trapp 1.2m"
  }
];

export const componentsStairwayGuardrailsWideInner: Component[] = [
  {
    material: "",
    weight: 6.2,
    length: 0.7,
    width: 0,
    article_id: "1752.004",
    label_en: "Stairwell guardrail",
    label_se: "Insida trappräcke",
    label_no: "Innsiden trapperekkverk"
  }
];
export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    material: "",
    weight: 13.5,
    length: 2.25,
    height: 2,
    width: 0,
    article_id: "1752.007",
    label_en: "Stair guardrail T12 2.57m",
    label_se: "Trappräcke T12 2.57m",
    label_no: "Trapperekkverk T12 2.57m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  },
  {
    material: "",
    weight: 13.5,
    length: 2.25,
    height: 2,
    width: 0,
    article_id: "1752.008",
    label_en: "Stair guardrail T12 3.07m",
    label_se: "Trappräcke T12 3.07m",
    label_no: "Trapperekkverk T12 3.07m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  },
  {
    material: "",
    weight: 13.5,
    length: 2,
    height: 2,
    width: 0,
    article_id: "1752.012",
    label_en: "Stair guardrail T12 2.57m",
    label_se: "Trappräcke T12 2.57m",
    label_no: "Trapperekkverk T12 2.57m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsTrussbeams: Component[] = [
  {
    /* p 37 item 1 and 4*/
    material: "",
    weight: 46.4,
    length: 5.14,
    width: 0.45,
    article_id: "1781.514",
    label_en: "Truss beam steel LW 5.14 x 0.45m",
    label_se: "Fackverksbalk stål LV 5.14 x 0.45m",
    label_no: "Fagverksbjelke stål LV 5.14 x 0.45m"
  },
  {
    material: "",
    weight: 53.9,
    length: 6.14,
    width: 0.45,
    article_id: "1781.614",
    label_en: "Truss beam steel LW 6.14 x 0.45m",
    label_se: "Fackverksbalk stål LV 6.14 x 0.45m",
    label_no: "Fagverksbjelke stål LV 6.14 x 0.45m"
  },
  {
    material: "",
    weight: 67.2,
    length: 7.71,
    width: 0.45,
    article_id: "1781.771",
    label_en: "Truss beam steel LW 7.71 x 0.45m",
    label_se: "Fackverksbalk stål LV 7.71 x 0.45m",
    label_no: "Fagverksbjelke stål LV 7.71 x 0.45m"
  },
  {
    material: "",
    weight: 22.5,
    length: 5.14,
    width: 0.45,
    article_id: "1767.514",
    label_en: "Truss beam Alu 5.14 x 0.45m",
    label_se: "Fackverksbalk Alu 5.14 x 0.45m",
    label_no: "Fagverksbjelke Alu 5.14 x 0.45m"
  },
  {
    material: "",
    weight: 26.4,
    length: 6.14,
    width: 0.45,
    article_id: "1767.614",
    label_en: "Truss beam Alu 6.14 x 0.45m",
    label_se: "Fackverksbalk Alu 6.14 x 0.45m",
    label_no: "Fagverksbjelke Alu  6.14 x 0.45m"
  },
  {
    material: "",
    weight: 21.8,
    length: 2.25,
    width: 0.45,
    article_id: "4925.225",
    label_en: "Truss beam LW 2.25 x 0.45m",
    label_se: "Fackverksbalk LV 2.25 x 0.45m",
    label_no: "Fagverksbjelke 2.25 x 0.45m"
  },
  {
    material: "",
    weight: 30.9,
    length: 3.25,
    width: 0.45,
    article_id: "4925.325",
    label_en: "Truss beam LW 3.25 x 0.45m",
    label_se: "Fackverksbalk LV 3.25 x 0.45m",
    label_no: "Fagverksbjelke 3.25 x 0.45m"
  },
  {
    material: "",
    weight: 40.0,
    length: 4.25,
    width: 0.45,
    article_id: "4925.425",
    label_en: "Truss beam LW 4.25 x 0.45m",
    label_se: "Fackverksbalk LV 4.25 x 0.45m",
    label_no: "Fagverksbjelke 4.25 x 0.45m"
  },
  {
    material: "",
    weight: 49.5,
    length: 5.32,
    width: 0.45,
    article_id: "4925.532",
    label_en: "Trsuss beam LW 5.32 x 0.45m",
    label_se: "Fackverksbalk LV 5.32 x 0.45m",
    label_no: "Fagverksbjelke 5.32 x 0.45m"
  },
  {
    material: "",
    weight: 59.0,
    length: 6.32,
    width: 0.45,
    article_id: "4925.632",
    label_en: "Truss beam LW 6.32 x 0.45m",
    label_se: "Fackverksbalk LV 6.32 x 0.45m",
    label_no: "Fagverksbjelke 6.32 x 0.45m"
  }
];

export const componentsRailPosts: Component[] = [
  {
    material: "",
    weight: 4.3,
    length: 1.0,
    width: 0,
    article_id: "1746.100",
    label_en: "Railing post 1.00m",
    label_se: "Räckesstolpe 1.00m",
    label_no: "Rekkverksstolpe 1.00m"
  }
];

export const componentsBaseplates: Component[] = [
  {
    material: "",
    weight: 3.6,
    length: 0.6,
    width: 0,
    article_id: "4001.060",
    label_en: "Bottom screw 600 mm",
    label_se: "Bottenskruv 600 mm",
    label_no: "Bunnskrue 600 mm"
  },
  {
    material: "",
    weight: 4.9,
    length: 0.8,
    width: 0,
    article_id: "4002.080",
    label_en: "Bottom screw 800 mm",
    label_se: "Bottenskruv 800 mm",
    label_no: "Bunnskrue 800 mm"
  },
  {
    material: "",
    weight: 10,
    length: 1.5,
    width: 0,
    article_id: "4002.130",
    label_en: "Bottom screw 1.5 m",
    label_se: "Bottenskruv 1.5 m",
    label_no: "Bunnskrue 1.5 m"
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    length: 0.2,
    width: 0,
    label_se: "Skarvtapp för u-profil",
    material: "",
    label_no: "Bjelkespiss U-profil",
    article_id: "1775.000",
    label_en: "Spigot for U-profile",
    weight: 1.8
  }
];

export const componentsCouplers: Component[] = [
  {
    label_no: "Dobbeltkobling",
    label_se: "Dubbelkoppling",
    article_id: "4700.019",
    material: "",
    length: 0.07,
    label_en: "Double coupler",
    weight: 1.3,
    width: 0
  }
];
export const componentsScrews: Component[] = [
  {
    article_id: "4009.097",
    weight: 0.16, // 1.6kg per 10 pcs
    material: "",
    length: 0.095,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.122",
    weight: 0.18, // 1.8kg per 10 pcs
    material: "",
    length: 0.12,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.192",
    weight: 0.25, // 2.5kg per 10 pcs
    material: "",
    length: 0.19,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.232",
    weight: 0.3, // 3kg per 10 pcs
    material: "",
    length: 0.23,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.302",
    weight: 0.35, // 3.5kg per 10 pcs
    material: "",
    length: 0.3,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  },
  {
    article_id: "4009.352",
    weight: 0.35, // 5kg per 10 pcs
    material: "",
    length: 0.5,
    label_en: "Ring screw",
    label_no: "Forankringsløkke",
    label_se: "Förankringsögla",
    width: 0
  }
];

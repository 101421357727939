import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { DiagonalBrace } from "./DiagonalBrace.types";
import { getEndPointFromStartPoint } from "math/vectors";

export const getGraphData = (diagonalBrace: DiagonalBrace): GraphData => {
  const {
    length,
    position,
    rotation,
    endPosition: inputEndPosition
  } = diagonalBrace;

  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: diagonalBrace.id,
    type: ComponentType.DIAGONAL_BRACE,
    metadata: {
      elementVector: new Vector3(0, 0, 1)
        .applyEuler(new Euler().fromArray(rotation))
        .toArray()
    }
  };
};

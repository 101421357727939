import { getGraphData } from "world/core/BeamSpigot/BeamSpigot.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { BeamSpigot } from "world/core/BeamSpigot/BeamSpigot.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding beamSpigots to the graph.
 * @param props
 */
export const addBeamSpigotsToGraph = (props: {
  graph: Graph;
  beamSpigots: BeamSpigot[];
}) => {
  const { graph, beamSpigots } = props;

  const beamSpigotsGraphData = beamSpigots
    .map((beamSpigot) => {
      return getGraphData(beamSpigot);
    })
    .flat();
  addComponentsToGraph({ graph, components: beamSpigotsGraphData });
};

/**
 * Mutates the graph by removing beamSpigots from the graph.
 * @param props
 */
export const removeBeamSpigotsFromGraph = (props: {
  graph: Graph;
  beamSpigots: BeamSpigot[];
}) => {
  const { graph, beamSpigots } = props;

  const beamSpigotsGraphData = beamSpigots
    .map((beamSpigot) => {
      return getGraphData(beamSpigot);
    })
    .flat();

  removeComponentsFromGraph({
    graph,
    components: beamSpigotsGraphData,
    force: true
  });
};

export const setBeamSpigotsInGraph = (props: {
  graph: Graph;
  newBeamSpigots: BeamSpigot[];
  oldBeamSpigots: BeamSpigot[];
}) => {
  const { graph, newBeamSpigots, oldBeamSpigots } = props;
  removeBeamSpigotsFromGraph({ graph, beamSpigots: oldBeamSpigots });
  addBeamSpigotsToGraph({ graph, beamSpigots: newBeamSpigots });
};

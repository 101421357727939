import { useStoreWithUndo } from "store/store";

/** Snackbar */
export const useUiActions = () => useStoreWithUndo((state) => state.uiActions);
export const useLeftSidebarCollapsed = () =>
  useStoreWithUndo((state) => state.leftSidebarCollapsed);
export const usePreferSidebarCollapsed = () =>
  useStoreWithUndo((state) => state.preferSidebarCollapsed);
export const useLeftSidebarWidth = () =>
  useStoreWithUndo((state) => state.leftSidebarWidth);
export const useRightSidebarWidth = () =>
  useStoreWithUndo((state) => state.rightSidebarWidth);
export const useIsAppLayout = () => useStoreWithUndo((state) => state.isAppLayout);

import { ReactElement, useEffect, useMemo } from "react";
import TrialInfoProps from "./TrialInfo.types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserAccountType, useUserExpires } from "store/selectors/user";
import { USER_ACCOUNTTYPE } from "shared/enums/user";
import Button from "ui/core/Button";
import Snackbar from "ui/module/Snackbar";
import Breakpoints from "managers/ThemeManager/theme/breakpoints";
import { useIsAppLayout } from "store/selectors/ui";
import Modal from "ui/module/Modal";
import Text from "ui/core/Text";
import { Link } from "@mui/material";

const TrialInfo = (props: TrialInfoProps): ReactElement => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const userAccountType = useUserAccountType();
  const userExpires = useUserExpires();
  const isAppLayout = useIsAppLayout();

  const { belowSmallScreen } = Breakpoints();

  const daysToExpiration = useMemo(() => {
    let daysToExpiration = null;

    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || userExpires === null)
      return daysToExpiration;

    let today: number = new Date().getTime();
    let expirationDate: number = userExpires.getTime();

    daysToExpiration = Math.ceil((expirationDate - today) / (1000 * 3600 * 24));

    return daysToExpiration;
  }, [userAccountType, userExpires]);

  const isExpired = useMemo(
    () => daysToExpiration !== null && daysToExpiration < 1,
    [daysToExpiration]
  );

  const bannerText = useMemo(() => {
    if (userAccountType !== USER_ACCOUNTTYPE.TRIAL || daysToExpiration === null)
      return "";
    return `${t("copy.trial.daysLeft", { days: daysToExpiration })}`;
  }, [daysToExpiration, t, userAccountType]);

  const buttonText = useMemo(() => {
    return t("actions.upgrade");
  }, [t]);

  const handleOnClickButton = () => {
    window.open("https://www.scaffcalc.com/support", "_blank");
  };

  useEffect(() => {
    if (isExpired) {
      navigate("/home");
    }
  }, [isExpired, navigate]);

  return (
    <>
      {isExpired ? (
        <Modal
          content={
            <Text variant={"body1"}>
              {t("copy.trial.expired")}{" "}
              <Link
                href="mailto:support@scaffcalc.com"
                sx={{ userSelect: "text" }}
              >
                support@scaffcalc.com
              </Link>
            </Text>
          }
          open={true}
          title={t("headers.trial")}
          width={"xs"}
        />
      ) : (
        <>
          {userAccountType === USER_ACCOUNTTYPE.TRIAL && (
            <>
              {belowSmallScreen ? (
                <Snackbar
                  open={true}
                  message={bannerText}
                  type={"secondary"}
                  autoHideDuration={null}
                  button={
                    <Button
                      onClick={handleOnClickButton}
                      color={"inherit"}
                      variant="text"
                    >
                      {buttonText}
                    </Button>
                  }
                  sx={{
                    position: "relative",
                    left: "0px !important",
                    right: "0px !important",
                    bottom: "0px !important",
                    mx: isAppLayout ? 0 : 2,
                    zIndex: 1150
                  }}
                />
              ) : (
                <Snackbar
                  open={true}
                  message={bannerText}
                  type={"secondary"}
                  autoHideDuration={null}
                  button={
                    <Button
                      onClick={handleOnClickButton}
                      color={"inherit"}
                      variant="text"
                    >
                      {buttonText}
                    </Button>
                  }
                  sx={{
                    position: "relative",
                    bottom: isAppLayout ? "0px !important" : "16px !important",
                    right: "0px !important"
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TrialInfo;

import Graph from "graphology";
import { Standard } from "world/core/Standard/Standard.types";
import {
  getGraphData,
  getGraphSplitData
} from "world/core/Standard/Standard.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";

/**
 * Mutates the graph by adding standards to the graph.
 * @param props
 */
export const addStandardsToGraph = (props: {
  graph: Graph;
  standards: Standard[];
}) => {
  const { graph, standards } = props;

  const standardsGraphData = standards
    .map((standard) => {
      if (standard.splits) return getGraphSplitData(standard);
      return getGraphData(standard);
    })
    .flat();
  addComponentsToGraph({ graph, components: standardsGraphData });
};

/**
 * Mutates the graph by removing standards from the graph.
 * @param props
 */
export const removeStandardsFromGraph = (props: {
  graph: Graph;
  standards: Standard[];
}) => {
  const { graph, standards } = props;

  const standardsGraphData = standards
    .map((standard) => {
      if (standard.splits) return getGraphSplitData(standard);
      return getGraphData(standard);
    })
    .flat();

  removeComponentsFromGraph({
    graph,
    components: standardsGraphData,
    force: true
  });
};

export const setStandardsInGraph = (props: {
  graph: Graph;
  newStandards: Standard[];
  oldStandards: Standard[];
}) => {
  const { graph, newStandards, oldStandards } = props;
  removeStandardsFromGraph({ graph, standards: oldStandards });
  addStandardsToGraph({ graph, standards: newStandards });
};

import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { GroundSlice } from "./ground.interface";
import useStoreWithUndo, { sliceResetFns } from "store/store";
import { isVector3Tuple } from "validation/three";
import { Vector3Tuple } from "three";
import { Ground } from "shared/interfaces/firestore";
import { GROUND_TYPE } from "shared/enums/world";
import { minus } from "math";
import { updateSelectedObjectsPositionRotation } from "../world.utils";

/** Initial state */
const initialGroundState = {
  grounds: []
};

/** Slice creation */
const createGroundSlice: StateCreator<Store, [], [], GroundSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialGroundState));

  /** Return state */
  return {
    ...initialGroundState,
    groundActions: {
      add: (grounds) => {
        set((state) => ({
          grounds: [...state.grounds, ...grounds]
        }));
      },
      set: (grounds) => {
        set({ grounds });
      },
      remove: (ids) => {
        set((state) => ({
          grounds: state.grounds.filter((ground) => !ids.includes(ground.id))
        }));
      },
      update: (ids, data) => {
        set((state) => {
          return {
            grounds: state.grounds.map((ground) => {
              if (!ids.includes(ground.id)) return ground;

              const cleanData = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  if (isVector3Tuple(value)) {
                    const groundKeyValue = ground[key] as Vector3Tuple;

                    acc[key] = value.map((v, idx) =>
                      isNaN(v) ? groundKeyValue[idx] : v
                    ) as Vector3Tuple;
                  } else {
                    acc[key] = value;
                  }

                  return acc;
                },
                {} as Partial<Ground>
              );

              return {
                ...ground,
                ...cleanData
              };
            }),
            worldSelectedObjects: updateSelectedObjectsPositionRotation(state.worldSelectedObjects, ids, data),
          };
        });
      },
      heightOperation: (
        ids: string[],
        operation: "add" | "subtract",
        value: number
      ) => {
        set((state) => ({
          grounds: state.grounds.map((ground) => {
            if (!ids.includes(ground.id)) return ground;
            const { points } = ground;

            let newPoints = [...points];
            const heightDiff = operation === "add" ? value : -value;

            if (
              ground.type === GROUND_TYPE.FLAT ||
              ground.type === GROUND_TYPE.SLOPE
            ) {
              const editPoints = points.filter(
                (point) => point.position[1] > 0
              );
              newPoints = points.map((point) => {
                if (!editPoints.map((point) => point.id).includes(point.id))
                  return point;
                return {
                  ...point,
                  position: [
                    point.position[0],
                    Math.max(point.position[1] + heightDiff, 0.1),
                    point.position[2]
                  ] as Vector3Tuple
                };
              });
            }
            return { ...ground, points: newPoints };
          })
        }));
      },
      updatePointsByLengthOnAxis: (ids, value, axis) => {
        set((state) => ({
          grounds: state.grounds.map((ground) => {
            if (!ids.includes(ground.id)) return ground;

            const axisIdx =
              axis === "x" ? 2 : axis === "y" ? 1 : axis === "z" ? 0 : null;
            if (axisIdx === null) return ground;

            const { points } = ground;

            let newPoints = [...points];

            if (
              ground.type === GROUND_TYPE.FLAT ||
              ground.type === GROUND_TYPE.SLOPE
            ) {
              if (axis === "y") {
                const editPoints = points.filter(
                  (point) => point.position[1] > 0
                );
                newPoints = points.map((point) => {
                  if (!editPoints.map((point) => point.id).includes(point.id))
                    return point;
                  return {
                    ...point,
                    position: [
                      point.position[0],
                      value,
                      point.position[2]
                    ] as Vector3Tuple
                  };
                });
              } else {
                const editPoints = points.filter(
                  (point) => point.position[1] === 0
                );
                const p1 = editPoints[0];
                const p2 = editPoints[2];

                const delta = Math.abs(
                  minus(p1.position[axisIdx], p2.position[axisIdx])
                );
                const scale = value / delta;

                newPoints = points.map((point) => {
                  const newPosition = [...point.position].map((pos, idx) => {
                    if (idx === axisIdx) {
                      return pos * scale;
                    }

                    return pos;
                  });

                  return {
                    ...point,
                    position: newPosition as Vector3Tuple
                  };
                });
              }
            }

            return { ...ground, points: newPoints };
          })
        }));
      },
      setIdsDimensions: (dimensions) => {
        set((state) => {
          const ids = dimensions.map((p) => p.id);

          return {
            blueprints: state.blueprints.map((ground) => {
              if (!ids.includes(ground.id)) return ground;

              const dimensionObject = dimensions.find(
                (p) => p.id === ground.id
              );

              return {
                ...ground,
                position: dimensionObject?.position ?? ground.position,
                rotation: dimensionObject?.rotation ?? ground.rotation
              };
            })
          };
        });
      }
    }
  };
};

/** Util function */
export const applyGroundDimensions = (
  dimensions: {
    id: string;
    position: Vector3Tuple;
    rotation: Vector3Tuple;
  }[]
) => {
  const state = useStoreWithUndo.getState();

  const ids = dimensions.map((p) => p.id);

  return state.grounds.map((ground) => {
    if (!ids.includes(ground.id)) return ground;

    const dimensionObject = dimensions.find((p) => p.id === ground.id);

    return {
      ...ground,
      position: dimensionObject?.position ?? ground.position,
      rotation: dimensionObject?.rotation ?? ground.rotation
    };
  });
};

export default createGroundSlice;

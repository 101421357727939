import Graph from "graphology";
import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import {
  BaseBoard,
  BaseCollar,
  BasePlate,
  Box,
  Ledger,
  Standard
} from "shared/interfaces/firestore";
import { BoxComponents } from "store/world/box/box.interface";

export interface Supplier {
  isFrameSupplier: () => boolean;
  getPlankMaterials: () => string[];
  getBayBoxLengths: () => number[];
  getBayBoxWidths: () => number[];
  getPassageBaseBoxWidths: () => number[];
  getStandardLengths: (bayWidth?: number) => number[];
  generateBayBoxComponents: (props: {
    boxProps: BayBoxProps;
    allBoxes?: Box[];
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
    preStateCommitGraph: Graph;
  }) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  generateStairBoxComponents: (props: {
    boxProps: StairBoxProps;
    allBoxes?: Box[];
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
    preStateCommitGraph: Graph;
  }) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  generatePassageBoxComponents: (props: {
    boxProps: PassageBoxProps;
    allBoxes?: Box[];
    preStateCommitGraph: Graph;
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
  }) => {
    replacedComponents: ReplacedComponent[];
    components: BoxComponents;
  };
  getComponents: () => Component[];
  getStairBoxLengths: () => number[];
  getStairBoxWidths: () => number[];
  getAvailableLockAnchorRanges: (pos: [number, number, number]) => LockRange[];
  getComponentLabel: (id: string, languageCode: LANGUAGE_CODE) => string;
  getComponentWeight: (id: string) => number;
  getPlankConfigurations: (props: {
    length: number;
    width: number;
    plankType: SCAFFOLD_PLANKTYPE;
  }) => number[][];
  getConsoleWidths: () => number[];
  getLedgerAlternatives: (props: { length: number }) => Component[];
}

export interface ReplacedComponent {
  id: string;
  replacedById: string;
}

export interface BayBoxProps {
  height: Box["height"];
  depth: Box["depth"];
  width: Box["width"];
  rotation: Box["rotation"];
  options: Box["options"];
  position: Box["position"];
  stair?: boolean;
  id: string;
}
export interface StairBoxProps extends BayBoxProps {}
export interface PassageBoxProps extends BayBoxProps {}

export interface LockRange {
  min: number;
  max: number;
}

export interface ComponentBaseProps {
  id: string;
  supplier: string;
}

export enum LANGUAGE_CODE {
  EN = "en",
  SE = "se",
  NO = "no"
}

interface Component {
  article_id: string;
  label_en: string;
  label_no: string;
  label_se: string;
  length: number;
  material: string | SCAFFOLD_PLANKTYPE;
  weight: number;
  width: number;
  height?: number;
  variant?: string;
}

export default Component;

import { genId } from "math/generators";
import { getCylinderDimensions } from "pages/ScaffoldPage/deps/SelectedManager/deps/SelectedObjectsManager/deps/SelectedObjectCylinderManager/SelectedObjectCylinderManager.utils";
import { Point } from "shared/interfaces/firestore";
import { Box2, Box3, Shape, Vector2, Vector3 } from "three";

export const updateHolesInRectangle = (points: Point[], thickness: number) => {
  const bbox = new Box3().setFromPoints(
    points.map((point) => new Vector3(...point.position))
  );

  const holePoints: Point[] = [
    {
      position: [bbox.min.x + thickness, 0, bbox.min.z + thickness],
      id: genId()
    },
    {
      position: [bbox.max.x - thickness, 0, bbox.min.z + thickness],
      id: genId()
    },
    {
      position: [bbox.max.x - thickness, 0, bbox.max.z - thickness],
      id: genId()
    },

    {
      position: [bbox.min.x + thickness, 0, bbox.max.z - thickness],
      id: genId()
    }
  ];

  return [{ points: holePoints, id: genId() }];
};

export const updateHolesInCylinder = (points: Point[], thickness: number) => {
  const currentDimensions = getCylinderDimensions(points);

  /** New Points */
  const bbox = new Box2().setFromPoints(
    points.map((x) => new Vector2(x.position[0], x.position[2]))
  );
  const bboxCenter = bbox.getCenter(new Vector2());

  /** New Holes */
  const hole = new Shape().absellipse(
    bboxCenter.x,
    bboxCenter.y,
    currentDimensions.width / 2 - thickness,
    currentDimensions.length / 2 - thickness,
    0,
    Math.PI * 2,
    true
  );

  const holePoints: Point[] = hole.getPoints().map((point) => {
    return {
      position: [point.x, 0, point.y],
      id: genId()
    };
  });

  return [{ points: holePoints, id: genId() }];
};

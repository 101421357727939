import Dexie, { Table } from "dexie";
import { Timestamp } from "firebase/firestore";

export interface BlueprintTable {
  id: string;
  image: Blob;
  lastUpdatedBlob: Timestamp;
}

class ModdedDexie extends Dexie {
  /** See https://dexie.org/docs/Tutorial/React#using-typescript */
  models!: Table<BlueprintTable>;

  constructor() {
    super("scaffcalc-blueprints-db");
    this.version(1).stores({
      models: "++id, image, lastUpdatedBlob"
    });
  }
}

/** Wrapped ModdedDexie to expose used functions */
class DbBlueprint extends ModdedDexie {
  public saveToDb = async (item: BlueprintTable, id: string) => {
    await this.models.put(item, id);
  };

  public readFromDb = async (id: string) => {
    return this.models.where("id").equals(id).toArray();
  };

  public readAllFromDb = async () => {
    return this.models.toArray();
  };

  public existsInDb = async (id: string) => {
    return this.models.where("id").equals(id).count();
  };

  public deleteFromDb = async (id: string) => {
    await this.models.delete(id);
  };

  public clearDb = async () => {
    await this.models.clear();
  };
}

export default DbBlueprint;

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import { Unsubscribe } from "firebase/auth";
import { authentication, database } from "services";
import {
  CompanyDoc,
  CompanyPrivateDoc,
  UserDoc
} from "shared/interfaces/firestore";
import { useUserActions, useUserAppPreferences } from "store/selectors/user";
import { useCompanyActions } from "store/selectors/company";
import { useSnackbarActions } from "store/selectors/snackbar";
import { useLoadingActions } from "store/selectors/loading";
import SessionManagerProps from "./SessionManager.types";
import { useTranslation } from "react-i18next";
import useStoreWithUndo from "store/store";
import {
  getQuotaPeriodEndDate,
  getQuotaPeriodStartDate
} from "pages/ProfilePage/ProfilePage.utils";
import { ROUTE } from "enums/routing";
import { USER_ROLE } from "shared/enums/user";
import * as Sentry from "@sentry/react";
import { devConfig, prodConfig } from "services/firebase/config";

const SessionManager = (props: SessionManagerProps): ReactElement => {
  /** Hook: store userActions */
  const {
    setUserEmail,
    setUserFirstName,
    setUserLastName,
    setUserId,
    clearUser,
    setUserAppPreferences,
    setUserScaffoldPreferences,
    setUserAchievements,
    setUserAccountType,
    setUserExpires,
    setUserRole
  } = useUserActions();

  // const userId = useUserId();

  const userAppPreferences = useUserAppPreferences();

  /** Hook: store companyAction */
  const {
    setCompanyName,
    setCompanyId,
    setCompanyUsers,
    setCompanyQuotaEndDate,
    setCompanyQuotaRenewalDays,
    setCompanyQuotaLimit,
    setCompanyQuotaStartDate,
    setCompanyQuotaUsage
  } = useCompanyActions();

  /** Hook: snackbar */
  const snackbarActions = useSnackbarActions();

  /** Hook: store loadingActions */
  const { setLoading } = useLoadingActions();

  const { i18n } = useTranslation();

  /** Hook: location */
  const location = useLocation();

  /** Hook: navigation */
  const navigate = useNavigate();

  /** Ref: unsubscribe function for onAuthStateChanged */
  const unSubOnAuthStateChanged = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateUserDoc */
  const unSubOnUpdateUserDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyDoc */
  const unSubOnUpdateCompanyDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdatePrivateCompanyDoc */
  const unSubOnUpdateCompanyPrivateDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyUsersDoc */
  const unSubOnUpdateCompanyUserDocs = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyScaffoldDocs */
  const unSubOnUpdateCompanyScaffoldDocs = useRef<void | Unsubscribe>();

  /** Callback: error handler */
  const handleOnError = useCallback(
    (error?: any) => {
      clearUser();
      if (unSubOnUpdateUserDoc.current) unSubOnUpdateUserDoc.current();
      if (unSubOnUpdateCompanyDoc.current) unSubOnUpdateCompanyDoc.current();
      if (unSubOnUpdateCompanyPrivateDoc.current)
        unSubOnUpdateCompanyPrivateDoc.current();
      if (unSubOnUpdateCompanyUserDocs.current)
        unSubOnUpdateCompanyUserDocs.current();
      if (unSubOnUpdateCompanyScaffoldDocs.current)
        unSubOnUpdateCompanyScaffoldDocs.current();
      setLoading(false);
      authentication.signOut();
      navigate("/login");
    },
    [clearUser, navigate, setLoading]
  );

  const handleOnUpdateCompanyDocSuccess = useCallback(
    (companyDoc: CompanyDoc, companyId: string) => {
      const { name } = companyDoc;
      setCompanyName(name);
      // storage.getCompanyLogoDownloadUrl({
      //   companyId,
      //   onSuccess: (logoUrl) => {
      //     setCompanyLogoUrl(logoUrl);
      //   },
      //   onError: () => {},
      // });
      // setLoading(false);
    },
    [setCompanyName]
  );

  const checkQuotaLimit = useCallback(() => {
    const {
      companyId,
      companyQuotaRenewalDays,
      companyQuotaStartDate,
      companyQuotaEndDate
    } = useStoreWithUndo.getState();

    if (!companyId) return;

    const currentQuotaPeriodStart =
      companyQuotaRenewalDays === 0
        ? companyQuotaStartDate
        : getQuotaPeriodStartDate(
            companyQuotaStartDate,
            companyQuotaRenewalDays
          );

    const currentQuotaPeriodEnd =
      companyQuotaRenewalDays === 0
        ? companyQuotaEndDate
        : getQuotaPeriodEndDate(
            companyQuotaStartDate,
            companyQuotaRenewalDays,
            currentQuotaPeriodStart
          );

    database
      .getCompanyQuotaUsage({
        companyId,
        quotaEndDate: currentQuotaPeriodEnd,
        quotaStartDate: currentQuotaPeriodStart
      })
      .then((size) => {
        const count = size.data().count;
        setCompanyQuotaUsage(count);
      });
  }, [setCompanyQuotaUsage]);

  const handleOnUpdateCompanyPrivateDocSuccess = useCallback(
    (companyPrivateDoc: CompanyPrivateDoc, companyId: string) => {
      const { quotaEndDate, quotaLimit, quotaRenewalDays, quotaStartDate } =
        companyPrivateDoc;
      setCompanyQuotaEndDate(quotaEndDate.toDate());
      setCompanyQuotaLimit(quotaLimit);
      setCompanyQuotaRenewalDays(quotaRenewalDays);
      setCompanyQuotaStartDate(quotaStartDate.toDate());

      /** Fetch latest scaffold company document from database and perform quota check */
      unSubOnUpdateCompanyScaffoldDocs.current = database.onUpdateScaffoldDocs({
        companyIdFilterValues: [],
        limit: quotaLimit,
        orderBy: "created",
        onSuccess: async () => {
          /** Check quota when scaffolds collection gets an update  */
          checkQuotaLimit();
        },
        onError: () => {}
      });
    },
    [
      checkQuotaLimit,
      setCompanyQuotaEndDate,
      setCompanyQuotaLimit,
      setCompanyQuotaRenewalDays,
      setCompanyQuotaStartDate
    ]
  );
  const isValidRouting = useCallback((role: string, pathname: string) => {
    if (pathname !== ROUTE.SUPERADMIN) return true;
    if (role === USER_ROLE.SUPERADMIN) return true;
  }, []);

  const setUserStateProperties = useCallback(
    (userDoc: UserDoc, userId: string) => {
      const {
        email,
        firstName,
        lastName,
        companyId,
        appPreferences,
        scaffoldPreferences,
        accountType,
        expires,
        role,
        achievements
      } = userDoc;

      setUserEmail(email);
      setUserId(userId);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      setCompanyId(companyId);
      setUserAppPreferences(appPreferences);
      setUserScaffoldPreferences(scaffoldPreferences);
      achievements && setUserAchievements(achievements);
      setUserAccountType(accountType);
      setUserExpires(expires.toDate());
      setUserRole(role);
    },
    [
      setUserEmail,
      setUserId,
      setUserFirstName,
      setUserLastName,
      setCompanyId,
      setUserAppPreferences,
      setUserScaffoldPreferences,
      setUserAchievements,
      setUserAccountType,
      setUserExpires,
      setUserRole
    ]
  );

  const handleOnEmailNotVerified = useCallback(() => {
    snackbarActions.add({
      text: "Please verify your email address",
      type: "error",
      key: "email-not-verified"
    });
    authentication.signOut();
  }, [snackbarActions]);

  const handleOnUpdateUserDocSuccess = useCallback(
    (userDoc: UserDoc, userId: string) => {
      const {
        companyId,
        active,
        role,
        email,
        accountType,
        firstName,
        lastName
      } = userDoc;
      const isSentryInitialized = Sentry.isInitialized();
      if (
        !isSentryInitialized &&
        process.env.REACT_APP_FB_ENV &&
        process.env.REACT_APP_FB_ENV !== "local"
      ) {
        Sentry.init({
          dsn: process.env.REACT_APP_SENTRY_DSN,
          integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
            Sentry.replayCanvasIntegration(),
            Sentry.browserProfilingIntegration()
          ],
          initialScope: {
            user: {
              id: userId,
              email
            },
            tags: {
              companyId,
              role,
              active,
              accountType,
              name: `${firstName} ${lastName}`
            }
          },
          environment: process.env.REACT_APP_FB_ENV,
          // Tracing
          tracesSampleRate: 1.0, //  Capture 100% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            prodConfig.functionsLink,
            devConfig.functionsLink
          ],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          profilesSampleRate: 1.0
        });
      }

      if (!active || !isValidRouting(role, location.pathname))
        return handleOnError();
      setUserStateProperties(userDoc, userId);

      /** Setup company document listener and provide onSuccess function */
      unSubOnUpdateCompanyDoc.current = database.onUpdateCompanyDoc({
        companyId,
        onSuccess: (companyDoc) =>
          handleOnUpdateCompanyDocSuccess(companyDoc, companyId),
        onError: handleOnError
      });

      /** Setup company document listener and provide onSuccess function */
      unSubOnUpdateCompanyUserDocs.current = database.onUpdateCompanyUserDocs({
        companyId,
        onSuccess: (userDocs) => {
          setCompanyUsers(userDocs);
        },
        onError: handleOnError
      });

      /** Setup company private document listener and provide onSuccess function */
      unSubOnUpdateCompanyPrivateDoc.current =
        database.onUpdateCompanyPrivateDoc({
          companyId,
          onSuccess: (doc) =>
            handleOnUpdateCompanyPrivateDocSuccess(doc, companyId),
          onError: handleOnError
        });
    },
    [
      isValidRouting,
      handleOnError,
      handleOnUpdateCompanyDocSuccess,
      handleOnUpdateCompanyPrivateDocSuccess,
      location.pathname,
      setCompanyUsers,
      setUserStateProperties
    ]
  );

  useEffect(() => {
    /** Setup listener on auth object and provide onUpdate function */
    unSubOnAuthStateChanged.current = authentication.onSessionUpdate(
      (authUser) => {
        if (!authUser) {
          handleOnError();
          return;
        }

        const { uid, emailVerfied } = authUser;
        if (!emailVerfied) {
          handleOnEmailNotVerified();
          return;
        }

        /** Setup user document listener and provide onSuccess function */
        unSubOnUpdateUserDoc.current = database.onUpdateUserDoc({
          uid,
          onSuccess: (userDoc) => handleOnUpdateUserDocSuccess(userDoc, uid),
          onError: handleOnError
        });

        /** Navigate to /home if current route is /login
         * otherwise continue with specified route
         */
        if (location.pathname === "/login") navigate("/home");
      }
    );
  }, [
    handleOnEmailNotVerified,
    handleOnError,
    handleOnUpdateUserDocSuccess,
    location.pathname,
    navigate
  ]);

  useEffect(() => {
    if (userAppPreferences?.languageCode)
      i18n.changeLanguage(userAppPreferences?.languageCode);
  }, [i18n, userAppPreferences?.languageCode]);

  return <Outlet />;
};

export default SessionManager;

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsCouplers: Component[] = [
  {
    label_no: "forankringskobling",
    label_se: "Förankringskoppling",
    article_id: "xxxx.xxx",
    material: "",
    length: 0.1,
    label_en: "Anchoring coupling",
    weight: 1.3,
    width: 0
  }
];

export const componentsBaseplates: Component[] = [
  {
    length: 0.75,
    label_no: "Bottenskrue 750 x 37mm. Hull 5,2",
    width: 0,
    material: "",
    label_se: "Bottenskruv 750 x 37mm. hål 5,2",
    weight: 5.2,
    label_en: "Bottom screw 750 x 37mm. Hole 5,2",
    article_id: "T00027"
  },
  {
    article_id: "T00052",
    label_en: "Bottom screw led for sloping surfaces 500 x 38mm. Hole",
    label_no: "Bottenskrue ledet for skrånende overflater 500 x 38mm. hull",
    label_se: "Bottenskruv ledad för sluttande ytor 500 x 38mm. hål",
    length: 0.5,
    material: "",
    weight: 8.0,
    width: 0
  },
  {
    label_se: "Bottenskruv 650 x 37mm. hål 6,0",
    label_no: "Bottenskrue 650 x 37mm. Hull 6,0",
    weight: 6,
    width: 0,
    label_en: "Bottom screw 650 x 37mm. Hole 6,0",
    material: "",
    article_id: "T00113",
    length: 0.65
  },
  {
    article_id: "T00079",
    label_no: "Bottenskrue 500 x 38mm massiv 6,6",
    width: 0,
    label_se: "Bottenskruv 500 x 38mm massiv 6,6",
    length: 0,
    label_en: "Bottom screw 500 x 38mm massive 6,6",
    weight: 6.6,
    material: ""
  },
  {
    label_se: "Bottenskruv 500 x 37mm. hål 4,5",
    weight: 4.5,
    article_id: "T00026",
    label_en: "Bottom screw 500 x 37mm. Hole 4,5",
    material: "",
    width: 0,
    length: 0.5,
    label_no: "Bottenskrue 500 x 37mm. Hull 4,5"
  },
  {
    label_se: "Bottenskruv 900 x 37mm. hål 5,4",
    width: 0,
    label_no: "Bottenskrue 900 x 37mm. Hull 5,4",
    article_id: "T00013",
    length: 0.9,
    label_en: "Bottom screw 900 x 37mm. Hole 5,4",
    material: "",
    weight: 5.4
  }
];

export const componentsFrames: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 8.3,
    label_no: "Ramme Al. 1,50x0,73m",
    label_en: "Frame Alu. 1,50x0,73m",
    article_id: "C282215",
    length: 1.5,
    label_se: "Ram aluminium. 1,50x0,73m",
    width: 0.73
  },
  {
    article_id: "C282206",
    width: 0.73,
    weight: 5.2,
    label_en: "Frame Alu. 0,66x0,73m",
    label_se: "Ram aluminium. 0,66x0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Ramme Al. 0,66x0,73m",
    length: 0.66
  },
  {
    length: 1,
    label_en: "Frame Alu. 1,00x0,73m",
    article_id: "C282210",
    label_no: "Ramme Al. 1,00x0,73m",
    weight: 6.5,
    label_se: "Ram aluminium. 1,00x0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.73
  },
  {
    article_id: "C282220",
    length: 2,
    width: 0.73,
    label_en: "Frame Alu. 2,00x0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 9.5,
    label_no: "Ramme Al. 2,00x0,73m",
    label_se: "Ram aluminium. 2,00x0,73m"
  }
  //   {
  //     label_se: "Bypassram aluminium",
  //     weight: 10.8,
  //     length: 0,
  //     article_id: "C280520",
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     label_no: "Bypass ramme aluminium",
  //     width: 0,
  //     label_en: "Bypass frame aluminium"
  //   }
];

export const componentsPassageFrames: Component[] = [
  {
    width: 1.55,
    article_id: "C281515",
    label_no: "Gjennomgang ramme i aluminium 1,55x2,2m",
    label_se: "Genomgångsram i aluminium 1,55x2,2m",
    length: 2.2,
    weight: 13.9,
    material: "",
    label_en: "Passthrough frame in aluminium 1,55x2,2m"
  }
];

export const componentsTrussbeams: Component[] = [
  {
    length: 6.2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.4,
    label_se: "Fackverksbalk aluminium 6,2 x 0,4m",
    article_id: "C000259",
    label_en: "Truss beam aluminium 6,2 x 0,4m",
    weight: 23,
    label_no: "Fagverksbjelke aluminium 6,2 x 0,4m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 31,
    width: 0.4,
    article_id: "C000261",
    label_se: "Fackverksbalk aluminium 8,1 x 0,4m",
    label_no: "Fagverksbjelke aluminium 8,1 x 0,4m",
    label_en: "Truss beam aluminium 8,1 x 0,4m",
    length: 8.1
  },
  {
    article_id: "C000255",
    label_no: "Fagverksbjelke aluminium 3,2 x 0,4m",
    width: 0.4,
    label_en: "Truss beam aluminium 3,2 x 0,4m",
    label_se: "Fackverksbalk aluminium 3,2 x 0,4m",
    length: 3.2,
    weight: 12,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    width: 0.4,
    label_se: "Fackverksbalk aluminium 5,2 x 0,4m",
    label_en: "Truss beam aluminium 5,2 x 0,4m",
    article_id: "C000258",
    weight: 20,
    length: 5.2,
    label_no: "Fagverksbjelke aluminium 5,2 x 0,4m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_no: "Fagverksbjelke aluminium 4,2 x 0,4m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.4,
    weight: 15,
    article_id: "C000257",
    length: 4.2,
    label_en: "Truss beam aluminium 4,2 x 0,4m",
    label_se: "Fackverksbalk aluminium 4,2 x 0,4m"
  }
];

export const componentsDiagonalBraces: Component[] = [
  /** 2m height diagonal braces */
  {
    label_no: "Diagonalstag med kilekobling 1,57m.",
    label_se: "Diagonalstag med kilkoppling 1,57m.",
    material: "",
    weight: 5.9,
    length: 1.57,
    height: 2,
    article_id: "C284815",
    label_en: "Diagonal brace with wedge connection 1,57m.",
    width: 0
  },
  {
    label_se: "Diagonalstag med kilkoppling 2,07m.",
    label_en: "Diagonal brace with wedge connection 2,07m.",
    length: 2.07,
    height: 2,
    article_id: "C284820",
    weight: 6.8,
    material: "",
    label_no: "Diagonalstag med kilekobling 2,07m.",
    width: 0
  },
  {
    label_se: "Diagonalstag teleskop 1,57- 3,07m.",
    width: 0,
    height: 2,
    length: 3.07,
    label_en: "Diagonal brace telescope 1,57- 3,07m",
    label_no: "Diagonalstag teleskopisk 1,57-3,07m.",
    material: "",
    weight: 10.6,
    article_id: "C284800"
  },
  {
    label_no: "Diagonalstag 3,07m.",
    material: "",
    height: 2,
    label_en: "Diagonal brace 3,07m.",
    width: 0,
    article_id: "C284730",
    label_se: "Diagonalstag 3,07m.",
    weight: 8.5,
    length: 3.07
  },
  {
    weight: 7.6,
    article_id: "C284725",
    label_no: "Diagonalstag 2,57m.",
    width: 0,
    height: 2,
    material: "",
    length: 2.57,
    label_en: "Diagonal brace 2,57m.",
    label_se: "Diagonalstag 2,57m."
  },
  {
    width: 0,
    article_id: "C284715",
    label_en: "Diagonal brace 1,57m.",
    label_no: "Diagonalstag 1,57m.",
    weight: 5.9,
    height: 2,
    material: "",
    length: 1.57,
    label_se: "Diagonalstag 1,57m."
  },
  {
    width: 0,
    article_id: "C284830",
    weight: 8.5,
    height: 2,
    label_se: "Diagonalstag med kilkoppling 3,07m.",
    length: 3.07,
    label_en: "Diagonal brace with wedge connection 3,07m.",
    material: "",
    label_no: "Diagonalstag med kilekobling 3,07m."
  },
  {
    label_en: "Diagonal brace with wedge connection 2,57m.",
    length: 2.57,
    material: "",
    height: 2,
    width: 0,
    label_no: "Diagonalstag med kilekobling 2,57m.",
    weight: 7.6,
    article_id: "C284825",
    label_se: "Diagonalstag med kilkoppling 2,57m."
  },
  {
    weight: 6.8,
    length: 2.07,
    height: 2,
    article_id: "C284720",
    label_en: "Diagonal brace 2,07m.",
    width: 0,
    label_no: "Diagonalstag 2,07m.",
    label_se: "Diagonalstag 2,07m.",
    material: ""
  }
];

export const componentsDecks: Component[] = [
  {
    label_en: "Console panel 0,73m in aluminium.",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 0,
    article_id: "C288501",
    label_se: "Konsolpanel 0,73m i aluminium.",
    weight: 6,
    width: 0.73,
    label_no: "Konsollpanel 0,73m i aluminium."
  },
  {
    label_no: "Plattform 1,07X0,61m kompositt",
    article_id: "C491910A",
    label_en: "Platform 1,07X0,61m composite",
    weight: 6.5,
    length: 1.07,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_se: "Plattform 1,07X0,61m komposit",
    width: 0.61
  },
  {
    label_en: "Steel plank ECO reinforced 0,73x0,32",
    label_se: "Stålplank ECO forsterket 0,73x0,32",
    weight: 5.8,
    length: 0.73,
    article_id: "C491607",
    label_no: "Stålplank ECO forsterket 0,73x0,32",
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_en: "Platform 3.07X0.61 m / hatch and ladder plywood",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    width: 0.61,
    label_se: "Plattform 3,07X0,61 m / lucka och stege plywood",
    article_id: "C492130",
    weight: 41.2,
    length: 3.07,
    label_no: "Plattform 3,07X0,61 m / luke og stige plywood"
  },
  {
    label_no: "Stålplank ECO forsterket 3,07x0,32",
    length: 3.07,
    article_id: "C491630",
    width: 0.32,
    weight: 18.6,
    label_se: "Stålplank ECO forsterket 3,07x0,32",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank ECO reinforced 3,07x0,32"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank ECO 2,57x0,32",
    label_no: "Aluplank ECO 2,57x0,32",
    width: 0.32,
    weight: 9.2,
    article_id: "C491525",
    length: 2.57,
    label_en: "Alu plank ECO 2,57x0,32"
  },
  {
    width: 0.32,
    label_no: "Plattform 1,07X0,32m kompositt",
    label_en: "Platform 1,07X0,32m composite",
    weight: 4.6,
    label_se: "Plattform 1,07X0,32m komposit",
    length: 1.07,
    article_id: "C493010A",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE
  },
  {
    article_id: "C492520",
    label_no: "Plattform - plywood med luke 2,07x0,61 m",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_en: "Platform - plywood with hatch 2,07x0,61 m",
    label_se: "Plattform - plywood med lucka 2,07 x0,61 m",
    length: 2.07,
    width: 0.61,
    weight: 15.6
  },
  {
    label_no: "Plattform 3,07x0,61m plywood",
    label_en: "Platform 3,07x0,61m plywood",
    article_id: "C491930",
    length: 3.07,
    weight: 35.4,
    label_se: "Plattform 3,07x0,61m plywood",
    width: 0.61,
    material: SCAFFOLD_PLANKTYPE.PLYWOOD
  },
  {
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_no: "Plattform 2,57X0,61 m / luke og stige kompositt",
    width: 0.61,
    length: 2.57,
    article_id: "C492125A",
    weight: 19.4,
    label_se: "Plattform 2,57X0,61 m / lucka och stege komposit",
    label_en: "Platform 2,57X0,61 m / hatch and ladder composite"
  },
  {
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_se: "Plattform 0,73x0,32m plywood",
    width: 0.32,
    length: 0.73,
    label_en: "Platform 0,73x0,32m plywood",
    weight: 3.8,
    article_id: "C493007",
    label_no: "Plattform 0,73x0,32m plywood"
  },
  {
    label_se: "Aluplank ECO 3,07x0,32",
    length: 3.07,
    article_id: "C491530",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.32,
    weight: 10.6,
    label_en: "Alu plank ECO 3,07x0,32",
    label_no: "Aluplank ECO 3,07x0,32"
  },
  {
    width: 0.61,
    length: 1.07,
    label_no: "Plattform 1,07X0,61m plywood",
    label_se: "Plattform 1,07X0,61m plywood",
    article_id: "C491910",
    weight: 12.6,
    label_en: "Platform 1,07X0,61m plywood",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD
  },
  {
    width: 0.32,
    label_no: "Plattform 1,57x0,32m plywood",
    label_se: "Plattform 1,57x0,32m plywood",
    label_en: "Platform 1,57x0,32m plywood",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    article_id: "C493015",
    weight: 7.9,
    length: 1.57
  },
  {
    width: 0.61,
    weight: 8.6,
    article_id: "C491907",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    length: 0.73,
    label_no: "Plattform 0,73x0,61m plywood",
    label_se: "Plattform 0,73x0,61m plywood",
    label_en: "Platform 0,73x0,61m plywood"
  },
  {
    width: 0.61,
    length: 0.73,
    weight: 4.7,
    label_se: "Plattform 0,73x0,61m komposit",
    article_id: "C491907A",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_no: "Plattform 0,73x0,61m kompositt",
    label_en: "Platform 0,73x0,61m composite"
  },
  {
    width: 0.32,
    length: 1.07,
    label_se: "Stålplank ECO forsterket 1,07x0,32",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8,
    label_no: "Stålplank ECO forsterket 1,07x0,32",
    label_en: "Steel plank ECO reinforced 1,07x0,32",
    article_id: "C491610"
  },
  {
    article_id: "C493020A",
    weight: 8.5,
    width: 0.32,
    label_en: "Platform 2,07x0,32m composite",
    length: 2.07,
    label_se: "Plattform 2,07x0,32m komposit",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_no: "Plattform 2,07x0,32m kompositt"
  },
  {
    label_en: "Platform 2.57X0.61 m / hatch and ladder plywood",
    label_no: "Plattform 2,57X0,61 m / luke og stige plywood",
    length: 2.57,
    article_id: "C492125",
    weight: 34.4,
    label_se: "Plattform 2,57X0,61 m / lucka och stege plywood",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    width: 0.61
  },
  {
    label_se: "Plattform 1,57x0,61m komposit",
    length: 1.57,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_en: "Platform 1,57x0,61m composite",
    weight: 9.3,
    width: 0.61,
    label_no: "Plattform 1,57x0,61m kompositt",
    article_id: "C491915A"
  },
  {
    width: 0.61,
    length: 1.57,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    article_id: "C492515A",
    label_no: "Plattform - kompositt med luke 1,57x0,61 m",
    weight: 9.4,
    label_en: "Platform - composite with hatch 1,57x0,61 m",
    label_se: "Plattform - komposit med lucka 1,57x0,61 m"
  },
  {
    label_en: "Alu plank ECO 2,07x0,32",
    weight: 7.9,
    length: 2.07,
    label_se: "Aluplank ECO 2,07x0,32",
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "C491520",
    label_no: "Aluplank ECO 2,07x0,32"
  },
  {
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 0.73,
    weight: 4.2,
    label_en: "Alu plank ECO 0,73x0,32",
    article_id: "C491507",
    label_no: "Aluplank ECO 0,73x0,32",
    label_se: "Aluplank ECO 0,73x0,32"
  },
  {
    label_en: "Platform 2,07x0,32m plywood",
    label_se: "Plattform 2,07x0,32m plywood",
    length: 2.07,
    width: 0.32,
    weight: 10.3,
    article_id: "C493020",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_no: "Plattform 2,07x0,32m plywood"
  },
  {
    length: 1.57,
    width: 0.61,
    article_id: "C492515",
    label_en: "Platform - plywood with hatch 1,57x0,61 m",
    label_no: "Plattform - plywood med luke 1,57x0,61 m",
    weight: 12,
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_se: "Plattform - plywood med lucka 1,57x0,61 m"
  },
  {
    weight: 22.9,
    article_id: "C492530",
    label_no: "Plattform - plywood med luke 3,07x0,61 m",
    label_en: "Platform - plywood with hatch 3,07x0,61 m",
    width: 0.61,
    length: 3.07,
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_se: "Plattform - plywood med lucka 3,07x0,61 m"
  },
  {
    label_no: "Stålplank ECO forsterket 2,07x0,32",
    weight: 13.5,
    label_se: "Stålplank ECO forsterket 2,07x0,32",
    article_id: "C491620",
    length: 2.07,
    label_en: "Steel plank ECO reinforced 2,07x0,32",
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_no: "Aluplank ECO 1,07x0,32",
    label_se: "Aluplank ECO 1,07x0,32",
    label_en: "Alu plank ECO 1,07x0,32",
    article_id: "C491510",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.32,
    weight: 5.1,
    length: 1.07
  },
  {
    width: 0.61,
    length: 3.07,
    label_se: "Plattform 3,07x0,61m komposit",
    label_en: "Platform 3,07x0,61m composite",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_no: "Plattform 3,07x0,61m kompositt",
    weight: 17.2,
    article_id: "C491930A"
  },
  {
    width: 0.32,
    label_en: "Platform 2,57x0,32m plywood",
    weight: 12.7,
    length: 2.57,
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_se: "Plattform 2,57x0,32m plywood",
    article_id: "C493025",
    label_no: "Plattform 2,57x0,32m plywood"
  },
  {
    weight: 15.1,
    label_se: "Plattform - komposit med lucka 2,57x0,61 m",
    length: 2.57,
    label_en: "Platform - composite with hatch 2,57x0,61 m",
    width: 0.61,
    article_id: "C492525A",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_no: "Plattform - kompositt med luke 2,57x0,61 m"
  },
  {
    weight: 16.1,
    label_no: "Stålplank ECO forsterket 2,57x0,32",
    article_id: "C491625",
    length: 2.57,
    label_se: "Stålplank ECO forsterket 2,57x0,32",
    width: 0.32,
    label_en: "Steel plank ECO reinforced 2,57x0,32",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    weight: 12.1,
    label_en: "Platform - composite with hatch 2,07x0,61 m",
    label_se: "Plattform - komposit med lucka 2,07x0,61 m",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    article_id: "C492520A",
    width: 0.61,
    length: 2.07,
    label_no: "Plattform - kompositt med luke 2,07x0,61 m"
  },
  {
    article_id: "C493010",
    weight: 5.5,
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_no: "Plattform 1,07X0,32m plywood",
    label_en: "Platform 1,07X0,32m plywood",
    length: 1.07,
    label_se: "Plattform 1,07X0,32m plywood",
    width: 0.32
  },
  {
    label_no: "Plattform 1,57x0,32m kompositt",
    length: 1.57,
    label_en: "Platform 1,57x0,32m composite",
    width: 0.32,
    article_id: "C493015A",
    weight: 6.5,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_se: "Plattform 1,57x0,32m komposit"
  },
  {
    width: 0.61,
    weight: 19.5,
    length: 2.57,
    label_en: "Platform - plywood with hatch 2,57x0,61 m",
    label_no: "Plattform - plywood med luke 2,57x0,61 m",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    article_id: "C492525",
    label_se: "Plattform - plywood med lucka 2,57x0,61 m"
  },
  {
    label_en: "Platform 3,07X0,61 w / hatch and ladder composite",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    length: 3.07,
    label_se: "Plattform 3,07X0,61 m / lucka och stege komposit",
    article_id: "C492130A",
    label_no: "Plattform 3,07X0,61 m / luke og stige kompositt",
    width: 0.61,
    weight: 23.2
  },
  {
    width: 0.61,
    length: 3.07,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    weight: 17.6,
    label_se: "Plattform - komposit med lucka 3,07x0,61 m",
    article_id: "C492530A",
    label_en: "Platform - composite with hatch 3,07x0,61 m",
    label_no: "Plattform - kompositt med luke 3,07x0,61 m"
  },
  {
    article_id: "C493030A",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    width: 0.32,
    label_no: "Plattform 3,07x0,32m kompositt",
    label_se: "Plattform 3,07x0,32m komposit",
    length: 3.07,
    label_en: "Platform 3,07x0,32m composite",
    weight: 12.4
  },
  {
    length: 1.57,
    width: 0.32,
    weight: 10.8,
    label_se: "Stålplank ECO forsterket 1,57x0,32",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank ECO reinforced 1,57x0,32",
    article_id: "C491615",
    label_no: "Stålplank ECO forsterket 1,57x0,32"
  },
  {
    width: 0.61,
    weight: 11.9,
    length: 2.07,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_se: "Plattform 2,07x0,61m komposit",
    label_en: "Platform 2,07x0,61m composite",
    label_no: "Plattform 2,07x0,61m kompositt",
    article_id: "C491920A"
  },
  {
    weight: 6.5,
    label_en: "Alu plank ECO 1,57x0,32",
    label_se: "Aluplank ECO 1,57x0,32",
    width: 0.32,
    length: 1.57,
    article_id: "C491515",
    label_no: "Aluplank ECO 1,57x0,32",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    weight: 18.3,
    label_no: "Plattform 1,57x0,61m plywood",
    width: 0.61,
    label_se: "Plattform 1,57x0,61m plywood",
    length: 1.57,
    article_id: "C491915",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    label_en: "Platform 1,57x0,61m plywood"
  },
  {
    length: 2.57,
    label_no: "Plattform 2,57x0,32m kompositt",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_en: "Platform 2,57x0,32m composite",
    weight: 10.4,
    label_se: "Plattform 2,57x0,32m komposit",
    width: 0.32,
    article_id: "C493025A"
  },
  {
    weight: 29.4,
    article_id: "C491925",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    width: 0.61,
    label_en: "Platform 2,57x0,61m plywood",
    label_se: "Plattform 2,57x0,61m plywood",
    label_no: "Plattform 2,57x0,61m plywood",
    length: 2.57
  },
  {
    weight: 24,
    article_id: "C491920",
    label_en: "Platform 2,07x0,61m plywood",
    length: 2.07,
    label_no: "Plattform 2,07x0,61m plywood",
    label_se: "Plattform 2,07x0,61m plywood",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD,
    width: 0.61
  },
  {
    label_se: "Plattform 0,73x0,32m komposit",
    label_en: "Platform 0,73x0,32m composite",
    length: 0.73,
    label_no: "Plattform 0,73x0,32m kompositt",
    weight: 3.2,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    article_id: "C493007A",
    width: 0.32
  },
  {
    article_id: "C491925A",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE,
    label_en: "Platform 2,57x0,61m composite",
    label_se: "Plattform 2,57x0,61m komposit",
    weight: 14.3,
    label_no: "Plattform 2,57x0,61m kompositt",
    width: 0.61,
    length: 2.57
  },
  {
    length: 3.07,
    weight: 15.1,
    label_se: "Plattform 3,07x0,32m plywood",
    label_no: "Plattform 3,07x0,32m plywood",
    width: 0.32,
    label_en: "Platform 3,07x0,32m plywood",
    article_id: "C493030",
    material: SCAFFOLD_PLANKTYPE.PLYWOOD
  }
];

export const componentsColanders: Component[] = [
  {
    width: 0.32,
    weight: 2.8,
    material: "",
    length: 0.7,
    label_se: "Durkplåt med handtag 0,7 x 0,32m",
    article_id: "R75032",
    label_no: "Dørslag med håndtak 0,7 x 0,32m",
    label_en: "Colander with handle 0,7 x 0,32m"
  },
  {
    article_id: "R75050",
    label_no: "Dørslag med håndtak 0,5 x 0,5m",
    material: "",
    weight: 2.8,
    width: 0.5,
    label_en: "Colander with handle 0,5 x 0,5m",
    label_se: "Durkplåt med handtag 0,5 x 0,5m",
    length: 0.5
  },
  {
    label_en: "Colander with handle 1,14 x 0,64m",
    article_id: "R75114",
    material: "",
    label_se: "Durkplåt med handtag 1,14 x 0,64m",
    label_no: "Dørslag med håndtak 1,14 x 0,64m",
    length: 1.14,
    weight: 8.2,
    width: 0.64
  },
  {
    weight: 6.3,
    width: 0.5,
    label_en: "Colander with handle 1,1 x 0,5m",
    label_no: "Dørslag med håndtak 1,1 x 0,5m",
    article_id: "R75110",
    material: "",
    label_se: "Durkplåt med handtag 1,1 x 0,5m",
    length: 1.1
  },
  {
    article_id: "R75070",
    width: 0.5,
    material: "",
    label_en: "Colander with handle 0,7 x 0,5m",
    label_se: "Durkplåt med handtag 0,7 x 0,5m",
    label_no: "Dørslag med håndtak 0,7 x 0,5m",
    weight: 4,
    length: 0.7
  }
];

export const componentsToeBoards: Component[] = [
  {
    article_id: "C286807",
    width: 0.15,
    length: 0.73,
    label_no: "Gavlsparklist 0,15x0,73m",
    weight: 1.9,
    material: "",
    label_en: "Gabel Toe Board 0,15x0,73m",
    label_se: "Gavelsparklist 0,15x0,73m"
  },
  {
    label_en: "Toe Board 0,15x0,73m.",
    label_no: "Fotlist 0,15x0,73m.",
    length: 0.73,
    label_se: "Sparklist 0,15x0,73m.",
    material: "",
    width: 0.15,
    weight: 2,
    article_id: "C286812"
  },
  {
    label_en: "Toe Board 0,15x2,57m",
    length: 2.57,
    label_no: "Fotlist 0,15x2,57m",
    label_se: "Sparklist 0,15x2,57m",
    width: 0.15,
    article_id: "C286825",
    weight: 6.7,
    material: ""
  },
  {
    article_id: "C286820",
    label_se: "Sparklist 0,15x2,07m",
    length: 2.07,
    label_en: "Toe Board 0,15x2,07m",
    material: "",
    width: 0.15,
    label_no: "Fotlist 0,15x2,07m",
    weight: 5.4
  },
  {
    length: 1.57,
    weight: 4.2,
    label_no: "Fotlist 0,15x1,57m",
    article_id: "C286815",
    label_en: "Toe Board 0,15x1,57m",
    width: 0.15,
    label_se: "Sparklist 0,15x1,57m",
    material: ""
  },
  {
    weight: 3,
    width: 0.15,
    length: 1.07,
    article_id: "C286813",
    label_se: "Sparklist 0,15x1,07m.",
    material: "",
    label_no: "Fotlist 0,15x1,07m.",
    label_en: "Toe Board 0,15x1,07m."
  },
  {
    label_no: "Fotlist 0,15x3,07m",
    width: 0.15,
    material: "",
    length: 3.07,
    weight: 8.4,
    article_id: "C286830",
    label_se: "Sparklist 0,15x3,07m",
    label_en: "Toe Board 0,15x3,07m"
  },
  {
    weight: 3,
    label_se: "Sparklist 0,15x1,09m",
    label_no: "Fotlist 0,15x1,09m",
    material: "",
    width: 0.15,
    article_id: "C286810",
    label_en: "Toe Board 0,15x1,09m",
    length: 1.09
  },
  {
    article_id: "C286807",
    length: 0.73,
    label_se: "Sparklist 0,15x0.73m",
    label_no: "Fotlist 0,15x0.73m",
    label_en: "Toe Board  0,15x0.73m",
    weight: 1.8,
    material: "",
    width: 0.15
  }
];

export const componentsGuardRails: Component[] = [
  {
    article_id: "C283903",
    label_en: "Gabel rail in aluminium. 0,36m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Gavelräcke i aluminium. 0,36m",
    weight: 1.9,
    label_no: "Gavlrekke i aluminium. 0,36m",
    length: 0.36,
    width: 0.5,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_no: "Gavlrekke i aluminium. 0,73m",
    weight: 2.6,
    length: 0.73,
    article_id: "C283907",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.5,
    label_se: "Gavelräcke i aluminium. 0,73m",
    label_en: "Gabel rail in aluminium. 0,73m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  //   {
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     article_id: "T00030A",
  //     width: 0,
  //     length: 0,
  //     label_en: "Rail 1m aluminium",
  //     label_se: "Räcke skena 1m aluminium.",
  //     label_no: "Rekkverk 1m aluminium",
  //     weight: 2.1,
  //     variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  //   },
  {
    label_se: "Dubbelräcke aluminium. m/diag.\r\n2,07 x 0,50m",
    width: 0.5,
    material: "",
    label_en: "Double railing aluminium. m/diag. 2,07 x 0,50m",
    weight: 5.6,
    length: 2.07,
    article_id: "C284320A",
    label_no: "Dobbelt rekkverk  aluminium. m/diag 2,07 x 0,50m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    length: 2.07,
    label_se: "Dubbelräcke aluminium. 2,07 x 0,50m",
    article_id: "C284320",
    width: 0.5,
    weight: 4.9,
    label_en: "Double railing 2,07 x 0,50m",
    label_no: "Dobbelt rekkverk 2,07 x 0,50m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_se: "Dubbelräcke aluminium. 2,57 x 0,50m",
    label_no: "Dobbelt rekkverk 2,57 x 0,50m",
    width: 0.5,
    weight: 5.8,
    article_id: "C284325",
    length: 2.57,
    material: "",
    label_en: "Double railing 2,57 x 0,50m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_en: "Double railing 1,07 x 0,50m",
    label_se: "Dubbelräcke aluminium. 1,07 x 0,50m",
    width: 0.5,
    article_id: "C284310",
    weight: 2.7,
    material: "",
    length: 1.07,
    label_no: "Dobbelt rekkverk 1,07 x 0,50m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    width: 0.5,
    weight: 6.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.57,
    label_en: "Double railing aluminium. m/diag. 2,57 x 0,50m",
    label_se: "Dubbelräcke aluminium. m/diag.\r\n2,57 x 0,50m",
    article_id: "C284325A",
    label_no: "Dobbelt rekkverk  aluminium. m/diag 2,57 x 0,50m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    length: 3.07,
    label_no: "Dobbelt rekkverk  aluminium. m/diag 3,07 x 0,50m",
    label_se: "Dubbelräcke aluminium. m/diag.\r\n3,07 x 0,50m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.5,
    weight: 6.9,
    label_en: "Double railing aluminium. m/diag. 3,07 x 0,50m",
    article_id: "C284330A",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    weight: 3.9,
    label_no: "Dobbelt rekkverk 1,57 x 0,50m",
    material: "",
    length: 1.57,
    width: 0.5,
    label_se: "Dubbelräcke aluminium. 1,57 x 0,50m",
    article_id: "C284315",
    label_en: "Double railing 1,57 x 0,50m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    label_en: "Double railing 0,73 x 0,50m",
    weight: 1.8,
    length: 0.73,
    label_no: "Dobbelt rekkverk 0,73 x 0,50m",
    label_se: "Dubbelräcke aluminium. 0,73 x 0,50m",
    article_id: "C284307",
    width: 0.5,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    label_se: "Dubbelräcke aluminium. 3,07 x 0,50m",
    length: 3.07,
    width: 0.5,
    label_no: "Dobbelt rekkverk 3,07 x 0,50m",
    label_en: "Double railing 3,07 x 0,50m",
    weight: 6.7,
    article_id: "C284330",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  }
];

export const componentsSingleGuardRails: Component[] = [
  {
    label_no: "Rekkverk enkel aluminium 0,73m",
    length: 0.73,
    width: 0,
    article_id: "C283607A",
    weight: 0.7,
    label_se: "Räcke enkel aluminium 0,73m",
    label_en: "Rail simple aluminium 0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_en: "Rail simple steel 1,57m",
    weight: 3.1,
    article_id: "C283615",
    length: 1.57,
    label_no: "Rekkverk enkel stål 1,57m",
    width: 0,
    label_se: "Räcke enkel stål 1,57m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_no: "Rekkverk enkel stål 3,07m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    length: 3.07,
    label_se: "Räcke enkel stål 3,07m",
    label_en: "Rail simple steel 3,07m",
    weight: 5.5,
    article_id: "C283630"
  },
  {
    label_se: "Räcke enkel aluminium 1,57m",
    label_en: "Rail simple aluminium 1,57m",
    length: 1.57,
    weight: 1.4,
    label_no: "Rekkverk enkel aluminium 1,57m",
    article_id: "C283615A",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0
  },
  {
    length: 2,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Rekkverk enkel stål 2,0m",
    label_en: "Rail simple steel 2,0m",
    width: 0,
    article_id: "C283620",
    weight: 3.9,
    label_se: "Räcke enkel stål 2,0 m"
  },
  {
    article_id: "C283610A",
    length: 1.07,
    label_en: "Rail simple aluminium 1,07m",
    label_se: "Räcke enkel aluminium 1,07m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 1,
    label_no: "Rekkverk enkel aluminium 1,07m",
    width: 0
  },
  {
    label_se: "Räcke enkel aluminium 2,57m",
    weight: 2.1,
    label_en: "Rail simple aluminium 2,57m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "C283625A",
    length: 2.57,
    label_no: "Rekkverk enkel aluminium 2,57m",
    width: 0
  },
  {
    label_se: "Räcke enkel stål 1,07m",
    weight: 2.3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Rail simple steel 1,07m",
    width: 0,
    article_id: "C283610",
    length: 1.07,
    label_no: "Rekkverk enkel stål 1,07m"
  },
  {
    label_no: "Rekkverk enkel aluminium 2,0m",
    label_en: "Rail simple aluminium 2,0m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 1.7,
    length: 2,
    width: 0,
    label_se: "Räcke enkel aluminium 2,0 m",
    article_id: "C283620A"
  },
  {
    label_en: "Rail simple steel 0,73m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Räcke enkel stål 0,73m",
    length: 0.73,
    article_id: "C283607",
    width: 0,
    label_no: "Rekkverk enkel stål 0,73m",
    weight: 1.7
  },
  {
    label_en: "Rail simple steel 2,57m",
    article_id: "C283625",
    length: 2.57,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Rekkverk enkel stål 2,57m",
    label_se: "Räcke enkel stål 2,57m",
    width: 0,
    weight: 4.7
  }
];

export const componentsULedgersStair: Component[] = [
  //   {
  //     label_en: "U-beam steel 0,73m with coupling",
  //     label_no: "U-Tverrstang stål 0,73m med kobling",
  //     length: 0.73,
  //     width: 0,
  //     article_id: "C285379",
  //     label_se: "U-bom stål 0,73m med koppling",
  //     weight: 2.2,
  //     material: SCAFFOLD_PLANKTYPE.STEEL
  //   },
  {
    label_en: "U-beam for stairs aluminium 0,73m",
    article_id: "C286207",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "U-bom för trappa aluminium 0,73m",
    length: 0.73,
    label_no: "U-Tverrstang for trapp aluminium 0,73m",
    width: 0,
    weight: 2
  }
];

export const componentsOLedgers: Component[] = [
  {
    width: 0,
    length: 2.07,
    label_no: "Horisontalstag 2,07m.",
    label_se: "Horisontellstag 2,07m.",
    article_id: "C283820",
    label_en: "Horisontal brace 2,07m.",
    material: "",
    weight: 7.4
  },
  {
    label_se: "Räcke enkel stål 1,07m",
    weight: 2.3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Rail simple steel 1,07m",
    width: 0,
    article_id: "C283610",
    length: 1.07,
    label_no: "Rekkverk enkel stål 1,07m"
  },
  {
    label_en: "Horisontal brace 3,07m.",
    length: 3.07,
    material: "",
    width: 0,
    article_id: "C283830",
    label_se: "Horisontellstag 3,07m.",
    weight: 10.4,
    label_no: "Horisontalstag 3,07m."
  },
  {
    label_no: "Horisontalstag 1,57m.",
    article_id: "C283815",
    weight: 6,
    label_en: "Horisontal brace 1,57m.",
    length: 1.57,
    label_se: "Horisontellstag 1,57m.",
    material: "",
    width: 0
  },
  {
    weight: 8.9,
    article_id: "C283825",
    width: 0,
    material: "",
    label_no: "Horisontalstag 2,57m.",
    label_se: "Horisontellstag 2,57m.",
    length: 2.57,
    label_en: "Horisontal brace 2,57m."
  }
];

export const componentsWedgeCouplers: Component[] = [
  {
    label_en: "Coupling with wedge for railing",
    article_id: "C284600",
    weight: 1.1,
    width: 0,
    label_se: "Koppling med kil för räcke",
    length: 0,
    material: "",
    label_no: "Kobling med kile for rekkverk"
  },
  {
    article_id: "T008297",
    label_se: "Distanskoppling Ø 48,3 - 160mm",
    width: 0,
    label_en: "Distance coupling Ø 48.3 - 160mm",
    length: 0,
    label_no: "Avstandskobling Ø 48,3 - 160mm",
    material: "",
    weight: 1.8
  }
];

export const componentsAnchors: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Veggmonteringsrør stål 1,0m",
    label_en: "Wall mounting Steel pipe 1,0 m",
    width: 0,
    article_id: "T00178U",
    label_se: "Väggfäströr stål 1,0m",
    length: 0,
    weight: 3.8
  },
  {
    label_no: "Veggmonteringsrør stål 1,2m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4,
    label_en: "Wall mounting Steel pipe 1,2 m",
    width: 0,
    label_se: "Väggfäströr stål 1,2m",
    length: 0,
    article_id: "T00054U"
  },
  {
    article_id: "T00199U",
    label_se: "Väggfäströr stål - Justerbar 0,75-1,25m",
    label_no: "Veggmonteringsrør stål regulerbar 0,75-1,25m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Wall mounting Steel pipe - adjustable 0,75-1,25m",
    width: 0,
    weight: 4.5,
    length: 0
  },
  {
    weight: 3.3,
    label_se: "Väggfäströr stål - Justerbar 0,50-0,85m",
    length: 0,
    article_id: "T00247U",
    width: 0,
    label_no: "Veggmonteringsrør stål regulerbar 0,50-0,85m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Wall mounting Steel pipe - adjustable 0,50-0,85m"
  },
  {
    weight: 2,
    length: 0,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Väggfäströr stål 0,6m",
    label_no: "Veggmonteringsrør stål 0,6m",
    label_en: "Wall mounting Steel pipe 0,6 m",
    width: 0,
    article_id: "T00053U"
  },
  {
    label_en: "Wall bracket adjustable 0,4m w/coupling",
    material: "",
    label_no: "Veggfeste justerbar 0,4m m/kobling",
    label_se: "Väggfäste justerbar 0,4m m/koppling",
    width: 0,
    weight: 2,
    article_id: "T00153",
    length: 0
  },
  {
    width: 0,
    article_id: "T00007U",
    label_en: "Wall mounting Steel pipe 0,4 m",
    length: 0,
    weight: 1.6,
    label_no: "Veggmonteringsrør stål 0,4m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Väggfäströr stål 0,4m"
  },
  {
    label_no: "Veggmonteringsrør stål 0,8m",
    width: 0,
    article_id: "T00056U",
    label_se: "Väggfäströr stål 0,8m",
    length: 0,
    label_en: "Wall mounting Steel pipe 0,8 m",
    weight: 3,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    weight: 2.4,
    material: "",
    label_se: "Väggfäste justerbar 0,6m m/koppling",
    length: 0,
    width: 0,
    article_id: "T00085",
    label_no: "Veggfeste justerbar 0,6m m/kobling",
    label_en: "Wall bracket adjustable 0,6m w/coupling"
  },
  {
    label_en: "Wall mounting Steel pipe 1,5 m",
    article_id: "T00008U",
    label_se: "Väggfäströr stål 1,5m",
    weight: 4.8,
    width: 0,
    length: 0,
    label_no: "Veggmonteringsrør stål 1,5m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  }
];

export const componentsConsoles: Component[] = [
  {
    width: 0.73,
    material: "",
    length: 0,
    label_en: "Console 0,73m",
    weight: 3.7,
    label_se: "Konsol 0,73m",
    label_no: "Konsoll 0,73m",
    article_id: "C285579",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    label_en: "Console 0,36m",
    width: 0.36,
    weight: 2.4,
    article_id: "C285539",
    length: 0,
    label_se: "Konsol 0,36m",
    material: "",
    label_no: "Konsoll 0,36m",
    variant: CONSOLE_VARIANT.SPIGOT
  }
];

export const componentsConsoleBracketBraces: Component[] = [
  {
    weight: 8.2,
    width: 0.73,
    article_id: "C285179",
    material: "",
    label_se: "Förstyvning för konsol 1,77m.",
    label_no: "Avstivning for konsoll 1,77m.",
    label_en: "Stiffening for console 1,77m.",
    length: 1.77
  }
];

export const componentsConsoleRailPost: Component[] = [
  {
    label_se: "Räckesstolpe för konsol 1,00m",
    length: 1,
    label_no: "Rekkverksstolpe for konsoll 1,00m",
    weight: 2.1,
    article_id: "C203084",
    material: "",
    label_en: "Railing post for console 1,00m",
    width: 0
  }
];

export const componentsMeshPanels: Component[] = [
  {
    label_en: "Mesh panel post Alu. 0,73x2,0m hole for console",
    label_se: "Nätpanel stolpe Alu. 0,73x2,0m hål för konsol",
    length: 0,
    width: 0,
    material: "",
    article_id: "C285914",
    weight: 4.5,
    label_no: "Mesh panelstolpe Alu. 0,73x2,0m hull for konsoll"
  },
  {
    width: 0,
    label_en: "Mesh panel post Alu. 0,73x2,0m",
    weight: 4.5,
    label_se: "Nätpanel stolpe Alu. 0,73x2,0m",
    label_no: "Mesh panelstolpe Alu. 0,73x2,0m",
    length: 0,
    material: "",
    article_id: "C285908"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "C285020",
    width: 0,
    label_se: "Nätpanel aluminium 2,07m.",
    length: 2.07,
    weight: 20.1,
    label_no: "Meshpanel aluminium 2,07m.",
    label_en: "Mesh panel aluminium 2,07m."
  },
  {
    length: 2.57,
    weight: 23.7,
    label_se: "Nätpanel aluminium 2,57m.",
    label_en: "Mesh panel aluminium 2,57m.",
    width: 0,
    article_id: "C285025",
    label_no: "Meshpanel aluminium 2,57m.",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Meshpanel aluminium 3,07m.",
    width: 0,
    length: 3.07,
    weight: 27.4,
    label_en: "Mesh panel aluminium 3,07m.",
    article_id: "C285030",
    label_se: "Nätpanel aluminium 3,07m."
  },
  {
    label_se: "Nätpanel aluminium 1,57m.",
    length: 1.57,
    article_id: "C285015",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0,
    weight: 16.5,
    label_no: "Meshpanel aluminium 1,57m.",
    label_en: "Mesh panel aluminium 1,57m."
  }
];

export const componentsStairs: Component[] = [
  {
    width: 0.64,
    length: 3.07,
    article_id: "C286230",
    weight: 29.9,
    height: 2,
    label_se: "Trappa aluminium för fack 3,07x2mm",
    label_no: "Trapp aluminium for rom 3,07x2m",
    label_en: "Stair aluminium for bay 3,07x2m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Stair aluminium for bay 2,57x2m",
    label_no: "Trapp aluminium for rom 2,57x2m",
    weight: 25.1,
    width: 0.64,
    height: 2,
    label_se: "Trappa aluminium för fack 2,57x2m",
    article_id: "C286225",
    length: 2.57
  }
];

export const componentsStartStairs: Component[] = [
  {
    weight: 12,
    width: 0.64,
    height: 1,
    material: "",
    label_en: "Stair 1,30x1,00m",
    label_se: "Trappa 1,30x1,00m",
    article_id: "C286210",
    length: 1.3,
    label_no: "Trapp 1,30x1,00m"
  }
];

export const componentsStairHatches: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Stege aluminium för plattform med lucka, utan stege",
    label_en: "ALUMINIUM ladder for platform with hatch, without ladder",
    length: 0,
    article_id: "C511600",
    weight: 4.8,
    width: 0,
    label_no: "Aluminiumsstige for plattform med luke, uten stige"
  }
];

export const componentsLocks: Component[] = [
  {
    label_no: "Lås til ramme",
    width: 0,
    weight: 0.1,
    label_se: "Lås till ram",
    length: 0,
    label_en: "Lock for frame",
    material: "",
    article_id: "C511100"
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    weight: 17.8,
    length: 3.07,
    width: 0,
    height: 2,
    label_se: "Utvändigt trappräcke för fack 3,07 m",
    label_en: "External stair railing for bay 3,07 m",
    material: "",
    label_no: "Utvendig trapperekkverk for rom 3,07m",
    article_id: "C286330",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    weight: 16,
    article_id: "C286325",
    length: 2.57,
    label_no: "Utvendig trapperekkverk for rom 2,57m",
    label_en: "External stair railing for bay 2,57 m",
    height: 2,
    width: 0,
    material: "",
    label_se: "Utvändigt trappräcke för fack 2,57 m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];
export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    length: 3.07,
    weight: 12.8,
    label_se: "Invändigt trappräcke för fack 3,07 m",
    material: "",
    label_en: "Internal stair railing for bay 3,07 m",
    width: 0,
    height: 2,
    label_no: "Innvendig trapperekkverk for rom 3,07m",
    article_id: "C286300",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsStairwayGuardrailsWideInner: Component[] = [
  {
    label_se: "Räcke invändigt för trappa undersida",
    article_id: "C286310",
    material: "",
    weight: 2.4,
    label_en: "Inside handrail for the underside of the stairs",
    length: 0.6,
    label_no: "Innvendig håndløper for undersiden av trappen",
    width: 0
  }
];

export const componentsTopFrames: Component[] = [
  {
    label_en: "Gabel rail frame 1,00x0,73m",
    weight: 6.9,
    material: "",
    article_id: "C288107",
    width: 0.73,
    label_no: "Gavlrekkverksramme 1,00x0,73m",
    length: 1,
    label_se: "Gavelräckesram 1,00x0,73m",
    variant: FRAME_VARIANT.TOP_END
  },
  {
    label_en: "Combi frame 1,06 x 0,73m",
    label_no: "Kombiramme 1,06 x 0,73m",
    length: 1.06,
    width: 0.73,
    label_se: "Kombiram 1,06 x 0,73m",
    weight: 8.4,
    article_id: "C282210A",
    material: "",
    variant: FRAME_VARIANT.TOP_END
  },
  {
    weight: 3.5,
    width: 0.73,
    length: 1,
    label_no: "Rekkverksstolpe aluminium. 1,00x0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Railing post aluminium. 1,00x0,73m",
    label_se: "Räckesstolpe aluminium. 1,00x0,73m",
    article_id: "C287007",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    label_no: "Rekkverksstolpe aluminium. m/bajonett 1,00x0,73m",
    article_id: "C287007A",
    width: 0.73,
    label_en: "Railing post aluminium. w/bayonet 1,00x0,73m",
    label_se: "Räckesstolpe aluminium. m/bajonett 1,00x0,73m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 1,
    weight: 3.7,
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  }
];

export const componentsFrameSupports: Component[] = [
  {
    article_id: "C503573",
    weight: 7.4,
    label_no: "Rammestøtte 0,73 for bjelke",
    length: 0.73,
    material: "",
    width: 0,
    label_en: "Frame support 0,73 for beam",
    label_se: "Ramstöd 0,73 för balk"
  }
];

export const componentsTrussBeamJoints: Component[] = [
  {
    weight: 1.4,
    length: 0,
    label_se: "Skarv för fackverksbalk",
    width: 0,
    label_en: "Joint for truss beam",
    material: "",
    label_no: "Skjøt for fagverksbjelke",
    article_id: "C003588"
  }
];

export const componentsScrews: Component[] = [
  {
    label_en: "Ring screw",
    width: 0.012,
    material: "",
    article_id: "-",
    weight: 0.16,
    label_se: "Förankringsögla",
    label_no: "Forankringsløkke",
    length: 0.095
  }
];

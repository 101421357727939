import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { MeasureSlice } from "./measurement.interface";

/** Initial state */
const initialMeasurementState = {
  measurements: []
};

/** Slice creation */
const createMeasureSlice: StateCreator<Store, [], [], MeasureSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialMeasurementState));

  /** Return state */
  return {
    ...initialMeasurementState,
    measurementActions: {
      add: (measurements) =>
        set((state) => ({
          measurements: [...state.measurements, ...measurements]
        })),
      remove: (ids) =>
        set((state) => ({
          measurements: state.measurements.filter(
            (point) => !ids.includes(point.id)
          )
        })),
      set: (points) => set({ measurements: points }),
      update: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                ...data
              };
            })
          };
        });
      },
      updateStartX: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                start: [data, measurement.start[1], measurement.start[2]]
              };
            })
          };
        });
      },
      updateStartY: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                start: [measurement.start[0], data, measurement.start[2]]
              };
            })
          };
        });
      },
      updateStartZ: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                start: [measurement.start[0], measurement.start[1], data]
              };
            })
          };
        });
      },
      updateEndX: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                end: [data, measurement.end[1], measurement.end[2]]
              };
            })
          };
        });
      },
      updateEndY: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                end: [measurement.end[0], data, measurement.end[2]]
              };
            })
          };
        });
      },
      updateEndZ: (ids, data) => {
        set((state) => {
          return {
            measurements: state.measurements.map((measurement) => {
              if (!ids.includes(measurement.id)) return measurement;

              return {
                ...measurement,
                end: [measurement.end[0], measurement.end[1], data]
              };
            })
          };
        });
      }
    }
  };
};

export default createMeasureSlice;

import { ReactElement, useEffect, useRef, useState } from "react";
import VersionInfoProps from "./VersionInfo.types";
import { database } from "services";
import { Unsubscribe } from "firebase/firestore";
import { ReleaseDoc } from "shared/interfaces/firestore";
import { useTranslation } from "react-i18next";
import Modal from "ui/module/Modal";
import Button from "ui/core/Button";
import { diff } from "semver";
import Snackbar from "ui/module/Snackbar";
import Text from "ui/core/Text";

const VersionInfo = (props: VersionInfoProps): ReactElement => {
  const unSubReleaseDocs = useRef<void | Unsubscribe>();

  const [currentVersion, setCurrentVersion] = useState<string>("");

  const [majorUpdate, setMajorUpdate] = useState<boolean>(false);
  const [minorUpdate, setMinorUpdate] = useState<boolean>(false);

  useEffect(() => {
    unSubReleaseDocs.current = database.onUpdateReleaseDocs({
      onSuccess: (releaseDocs: ReleaseDoc[]) => {
        if (releaseDocs.length === 0) return;

        const latestRelease = releaseDocs.reduce((a, b) => {
          return a.date.toDate() > b.date.toDate() ? a : b;
        });

        if (currentVersion === "") {
          setCurrentVersion(latestRelease.version);
          return;
        }

        const difference = diff(currentVersion, latestRelease.version);

        if (difference === null) return;

        if (difference === "major") {
          setMajorUpdate(true);
        } else {
          setMinorUpdate(true);
        }

        setCurrentVersion(latestRelease.version);
      },
      onError: () => {}
    });

    /** Effect: on un-mount */
    return () => {
      if (unSubReleaseDocs.current) unSubReleaseDocs.current();
    };
  }, [currentVersion]);

  const { t } = useTranslation();

  const updateHandler = () => {
    window.location.reload();
  };

  const closeHandler = () => {
    setMinorUpdate(false);
  };

  return (
    <>
      <Text
        variant="caption"
        color="textDisabled"
        sx={{
          position: { xs: "relative ", sm: "fixed" },
          p: 1,
          right: 0,
          bottom: 0,
          zIndex: 1150,
          textAlign: "right",
          pointerEvents: "none"
        }}
      >
        {`${t("headers.version")}: ${currentVersion}`}
      </Text>
      {majorUpdate && (
        <Modal
          open={majorUpdate}
          content={t("copy.update.major.description")}
          title={t("copy.update.major.title")}
          width={"xs"}
          commitButton={{
            label: t("actions.update.now"),
            onClick: updateHandler
          }}
        />
      )}
      {minorUpdate && (
        <Snackbar
          open={true}
          message={t("copy.update.minor.title")}
          type={"secondary"}
          autoHideDuration={null}
          button={
            <Button onClick={updateHandler} color={"inherit"} variant="text">
              {t("actions.update.now")}
            </Button>
          }
          onClose={closeHandler}
          sx={{
            position: "relative",
            bottom: "0px !important",
            right: "0px !important"
          }}
        />
      )}
    </>
  );
};

export default VersionInfo;

import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Anchor } from "./Anchor.types";
import { getEndPointFromStartPoint } from "math/vectors";

export const getGraphData = (anchor: Anchor): GraphData => {
  const {
    length,
    position,
    rotation,
    endPosition: inputEndPosition,
    grounded
  } = anchor;

  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: anchor.id,
    endGrounded: grounded,
    type: ComponentType.ANCHOR,
    metadata: {
      elementVector: new Vector3(0, 0, 1)
        .applyEuler(new Euler().fromArray(rotation))
        .toArray()
    }
  };
};

import { StateCreator } from "zustand";
import { UiSlice } from "./ui.types";

const sidebarWidth =
  window.innerWidth * 0.2 < 230
    ? 230
    : window.innerWidth * 0.2 > 360
      ? 360
      : window.innerWidth * 0.2;

const createUiSlice: StateCreator<UiSlice, [], [], UiSlice> = (set) => ({
  leftSidebarCollapsed: false,
  leftSidebarWidth: sidebarWidth,
  rightSidebarWidth: sidebarWidth,
  preferSidebarCollapsed: false,
  isAppLayout: false,
  uiActions: {
    setLeftSidebarCollapsed: (leftSidebarCollapsed) =>
      set({ leftSidebarCollapsed }),
    setPreferSidebarCollapsed: (preferSidebarCollapsed) =>
      set({ preferSidebarCollapsed }),
    setLeftSidebarWidth: (leftSidebarWidth) => set({ leftSidebarWidth }),
    setRightSidebarWidth: (rightSidebarWidth) => set({ rightSidebarWidth }),
    setIsAppLayout: (isAppLayout) => set({ isAppLayout })
  }
});

export default createUiSlice;

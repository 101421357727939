import { Euler, Vector3, Vector3Tuple } from "three";
import { ComponentType, GraphData, Standard } from "./Standard.types";
import { plus } from "math";

const INIT_LOCK_TOP_OFFSET = 0.1;
const DELTA_LOCK_HEIGHT = 0.5;

const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();

/** Calculate lock positions from top-down */
export const calcLockHeights = (length: number) => {
  const lockPositions: number[] = [];

  /** Early return if lenght is less than INIT_LOCK_TOP_OFFSET */
  if (length < INIT_LOCK_TOP_OFFSET) return lockPositions;

  /** Calcualte remaining length */
  let remainingLength = length - INIT_LOCK_TOP_OFFSET;

  /** Push init top height*/
  lockPositions.push(remainingLength);

  /** Continue to push more heights if possible */
  let lockCount = 1;
  while (remainingLength > DELTA_LOCK_HEIGHT) {
    remainingLength -= DELTA_LOCK_HEIGHT;
    lockPositions.push(
      length - INIT_LOCK_TOP_OFFSET - lockCount * DELTA_LOCK_HEIGHT
    );
    lockCount++;
  }

  return lockPositions;
};

export const getGraphData = (standard: Standard): GraphData => {
  const { length, position, rotation } = standard;
  const endPosition = [
    position[0],
    plus(position[1], length),
    position[2]
  ] as Vector3Tuple;

  return {
    startPosition: position,
    endPosition: endPosition,
    id: standard.id,
    type: ComponentType.STANDARD,
    metadata: {
      elementVector: ELEMENT_VECTOR.set(0, 0, 1)
        .applyEuler(EULER.fromArray(rotation))
        .toArray()
    }
  };
};

export const getGraphSplitData = (standard: Standard): GraphData[] => {
  const { length, position, rotation, splits } = standard;

  if (!splits) return [];

  const newGraphData: GraphData[] = [];
  const mergedLengths = [...splits, length].sort((a, b) => a - b);

  let startPosition = position;
  for (let i = 0; i < mergedLengths.length; i++) {
    const length = mergedLengths[i];

    const endPosition = [
      position[0],
      plus(position[1], length),
      position[2]
    ] as Vector3Tuple;

    newGraphData.push({
      startPosition: startPosition,
      endPosition: endPosition,
      id: standard.id,
      type: ComponentType.STANDARD,
      metadata: {
        elementVector: ELEMENT_VECTOR.set(0, 0, 1)
          .applyEuler(EULER.fromArray(rotation))
          .toArray()
      }
    });

    startPosition = endPosition;
  }

  return newGraphData;
};

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsConsoles: Array<Component> = [
  {
    weight: 2,
    article_id: "7211033",
    length: 0,
    width: 0.3,
    label_en: "Console without spigot 0,3m",
    material: "",
    label_se: "Konsol utan spirskarv 0,3m",
    label_no: "Konsoll uten spiss 0,3m"
  },
  {
    width: 0.23,
    article_id: "7211025",
    material: "",
    label_en: "Console without spigot 0,23m",
    length: 0,
    weight: 1.6,
    label_se: "Konsol utan spirskarv 0,23m",
    label_no: "Konsoll uten spiss 0,23m"
  },
  {
    weight: 5.9,
    article_id: "7211067",
    length: 0,
    material: "",
    width: 0.7,
    label_no: "Konsoll med spiss 0,7m",
    label_se: "Konsol med spirskarv 0,7m",
    label_en: "Console with spigot 0,7m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    width: 0.6,
    article_id: "7211061",
    weight: 2.7,
    label_se: "Konsol utan spirskarv 0,6m",
    label_no: "Konsoll uten spiss 0,6m",
    length: 0,
    label_en: "Console without spigot 0,6m",
    material: ""
  },
  {
    label_no: "Konsoll uten spiss 0,46m",
    label_en: "Console without spigot 0,46m",
    weight: 2.3,
    label_se: "Konsol utan spirskarv 0,46m",
    article_id: "7211045",
    material: "",
    length: 0,
    width: 0.46
  },
  {
    weight: 2.1,
    width: 0.4,
    label_en: "Console without spigot 0,4m",
    label_no: "Konsoll uten spiss 0,4m",
    article_id: "7211041",
    length: 0,
    label_se: "Konsol utan spirskarv 0,4m",
    material: ""
  },
  {
    material: "",
    weight: 2,
    article_id: "7211035",
    label_se: "Konsol utan spirskarv 0,334m",
    label_no: "Konsoll uten spiss 0,334m",
    width: 0.334,
    length: 0,
    label_en: "Console without spigot 0,334m"
  },
  {
    length: 0,
    label_en: "Console with spigot 0,77m",
    weight: 6.5,
    label_no: "Konsoll med spiss 0,77m",
    label_se: "Konsol med spirskarv 0,77m",
    width: 0.77,
    article_id: "7211071",
    material: "",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    length: 0,
    label_en: "Console with spigot 0,564m",
    weight: 5.8,
    label_no: "Konsoll med spiss 0,564m",
    label_se: "Konsol med spirskarv 0,564m",
    width: 0.564,
    article_id: "7211051",
    material: "",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  }
];

export const componentsAnchors: Array<Component> = [
  {
    label_se: "Väggfästestag rör",
    length: 0.6,
    material: "",
    label_en: "Anchor - Tube",
    label_no: "Rörforankring",
    weight: 1.9,
    width: 0,
    article_id: "8832061"
  }
];

export const componentsULedgers: Array<Component> = [
  {
    label_en: "ERB Beam 1,05m",
    material: "",
    article_id: "7022101",
    weight: 4.4,
    length: 1.05,
    label_no: "Enrörsbjelke 1,05m",
    label_se: "Enrörsbalk 1,05",
    width: 0
  },
  {
    length: 1.655,
    label_se: "Enrörsbalk 1,655",
    material: "",
    label_en: "ERB Beam 1,655m",
    label_no: "Enrörsbjelke 1,655m",
    article_id: "7022161",
    weight: 6.3,
    width: 0
  },
  {
    width: 0,
    weight: 3.6,
    material: "",
    label_no: "Enrörsbjelke 0,77m",
    label_en: "ERB Beam 0,77m",
    label_se: "Enrörsbalk 0,77",
    length: 0.77,
    article_id: "7022073"
  },
  {
    width: 0,
    article_id: "7022191",
    label_en: "ERB Beam 1,964m",
    label_se: "Enrörsbalk 1,964",
    weight: 7.3,
    length: 1.955,
    label_no: "Enrörsbjelke 1,964m",
    material: ""
  },
  {
    label_en: "ERB Beam 0,7m",
    material: "",
    width: 0,
    weight: 3.3,
    length: 0.7,
    label_se: "Enrörsbalk 0,7",
    article_id: "7022066",
    label_no: "Enrörsbjelke 0,7m"
  },
  {
    material: "",
    label_en: "ERB Beam 3,05m",
    label_se: "Enrörsbalk 3,05",
    article_id: "7022301",
    length: 3.05,
    weight: 11.3,
    width: 0,
    label_no: "Enrörsbjelke 3,05m"
  },
  {
    label_se: "Enrörsbalk 1,25",
    label_no: "Enrörsbjelke 1,25m",
    material: "",
    weight: 5.1,
    label_en: "ERB Beam 1,25m",
    article_id: "7022121",
    width: 0,
    length: 1.25
  },
  {
    article_id: "7022253",
    width: 0,
    label_en: "ERB Beam 2,55m",
    length: 2.55,
    material: "",
    weight: 9.7,
    label_se: "Enrörsbalk 2,55",
    label_no: "Enrörsbjelke 2,55m"
  },
  {
    width: 0,
    label_no: "Enrörsbjelke 3,05m",
    length: 3.05,
    material: "",
    weight: 11.3,
    label_se: "Enrörsbalk 3,05",
    article_id: "4022301",
    label_en: "ERB Beam 3,05m"
  },
  {
    material: "",
    label_en: "ERB Beam 2,05m",
    label_se: "Enrörsbalk 2,05",
    length: 2.05,
    article_id: "7022201",
    label_no: "Enrörsbjelke 2,05m",
    width: 0,
    weight: 7.6
  },
  {
    label_se: "Enrörsbalk 2,5",
    material: "",
    label_no: "Enrörsbjelke 2,5m",
    length: 2.5,
    label_en: "ERB Beam 2,5m",
    weight: 9.9,
    article_id: "7022246",
    width: 0
  },
  {
    weight: 6.2,
    label_se: "Enrörsbalk 1,55",
    article_id: "7022153",
    material: "",
    label_no: "Enrörsbjelke 1,55m",
    label_en: "ERB Beam 1,55m",
    width: 0,
    length: 1.55
  },
  {
    label_en: "ERB Beam 0,564m",
    label_se: "Enrörsbalk 0,564",
    length: 0.564,
    width: 0,
    weight: 3.6,
    article_id: "7022050",
    material: "",
    label_no: "Enrörsbjelke 0,564m"
  }
];

export const componentsOLedgers: Array<Component> = [
  {
    length: 1.964,
    material: "",
    label_en: "LBL Beam, longitudinal 1,964m",
    label_se: "Längdbalk LBL 1,964",
    weight: 8,
    label_no: "Lengdebjelke 1,964m",
    width: 0,
    article_id: "7021192",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    label_en: "LBL Beam, longitudinal 1,655m",
    article_id: "7021162",
    label_no: "Lengdebjelke 1,655m",
    width: 0,
    label_se: "Längdbalk LBL 1,655",
    length: 1.655,
    weight: 6.7,
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    label_en: "LBL Beam, longitudinal 3,05m",
    label_se: "Längdbalk LBL 3,05",
    article_id: "7021302",
    width: 0,
    material: "",
    weight: 12.3,
    label_no: "Lengdebjelke 3,05m",
    length: 3.05,
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    label_se: "Längdbalk LBL 0,7",
    article_id: "4021066",
    label_no: "Lengdebjelke 0,7m",
    weight: 3.3,
    label_en: "LBL Beam, longitudinal 0,7m",
    length: 0.7,
    width: 0,
    material: "",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    label_en: "LBL Beam, longitudinal 1,05m",
    weight: 4.8,
    label_no: "Lengdebjelke 1,05m",
    label_se: "Längdbalk LBL 1,05",
    width: 0,
    article_id: "7021102",
    length: 1.05,
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    width: 0,
    weight: 3.6,
    label_en: "LBL Beam, longitudinal 0,77m",
    material: "",
    label_no: "Lengdebjelke 0,77m",
    length: 0.77,
    label_se: "Längdbalk LBL 0,77",
    article_id: "4021073",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    width: 0,
    label_en: "LBL Beam, longitudinal 2,5m",
    weight: 10.9,
    article_id: "7021252",
    length: 2.5,
    label_se: "Längdbalk LBL 2,5",
    label_no: "Lengdebjelke 2,5m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    label_en: "LBL Beam, longitudinal 1,25m",
    width: 0,
    label_se: "Längdbalk LBL 1,25",
    material: "",
    weight: 6.5,
    label_no: "Lengdebjelke 1,25m",
    article_id: "7021122",
    length: 1.25,
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    length: 2.05,
    material: "",
    article_id: "7021202",
    width: 0,
    label_se: "Längdbalk LBL 2,05",
    weight: 8.5,
    label_no: "Lengdebjelke 2,05m",
    label_en: "LBL Beam, longitudinal 2,05m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    label_no: "Lengdebjelke 2,55m",
    label_en: "LBL Beam, longitudinal 2,55m",
    label_se: "Längdbalk LBL 2,55",
    width: 0,
    article_id: "7021257",
    weight: 10.8,
    length: 2.55,
    material: "",
    variant: LEDGER_VARIANT.LBL_LEDGER
  }
];

export const componentsToeBoards: Array<Component> = [
  {
    material: "",
    weight: 1.9,
    article_id: "4161105",
    label_se: "Sparklist 1,05",
    length: 1.05,
    label_en: "Toe Board 1,05m",
    label_no: "Fotlist 1,05m",
    width: 0.15
  },
  {
    width: 0.15,
    material: "",
    article_id: "2640.205",
    label_en: "Toe Board 2,05m",
    weight: 3.6,
    label_no: "Fotlist 2,05m",
    label_se: "Sparklist 2,05",
    length: 2.05
  },
  {
    length: 2.55,
    material: "",
    label_en: "Toe Board 2,55m",
    label_se: "Sparklist 2,55",
    article_id: "2640.255",
    label_no: "Fotlist 2,55m",
    weight: 4.6,
    width: 0.15
  },
  {
    label_no: "Fotlist 3,05m",
    material: "",
    weight: 5.5,
    label_se: "Sparklist 3,05",
    width: 0.15,
    article_id: "2640.305",
    length: 3.05,
    label_en: "Toe Board 3,05m"
  },
  {
    article_id: "2640.155",
    label_no: "Fotlist 1,55m",
    material: "",
    label_en: "Toe Board 1,55m",
    width: 0.15,
    label_se: "Sparklist 1,55",
    weight: 2.8,
    length: 1.55
  },
  {
    article_id: "2640.1964",
    label_en: "Toe Board 1,964m",
    label_se: "Sparklist 1,964",
    weight: 3.6,
    width: 0.15,
    material: "",
    length: 1.955,
    label_no: "Fotlist 1,964m"
  },
  {
    label_se: "Sparklist 1,655",
    material: "",
    length: 1.655,
    weight: 2.9,
    width: 0.15,
    label_no: "Fotlist 1,655m",
    article_id: "2640.1655",
    label_en: "Toe Board 1,655m"
  },
  {
    label_en: "Toe Board 0,7m",
    length: 0.7,
    weight: 1.3,
    material: "",
    label_se: "Sparklist 0,7",
    label_no: "Fotlist 0,7m",
    width: 0.15,
    article_id: "4161071"
  },
  {
    label_se: "Sparklist 2,5",
    width: 0.15,
    label_en: "Toe Board 2,5m",
    weight: 4.6,
    label_no: "Fotlist 2,5m",
    material: "",
    article_id: "2640.250",
    length: 2.5
  },
  {
    length: 0.77,
    label_se: "Sparklist 0,77",
    material: "",
    width: 0.15,
    weight: 1.4,
    label_en: "Toe Board 0,77m",
    article_id: "4161077",
    label_no: "Fotlist 0,77m"
  },
  {
    label_no: "Fotlist 1,25m",
    material: "",
    article_id: "2640.125",
    label_se: "Sparklist 1,25",
    weight: 2.2,
    width: 0.15,
    length: 1.25,
    label_en: "Toe Board 1,25m"
  },
  {
    label_en: "Toe Board 1,964m",
    length: 1.955,
    label_no: "Fotlist 1,964m",
    width: 0.15,
    label_se: "Sparklist 1,964",
    material: "",
    article_id: "2640.1964",
    weight: 3.6
  }
];

export const componentsDecks: Array<Component> = [
  {
    material: SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD,
    label_en: "Composite deck 1,25",
    label_se: "Komposittrall  1,25",
    width: 0.495,
    length: 1.25,
    weight: 9.5,
    label_no: "Komposittrall  1,25",
    article_id: "nordicplatform125"
  },
  {
    weight: 10,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_DUCKBOARD,
    label_en: "Alu deck 1,655",
    label_no: "Alutrall 1,655",
    length: 1.655,
    width: 0.495,
    article_id: "2021204",
    label_se: "Alutrall 1,655"
  },
  {
    article_id: "2153164",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Alu plank 295mm wide 1,655m long",
    weight: 7.5,
    label_se: "Aluplank 295mm bred 1,655 lång",
    length: 1.655,
    width: 295,
    label_no: "Al plank 295mm bred 1,655m lang"
  },
  {
    length: 1.655,
    label_en: "Alu plank 230mm wide 1,655 long",
    label_no: "Aluplank 230mm bred 1,655 lang",
    width: 0.23,
    article_id: "2153160",
    label_se: "Aluplank 230mm bred 1,655 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.9
  },
  {
    label_en: "Alu plank 295mm wide 3,05m long",
    width: 295,
    label_se: "Aluplank 295mm bred 3,05 lång",
    article_id: "2153304",
    length: 3.05,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 12.4,
    label_no: "Al plank 295mm bred 3,05 lang"
  },
  {
    label_en: "Tree deck 1,655",
    article_id: "2021198",
    weight: 19,
    length: 1.655,
    width: 0.495,
    label_se: "Trätrall 1,655",
    label_no: "Trätrall 1,655",
    material: SCAFFOLD_PLANKTYPE.WOOD_DUCKBOARD
  },
  {
    length: 1.25,
    label_no: "Al plank 320mm bred 1,25m lang",
    label_en: "Alu plank 320mm wide 1,25m long",
    label_se: "Aluplank 320mm bred 1,25 lång",
    article_id: "2153123",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.32,
    weight: 6.4
  },
  {
    label_en: "Steel plank 230mm wide 3,05 long",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 18.1,
    label_se: "Stålplank 230mm bred 3,05 lång",
    width: 0.23,
    length: 3.05,
    label_no: "Stålplanke 230mm bred 3,05 lang",
    article_id: "21523050"
  },
  {
    width: 0.23,
    label_no: "Stålplank 230mm bred 1,25 lang",
    article_id: "21521250",
    length: 1.25,
    label_en: "Steel plank 230mm wide 1,25m long",
    weight: 7.9,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 230mm bred 1,25 lång"
  },
  {
    length: 0.7,
    label_se: "Stålplank 230mm bred 0,7 lång",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank 230mm wide 0,7m long",
    width: 0.23,
    article_id: "21520700",
    weight: 5.1,
    label_no: "Stålplank 230mm bred 0,7 lang"
  },
  {
    label_se: "Aluplank 230mm bred 2,05 lång",
    length: 2.05,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 8.1,
    width: 0.23,
    label_en: "Alu plank 230mm wide 2,05 long",
    article_id: "2158205",
    label_no: "Al plank 230mm bred 2,05 lang"
  },
  {
    label_en: "Alu plank 230mm wide 0,7 long",
    length: 0.7,
    width: 0.23,
    label_se: "Aluplank 230mm bred 0,7 lång",
    article_id: "2158070",
    label_no: "Aluplank 230mm bred 0,7 lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.7
  },
  {
    length: 3.05,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    width: 0.4,
    label_se: "Krokplan 400mm bred 3,05 lång",
    weight: 18.5,
    label_no: "Krokplan 400mm bred 3,05 lang",
    article_id: "4073302",
    label_en: "Decking Unit light 400mm wide 3,05m long"
  },
  {
    label_no: "Krokplan 400mm bred 1,25 lang",
    width: 0.4,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    weight: 8.5,
    article_id: "4073122",
    label_en: "Decking Unit light 400mm wide 1,25m long",
    length: 1.25,
    label_se: "Krokplan 400mm bred 1,25 lång"
  },
  {
    label_no: "Krokplan 600mm bred 0,7 lang",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_se: "Krokplan 600mm bred 0,7 lång",
    width: 0.6,
    article_id: "4071072",
    label_en: "Decking Unit light 600mm wide 0,7m long",
    length: 0.7,
    weight: 6.6
  },
  {
    weight: 14.2,
    article_id: "Trall1950",
    label_no: "Sydtrall 1,655",
    material: SCAFFOLD_PLANKTYPE.WOOD_SOUTH_DUCKBOARD,
    length: 1.655,
    label_se: "Sydtrall 1,655",
    width: 0.495,
    label_en: "Syd deck 1,655"
  },
  {
    label_en: "Decking Unit light 600mm wide 0,77m long",
    length: 0.77,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_no: "Krokplan 600mm bred 0,77 lang",
    weight: 7.3,
    article_id: "4071077",
    width: 0.6,
    label_se: "Krokplan 600mm bred 0,77 lång"
  },
  {
    weight: 4.1,
    article_id: "2153080",
    length: 0.77,
    label_no: "Aluplank 230mm bred 0,77 lang",
    label_se: "Aluplank 230mm bred 0,77 lång",
    width: 0.23,
    label_en: "Alu plank 230mm wide 0,77 long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_en: "Decking Unit light 400mm wide 2,05m long",
    length: 2.05,
    weight: 12.4,
    article_id: "4073202",
    label_se: "Krokplan 400mm bred 2,05 lång",
    width: 0.4,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_no: "Krokplan 400mm bred 2,05 lang"
  },
  {
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_en: "Decking Unit light 600mm wide 1,25m long",
    weight: 10.6,
    label_se: "Krokplan 600mm bred 1,25 lång",
    label_no: "Krokplan 600mm bred 1,25 lang",
    length: 1.25,
    article_id: "4071122",
    width: 0.6
  },
  {
    label_no: "Komposittrall 1,655",
    label_en: "Composite deck 1,655",
    length: 1.655,
    weight: 11.5,
    article_id: "nordicplatform1655",
    material: SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD,
    label_se: "Komposittrall 1,655",
    width: 0.495
  },
  {
    label_no: "Al plank 230mm bred 1,05 lang",
    width: 0.23,
    label_se: "Aluplank 230mm bred 1,05 lång",
    length: 1.05,
    label_en: "Alu plank 230mm wide 1,05 long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.9,
    article_id: "2153100"
  },
  {
    width: 0.4,
    label_no: "Krokplan 400mm bred 0,77 lang",
    length: 0.77,
    article_id: "4073077",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_en: "Decking Unit light 400mm wide 0,77m long",
    label_se: "Krokplan 400mm bred 0,77 lång",
    weight: 5.2
  },
  {
    width: 0.2,
    article_id: "21511550",
    label_se: "Stålplank 200mm bred 1,55 lång",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8.9,
    label_no: "Stålplanke 200mm bred 1,55 lang",
    label_en: "Steel plank 200mm wide 1,55 long",
    length: 1.55
  },
  {
    label_no: "Sydtrall 1,25",
    width: 0.495,
    label_en: "Syd deck 1,25",
    material: SCAFFOLD_PLANKTYPE.WOOD_SOUTH_DUCKBOARD,
    length: 1.25,
    weight: 10,
    label_se: "Sydtrall 1,25",
    article_id: "Trall1400"
  },
  {
    label_no: "Krokplan 400mm bred 1,55 lang",
    length: 1.55,
    width: 0.4,
    label_se: "Krokplan 400mm bred 1,55 lång",
    article_id: "4073152",
    weight: 10,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_en: "Decking Unit light 400mm wide 1,55m long"
  },
  {
    label_se: "Aluplank 230mm bred 1,25 lång",
    width: 0.23,
    weight: 5.6,
    label_no: "Aluplank 230mm bred 1,25 lang",
    label_en: "Alu plank 230mm wide 1,25 long",
    article_id: "2153120",
    length: 1.25,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_en: "Alu plank 320mm wide 3,05m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 13.1,
    label_se: "Aluplank 320mm bred 3,05 lång",
    width: 0.32,
    label_no: "Al plank 320mm bred 3,05 lang",
    article_id: "2153303",
    length: 3.05
  },
  {
    weight: 24.6,
    article_id: "4071302",
    width: 0.6,
    length: 3.05,
    label_en: "Decking Unit light 600mm wide 3,05m long",
    label_no: "Krokplan 600mm bred 3,05 lang",
    label_se: "Krokplan 600mm bred 3,05 lång",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE
  },
  {
    width: 0.6,
    label_no: "Krokplan 600mm bred 2,55 lang",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    weight: 20,
    label_se: "Krokplan 600mm bred 2,55 lång",
    article_id: "4071257",
    length: 2.55,
    label_en: "Decking Unit light 600mm wide 2,55m long"
  },
  {
    article_id: "2153300",
    length: 3.05,
    label_en: "Alu plank 230mm wide 3,05 long",
    width: 0.23,
    weight: 11.2,
    label_se: "Aluplank 230mm bred 3,05 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Al plank 230mm bred 3,05 lang"
  },
  {
    label_no: "Al plank 295mm bred 1,05m lang",
    label_en: "Alu plank 295mm wide 1,05m long",
    label_se: "Aluplank 295mm bred 1,05 lång",
    article_id: "2153104",
    width: 295,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.5,
    length: 1.05
  },
  {
    width: 0.32,
    weight: 5.7,
    article_id: "2153103",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Alu plank 320mm wide 1,05m long",
    label_no: "Al plank 320mm bred 1,05m lang",
    label_se: "Aluplank 320mm bred 1,05 lång",
    length: 1.05
  },
  {
    width: 0.495,
    article_id: "nordicplatform1955",
    length: 1.955,
    weight: 11.5,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD,
    label_no: "Komposittrall 1,955",
    label_en: "Composite deck 1,955",
    label_se: "Komposittrall 1,955"
  },
  {
    width: 0.2,
    label_se: "Stålplank 200mm bred 1,05 lång",
    weight: 6.4,
    length: 1.05,
    label_en: "Steel plank 200mm wide 1,05 long",
    article_id: "21511050",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplanke 200mm bred 1,05 lang"
  },
  {
    label_se: "Stålplank 230mm bred 2,5 lång",
    length: 2.5,
    article_id: "21522500",
    label_en: "Steel plank 230mm wide 2,5m long",
    weight: 15.2,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank 230mm bred 2,5 lang",
    width: 0.23
  },
  {
    label_se: "Stålplank 200mm bred 2,05 lång",
    width: 0.2,
    label_no: "Stålplanke 200mm bred 2,05 lang",
    length: 2.05,
    weight: 11.5,
    article_id: "21512050",
    label_en: "Steel plank 200mm wide 2,05 long",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_en: "Alu plank 295mm wide 1,25m long",
    length: 1.25,
    label_no: "Al plank 295mm bred 1,25m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 295,
    weight: 6.1,
    label_se: "Aluplank 295mm bred 1,25 lång",
    article_id: "2153124"
  },
  {
    weight: 12.5,
    label_en: "Steel deck 1,25",
    label_no: "Ståltrall 1,25",
    length: 1.25,
    article_id: "2152124",
    width: 0.495,
    material: SCAFFOLD_PLANKTYPE.STEEL_DUCKBOARD,
    label_se: "Ståltrall 1,25"
  },
  {
    label_no: "Stålplanke 230mm bred 2,55 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 230mm bred 2,55 lång",
    label_en: "Steel plank 230mm wide 2,55 long",
    length: 2.55,
    weight: 15.2,
    width: 0.23,
    article_id: "21522550"
  },
  {
    label_no: "Ståltrall 1,655",
    weight: 15,
    material: SCAFFOLD_PLANKTYPE.STEEL_DUCKBOARD,
    label_se: "Ståltrall 1,655",
    length: 1.655,
    label_en: "Steel deck 1,655",
    width: 0.495,
    article_id: "2152164"
  },
  {
    label_en: "Decking Unit light 400mm wide 2,55m long",
    label_se: "Krokplan 400mm bred 2,55 lång",
    width: 0.4,
    length: 2.55,
    article_id: "4073257",
    weight: 16.5,
    label_no: "Krokplan 400mm bred 2,55 lang",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE
  },
  {
    label_se: "Stålplank 200mm bred 3,05 lång",
    label_no: "Stålplanke 200mm bred 3,05 lang",
    width: 0.2,
    label_en: "Steel plank 200mm wide 3,05 long",
    length: 3.05,
    article_id: "21513050",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 17
  },
  {
    label_se: "Krokplan 400mm bred 2,5 lång",
    weight: 16.1,
    label_no: "Krokplan 400mm bred 2,5 lang",
    label_en: "Decking Unit light 400mm wide 2,5m long",
    length: 2.5,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    width: 0.4,
    article_id: "4073252"
  },
  {
    width: 0.32,
    length: 0.77,
    label_se: "Aluplank 320mm bred 0,77 lång",
    article_id: "2153077",
    label_en: "Alu plank 320mm wide 0,77m long",
    weight: 4.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Al plank 320mm bred 0,77m lang"
  },
  {
    label_en: "Alu plank 320mm wide 1,964m long",
    article_id: "2153193",
    label_no: "Al plank 320mm bred 1,964m lang",
    weight: 9,
    width: 0.32,
    length: 1.964,
    label_se: "Aluplank 320mm bred 1,964 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_en: "Alu plank 230mm wide 2,55 long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.23,
    label_se: "Aluplank 230mm bred 2,55 lång",
    length: 2.55,
    weight: 9.7,
    article_id: "2158255",
    label_no: "Al plank 230mm bred 2,55 lang"
  },
  {
    label_se: "Aluplank 230mm bred 1,55 lång",
    width: 0.23,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Alu plank 230mm wide 1,55 long",
    label_no: "Al plank 230mm bred 1,55 lang",
    weight: 6.5,
    length: 1.55,
    article_id: "2158150"
  },
  {
    label_se: "Aluplank 295mm bred 0,77 lång",
    article_id: "2153078",
    label_no: "Al plank 295mm bred 0,77m lang",
    length: 0.77,
    label_en: "Alu plank 295mm wide 0,77m long",
    width: 295,
    weight: 4.4,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    weight: 10.5,
    article_id: "2153254",
    width: 295,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Alu plank 295mm wide 2,55m long",
    label_se: "Aluplank 295mm bred 2,55 lång",
    length: 2.55,
    label_no: "Al plank 295mm bred 2,55 lang"
  },
  {
    label_en: "Alu plank 295mm wide 1,55m long",
    length: 1.55,
    width: 295,
    weight: 7.5,
    article_id: "2153164",
    label_se: "Aluplank 295mm bred 1,55 lång",
    label_no: "Al plank 295mm bred 1,55m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    weight: 14,
    width: 0.495,
    label_no: "Trätrall 1,25",
    label_en: "Tree deck 1,25",
    length: 1.25,
    label_se: "Trätrall 1,25",
    material: SCAFFOLD_PLANKTYPE.WOOD_DUCKBOARD,
    article_id: "2021150"
  },
  {
    length: 1.964,
    article_id: "2153194",
    label_en: "Alu plank 295mm wide 1,964m long",
    label_se: "Aluplank 295mm bred 1,964 lång",
    width: 295,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Al plank 295mm bred 1,964m lang",
    weight: 8.6
  },
  {
    width: 0.4,
    length: 1.05,
    label_en: "Decking Unit light 400mm wide 1,05m long",
    weight: 7.1,
    label_no: "Krokplan 400mm bred 1,05 lang",
    article_id: "4073102",
    label_se: "Krokplan 400mm bred 1,05 lång",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE
  },
  {
    label_se: "Aluplank 320mm bred 1,55 lång",
    article_id: "2153163",
    length: 1.55,
    width: 0.32,
    weight: 7.9,
    label_no: "Al plank 320mm bred 1,55m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Alu plank 320mm wide 1,55m long"
  },
  {
    width: 0.4,
    label_no: "Krokplan 400mm bred 1,964 lang",
    weight: 12,
    label_se: "Krokplan 400mm bred 1,964 lång",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    article_id: "4073192",
    length: 1.964,
    label_en: "Decking Unit light 400mm wide 1,964m long"
  },
  {
    weight: 8.1,
    label_no: "Aluplank 230mm bred 2,05 lang",
    label_en: "Alu plank 230mm wide 2,05 long",
    article_id: "2153190",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.23,
    label_se: "Aluplank 230mm bred 2,05 lång",
    length: 1.964
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplanke 230mm bred 1,55 lang",
    length: 1.55,
    width: 0.23,
    article_id: "21521550",
    label_se: "Stålplank 230mm bred 1,55 lång",
    label_en: "Steel plank 230mm wide 1,55 long",
    weight: 9.9
  },
  {
    length: 1.05,
    label_no: "Ståltrall 1,05",
    width: 0.495,
    label_en: "Steel deck 1,05",
    article_id: "21521050",
    weight: 10.8,
    label_se: "Ståltrall 1,05",
    material: SCAFFOLD_PLANKTYPE.STEEL_DUCKBOARD
  },
  {
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    length: 2.5,
    width: 0.6,
    label_en: "Decking Unit light 600mm wide 2,5m long",
    weight: 20,
    label_no: "Krokplan 600mm bred 2,5 lang",
    label_se: "Krokplan 600mm bred 2,5 lång",
    article_id: "4071257"
  },
  {
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    length: 1.55,
    weight: 12.8,
    label_se: "Krokplan 600mm bred 1,55 lång",
    label_en: "Decking Unit light 600mm wide 1,55m long",
    label_no: "Krokplan 600mm bred 1,55 lang",
    article_id: "4071152"
  },
  {
    label_no: "Stålplanke 230mm bred 1,05 lang",
    label_se: "Stålplank 230mm bred 1,05 lång",
    label_en: "Steel plank 230mm wide 1,05 long",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.23,
    length: 1.05,
    weight: 6.9,
    article_id: "21521050"
  },
  {
    label_se: "Aluplank 295mm bred 0,7 lång",
    article_id: "2153078",
    label_no: "Al plank 295mm bred 0,7m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 295,
    label_en: "Alu plank 295mm wide 0,7m long",
    length: 0.7,
    weight: 4.4
  },
  {
    width: 0.4,
    label_no: "Krokplan 400mm bred 1,655 lang",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_se: "Krokplan 400mm bred 1,655 lång",
    weight: 10.5,
    length: 1.655,
    label_en: "Decking Unit light 400mm wide 1,655m long",
    article_id: "4073162"
  },
  {
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_se: "Krokplan 600mm bred 1,964 lång",
    length: 1.964,
    label_en: "Decking Unit light 600mm wide 1,964m long",
    article_id: "4071192",
    weight: 17.3,
    width: 0.6,
    label_no: "Krokplan 600mm bred 1,964 lang"
  },
  {
    label_no: "Al plank 320mm bred 1,655m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 7.9,
    label_en: "Alu plank 320mm wide 1,655m long",
    article_id: "2153163",
    length: 1.655,
    width: 0.32,
    label_se: "Aluplank 320mm bred 1,655 lång"
  },
  {
    label_no: "Aluplank 230mm bred 2,55 lang",
    label_en: "Alu plank 230mm wide 2,55 long",
    label_se: "Aluplank 230mm bred 2,55 lång",
    length: 2.5,
    article_id: "2158255",
    width: 0.23,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 9.7
  },
  {
    article_id: "4073072",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_se: "Krokplan 400mm bred 0,7 lång",
    label_en: "Decking Unit light 400mm wide 0,7m long",
    weight: 4.7,
    label_no: "Krokplan 400mm bred 0,7 lang",
    width: 0.4,
    length: 0.7
  },
  {
    article_id: "2153078",
    width: 0.32,
    length: 0.7,
    weight: 4.6,
    label_se: "Aluplank 320mm bred 0,7 lång",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Al plank 320mm bred 0,7m lang",
    label_en: "Alu plank 320mm wide 0,7m long"
  },
  {
    width: 0.23,
    label_en: "Steel plank 230mm wide 1,964m long",
    length: 1.964,
    label_no: "Stålplank 230mm bred 1,964 lang",
    article_id: "21521964",
    weight: 11.8,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 230mm bred 1,964 lång"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.5,
    label_se: "Aluplank 295mm bred 2,5 lång",
    label_no: "Al plank 295mm bred 2,5m lang",
    label_en: "Alu plank 295mm wide 2,5m long",
    width: 295,
    weight: 10.5,
    article_id: "2153254"
  },
  {
    label_se: "Stålplank 230mm bred 2,05 lång",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank 230mm wide 2,05 long",
    width: 0.23,
    article_id: "21522050",
    length: 2.05,
    label_no: "Stålplanke 230mm bred 2,05 lang",
    weight: 12.2
  },
  {
    label_en: "Steel plank 230mm wide 1,655m long",
    width: 0.23,
    label_no: "Stålplank 230mm bred 1,655 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 1.655,
    article_id: "21521655",
    weight: 10.1,
    label_se: "Stålplank 230mm bred 1,655 lång"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 200mm bred 2,55 lång",
    width: 0.2,
    label_en: "Steel plank 200mm wide 2,55 long",
    article_id: "21512550",
    label_no: "Stålplanke 200mm bred 2,55 lang",
    length: 2.55,
    weight: 14.3
  },
  {
    label_no: "Krokplan 600mm bred 1,05 lang",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    label_se: "Krokplan 600mm bred 1,05 lång",
    weight: 9.1,
    length: 1.05,
    width: 0.6,
    article_id: "4071102",
    label_en: "Decking Unit light 600mm wide 1,05m long"
  },
  {
    label_se: "Alutrall 1,25",
    article_id: "2021144",
    weight: 7.7,
    width: 0.495,
    label_no: "Alutrall 1,25",
    length: 1.25,
    label_en: "Alu deck 1,25",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_DUCKBOARD
  },
  {
    width: 0.32,
    length: 2.55,
    article_id: "2153254",
    label_se: "Aluplank 320mm bred 2,55 lång",
    weight: 11,
    label_en: "Alu plank 320mm wide 2,55m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Al plank 320mm bred 2,55m lang"
  },
  {
    width: 0.495,
    weight: 7.5,
    label_en: "Composite deck 1,4",
    label_se: "Komposittrall 1,4",
    label_no: "Komposittrall 1,4",
    article_id: "nordicplatform140",
    length: 1.4,
    material: SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD
  },
  {
    weight: 5.3,
    label_se: "Stålplank 230mm bred 0,77 lång",
    article_id: "21520770",
    length: 0.77,
    label_en: "Steel plank 230mm wide 0,77m long",
    label_no: "Stålplank 230mm bred 0,77 lang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.23
  },
  {
    label_no: "Krokplan 600mm bred 2,05 lang",
    article_id: "4071202",
    label_en: "Decking Unit light 600mm wide 2,05m long",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    width: 0.6,
    weight: 17.3,
    label_se: "Krokplan 600mm bred 2,05 lång",
    length: 2.05
  },
  {
    label_en: "Decking Unit light 600mm wide 1,655m long",
    label_se: "Krokplan 600mm bred 1,655 lång",
    material: SCAFFOLD_PLANKTYPE.HOOKPLANE,
    length: 1.655,
    width: 0.6,
    label_no: "Krokplan 600mm bred 1,655 lang",
    weight: 13.5,
    article_id: "4071162"
  }
];

export const componentsBaseplates: Array<Component> = [
  {
    article_id: "2071000",
    width: 0,
    length: 0,
    weight: 5,
    label_no: "Bunnskrue",
    material: "",
    label_se: "Universalskruv",
    label_en: "Universal screw"
  }
];

export const componentsGuardRails: Array<Component> = [
  {
    label_en: "Guardrail Frame GFL 0,77",
    label_se: "Skyddräckesram GFL 0,77",
    weight: 4,
    length: 0.77,
    article_id: "7052077",
    material: "",
    label_no: "Rekkverksramme GFL 0,7",
    width: 0,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    article_id: "7052254",
    width: 0,
    label_se: "Skyddsräckesram GFL 2,5",
    length: 2.5,
    label_no: "Rekkverksramme GFL 2,5",
    weight: 9.2,
    label_en: "Guardrail Frame GFL 2,5",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 1.655,
    weight: 7.4,
    label_se: "Skyddsräckesram GFL 1,655",
    label_en: "Guardrail Frame GFL 1,655",
    label_no: "Rekkverksramme GFL 1,655",
    article_id: "7052164",
    material: "",
    width: 0,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    label_en: "Guardrail Frame GFL 1,964",
    article_id: "7052194",
    width: 0,
    label_se: "Skyddsräckesram GFL 1,964",
    weight: 8.1,
    length: 1.955,
    label_no: "Rekkverksramme GFL 1,964",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_en: "Guardrail Frame GFL 2,05",
    length: 2.05,
    label_se: "Skyddsräckesram GFL 2,05",
    article_id: "7052204",
    label_no: "Rekkverksramme GFL 2,05",
    weight: 8.2,
    material: "",
    width: 0,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    weight: 6.6,
    material: "",
    length: 1.55,
    label_se: "Skyddsräckesram GFL 1,55",
    width: 0,
    label_en: "Guardrail Frame GFL 1,55",
    article_id: "7052154",
    label_no: "Rekkverksramme GFL 1,55",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 2.55,
    label_en: "Guardrail Frame GFL 2,55",
    article_id: "7052255",
    label_se: "Skyddsräckesram GFL 2,55",
    width: 0,
    label_no: "Rekkverksramme GFL 2,55",
    weight: 9.3,
    material: "",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_no: "Rekkverksramme GFL 1,25",
    label_en: "Guardrail Frame GFL 1,25",
    length: 1.25,
    weight: 5.7,
    article_id: "7052124",
    label_se: "Skyddsräckesram GFL 1,25",
    width: 0,
    material: "",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 1.05,
    article_id: "7052106",
    label_en: "Guardrail Frame GFL 1,05",
    width: 0,
    weight: 4.9,
    material: "",
    label_no: "Rekkverksramme GFL 1,05",
    label_se: "Skyddsräckesram GFL 1,05",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    article_id: "7052304",
    width: 0,
    weight: 10.5,
    label_se: "Skyddsräckesram GFL 3,05",
    label_no: "Rekkverksramme GFL 3,05",
    material: "",
    length: 3.05,
    label_en: "Guardrail Frame GFL 3,05",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    article_id: "7052077",
    weight: 4,
    width: 0,
    label_se: "Skyddräckesram GFL 0,77",
    length: 0.77,
    label_en: "Guardrail Frame GFL 0,77",
    label_no: "Rekkverksramme GFL 0,77",
    material: "",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_no: "Rekkverksramme GFL 0,7",
    label_se: "Skyddräckesram GFL 0,7",
    label_en: "Guardrail Frame GFL 0,7",
    weight: 3.8,
    material: "",
    width: 0,
    article_id: "7052070",
    length: 0.7,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  }
];

export const componentsStandards: Array<Component> = [
  {
    weight: 10.1,
    width: 0,
    article_id: "7016200",
    label_en: "Standard, 2m",
    label_no: "Spire, 2m",
    label_se: "Spira, 2m",
    material: "",
    length: 2
  },
  {
    label_se: "Spira 0.5m",
    weight: 2.9,
    material: "",
    article_id: "7016050",
    length: 0.5,
    label_no: "Spire, 0.5m",
    width: 0,
    label_en: "Standard, 0.5m"
  },
  {
    width: 0,
    article_id: "7016300",
    label_en: "Standard, 3m",
    material: "",
    label_no: "Spire, 3m",
    length: 3,
    weight: 15.2,
    label_se: "Spira, 3m"
  },
  {
    article_id: "7016150",
    material: "",
    length: 1.5,
    label_no: "Vertikalspira 1.5m",
    label_en: "Vertikalspira 1.5m",
    label_se: "Vertikalspira 1.5m",
    width: 0,
    weight: 7.7
  },
  {
    material: "",
    label_no: "Spire, 1m",
    article_id: "7016100",
    label_en: "Standard,1m",
    weight: 5.3,
    width: 0,
    length: 1,
    label_se: "Spira, 1m"
  }
];

export const componentsStairwayGuardrailPosts: Array<Component> = [
  {
    weight: 6.1,
    label_en: "Stair guardrail post",
    width: 0,
    material: "",
    label_no: "Rekkverkstolpe for trapp",
    article_id: "2638.400",
    length: 1.3,
    label_se: "Trappräckesstolpe"
  }
];

export const componentsStairwayGuardrails: Array<Component> = [
  {
    label_se: "Trappräcke 2,500",
    width: 0,
    material: "",
    weight: 9.2,
    label_en: "Stairway guardrail 2,500",
    label_no: "Trapperekkverk 2,500",
    article_id: "4058245",
    length: 2.5,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER,
    height: 2
  },
  {
    label_en: "Stairway guardrail 3,050",
    label_no: "Trapperekkverk 3,050",
    weight: 10.3,
    label_se: "Trappräcke 3,050",
    material: "",
    width: 0,
    length: 3.05,
    article_id: "4058300",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER,
    height: 2
  }
];

export const componentsStairwayGuardrailsInternal: Array<Component> = [
  {
    label_se: "Trappräcke inner 2,500",
    width: 0,
    material: "",
    weight: 10.4,
    label_en: "Stairway inner guardrail 2,500",
    label_no: "Trapperekkverk indre 2,500",
    article_id: "7058256",
    length: 2.5,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  },
  {
    label_se: "Trappräcke inner 3,500",
    width: 0,
    material: "",
    weight: 11.4,
    label_en: "Stairway inner guardrail 3,500",
    label_no: "Trapperekkverk indre 3,500",
    article_id: "7058253",
    length: 3.05,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsStairs: Array<Component> = [
  {
    length: 3.05,
    label_en: "Stairway 3,050",
    width: 0.6,
    material: "",
    weight: 29.2,
    label_se: "Trappa 3,050",
    label_no: "Trapp 3,050",
    article_id: "4102302",
    height: 2
  },
  {
    label_en: "Stairway 2,500",
    label_no: "Trapp 2,500",
    label_se: "Trappa 2,500",
    material: "",
    article_id: "4102247",
    weight: 22.9,
    width: 0.6,
    length: 2.5,
    height: 2
  }
];

export const componentsStartStairs: Array<Component> = [
  {
    label_en: "UTV 500 Stairway AL",
    label_no: "UTV 500 Trapp AL",
    label_se: "UTV 500 Trappa AL",
    material: "",
    article_id: "4102050",
    weight: 5.5,
    width: 0.6,
    length: 1.2,
    height: 0.5
  },
  {
    label_en: "UTV 1000 Stairway AL",
    label_no: "UTV 1000 Trapp AL",
    label_se: "UTV 1000 Trappa AL",
    material: "",
    article_id: "4102100",
    weight: 9,
    width: 0.6,
    length: 1.5,
    height: 1
  },
  {
    label_en: "UTV 1500 Stairway AL",
    label_no: "UTV 1500 Trapp AL",
    label_se: "UTV 1500 Trappa AL",
    material: "",
    article_id: "4102150",
    weight: 11.3,
    width: 0.6,
    length: 1.7,
    height: 1.5
  }
];

export const componentsDiagonalBraces: Array<Component> = [
  /** Diagonal braces 2 meter height */
  {
    weight: 10.1,
    article_id: "7122160",
    material: "",
    label_en: "Diagonal Brace 1,55x2m",
    label_no: "Diagonalstag 1,55x2m",
    width: 0,
    length: 1.55,
    label_se: "Diagonalstag 1,55x2m"
  },
  {
    article_id: "7121250",
    label_no: "Diagonalstag 2,5x2m",
    label_se: "Diagonalstag 2,5x2m",
    length: 2.5,
    material: "",
    weight: 12.3,
    label_en: "Diagonal Brace 2,5x2m",
    width: 0
  },
  {
    width: 0,
    material: "",
    label_en: "Diagonal Brace 0,77x2m",
    weight: 8.5,
    article_id: "7122070",
    label_se: "Diagonalstag 0,77x2m",
    length: 0.77,
    label_no: "Diagonalstag 0,77x2m"
  },
  {
    label_se: "Diagonalstag 3,05x2m",
    label_en: "Diagonal Brace 3,05x2m",
    label_no: "Diagonalstag 3,05x2m",
    material: "",
    length: 3.05,
    width: 0,
    weight: 14.2,
    article_id: "7121300"
  },
  {
    width: 0,
    label_en: "Diagonal Brace 0,7x2m",
    label_no: "Diagonalstag 0,7x2m",
    length: 0.7,
    article_id: "7122070",
    weight: 8.5,
    label_se: "Diagonalstag 0,7x2m",
    material: ""
  },
  {
    label_no: "Diagonalstag 1,05x2m",
    length: 1.05,
    weight: 8.5,
    material: "",
    width: 0,
    label_se: "Diagonalstag 1,05x2m",
    article_id: "7122120",
    label_en: "Diagonal Brace 1,05x2m"
  },
  {
    length: 1.25,
    label_en: "Diagonal Brace 1,25x2m",
    width: 0,
    label_no: "Diagonalstag 1,25x2m",
    weight: 9,
    article_id: "7122120",
    material: "",
    label_se: "Diagonalstag 1,25x2m"
  },
  {
    article_id: "7122190",
    label_en: "Diagonal Brace 1,964x2m",
    label_no: "Diagonalstag 1,964x2m",
    material: "",
    width: 0,
    label_se: "Diagonalstag 1,964x2m",
    length: 1.964,
    weight: 10.7
  },
  {
    weight: 9,
    label_en: "Diagonal Brace 1,25x2m",
    article_id: "7122120",
    label_no: "Diagonalstag 1,25x2m",
    material: "",
    label_se: "Diagonalstag 1,25x2m",
    length: 1.25,
    width: 2
  },
  {
    length: 2.05,
    material: "",
    article_id: "7122190",
    label_se: "Diagonalstag 2,05x2m",
    weight: 10.7,
    width: 0,
    label_no: "Diagonalstag 2,05x2m",
    label_en: "Diagonal Brace 2,05x2m"
  },
  {
    label_en: "Diagonal Brace 2,55x2m",
    label_se: "Diagonalstag 2,55x2m",
    material: "",
    label_no: "Diagonalstag 2,55x2m",
    width: 0,
    weight: 12.3,
    length: 2.55,
    article_id: "7121250"
  },
  {
    label_se: "Diagonalstag 1,655x2m",
    width: 0,
    label_no: "Diagonalstag 1,655x2m",
    label_en: "Diagonal Brace 1,655x2m",
    material: "",
    article_id: "7122160",
    weight: 10.1,
    length: 1.655
  },
  {
    material: "",
    label_en: "Diagonal Brace 1,25x2m",
    label_se: "Diagonalstag 1,25x2m",
    length: 1.25,
    label_no: "Diagonalstag 1,25x2m",
    weight: 9,
    width: 2,
    article_id: "7122120"
  }
];

export const componentsPlasticSheets: Array<Component> = [
  {
    label_no: "Forsterket plastfolie 40 x 3.2m",
    material: "",
    length: 40,
    weight: 32,
    width: 3.2,
    article_id: "2034045",
    label_en: "Reinforced plastic sheet 40 x 3.2m",
    label_se: "Armerad plastduk 40 x 3.2m"
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    length: 0.2,
    width: 0,
    label_se: "Spirhållare med låsskruv",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "7208018",
    label_en: "Beam Spigot",
    weight: 3.9
  },
  {
    length: 0.2,
    width: 0,
    label_se: "Spirhållare med låssprint",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "7208025",
    label_en: "Beam Spigot",
    weight: 2.3
  },
  {
    length: 0.2,
    width: 0,
    label_se: "Spirhållare S4 AL",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "4208002",
    label_en: "Beam Spigot",
    weight: 1.7
  },
  {
    length: 0.2,
    width: 0,
    label_se: "Balkryttare",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "4208020",
    label_en: "Beam Spigot",
    weight: 1.1
  }
];
export const componentsCouplers: Array<Component> = [
  {
    label_no: "Kobling Fast",
    label_se: "Koppling fast",
    article_id: "7164000",
    material: "",
    length: 0.07,
    label_en: "Coupler Stiff",
    weight: 1.1,
    width: 0
  },
  {
    label_no: "Kobling fast, nøkkelbredde 22",
    label_se: "Koppling fast, nyckelvidd 22",
    article_id: "2048010",
    material: "",
    length: 0.07,
    label_en: "Coupler stiff, key width 22 ",
    weight: 1.2,
    width: 0
  },
  {
    label_no: "Kobling fast, nøkkelbredde 23",
    label_se: "Koppling fast Nyckelvidd 23",
    article_id: "2048012",
    material: "",
    length: 0.07,
    label_en: "Coupler stiff, key width 23",
    weight: 1.2,
    width: 0
  },
  {
    label_no: "Kobling variabel, nøkkelbredde 22",
    label_se: "Koppling variabel Nyckelvidd 22",
    article_id: "2048011",
    material: "",
    length: 0.07,
    label_en: "Coupler variable, key width 22",
    weight: 1.4,
    width: 0
  },
  {
    label_no: "Kobling variabel, nøkkelbredde 23",
    label_se: "Koppling variabel Nyckelvidd 23",
    article_id: "2048013",
    material: "",
    length: 0.07,
    label_en: "Coupler variable, key width 23",
    weight: 1.4,
    width: 0
  }
];
export const componentsScrews: Array<Component> = [
  {
    material: "",
    article_id: "-",
    label_no: "Forankringsløkke",
    label_en: "Ring screw",
    label_se: "Förankringsögla",
    weight: 0.16,
    length: 0.095,
    width: 0
  }
];

import { Stack } from "@mui/material";
import { APP_MODES } from "enums/pages";
import { ROUTE } from "enums/routing";
import SessionManager from "managers/SessionManager";
import InfoOverlay from "pages/sharedComponents/InfoOverlay";
import LoadingOverlay from "pages/sharedComponents/LoadingOverlay";
import { Suspense, lazy, ReactElement } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loading from "ui/core/Loading";

const ActionPage = lazy(
  () => import(/* webpackChunkName: "ActionPage" */ "pages/ActionPage")
);
const HomePage = lazy(
  () => import(/* webpackChunkName: "HomePage" */ "pages/HomePage")
);
const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ "pages/LoginPage")
);
const ProfilePage = lazy(
  () => import(/* webpackChunkName: "ProfilePage" */ "pages/ProfilePage")
);
const ProjectViewPage = lazy(
  () =>
    import(/* webpackChunkName: "ProjectViewPage" */ "pages/ProjectViewPage")
);
const ProjectsPage = lazy(
  () => import(/* webpackChunkName: "ProjectsPage" */ "pages/ProjectsPage")
);
const ScaffoldPage = lazy(
  () => import(/* webpackChunkName: "ScaffoldPage" */ "pages/ScaffoldPage")
);
const ScaffoldViewPage = lazy(
  () =>
    import(/* webpackChunkName: "ScaffoldViewPage" */ "pages/ScaffoldViewPage")
);
const SnapshotPage = lazy(
  () => import(/* webpackChunkName: "SnapshotPage" */ "pages/SnapshotPage")
);
const SuperAdminPage = lazy(
  () => import(/* webpackChunkName: "SuperAdminPage" */ "pages/SuperAdminPage")
);
// const TrsPage = lazy(
//   () => import(/* webpackChunkName: "TrsPage" */ "pages/TrsPage")
// );
const TrsViewPage = lazy(
  () => import(/* webpackChunkName: "TrsViewPage" */ "pages/TrsViewPage")
);

const TutorialPage = lazy(
  () => import(/* webpackChunkName: "TutorialPage" */ "pages/TutorialPage")
);

const LazyLoadPage = (props: { page: JSX.Element }): ReactElement => {
  const { page } = props;
  return (
    <Suspense
      fallback={
        <Stack height={"100dvh"} width={"100dvw"} justifyContent={"center"}>
          <Loading />
        </Stack>
      }
    >
      {page}
    </Suspense>
  );
};
const ProjectsPages = () => {
  return (
    <Routes>
      <Route
        path={ROUTE.NEW}
        element={<LazyLoadPage page={<ProjectsPage />} />}
      />
      <Route
        path={ROUTE.ID_PARAM}
        element={<LazyLoadPage page={<ProjectsPage />} />}
      />
      <Route
        path={ROUTE.ROOT}
        element={<LazyLoadPage page={<ProjectsPage />} />}
      />
    </Routes>
  );
};

const SharePages = () => {
  return (
    <Routes>
      <Route
        path={ROUTE.SHARE_PROJECT_PROJECTID}
        element={<LazyLoadPage page={<ProjectViewPage />} />}
      />
      <Route
        path={ROUTE.SHARE_PROJECT_PROJECTID_SCAFFOLDS_SCAFFOLDID}
        element={
          <LazyLoadPage
            page={<ScaffoldViewPage appMode={APP_MODES.SHARED} />}
          />
        }
      />
      <Route
        path={ROUTE.SHARE_PROJECT_PROJECTID_TRS_TRSID}
        element={
          <LazyLoadPage page={<TrsViewPage appMode={APP_MODES.SHARED} />} />
        }
      />
      <Route
        path={ROUTE.SHARE_SCAFFOLD_ID}
        element={
          <LazyLoadPage
            page={<ScaffoldViewPage appMode={APP_MODES.SHARED} />}
          />
        }
      />
      <Route
        path={ROUTE.SHARE_TRS_ID}
        element={
          <LazyLoadPage page={<TrsViewPage appMode={APP_MODES.SHARED} />} />
        }
      />
      <Route path={"*"} element={<Navigate replace to={ROUTE.LOGIN} />} />
    </Routes>
  );
};

const ViewDocumentPages = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTE.VIEW}${ROUTE.ID_PARAM}`}
        element={<LazyLoadPage page={<ProjectViewPage />} />}
      />
      <Route
        path={ROUTE.ROOT}
        element={<LazyLoadPage page={<ProjectsPage />} />}
      />
    </Routes>
  );
};

const ScaffoldPages = () => {
  return (
    <Routes>
      <Route
        path={`${ROUTE.TUTORIAL}${ROUTE.ID_PARAM}`}
        element={<LazyLoadPage page={<TutorialPage />} />}
      />
      <Route
        path={`${ROUTE.VIEW}${ROUTE.ID_PARAM}`}
        element={
          <LazyLoadPage page={<ScaffoldViewPage appMode={APP_MODES.VIEW} />} />
        }
      />
      <Route
        path={ROUTE.ID_PARAM}
        element={<LazyLoadPage page={<ScaffoldPage />} />}
      />
      <Route path={"*"} element={<LazyLoadPage page={<ProjectsPage />} />} />
    </Routes>
  );
};

// const TrsPages = () => {
//   return (
//     <Routes>
//       <Route
//         path={`${ROUTE.VIEW}${ROUTE.ID_PARAM}`}
//         element={
//           <LazyLoadPage page={<TrsViewPage appMode={APP_MODES.VIEW} />} />
//         }
//       />
//       <Route
//         path={ROUTE.ID_PARAM}
//         element={<LazyLoadPage page={<TrsPage />} />}
//       />
//       <Route path={"*"} element={<LazyLoadPage page={<ProjectsPage />} />} />
//     </Routes>
//   );
// };

/** RouteManager
 * This component is responsible for managing the routes of the application.
 *
 * @returns JSX.Element
 */
const RouteManager = () => {
  /** Return: Router with conditional routes */
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoadingOverlay />}>
          <Route element={<InfoOverlay />}>
            {/* Sessioned routes */}
            <Route path={"/"} element={<SessionManager />}>
              <Route
                path={ROUTE.PROFILE}
                element={<LazyLoadPage page={<ProfilePage />} />}
              />
              <Route
                path={ROUTE.LOGIN}
                element={<LazyLoadPage page={<LoginPage />} />}
              />
              <Route
                path={ROUTE.SUPERADMIN}
                element={<LazyLoadPage page={<SuperAdminPage />} />}
              />
              <Route
                path={ROUTE.ROOT}
                element={<LazyLoadPage page={<HomePage />} />}
              />
              <Route
                path={ROUTE.HOME}
                element={<LazyLoadPage page={<HomePage />} />}
              />
              <Route
                path={"/projects/*"}
                element={<LazyLoadPage page={<ProjectsPages />} />}
              />
              <Route path={"/scaffold/*"} element={<ScaffoldPages />} />,
              <Route path={"/project/*"} element={<ViewDocumentPages />} />,
              {/*<Route path={"/trs/*"} element={<TrsPages />} />*/}
            </Route>
            {/* Public routes with overlays */}
            <Route
              path={ROUTE.ACTION}
              element={<LazyLoadPage page={<ActionPage />} />}
            />
            <Route path={"/share/*"} element={<SharePages />} />
          </Route>
        </Route>
        {/* Public routes without overlays */}
        <Route
          path={ROUTE.SNAPSHOT}
          element={<LazyLoadPage page={<SnapshotPage />} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;

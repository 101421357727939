import Graph from "graphology";
import { BasePlate } from "world/core/BasePlate/BasePlate.types";
import { getGraphData } from "world/core/BasePlate/BasePlate.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addBasePlatesToGraph = (props: {
  graph: Graph;
  basePlates: BasePlate[];
}) => {
  const { graph, basePlates } = props;

  const basePlatesGraphData = basePlates.map((basePlate) =>
    getGraphData(basePlate)
  );

  addComponentsToGraph({ graph, components: basePlatesGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removeBasePlatesFromGraph = (props: {
  graph: Graph;
  basePlates: BasePlate[];
}) => {
  const { graph, basePlates } = props;

  const basePlatesGraphData = basePlates.map((basePlate) =>
    getGraphData(basePlate)
  );

  removeComponentsFromGraph({
    graph,
    components: basePlatesGraphData,
    force: true
  });
};

export const setBasePlatesInGraph = (props: {
  graph: Graph;
  newBasePlates: BasePlate[];
  oldBasePlates: BasePlate[];
}) => {
  const { graph, newBasePlates, oldBasePlates } = props;
  removeBasePlatesFromGraph({ graph, basePlates: oldBasePlates });
  addBasePlatesToGraph({ graph, basePlates: newBasePlates });
};

import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { PlankSlice } from "./plank.interface";
import { sliceResetFns } from "store/store";
import {
  addPlanksToGraph,
  removePlanksFromGraph,
  setPlanksInGraph
} from "./plank.utils";

/** Initial state */
const initialPlankState = {
  planks: []
};

/** Slice creation */
const createPlankSlice: StateCreator<Store, [], [], PlankSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialPlankState));

  /** Return state */
  return {
    ...initialPlankState,
    addPlanks: (planks) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addPlanksToGraph({ graph: newGraph, planks });
        return {
          graph: newGraph,
          planks: [...state.planks, ...planks]
        };
      });
    },
    setPlanks: (planks) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setPlanksInGraph({
          graph: newGraph,
          newPlanks: planks,
          oldPlanks: [...state.planks]
        });

        return {
          graph: newGraph,
          planks
        };
      });
    },
    removePlanks: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removePlanksFromGraph({
          graph: newGraph,
          planks: [...state.planks]
        });

        return {
          graph: newGraph,
          planks: state.planks.filter((plank) => !ids.includes(plank.id))
        };
      });
    }
  };
};

export default createPlankSlice;

/** Calculates the various combinatorial segment length combinations
 * required to reach (not surpass) the target length.
 * @param props.lengthSegments - The available segment lengths.
 * @param props.targetLength - The target length.
 * @returns combinatorial segment length combinations.
 */
export const calculateSegmentLengthCombinations = (props: {
  lengthSegments: number[];
  targetLength: number;
}) => {
  const { lengthSegments, targetLength } = props;
  const combinations: number[][] = [];
  const calculate = (props: {
    lengthSegments: number[];
    targetLength: number;
    combination: number[];
  }) => {
    const { lengthSegments, targetLength, combination } = props;
    const minSegment = Math.min(...lengthSegments, 0);
    if (targetLength < 0) {
      combination.pop();
      combinations.push(combination);
      return;
    }

    if (targetLength - minSegment < 0) {
      combinations.push(combination);
      return;
    }

    lengthSegments.forEach((segment) => {
      calculate({
        lengthSegments,
        targetLength: targetLength - segment,
        combination: [...combination, segment]
      });
    });
  };

  calculate({ lengthSegments, targetLength, combination: [] });
  return combinations;
};

/** Calculates the least amount of segments required to reach (not surpass)
 * the target length.
 * @param props.lengthSegments - The available segment lengths.
 * @param props.targetLength - The target length.
 * @returns The combination of segment lengths with the least amount of segment lengths .
 */
export const calculateOptimalSegmentLengthCombination = (props: {
  lengthSegments: number[];
  targetLength: number;
}) => {
  const { targetLength } = props;
  const combinations = calculateSegmentLengthCombinations(props);
  let optimalCombination: number[] = [];
  let optimalLenghtDiff = Infinity;
  combinations.forEach((combination) => {
    const lengthDiff =
      targetLength - combination.reduce((acc, val) => acc + val, 0);
    if (lengthDiff < optimalLenghtDiff) {
      optimalCombination = combination;
      optimalLenghtDiff = lengthDiff;
    } else if (
      lengthDiff === optimalLenghtDiff &&
      combination.length < optimalCombination.length
    ) {
      optimalCombination = combination;
    }
  });
  return optimalCombination;
};

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsConsoles: Array<Component> = [
  {
    article_id: "8120570",
    weight: 3.3,
    material: "",
    label_en: "Console 0,3",
    length: 0,
    width: 0.3,
    label_no: "Konsoll 0,3",
    label_se: "Konsol 0,3"
  },
  {
    label_en: "Console 0,6",
    label_se: "Konsol 0,6",
    label_no: "Konsoll 0,6",
    article_id: "8120530",
    width: 0.6,
    length: 0,
    material: "",
    weight: 4
  },
  {
    article_id: "8120540",
    weight: 3.6,
    label_en: "Console 0,45",
    length: 0,
    material: "",
    label_no: "Konsoll 0,45",
    width: 0.45,
    label_se: "Konsol 0,45"
  },
  {
    label_en: "Console with cup TU 0,6",
    label_se: "Konsol med kopp TU 0,6",
    label_no: "Konsoll med kopp TU 0,6",
    article_id: "8120090",
    width: 0.6,
    length: 0,
    material: "",
    weight: 4.2,
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    label_en: "Universal Console with cup TU 0.7",
    label_se: "UniversalKonsol med kopp TU 0.7",
    label_no: "UniveralKonsoll med kopp TU 0.7",
    article_id: "8124580",
    width: 0.7,
    length: 0,
    material: "",
    weight: 7.4,
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    label_en: "Universal Console with cup TU 1.0",
    label_se: "UniversalKonsol med kopp TU 1.0",
    label_no: "UniveralKonsoll med kopp TU 1.0",
    article_id: "8124570",
    width: 1.0,
    length: 0,
    material: "",
    weight: 8.7,
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    label_en: "Universal Console with cup TU 1.25",
    label_se: "UniversalKonsol med kopp TU 1.25",
    label_no: "UniveralKonsoll med kopp TU 1.25",
    article_id: "8124560",
    width: 1.25,
    length: 0,
    material: "",
    weight: 9.8,
    variant: CONSOLE_VARIANT.SPIGOT
  }
];

export const componentsConsoleBracketBraces: Component[] = [
  {
    article_id: "8124440",
    label_se: "Sträva till Universalkonsol",
    label_no: "Stag til UniversalKonsoll",
    label_en: "Brace for Universal Console",
    material: "",
    weight: 11,
    length: 2.173,
    height: 2,
    width: 0.7
  }
];

export const componentsAnchors: Array<Component> = [
  {
    width: 0,
    material: "",
    label_en: "Anchor tube",
    weight: 1.3,
    length: 0.6,
    article_id: "pluseightwalltie1",
    label_se: "Förankringsrör",
    label_no: "Rörforankring"
  }
];

export const componentsOLedgers: Array<Component> = [
  {
    weight: 3.2,
    length: 0.55,
    article_id: "8111460",
    width: 0,
    label_en: "H55+ Beam, 0,55m",
    material: "",
    label_se: "H55+ Bom,  0,55m",
    label_no: "H55+ Bom,  0,55m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    material: "",
    weight: 10,
    width: 0,
    label_en: "H175+ Beam, 1,75m",
    article_id: "8111240",
    label_se: "HB175+ Bom, 1,75m",
    label_no: "HB175+ Bom, 1,75m",
    length: 1.75,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_no: "HB150+ Bom, 1,5m",
    label_en: "H150+ Beam, 1,5m",
    label_se: "HB150+ Bom, 1,5m",
    weight: 9,
    width: 0,
    material: "",
    length: 1.5,
    article_id: "8111250",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    weight: 2.3,
    label_en: "H25+ Beam, 0,25m",
    material: "",
    width: 0,
    article_id: "8111490",
    length: 0.25,
    label_se: "H25+ Bom, 0,25m",
    label_no: "H25+ Bom, 0,25m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_se: "HB350+ Bom, 3,5m",
    article_id: "8111200",
    width: 0,
    label_en: "HB350+ Beam, 3,5m",
    weight: 19,
    label_no: "HB350+ Bom, 3,5m",
    length: 3.5,
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    length: 2,
    article_id: "8111230",
    weight: 11.4,
    label_se: "HB100+ Bom, 2m",
    material: "",
    label_no: "HB100+ Bom, 2m",
    label_en: "HB100+ Beam, 2m",
    width: 0,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    label_en: "H200+ Beam, 2,5m",
    length: 2.5,
    label_se: "HB200+ Bom, 2,5m",
    material: "",
    article_id: "8111220",
    weight: 14.1,
    label_no: "HB200+ Bom, 2,5m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    article_id: "8111080",
    label_en: "H70+ Beam, 0,7m",
    label_no: "H70+ Bom, 0,7m",
    label_se: "H70+ Bom, 0,7m",
    length: 0.7,
    material: "",
    weight: 3.6,
    width: 0,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_no: "HB150+ Bom, 1,5m",
    length: 1.5,
    material: "",
    label_en: "H150+ Beam, 1,5m",
    label_se: "HB150+ Bom, 1,5m",
    width: 0,
    weight: 9,
    article_id: "8111050",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H100+ Beam, 1m",
    weight: 6.1,
    material: "",
    label_se: "HB100+ Bom, 1m",
    width: 0,
    article_id: "8111070",
    label_no: "HB100+ Bom, 1m",
    length: 1,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H50+ Beam, 0,5m",
    width: 0,
    weight: 2.8,
    label_se: "H50+ Bom, 0,5m",
    length: 0.5,
    article_id: "8111290",
    label_no: "H50+ Bom, 0,5m",
    material: "",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H25+ Beam, 0,25m",
    label_no: "H25+ Bom, 0,25m",
    weight: 2.8,
    label_se: "H25+ Bom, 0,25m",
    width: 0,
    material: "",
    length: 0.25,
    article_id: "8111490",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    article_id: "8111210",
    material: "",
    length: 3,
    weight: 17,
    label_en: "H300+ Beam, 3m",
    label_se: "HB300+ Bom, 3m",
    width: 0,
    label_no: "HB300+ Bom, 3m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H30+ Beam, 0,3m",
    weight: 2.4,
    width: 0,
    material: "",
    article_id: "8111480",
    length: 0.3,
    label_no: "H30+ Bom, 0,3m",
    label_se: "H30+ Bom, 0,3m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_no: "H70+ bom 0,7m",
    weight: 3.6,
    material: "",
    length: 0.7,
    article_id: "8111280",
    label_se: "H70+ bom 0,7m",
    width: 0,
    label_en: "H70+ beam 0,7m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_no: "HB300+ Bom, 3m",
    label_se: "HB300+ Bom, 3m",
    width: 0,
    length: 3,
    article_id: "8111010",
    material: "",
    label_en: "HB300+ Beam, 3m",
    weight: 17,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_se: "H55+ Bom,  0,55m",
    article_id: "8111090",
    material: "",
    length: 0.55,
    width: 0,
    label_no: "H55+ Bom,  0,55m",
    label_en: "H55+ Beam, 0,55m",
    weight: 3.2,
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H125+ Beam, 1,25m",
    length: 1.25,
    label_no: "HB125+ Bom, 1,25m",
    material: "",
    width: 0,
    weight: 7.3,
    article_id: "8111060",
    label_se: "HB125+ Bom, 1,25m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_en: "H125+ Beam, 1,25m",
    label_no: "HB125+ Bom, 1,25m",
    width: 0,
    length: 1.25,
    weight: 7.3,
    article_id: "8111260",
    material: "",
    label_se: "HB125+ Bom, 1,25m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    width: 0,
    label_en: "H75+ Beam, 0,75m",
    label_se: "H75+ Bom,  0,75m",
    material: "",
    article_id: "8111450",
    label_no: "H75+ Bom,  0,75m",
    weight: 3.8,
    length: 0.75,
    variant: LEDGER_VARIANT.O_LEDGER
  }
];

export const componentsULedgers: Array<Component> = [
  {
    width: 0,
    length: 1.75,
    material: "",
    label_se: "Horisontal Förstärkt Ny Typ + (förstärkt) 1,75m",
    label_en: "Horisontal beam new type+ (reinforced) 1,75m",
    weight: 11.3,
    label_no: "Horisontalbjelke ny type+ (forsterket) 1,75m",
    article_id: "8111840",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    length: 1.5,
    material: "",
    label_se: "Horisontal Förstärkt Ny Typ + (förstärkt) 1,5m",
    label_en: "Horisontal beam new type+ (reinforced) 1,5m",
    weight: 10.7,
    label_no: "Horisontalbjelke ny type+ (forsterket) 1,5m",
    article_id: "8111850",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    length: 1.25,
    material: "",
    label_se: "Horisontal Förstärkt Ny Typ + (förstärkt) 1,25m",
    label_en: "Horisontal beam new type+ (reinforced) 1,25m",
    weight: 7.3,
    label_no: "Horisontalbjelke ny type+ (forsterket) 1,25m",
    article_id: "8111860",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    width: 0,
    length: 1,
    material: "",
    label_se: "Horisontal Förstärkt Ny Typ + (förstärkt) 1m",
    label_en: "Horisontal beam new type+ (reinforced) 1m",
    weight: 6.1,
    label_no: "Horisontalbjelke ny type+ (forsterket) 1m",
    article_id: "8111870",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    weight: 17,
    label_no: "Horisontalbjelke+ (forsterket) 3m",
    length: 3,
    width: 0,
    label_en: "Horisontal beam+ (reinforced) 3m",
    material: "",
    article_id: "8112310",
    label_se: "Horisontalbalk+ (förstärkt) 3m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_se: "Horisontalk (förstärkt)+ 1m",
    material: "",
    length: 1,
    weight: 6.1,
    label_no: "Horisontal (forsterket)+ 1m",
    article_id: "8111970",
    label_en: "Horisontal (reinforced)+ 1m",
    width: 0,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_se: "Horisontalk (förstärkt)+ 1,25m",
    material: "",
    length: 1.25,
    weight: 7.3,
    label_no: "Horisontal (forsterket)+ 1,25m",
    article_id: "8111960",
    label_en: "Horisontal (reinforced)+ 1,25m",
    width: 0,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_no: "Horisontalbjelke+ (forsterket) 2m",
    length: 2,
    label_se: "Horisontalbalk+ (förstärkt) 2m",
    label_en: "Horisontal beam+ (reinforced) 2m",
    article_id: "8112330",
    width: 0,
    material: "",
    weight: 11.4,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 3.5,
    label_se: "Horisontalbalk+ (förstärkt) 3,5m",
    width: 0,
    material: "",
    label_en: "Horisontal beam+ (reinforced) 3,5m",
    article_id: "8112300",
    weight: 19,
    label_no: "Horisontalbjelke+ (forsterket) 3,5m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    material: "",
    article_id: "8112320",
    label_se: "Horisontalbalk+ (förstärkt) 2,5m",
    length: 2.5,
    weight: 14.1,
    label_en: "Horisontal beam+ (reinforced) 2,5m",
    width: 0,
    label_no: "Horisontalbjelke+ (forsterket) 2,5m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_en: "Horisontal beam+ (reinforced) 1,75m",
    length: 1.75,
    article_id: "8112340",
    material: "",
    label_no: "Horisontalbjelke+ (forsterket) 1,75m",
    width: 0,
    weight: 10,
    label_se: "Horisontalbalk+ (förstärkt) 1,75m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_en: "Horisontal beam+ (reinforced) 1,5m",
    length: 1.5,
    article_id: "8112350",
    material: "",
    label_no: "Horisontalbjelke+ (forsterket) 1,5m",
    width: 0,
    weight: 9,
    label_se: "Horisontalbalk+ (förstärkt) 1,5m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    label_en: "Horisontal beam+ (reinforced) 1,25m",
    length: 1.25,
    article_id: "8112360",
    material: "",
    label_no: "Horisontalbjelke+ (forsterket) 1,25m",
    width: 0,
    weight: 7.3,
    label_se: "Horisontalbalk+ (förstärkt) 1,25m",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 1,
    width: 0,
    label_no: "Horisontalbjelke+ 1m",
    weight: 6.1,
    label_se: "Horisontalbalk+ 1m",
    material: "",
    label_en: "Horisontal beam+ 1m",
    article_id: "8112370"
  },
  {
    length: 2.5,
    width: 0,
    label_no: "Horisontalbjelke TU+ 2.5m",
    weight: 13.4,
    label_se: "Horisontalbalk TU+ 2.5m",
    material: "",
    label_en: "Horisontal beam TU+ 2.5m",
    article_id: "8112120",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 1.75,
    width: 0,
    label_no: "Horisontalbjelke TU+ 1,75m",
    weight: 12.7,
    label_se: "Horisontalbalk TU+ 1,75m",
    material: "",
    label_en: "Horisontal beam TU+ 1,75m",
    article_id: "8112240",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 3.5,
    width: 0,
    label_no: "Lastbjelke + 3,5m",
    weight: 28.1,
    label_se: "Lastbalk + 3,5m",
    material: "",
    label_en: "Load beam + 3,5m",
    article_id: "8112600",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 3,
    width: 0,
    label_no: "Lastbjelke + 3m",
    weight: 24.5,
    label_se: "Lastbalk + 3m",
    material: "",
    label_en: "Load beam + 3m",
    article_id: "8112610",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 2.5,
    width: 0,
    label_no: "Lastbjelke + 2,5m",
    weight: 21.1,
    label_se: "Lastbalk + 2,5m",
    material: "",
    label_en: "Load beam + 2,5m",
    article_id: "8112620",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 2,
    width: 0,
    label_no: "Lastbjelke + 2m",
    weight: 17.5,
    label_se: "Lastbalk + 2m",
    material: "",
    label_en: "Load beam + 2m",
    article_id: "8112630",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 1.75,
    width: 0,
    label_no: "Lastbjelke + 1.75m",
    weight: 16.1,
    label_se: "Lastbalk + 1.75m",
    material: "",
    label_en: "Load beam + 1.75m",
    article_id: "8112640",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 1.5,
    width: 0,
    label_no: "Lastbjelke + 1.5m",
    weight: 14.2,
    label_se: "Lastbalk + 1.5m",
    material: "",
    label_en: "Load beam + 1.5m",
    article_id: "8112650",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    length: 1.25,
    width: 0,
    label_no: "Lastbjelke + 1.25m",
    weight: 12.6,
    label_se: "Lastbalk + 1.25m",
    material: "",
    label_en: "Load beam + 1.25m",
    article_id: "8112660",
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsToeBoards: Array<Component> = [
  {
    label_en: "Toe Board 1m ",
    width: 0.15,
    weight: 3.2,
    label_no: "Fotlist 1m ",
    label_se: "Sparklist 1m ",
    length: 1,
    article_id: "8139370",
    material: ""
  },
  {
    width: 0.15,
    label_se: "Sparklist 0,5m ",
    article_id: "8139390",
    length: 0.5,
    label_en: "Toe Board 0,5m ",
    weight: 2.1,
    material: "",
    label_no: "Fotlist 0,5m "
  },
  {
    label_se: "Sparklist 1,25m ",
    length: 1.25,
    label_no: "Fotlist 1,25m ",
    label_en: "Toe Board 1,25m ",
    width: 0.15,
    article_id: "8139360",
    material: "",
    weight: 3.7
  },
  {
    label_en: "Toe Board 3m ",
    material: "",
    weight: 7.4,
    label_no: "Fotlist 3m ",
    label_se: "Sparklist 3m ",
    length: 3,
    article_id: "8139310",
    width: 0.15
  },
  {
    label_en: "Toe Board 0,7m ",
    article_id: "8139380",
    label_no: "Fotlist 0,7m ",
    length: 0.7,
    weight: 2.6,
    label_se: "Sparklist 0,7m ",
    width: 0.15,
    material: ""
  },
  {
    label_no: "Fotlist 2,5m ",
    material: "",
    weight: 6.3,
    label_en: "Toe Board 2,5m ",
    article_id: "8139320",
    width: 0.15,
    length: 2.5,
    label_se: "Sparklist 2,5m "
  },
  {
    label_se: "Sparklist 1,5m ",
    width: 0.15,
    label_en: "Toe Board 1,5m ",
    material: "",
    weight: 4.3,
    article_id: "8139350",
    label_no: "Fotlist 1,5m ",
    length: 1.5
  },
  {
    length: 2,
    width: 0.15,
    material: "",
    label_no: "Fotlist 2m ",
    label_se: "Sparklist 2m ",
    label_en: "Toe Board 2m ",
    weight: 5.3,
    article_id: "8139330"
  },
  {
    length: 1.75,
    article_id: "8139340",
    weight: 4.8,
    material: "",
    width: 0.15,
    label_en: "Toe Board 1,75m ",
    label_no: "Fotlist 1,75m ",
    label_se: "Sparklist 1,75m "
  },
  {
    weight: 8.4,
    width: 0.15,
    label_se: "Sparklist 3,5m ",
    label_en: "Toe Board 3,5m ",
    length: 3.5,
    label_no: "Fotlist 3,5m ",
    material: "",
    article_id: "8139300"
  }
];

export const componentsDecks: Array<Component> = [
  {
    article_id: "8134860",
    width: 0.6,
    length: 1.25,
    label_en: "Alu/Alu plank 600mm wide 1,25m long",
    label_se: "Alu/Aluplank Bred 600mm bred 1,25m långt",
    label_no: "Alu/Aluplank Bred 600mm bred 1,25m lang",
    weight: 9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    weight: 4.3,
    width: 0.6,
    length: 0.5,
    label_no: "Alu/Aluplank Bred 600mm bred 0,5m lang",
    article_id: "8134890",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_en: "Alu/Alu plank 600mm wide 0,5m long",
    label_se: "Alu/Aluplank Bred 600mm bred 0,5m långt"
  },
  {
    length: 2,
    article_id: "8130030",
    label_no: "Stålplank tynn 300mm bred 2m lang",
    label_en: "Steel plank 300mm wide 2m long",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.3,
    weight: 13.4,
    label_se: "Stålplank smal 300mm bredd 2m lång"
  },
  {
    article_id: "8130080",
    weight: 4.5,
    label_en: "Alu/Alu plank 300mm wide 0,7m long",
    length: 0.7,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank smal 300mm bred 0,7m långt",
    width: 0.3,
    label_no: "Alu/Aluplank tynn 300mm bred 0,7m lang"
  },
  {
    label_se: "Alu/Aluplank smal 300mm bred 1,75m långt",
    article_id: "8130040",
    weight: 8.8,
    length: 1.75,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_no: "Alu/Aluplank tynn 300mm bred 1,75m lang",
    width: 0.3,
    label_en: "Alu/Alu plank 300mm wide 1,75m long"
  },
  {
    label_no: "Alu/Plywoodplank tynn 300mm bred 1,25m lang",
    weight: 6.7,
    label_en: "Alu/Plywood plank 300mm wide 1,25m long",
    article_id: "8130660",
    width: 0.3,
    label_se: "Alu/Plywoodplank smal 300mm bred 1,25m långt",
    length: 1.25,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_se: "Alu/Aluplank mellan 450mm bred 1,5m långt",
    length: 1.5,
    article_id: "8132850",
    width: 0.45,
    label_no: "Alu/Aluplank mellom 450mm bred 1,5m lang",
    label_en: "Alu/Alu plank 450mm wide 1,5m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    weight: 9.5
  },
  {
    width: 0.3,
    label_no: "Alu/Aluplank tynn 300mm bred 1,5m lang",
    label_en: "Alu/Alu plank 300mm wide 1,5m long",
    label_se: "Alu/Aluplank smal 300mm bred 1,5m långt",
    weight: 7.7,
    length: 1.5,
    article_id: "8130850",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    label_se: "Alu/Aluplank mellan 450mm bred 1,75m långt",
    label_no: "Alu/Aluplank mellan 450mm bred 1,75m lang",
    label_en: "Alu/Alu plank 450mm wide 1,75m long",
    weight: 10.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    width: 0.45,
    article_id: "8132840",
    length: 1.75
  },
  {
    article_id: "8130870",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    weight: 5.7,
    label_se: "Alu/Aluplank smal 300mm bred 1m långt",
    length: 1,
    label_no: "Alu/Aluplank tynn 300mm bred 1m lang",
    width: 0.3,
    label_en: "Alu/Alu plank 300mm wide 1m long"
  },
  {
    width: 0.3,
    label_no: "Alu/Aluplank tynn 300mm bred 0,5m lang",
    label_en: "Alu/Alu plank 300mm wide 0,5m long",
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank smal 300mm bred 0,5m långt",
    weight: 3.6,
    article_id: "8130890"
  },
  {
    label_en: "Steel plank 300mm wide 0,5m long",
    width: 0.3,
    label_se: "Stålplank smal 300mm bredd 0,5m lång",
    weight: 5.5,
    article_id: "8130090",
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank tynn 300mm bred 0,5m lang"
  },
  {
    article_id: "8132870",
    length: 1,
    label_en: "Alu/Alu plank 450mm wide 1m long",
    label_se: "Alu/Aluplank mellan 450mm bred 1m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    weight: 6.7,
    width: 0.45,
    label_no: "Alu/Aluplank mellom 450mm bred 1m lang"
  },
  {
    width: 0.45,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_se: "Alu/Plywoodplank mellan 450mm bred 3m långt",
    length: 3,
    weight: 17.3,
    label_en: "Alu/Plywood plank 450mm wide 3m long",
    article_id: "8132610",
    label_no: "Alu/Plywoodplank mellom 450mm bred 3m lang"
  },
  {
    article_id: "8132600",
    label_no: "Alu/Aluplank mellom 450mm bred 3,5m lang",
    weight: 21.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    length: 3.5,
    label_se: "Alu/Aluplank mellan 450mm bred 3,5m långt",
    label_en: "Alu/Alu plank 450mm wide 3,5m long",
    width: 0.45
  },
  {
    width: 0.3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank tynn 300mm bred 1,75m lang",
    length: 1.75,
    article_id: "8130040",
    label_en: "Steel plank 300mm wide 1,75m long",
    label_se: "Stålplank smal 300mm bredd 1,75m lång",
    weight: 11.2
  },
  {
    label_no: "Alu/Plywoodplank bred 600mm bred 1,75m långt",
    weight: 11,
    label_se: "Alu/Plywoodplank bred 600mm bred 1,75m långt",
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    length: 1.75,
    label_en: "Alu/Plywood plank 600mm wide 1,75m long",
    article_id: "8134640"
  },
  {
    weight: 5.5,
    length: 0.7,
    label_no: "Alu/Aluplank Bred 600mm bred 0,7m lang",
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_en: "Alu/Alu plank 600mm wide 0,7m long",
    article_id: "8134680",
    label_se: "Alu/Aluplank Bred 600mm bred 0,7m långt"
  },
  {
    label_se: "Alu/Plywoodplank mellan 450mm bred 1,5m långt",
    length: 1.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_en: "Alu/Plywood plank 450mm wide 1,5m long",
    weight: 9.2,
    article_id: "8132650",
    label_no: "Alu/Plywoodplank mellom 450mm bred 1,5m lang",
    width: 0.45
  },
  {
    label_se: "Alu/Plywoodplank bred 600mm bred 0,5m långt",
    label_no: "Alu/Plywoodplank bred 600mm bred 0,5m lang",
    article_id: "8134490",
    width: 0.6,
    label_en: "Alu/Plywood plank 600mm wide 0,5m long",
    weight: 4.3,
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    article_id: "8134480",
    label_en: "Alu/Plywood plank 600mm wide 0,7m long",
    label_se: "Alu/Plywoodplank bred 600mm bred 0,7m långt",
    weight: 5.5,
    length: 0.7,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank bred 600mm bred 0,7m lang",
    width: 0.6
  },
  {
    label_no: "Alu/Aluplank tynn 300mm bred 1,25m lang",
    length: 1.25,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank smal 300mm bred 1,25m långt",
    label_en: "Alu/Alu plank 300mm wide 1,25m long",
    width: 0.3,
    article_id: "8130360",
    weight: 6.7
  },
  {
    weight: 9.1,
    length: 2,
    label_se: "Aluplank smal 300mm bred 2m långt",
    article_id: "8130330",
    width: 0.3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluplank tynn 300mm bred 2m lang",
    label_en: "Alu plank 300mm wide 2m long"
  },
  {
    label_en: "Alu/Plywood plank 600mm wide 0,7m long",
    label_no: "Alu/Plywoodplank bred 600mm bred 0,7m lang",
    width: 0.6,
    length: 0.7,
    article_id: "8134680",
    label_se: "Alu/Plywoodplank bred 600mm bred 0,7m långt",
    weight: 5.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_se: "Alu/Aluplank mellan 450mm bred 1,5m långt",
    label_no: "Alu/Aluplank mellan 450mm bred 1,5m lang",
    weight: 9.5,
    label_en: "Alu/Alu plank 450mm wide 1,5m long",
    article_id: "8132850",
    width: 0.45,
    length: 1.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    label_se: "Alu/Plywoodplank bred 600mm bred 2,5m långt",
    label_en: "Alu/Plywood plank 600mm wide 2,5m long",
    label_no: "Alu/Plywoodplank bred 600mm bred 2,5m lang",
    weight: 16.5,
    article_id: "8134620",
    length: 2.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    width: 0.6
  },
  {
    weight: 10.3,
    length: 1.5,
    label_en: "Steel plank 300mm wide 1,5m long",
    article_id: "8130050",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank tynn 300mm bred 1,5m lang",
    width: 0.3,
    label_se: "Stålplank smal 300mm bredd 1,5m lång"
  },
  {
    width: 0.3,
    label_no: "Alu/Aluplank tynn 300mm bred 0,5m lang",
    label_se: "Alu/Aluplank smal 300mm bred 0,5m långt",
    weight: 3.6,
    article_id: "8130890",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    length: 0.5,
    label_en: "Alu/Alu plank 300mm wide 0,5m long"
  },
  {
    label_se: "Aluplank smal 300mm bred 1,75m långt",
    label_no: "Aluplank tynn 300mm bred 1,75m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 1.75,
    width: 0.3,
    label_en: "Alu plank 300mm wide 1,75m long",
    article_id: "8130340",
    weight: 8.1
  },
  {
    article_id: "8130080",
    label_se: "Stålplank smal 300mm bredd 0,7m lång",
    weight: 6.5,
    label_en: "Steel plank 300mm wide 0,7m long",
    label_no: "Stålplank tynn 300mm bred 0,7m lang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 0.7,
    width: 0.3
  },
  {
    length: 2.5,
    weight: 11.9,
    label_no: "Alu/Aluplank tynn 300mm bred 2,5m lang",
    label_en: "Alu/Alu plank 300mm wide 2,5m long",
    article_id: "8130820",
    width: 0.3,
    label_se: "Alu/Aluplank smal 300mm bred 2,5m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    length: 1,
    label_se: "Alu/Plywoodplank mellan 450mm bred 1m långt",
    label_no: "Alu/Plywoodplank mellom 450mm bred 1m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    article_id: "8132670",
    width: 0.45,
    label_en: "Alu/Plywood plank 450mm wide 1m long",
    weight: 6.7
  },
  {
    weight: 14.2,
    label_no: "Alu/Plywoodplank mellom 450mm bred 2,5m lang",
    label_se: "Alu/Plywoodplank mellan 450mm bred 2,5m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    article_id: "8132620",
    width: 0.45,
    length: 2.5,
    label_en: "Alu/Plywood plank 450mm wide 2,5m long"
  },
  {
    label_en: "Alu/Alu plank 600mm wide 1m long",
    weight: 7.2,
    length: 1,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    article_id: "8134870",
    label_no: "Alu/Aluplank Bred 600mm bred 1m lang",
    width: 0.6,
    label_se: "Alu/Aluplank Bred 600mm bred 1m långt"
  },
  {
    width: 0.3,
    article_id: "8130630",
    weight: 10.6,
    label_en: "Alu/Plywood plank 300mm wide 2m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank tynn 300mm bred 2m lang",
    label_se: "Alu/Plywoodplank smal 300mm bred 2m långt",
    length: 2
  },
  {
    label_se: "Aluplank smal 300mm bred 1,5m långt",
    width: 0.3,
    article_id: "8130050",
    label_en: "Alu plank 300mm wide 1,5m long",
    label_no: "Aluplank tynn 300mm bred 1,5m lang",
    length: 1.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 7.2
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    width: 0.45,
    article_id: "8132820",
    label_en: "Alu/Alu plank 450mm wide 2,5m long",
    length: 2.5,
    weight: 14.2,
    label_se: "Alu/Aluplank mellan 450mm bred 2,5m långt",
    label_no: "Alu/Aluplank mellom 450mm bred 2,5m lang"
  },
  {
    weight: 16.5,
    article_id: "8134820",
    label_en: "Alu/Alu plank 600mm wide 2,5m long",
    length: 2.5,
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank Bred 600mm bred 2,5m långt",
    label_no: "Alu/Aluplank Bred 600mm bred 2,5m lang"
  },
  {
    article_id: "8130360",
    label_se: "Aluplank smal 300mm bred 1,25m långt",
    label_no: "Aluplank tynn 300mm bred 1,25m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 1.25,
    width: 0.3,
    label_en: "Alu plank 300mm wide 1,25m long",
    weight: 6.2
  },
  {
    width: 0.45,
    length: 3,
    label_en: "Alu/Alu plank 450mm wide 3m long",
    article_id: "8132810",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_no: "Alu/Aluplank mellom 450mm bred 3m lang",
    weight: 17.3,
    label_se: "Alu/Aluplank mellan 450mm bred 3m långt"
  },
  {
    width: 0.3,
    label_en: "Alu/Plywood plank 300mm wide 3,5m long",
    label_no: "Alu/Plywoodplank tynn 300mm bred 3,5m lang",
    article_id: "8130600",
    label_se: "Alu/Plywoodplank smal 300mm bred 3,5m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    length: 3.5,
    weight: 18.5
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 3.6,
    label_en: "Alu/Plywood plank 300mm wide 0,5m long",
    label_se: "Alu/Plywoodplank smal 300mm bred 0,5m långt",
    length: 0.5,
    width: 0.3,
    label_no: "Alu/Plywoodplank tynn 300mm bred 0,5m lang",
    article_id: "8130690"
  },
  {
    label_en: "Alu/Plywood plank 300mm wide 0,7m long",
    weight: 4.5,
    length: 0.7,
    width: 0.3,
    label_no: "Alu/Plywoodplank tynn 300mm bred 0,7m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    article_id: "8130680",
    label_se: "Alu/Plywoodplank smal 300mm bred 0,7m långt"
  },
  {
    label_en: "Alu/Alu plank 450mm wide 1,25m long",
    label_no: "Alu/Aluplank mellan 450mm bred 1,25m lang",
    width: 0.45,
    label_se: "Alu/Aluplank mellan 450mm bred 1,25m långt",
    length: 1.25,
    article_id: "8132860",
    weight: 8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    length: 2,
    weight: 9.1,
    width: 0.3,
    article_id: "8130310",
    label_no: "Aluplank tynn 300mm bred 2m lang",
    label_en: "Alu plank 300mm wide 2m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank smal 300mm bred 2m långt"
  },
  {
    label_en: "Alu/Plywood plank 600mm wide 2m long",
    label_no: "Alu/Plywoodplank bred 600mm bred 2m lang",
    width: 0.6,
    article_id: "8134630",
    weight: 14.3,
    length: 2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_se: "Alu/Plywoodplank bred 600mm bred 2m långt"
  },
  {
    label_en: "Alu plank 300mm wide 3,5m long",
    label_no: "Aluplank tynn 300mm bred 3,5m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 3.5,
    label_se: "Aluplank smal 300mm bred 3,5m långt",
    weight: 14.7,
    article_id: "8130300",
    width: 0.3
  },
  {
    label_no: "Alu/Plywoodplank mellom 450mm bred 1,75m lang",
    label_en: "Alu/Plywood plank 450mm wide 1,75m long",
    weight: 10.5,
    article_id: "8132640",
    label_se: "Alu/Plywoodplank mellan 450mm bred 1,75m långt",
    length: 1.75,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    width: 0.45
  },
  {
    weight: 10.4,
    label_en: "Alu/Plywood plank 600mm wide 1,5m long",
    article_id: "8134450",
    label_se: "Alu/Plywoodplank bred 600mm bred 1,5m långt",
    length: 1.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    width: 0.6,
    label_no: "Alu/Plywoodplank bred 600mm bred 1,5m lang"
  },
  {
    label_en: "Steel plank 300mm wide 1,25m long",
    width: 0.3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 1.25,
    label_no: "Stålplank tynn 300mm bred 1,25m lang",
    weight: 9.4,
    label_se: "Stålplank smal 300mm bredd 1,25m lång",
    article_id: "8130060"
  },
  {
    weight: 4.1,
    label_en: "Alu/Alu plank 450mm wide 0,5m long",
    article_id: "8132890",
    length: 0.5,
    label_no: "Alu/Aluplank mellom 450mm bred 0,5m lang",
    width: 0.45,
    label_se: "Alu/Aluplank mellan 450mm bred 0,5m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM
  },
  {
    article_id: "8130800",
    label_en: "Alu/Alu plank 300mm wide 3,5m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    length: 3.5,
    width: 0.3,
    label_se: "Alu/Aluplank smal 300mm bred 3,5m långt",
    weight: 18.5,
    label_no: "Alu/Aluplank tynn 300mm bred 3,5m lang"
  },
  {
    label_en: "Alu/Alu plank 600mm wide 1,5m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    length: 1.5,
    article_id: "8134850",
    weight: 10.4,
    width: 0.6,
    label_se: "Alu/Aluplank Bred 600mm bred 1,5m långt",
    label_no: "Alu/Aluplank Bred 600mm bred 1,5m lang"
  },
  {
    label_en: "Steel plank 300mm wide 3m long",
    length: 3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 18.6,
    width: 0.3,
    article_id: "8130010",
    label_no: "Stålplank tynn 300mm bred 3m lang",
    label_se: "Stålplank smal 300mm bredd 3m lång"
  },
  {
    label_se: "Alu/Aluplank mellan 450mm bred 1,25m långt",
    article_id: "8132860",
    width: 0.45,
    length: 1.25,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_en: "Alu/Alu plank 450mm wide 1,25m long",
    label_no: "Alu/Aluplank mellom 450mm bred 1,25m lang",
    weight: 8
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "8130310",
    length: 3,
    label_no: "Aluplank tynn 300mm bred 3m lang",
    width: 0.3,
    label_se: "Aluplank smal 300mm bred 3m långt",
    weight: 12.8,
    label_en: "Alu plank 300mm wide 3m long"
  },
  {
    length: 2,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    weight: 14.3,
    label_no: "Alu/Aluplank Bred 600mm bred 2m lang",
    width: 0.6,
    label_en: "Alu/Alu plank 600mm wide 2m long",
    label_se: "Alu/Aluplank Bred 600mm bred 2m långt",
    article_id: "8134830"
  },
  {
    label_en: "Alu/Plywood plank 450mm wide 0,5m long",
    weight: 4.1,
    width: 0.45,
    article_id: "8134690",
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_se: "Alu/Plywoodplank mellan 450mm bred 0,5m långt",
    label_no: "Alu/Plywoodplank mellom 450mm bred 0,5m lang"
  },
  {
    length: 3.5,
    width: 0.3,
    label_se: "Stålplank smal 300mm bredd 3,5m lång",
    article_id: "8130000",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank tynn 300mm bred 3,5m lang",
    weight: 21.3,
    label_en: "Steel plank 300mm wide 3,5m long"
  },
  {
    article_id: "8130670",
    width: 0.3,
    label_no: "Alu/Plywoodplank tynn 300mm bred 1m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_se: "Alu/Plywoodplank smal 300mm bred 1m långt",
    length: 1,
    label_en: "Alu/Plywood plank 300mm wide 1m long",
    weight: 5.7
  },
  {
    article_id: "8132830",
    length: 2,
    width: 0.45,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    weight: 12.7,
    label_en: "Alu/Alu plank 450mm wide 2m long",
    label_se: "Alu/Aluplank mellan 450mm bred 2m långt",
    label_no: "Alu/Aluplank mellom 450mm bred 2m lang"
  },
  {
    weight: 5.2,
    label_no: "Alu/Aluplank mellom 450mm bred 0,7m lang",
    length: 0.7,
    article_id: "8132880",
    label_en: "Alu/Alu plank 450mm wide 0,7m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank mellan 450mm bred 0,7m långt",
    width: 0.45
  },
  {
    label_se: "Alu/Aluplank smal 300mm bred 3,5m långt",
    article_id: "8132800",
    weight: 18.5,
    length: 3.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_no: "Alu/Aluplank tynn 300mm bred 3,5m lang",
    width: 0.3,
    label_en: "Alu/Alu plank 300mm wide 3,5m long"
  },
  {
    length: 1,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "8130070",
    label_no: "Stålplank tynn 300mm bredd 1m lang",
    label_en: "Steel plank 300mm wide 1m long",
    label_se: "Stålplank smal 300mm bredd 1m lång",
    weight: 8.1,
    width: 0.3
  },
  {
    label_se: "Alu/Plywoodplank bred 600mm bred 2m långt",
    article_id: "8130630",
    length: 2,
    label_no: "Alu/Plywoodplank bred 600mm bred 2m lang",
    label_en: "Alu/Plywood plank 600mm wide 2m long",
    weight: 14.3,
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_no: "Aluplank tynn 300mm bred 0,7m lang",
    label_en: "Alu plank 300mm wide 0,7m long",
    length: 0.7,
    article_id: "8130380",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.7,
    label_se: "Aluplank smal 300mm bred 0,7m långt",
    width: 0.3
  },
  {
    article_id: "8130360",
    label_se: "Aluplank smal 300mm bred 1,25m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluplank tynn 300mm bred 1,25m lang",
    width: 0.3,
    weight: 6.2,
    length: 1.25,
    label_en: "Alu plank 300mm wide 1,25m long"
  },
  {
    label_en: "Alu plank 300mm wide 2,5m long",
    article_id: "8130320",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 10.9,
    width: 0.3,
    length: 2.5,
    label_no: "Aluplank tynn 300mm bred 2,5m lang",
    label_se: "Aluplank smal 300mm bred 2,5m långt"
  },
  {
    weight: 24.7,
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_en: "Alu/Plywood plank 600mm wide 3,5m long",
    label_se: "Alu/Plywoodplank bred 600mm bred 3,5m långt",
    article_id: "8134600",
    length: 3.5,
    label_no: "Alu/Plywoodplank bred 600mm bred 3,5m lang"
  },
  {
    label_no: "Alu/Plywoodplank tynn 300mm bred 0,5m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    width: 0.3,
    label_en: "Alu/Plywood plank 300mm wide 0,5m long",
    label_se: "Alu/Plywoodplank smal 300mm bred 0,5m långt",
    length: 0.5,
    weight: 3.6,
    article_id: "8130690"
  },
  {
    label_en: "Alu/Alu plank 300mm wide 3m long",
    article_id: "8130810",
    label_no: "Alu/Aluplank tynn 300mm bred 3m lang",
    weight: 14.6,
    length: 3,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank smal 300mm bred 3m långt",
    width: 0.3
  },
  {
    label_se: "Alu/Aluplank Bred 600mm bred 1,75m långt",
    label_en: "Alu/Alu plank 600mm wide 1,75m long",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    width: 0.6,
    article_id: "8134840",
    weight: 11,
    length: 1.75,
    label_no: "Alu/Aluplank Bred 600mm bred 1,75m lang"
  },
  {
    weight: 7.2,
    article_id: "8134670",
    width: 0.6,
    label_en: "Alu/Plywood plank 600mm wide 1m long",
    label_se: "Alu/Plywoodplank bred 600mm bred 1m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank bred 600mm bred 1m lang",
    length: 1
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 8.8,
    label_en: "Alu/Plywood plank 300mm wide 1,75m long",
    width: 0.3,
    length: 1.75,
    label_se: "Alu/Plywoodplank smal 300mm bred 1,75m långt",
    article_id: "8130640",
    label_no: "Alu/Plywoodplank tynn 300mm bred 1,75m lang"
  },
  {
    label_no: "Alu/Plywoodplank bred 600mm bred 1,75m lang",
    label_en: "Alu/Plywood plank 600mm wide 1,75m long",
    article_id: "8134840",
    length: 1.75,
    label_se: "Alu/Plywoodplank bred 600mm bred 1,75m långt",
    weight: 11,
    width: 0.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_no: "Aluplank tynn 300mm bred 0,5m lang",
    weight: 4,
    width: 0.3,
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "8130390",
    label_en: "Alu plank 300mm wide 0,5m long",
    label_se: "Aluplank smal 300mm bred 0,5m långt"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_se: "Alu/Aluplank mellan 450mm bred 1,75m långt",
    length: 1.75,
    label_en: "Alu/Alu plank 450mm wide 1,75m long",
    width: 0.45,
    article_id: "8132840",
    weight: 10.5,
    label_no: "Alu/Aluplank mellom 450mm bred 1,75m lang"
  },
  {
    label_se: "Alu/Plywoodplank mellan 450mm bred 2m långt",
    width: 0.45,
    length: 2,
    label_en: "Alu/Plywood plank 450mm wide 2m long",
    weight: 12.7,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank mellom 450mm bred 2m lang",
    article_id: "8132630"
  },
  {
    label_en: "Alu/Plywood plank 300mm wide 2,5m long",
    length: 2.5,
    article_id: "8130020",
    label_no: "Alu/Plywoodplank tynn 300mm bred 2,5m lang",
    width: 0.3,
    label_se: "Alu/Plywoodplank smal 300mm bred 2,5m långt",
    weight: 11.9,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    width: 0.3,
    article_id: "8130370",
    length: 1,
    weight: 5.8,
    label_en: "Alu plank 300mm wide 1m long",
    label_no: "Aluplank tynn 300mm bred 1m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Aluplank smal 300mm bred 1m långt"
  },
  {
    label_en: "Alu/Plywood plank 450mm wide 0,7m long",
    width: 0.45,
    weight: 5.2,
    label_se: "Alu/Plywoodplank mellan 450mm bred 0,7m långt",
    label_no: "Alu/Plywoodplank mellom 450mm bred 0,7m lang",
    length: 0.7,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    article_id: "8132680"
  },
  {
    width: 0.3,
    label_se: "Stålplank smal 300mm bredd 2,5m lång",
    length: 2.5,
    article_id: "8130020",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 16,
    label_no: "Stålplank tynn 300mm bred 2,5m lang",
    label_en: "Steel plank 300mm wide 2,5m long"
  },
  {
    label_se: "Alu/Plywoodplank smal 300mm bred 1,5m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_en: "Alu/Plywood plank 300mm wide 1,5m long",
    article_id: "8130650",
    width: 0.3,
    weight: 7.7,
    length: 1.5,
    label_no: "Alu/Plywoodplank tynn 300mm bred 1,5m lang"
  },
  {
    weight: 6.7,
    article_id: "8130860",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    label_en: "Alu/Alu plank 300mm wide 1,25m long",
    label_se: "Alu/Aluplank smal 300mm bred 1,25m långt",
    width: 0.3,
    length: 1.25,
    label_no: "Alu/Aluplank tynn 300mm bred 1,25m lang"
  },
  {
    article_id: "8130070",
    weight: 8.1,
    label_se: "Stålplank smal 300mm bredd 1m lång",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_no: "Stålplank tynn 300mm bred 1m lang",
    length: 1,
    label_en: "Steel plank 300mm wide 1m long",
    width: 0.3
  },
  {
    label_no: "Alu/Plywoodplank mellom 450mm bred 3,5m lang",
    weight: 21.6,
    label_se: "Alu/Plywoodplank mellan 450mm bred 3,5m långt",
    article_id: "8132600",
    width: 0.45,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    length: 3.5,
    label_en: "Alu/Plywood plank 450mm wide 3,5m long"
  },
  {
    label_se: "Alu/Aluplank Bred 600mm bred 3m långt",
    width: 0.6,
    label_en: "Alu/Alu plank 600mm wide 3m long",
    weight: 20.5,
    length: 3,
    label_no: "Alu/Aluplank Bred 600mm bred 3m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    article_id: "8134810"
  },
  {
    label_en: "Alu/Plywood plank 300mm wide 3m long",
    length: 3,
    article_id: "8130610",
    label_se: "Alu/Plywoodplank smal 300mm bred 3m långt",
    label_no: "Alu/Plywoodplank tynn 300mm bred 3m lang",
    width: 0.3,
    weight: 14.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_se: "Alu/Plywoodplank mellan 450mm bred 2m långt",
    length: 2,
    label_en: "Alu/Plywood plank 450mm wide 2m long",
    article_id: "8132630",
    label_no: "Alu/Plywoodplank mellan 450mm bred 2m lang",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 12.7,
    width: 0.45
  },
  {
    label_se: "Alu/Plywoodplank bred 600mm bred 3m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank bred 600mm bred 3m lang",
    length: 3,
    label_en: "Alu/Plywood plank 600mm wide 3m long",
    weight: 20.5,
    article_id: "8134610",
    width: 0.6
  },
  {
    label_se: "Alu/Plywoodplank mellan 450mm bred 1,25m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    weight: 8,
    article_id: "8132660",
    label_en: "Alu/Plywood plank 450mm wide 1,25m long",
    length: 1.25,
    width: 0.45,
    label_no: "Alu/Plywoodplank mellom 450mm bred 1,25m lang"
  },
  {
    article_id: "8132660",
    width: 0.6,
    weight: 9,
    label_en: "Alu/Plywood plank 600mm wide 1,25m long",
    length: 1.25,
    label_se: "Alu/Plywoodplank bred 600mm bred 1,25m långt",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD,
    label_no: "Alu/Plywoodplank bred 600mm bred 1,25m lang"
  },
  {
    label_en: "Alu/Plywood plank 450mm wide 0,5m long",
    width: 0.45,
    weight: 4.1,
    article_id: "8132690",
    label_se: "Alu/Plywoodplank mellan 450mm bred 0,5m långt",
    label_no: "Alu/Plywoodplank mellan 450mm bred 0,5m lang",
    length: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_PLYWOOD
  },
  {
    label_se: "Alu/Aluplank mellan 450mm bred 3,5m långt",
    length: 3.5,
    label_en: "Alu/Alu plank 450mm wide 3,5m long",
    article_id: "8132610",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    width: 0.45,
    weight: 21.6,
    label_no: "Alu/Aluplank mellan 450mm bred 3,5m lang"
  },
  {
    weight: 10.6,
    label_se: "Alu/Aluplank smal 300mm bred 2m långt",
    article_id: "8130830",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM_ALUMINIUM,
    length: 2,
    width: 0.3,
    label_no: "Alu/Aluplank tynn 300mm bred 2m lang",
    label_en: "Alu/Alu plank 300mm wide 2m long"
  }
];

export const componentsBaseplates: Array<Component> = [
  {
    article_id: "8100015",
    label_en: "Universal bottom screw +",
    material: "",
    length: 0.545,
    label_no: "Universalskrue +",
    weight: 4.7,
    width: 0,
    label_se: "Universalskruv +"
  },
  {
    article_id: "8100000",
    label_en: "Universal bottom screw TU",
    material: "",
    length: 0.62,
    label_no: "Universalskrue TU",
    weight: 5,
    width: 0,
    label_se: "Universalskruv TU"
  }
];

export const componentsGuardRails: Array<Component> = [
  {
    label_en: "Guardrail Double Lattice 3m ",
    label_se: "Gitterräckesram 3m ",
    length: 3,
    width: 0,
    article_id: "8110210",
    material: "",
    weight: 11.3,
    label_no: "Rekkverksramme (gitter) 3m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_no: "Rekkverksramme (gitter) 1,5m ",
    label_se: "Gitterräckesram 1,5m ",
    article_id: "8110250",
    label_en: "Guardrail Double Lattice 1,5m ",
    weight: 7,
    length: 1.5,
    material: "",
    width: 0,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_se: "Gitterräckesram 2m ",
    length: 2,
    weight: 9.1,
    material: "",
    label_en: "Guardrail Double Lattice 2m ",
    article_id: "8110230",
    width: 0,
    label_no: "Rekkverksramme (gitter) 2m",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    weight: 7.7,
    length: 1.75,
    label_en: "Guardrail Double Lattice 1,75m ",
    label_se: "Gitterräckesram 1,75m ",
    material: "",
    article_id: "8110240",
    width: 0,
    label_no: "Rekkverksramme (gitter) 1,75m ",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    weight: 10,
    label_en: "Guardrail Double Lattice 2,5m ",
    length: 2.5,
    label_se: "Gitterräckesram 2,5m ",
    article_id: "8110220",
    material: "",
    label_no: "Rekkverksramme (gitter) 2,5m ",
    width: 0,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    width: 0,
    label_se: "Gitterräckesram 0,75m ",
    article_id: "8110350",
    length: 0.75,
    label_no: "Rekkverksramme 0,75 ",
    material: "",
    weight: 4.6,
    label_en: "Guardrail Double Lattice 0,75m ",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    width: 0,
    label_en: "Guardrail Double Lattice 0,25m ",
    label_no: "Rekkverksramme 0,25 ",
    weight: 2.8,
    material: "",
    label_se: "Gitterräckesram 0,25m ",
    length: 0.25,
    article_id: "8110390",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    label_en: "Guardrail Double Lattice 0,7m ",
    weight: 4.5,
    width: 0,
    label_no: "Rekkverksramme (gitter) 0,7m ",
    length: 0.7,
    label_se: "Gitterräckesram 0,7m ",
    article_id: "8110280",
    material: "",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    article_id: "8110330",
    material: "",
    label_en: "Guardrail Double Lattice 0,9m ",
    width: 0,
    weight: 5,
    label_se: "Gitterräckesram 0,9m ",
    label_no: "Rekkverksramme 0,9 ",
    length: 0.9,
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    width: 0,
    article_id: "8110090",
    label_en: "Guardrail Double Lattice 0,5m ",
    weight: 4,
    label_se: "Gitterräckesram 0,5m ",
    label_no: "Rekkverksramme (gitter) 0,5m ",
    length: 0.5,
    material: "",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    weight: 5.2,
    width: 0,
    length: 1,
    article_id: "8110070",
    label_en: "Guardrail Double Lattice 1m ",
    material: "",
    label_no: "Rekkverksramme (gitter) 1m ",
    label_se: "Gitterräckesram 1m ",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  },
  {
    material: "",
    weight: 13.2,
    width: 0,
    label_se: "Gitterräckesram 3,5m ",
    length: 3.5,
    article_id: "8110000",
    label_no: "Rekkverksramme (gitter) 3,5m ",
    label_en: "Guardrail Double Lattice 3,5m ",
    variant: GUARD_RAIL_VARIANT.LATTICE_GUARD_RAIL
  }
];

export const componentsStandards: Array<Component> = [
  {
    article_id: "8101340",
    length: 2,
    label_no: "Spire, 2m",
    width: 0,
    label_se: "Spira, 2m",
    weight: 13.1,
    material: "",
    label_en: "Standard, 2m"
  },
  {
    label_se: "Spira, 4m",
    label_no: "Spire, 4m",
    length: 4,
    weight: 17.8,
    label_en: "Standard, 4m",
    article_id: "8101000",
    width: 0,
    material: ""
  },
  {
    width: 0,
    label_se: "Spira, 3m",
    material: "",
    label_en: "Standard, 3m",
    weight: 13.6,
    length: 3,
    label_no: "Spire, 3m",
    article_id: "8101020"
  },
  {
    label_se: "Spira, 1m",
    article_id: "8101070",
    weight: 5,
    material: "",
    length: 1,
    width: 0,
    label_en: "Standard, 1m",
    label_no: "Spire, 1m"
  }
];

export const componentsStartStandards: Array<Component> = [
  {
    label_se: "HRA StartSpira, 1m",
    article_id: "8102670",
    weight: 3.9,
    material: "",
    length: 1,
    width: 0,
    label_en: "HRA Start Standard, 1m",
    label_no: "HRA Startspire, 1m"
  },
  {
    label_se: "HRA StartSpira, 1.5m",
    article_id: "8102650",
    weight: 6,
    material: "",
    length: 1.5,
    width: 0,
    label_en: "HRA Start Standard, 1.5m",
    label_no: "HRA Startspire, 1.5m"
  }
];
export const componentsBaseCollars: Array<Component> = [
  {
    article_id: "8102690",
    weight: 1.8,
    width: 0,
    material: "",
    length: 0.5,
    label_en: "HRA Start adapter",
    label_no: "HRA Startadapter",
    label_se: "HRA Startadapter"
  }
];

export const componentsStairwayGuardrailsInternal: Array<Component> = [
  {
    label_en: "Stairway guardrail internal 2,5x2m",
    width: 0,
    material: "",
    article_id: "8146890",
    length: 2.5,
    weight: 8,
    label_no: "Trapperekkverk inner 2,5x2m",
    label_se: "Innerräcke till trappa 2,5x2m",
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];
export const componentsStairwayGuardrails: Array<Component> = [
  {
    label_en: "Stairway guardrail 2,5",
    width: 0,
    material: "",
    article_id: "8146720",
    length: 2.5,
    weight: 13,
    label_no: "Trapperekkverk 2,5",
    label_se: "Trappräcke 2,5",
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsStartStairs: Component[] = [
  {
    width: 0.6,
    height: 1.75,
    label_en: "Stairway Aluminium Start 1,75m",
    article_id: "8145450",
    length: 1.92,
    label_se: "Aluminiumtrappa Mark 1.75m",
    weight: 17.1,
    material: "",
    label_no: "Aluminiumtrapp Mark 1.75m"
  },
  {
    width: 0.6,
    height: 1.5,
    label_en: "Stairway Aluminium Start 1.5m",
    article_id: "8145460",
    length: 1.7,
    label_se: "Aluminiumtrappa Mark 1.5m",
    weight: 15.4,
    material: "",
    label_no: "Aluminiumtrapp Mark 1.5m"
  },
  {
    width: 0.6,
    height: 1.25,
    label_en: "Stairway Aluminium Start 1.25m",
    article_id: "8145465",
    length: 1.45,
    label_se: "Aluminiumtrappa Mark 1.25m",
    weight: 13.5,
    material: "",
    label_no: "Aluminiumtrapp Mark 1.25m"
  },
  {
    width: 0.6,
    height: 1,
    label_en: "Stairway Aluminium Start 1m",
    article_id: "8145470",
    length: 1.28,
    label_se: "Aluminiumtrappa Mark 1m",
    weight: 11.6,
    material: "",
    label_no: "Aluminiumtrapp Mark 1m"
  }
];

export const componentsStairs: Array<Component> = [
  {
    article_id: "8145420",
    label_no: "Trapp 2,5",
    height: 2,
    width: 0.6,
    length: 2.5,
    label_en: "Stairway 2,5",
    weight: 22,
    material: "",
    label_se: "Trappa 2,5"
  }
];

export const componentsDiagonalBraces: Array<Component> = [
  /** Digaonal braces, 2m height */
  {
    label_no: "Diagonalstag DS 1,5x2m",
    material: "",
    label_en: "Diagonal Brace DS 1,5x2m",
    weight: 10.4,
    length: 1.5,
    width: 2,
    label_se: "Diagonalstag DS 1,5x2m",
    article_id: "8151530"
  },
  {
    material: "",
    length: 1.75,
    width: 2,
    label_se: "Diagonalstag DS 1,75x2m",
    weight: 10.9,
    article_id: "8154430",
    label_en: "Diagonal Brace DS 1,75x2m",
    label_no: "Diagonalstag DS 1,75x2m"
  },
  {
    width: 2,
    label_en: "Diagonal Brace DS 1,25x2m",
    label_no: "Diagonalstag DS 1,25x2m",
    weight: 10,
    length: 1.25,
    label_se: "Diagonalstag DS 1,25x2m",
    article_id: "8154630",
    material: ""
  },
  {
    material: "",
    length: 0.7,
    width: 2,
    label_en: "Diagonal Brace DS 0,7x2m",
    weight: 9.4,
    article_id: "8154830",
    label_no: "Diagonalstag DS 0,7x2m",
    label_se: "Diagonalstag DS 0,7x2m"
  },
  {
    length: 3.5,
    label_no: "Diagonalstag DS 3,5x2m",
    material: "",
    label_en: "Diagonal Brace DS 3,5x2m",
    label_se: "Diagonalstag DS 3,5x2m",
    weight: 15.1,
    width: 2,
    article_id: "8155000"
  },
  {
    article_id: "8154210",
    label_se: "Diagonalstag DS 2,5x2m",
    width: 2,
    material: "",
    weight: 12.5,
    label_no: "Diagonalstag DS 2,5x2m",
    label_en: "Diagonal Brace DS 2,5x2m",
    length: 2.5
  },
  {
    article_id: "8155010",
    length: 3,
    label_se: "Diagonalstag DS 3x2m",
    label_en: "Diagonal Brace DS 3x2m",
    material: "",
    weight: 13.8,
    width: 2,
    label_no: "Diagonalstag DS 3x2m"
  },
  {
    material: "",
    width: 2,
    label_no: "Diagonalstag DS 1x2m",
    length: 1,
    label_se: "Diagonalstag DS 1x2m",
    weight: 9.7,
    label_en: "Diagonal Brace DS 1x2m",
    article_id: "8157020"
  },
  {
    weight: 11.4,
    label_se: "Diagonalstag DS 2x2m",
    length: 2,
    width: 2,
    label_en: "Diagonal Brace DS 2x2m",
    material: "",
    label_no: "Diagonalstag DS 2x2m",
    article_id: "8153330"
  },

  /** Diagonal braces, 2.5m height */
  {
    weight: 16,
    label_se: "Diagonalstag DS 3.5x2.5m",
    length: 3.5,
    width: 2.5,
    label_en: "Diagonal Brace DS 3.5x2.5m",
    material: "",
    label_no: "Diagonalstag DS 3.5x2.5m",
    article_id: "8151300"
  },
  {
    weight: 14.8,
    label_se: "Diagonalstag DS 3x2.5m",
    length: 3,
    width: 2.5,
    label_en: "Diagonal Brace DS 3x2.5m",
    material: "",
    label_no: "Diagonalstag DS 3x2.5m",
    article_id: "8151310"
  },
  {
    weight: 13.7,
    label_se: "Diagonalstag DS 2.5x2.5m",
    length: 2.5,
    width: 2.5,
    label_en: "Diagonal Brace DS 2.5x2.5m",
    material: "",
    label_no: "Diagonalstag DS 2.5x2.5m",
    article_id: "8151320"
  },
  {
    weight: 12.6,
    label_se: "Diagonalstag DS 2x2.5m",
    length: 2,
    width: 2.5,
    label_en: "Diagonal Brace DS 2x2.5m",
    material: "",
    label_no: "Diagonalstag DS 2x2.5m",
    article_id: "8151330"
  },
  {
    weight: 12.2,
    label_se: "Diagonalstag DS 1.75x2.5m",
    length: 1.75,
    width: 2.5,
    label_en: "Diagonal Brace DS 1.75x2.5m",
    material: "",
    label_no: "Diagonalstag DS 1.75x2.5m",
    article_id: "8151340"
  },
  {
    weight: 11.8,
    label_se: "Diagonalstag DS 1.5x2.5m",
    length: 1.5,
    width: 2.5,
    label_en: "Diagonal Brace DS 1.5x2.5m",
    material: "",
    label_no: "Diagonalstag DS 1.5x2.5m",
    article_id: "8151360"
  },
  {
    weight: 11.5,
    label_se: "Diagonalstag DS 1.25x2.5m",
    length: 1.25,
    width: 2.5,
    label_en: "Diagonal Brace DS 1.25x2.5m",
    material: "",
    label_no: "Diagonalstag DS 1.25x2.5m",
    article_id: "8151350"
  },
  {
    weight: 11,
    label_se: "Diagonalstag DS 1x2.5m",
    length: 1,
    width: 2.5,
    label_en: "Diagonal Brace DS 1x2.5m",
    material: "",
    label_no: "Diagonalstag DS 1x2.5m",
    article_id: "8151370"
  },
  /** Diagonal braces, 1.5m height */
  {
    weight: 14.4,
    label_se: "Diagonalstag DS 3.5x1.5m",
    length: 3.5,
    width: 1.5,
    label_en: "Diagonal Brace DS 3.5x1.5m",
    material: "",
    label_no: "Diagonalstag DS 3.5x1.5m",
    article_id: "8151500"
  },
  {
    weight: 12.9,
    label_se: "Diagonalstag DS 3x1.5m",
    length: 3,
    width: 1.5,
    label_en: "Diagonal Brace DS 3x1.5m",
    material: "",
    label_no: "Diagonalstag DS 3x1.5m",
    article_id: "8151520"
  },
  {
    weight: 11.5,
    label_se: "Diagonalstag DS 2.5x1.5m",
    length: 2.5,
    width: 1.5,
    label_en: "Diagonal Brace DS 2.5x1.5m",
    material: "",
    label_no: "Diagonalstag DS 2.5x1.5m",
    article_id: "8151510"
  },
  {
    weight: 10.2,
    label_se: "Diagonalstag DS 2x1.5m",
    length: 2,
    width: 1.5,
    label_en: "Diagonal Brace DS 2x1.5m",
    material: "",
    label_no: "Diagonalstag DS 2x1.5m",
    article_id: "8151530"
  },
  {
    weight: 9.6,
    label_se: "Diagonalstag DS 1.75x1.5m",
    length: 1.75,
    width: 1.5,
    label_en: "Diagonal Brace DS 1.75x1.5m",
    material: "",
    label_no: "Diagonalstag DS 1.75x1.5m",
    article_id: "8151540"
  },

  {
    weight: 9.1,
    label_se: "Diagonalstag DS 1.5x1.5m",
    length: 1.5,
    width: 1.5,
    label_en: "Diagonal Brace DS 1.5x1.5m",
    material: "",
    label_no: "Diagonalstag DS 1.5x1.5m",
    article_id: "8151550"
  },
  {
    weight: 8.6,
    label_se: "Diagonalstag DS 1.25x1.5m",
    length: 1.25,
    width: 1.5,
    label_en: "Diagonal Brace DS 1.25x1.5m",
    material: "",
    label_no: "Diagonalstag DS 1.25x1.5m",
    article_id: "8151560"
  },
  {
    weight: 8.2,
    label_se: "Diagonalstag DS 1x1.5m",
    length: 1,
    width: 1.5,
    label_en: "Diagonal Brace DS 1x1.5m",
    material: "",
    label_no: "Diagonalstag DS 1x1.5m",
    article_id: "8151570"
  },
  {
    weight: 7.8,
    label_se: "Diagonalstag DS 0.7x1.5m",
    length: 0.7,
    width: 1.5,
    label_en: "Diagonal Brace DS 0.7x1.5m",
    material: "",
    label_no: "Diagonalstag DS 0.7x1.5m",
    article_id: "8151580"
  },
  /** Diagonal braces, 1m height */
  {
    weight: 13.8,
    label_se: "Diagonalstag DS 3.5x1m",
    length: 3.5,
    width: 1,
    label_en: "Diagonal Brace DS 3.5x1m",
    material: "",
    label_no: "Diagonalstag DS 3.5x1m",
    article_id: "8151700"
  },
  {
    weight: 12.2,
    label_se: "Diagonalstag DS 3x1m",
    length: 3,
    width: 1,
    label_en: "Diagonal Brace DS 3x1m",
    material: "",
    label_no: "Diagonalstag DS 3x1m",
    article_id: "8151710"
  },
  {
    weight: 10.7,
    label_se: "Diagonalstag DS 2.5x1m",
    length: 2.5,
    width: 1,
    label_en: "Diagonal Brace DS 2.5x1m",
    material: "",
    label_no: "Diagonalstag DS 2.5x1m",
    article_id: "8151720"
  },
  {
    weight: 9.3,
    label_se: "Diagonalstag DS 2x1m",
    length: 2,
    width: 1,
    label_en: "Diagonal Brace DS 2x1m",
    material: "",
    label_no: "Diagonalstag DS 2x1m",
    article_id: "8151730"
  },
  {
    weight: 8.6,
    label_se: "Diagonalstag DS 1.75x1m",
    length: 1.75,
    width: 1,
    label_en: "Diagonal Brace DS 1.75x1m",
    material: "",
    label_no: "Diagonalstag DS 1.75x1m",
    article_id: "8151740"
  },
  {
    weight: 7.9,
    label_se: "Diagonalstag DS 1.5x1m",
    length: 1.5,
    width: 1,
    label_en: "Diagonal Brace DS 1.5x1m",
    material: "",
    label_no: "Diagonalstag DS 1.5x1m",
    article_id: "8151750"
  },
  {
    weight: 7.3,
    label_se: "Diagonalstag DS 1.25x1m",
    length: 1.25,
    width: 1,
    label_en: "Diagonal Brace DS 1.25x1m",
    material: "",
    label_no: "Diagonalstag DS 1.25x1m",
    article_id: "8151760"
  },
  {
    weight: 6.8,
    label_se: "Diagonalstag DS 1x1m",
    length: 1,
    width: 1,
    label_en: "Diagonal Brace DS 1x1m",
    material: "",
    label_no: "Diagonalstag DS 1x1m",
    article_id: "8151770"
  },
  {
    weight: 6.3,
    label_se: "Diagonalstag DS 0.7x1m",
    length: 0.7,
    width: 1,
    label_en: "Diagonal Brace DS 0.7x1m",
    material: "",
    label_no: "Diagonalstag DS 0.7x1m",
    article_id: "8151780"
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    length: 0.5,
    width: 0,
    label_se: "AVS - Avväxlingsspira",
    material: "",
    label_no: "Bjelkespiss",
    article_id: "8100400",
    label_en: "Beam Spigot",
    weight: 3.5
  }
];
export const componentsCouplers: Array<Component> = [
  {
    label_no: "Stillas Kobling +8 Fast",
    label_se: "Ställningskoppling +8 Fast",
    article_id: "8150800",
    material: "",
    length: 0.07,
    label_en: "Scaffold coupler +8 Stiff",
    weight: 1.7,
    width: 0
  },
  {
    label_no: "Stillas Kobling +8 Fast",
    label_se: "Ställningskoppling +8 Fast",
    article_id: "8150830",
    material: "",
    length: 0.07,
    label_en: "Scaffold coupler +8 Stiff",
    weight: 1.7,
    width: 0
  },
  {
    label_no: "Stillas Kobling +8 Variabel",
    label_se: "Ställningskoppling +8 Variabel",
    article_id: "8150820",
    material: "",
    length: 0.07,
    label_en: "Scaffold coupler +8 Variable",
    weight: 1.8,
    width: 0
  },
  {
    label_no: "Stillas Kobling +8 Variabel",
    label_se: "Ställningskoppling +8 Variabel",
    article_id: "8150840",
    material: "",
    length: 0.07,
    label_en: "Scaffold coupler +8 Variable",
    weight: 1.8,
    width: 0
  }
];
export const componentsScrews: Array<Component> = [
  {
    material: "",
    article_id: "-",
    label_no: "Forankringsløkke",
    label_en: "Ring screw",
    label_se: "Förankringsögla",
    weight: 0.16,
    length: 0.095,
    width: 0
  }
];

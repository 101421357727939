import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { StairwayInnerGuardRailSlice } from "./stairwayInnerGuardrail.interface";
import {
  addStairwayInnerGuardRailsToGraph,
  removeStairwayInnerGuardRailsFromGraph,
  setStairwayInnerGuardRailsInGraph
} from "./stairwayInnerGuardRails.utils";

/** Initial state */
const initialStairwayInnerGuardRailState = {
  stairwayInnerGuardRails: []
};

/** Slice creation */
const createStairwayInnerGuardRailSlice: StateCreator<
  Store,
  [],
  [],
  StairwayInnerGuardRailSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialStairwayInnerGuardRailState));

  /** Return state */
  return {
    ...initialStairwayInnerGuardRailState,
    addStairwayInnerGuardRails: (stairwayInnerGuardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addStairwayInnerGuardRailsToGraph({
          graph: newGraph,
          stairwayInnerGuardRails
        });

        return {
          stairwayInnerGuardRails: [
            ...state.stairwayInnerGuardRails,
            ...stairwayInnerGuardRails
          ],
          graph: newGraph
        };
      });
    },
    setStairwayInnerGuardRails: (stairwayInnerGuardRails) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setStairwayInnerGuardRailsInGraph({
          graph: newGraph,
          newStairwayInnerGuardRails: stairwayInnerGuardRails,
          oldStairwayInnerGuardRails: [...state.stairwayInnerGuardRails]
        });

        return {
          stairwayInnerGuardRails,
          graph: newGraph
        };
      });
    },
    removeStairwayInnerGuardRails: (ids) => {
      set((state) => {
        const removeStairwayInnerGuardRails = [
          ...state.stairwayInnerGuardRails
        ];
        const newGraph = state.graph.copy();
        removeStairwayInnerGuardRailsFromGraph({
          graph: newGraph,
          stairwayInnerGuardRails: removeStairwayInnerGuardRails
        });
        return {
          stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
            (stairwayInnerGuardRail) => !ids.includes(stairwayInnerGuardRail.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createStairwayInnerGuardRailSlice;

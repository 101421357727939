import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { AnchorSlice } from "./anchor.interface";
import {
  addAnchorsToGraph,
  removeAnchorsFromGraph,
  setAnchorsInGraph
} from "./anchor.utils";

/** Initial state */
const initialAnchorState = {
  anchors: []
};

/** Slice creation */
const createAnchorSlice: StateCreator<Store, [], [], AnchorSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialAnchorState));

  /** Return state */
  return {
    ...initialAnchorState,
    addAnchors: (anchors) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addAnchorsToGraph({ graph: newGraph, anchors });

        return {
          anchors: [...state.anchors, ...anchors],
          graph: newGraph
        };
      });
    },
    setAnchors: (anchors) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setAnchorsInGraph({
          graph: newGraph,
          newAnchor: anchors,
          oldAnchor: [...state.anchors]
        });

        return {
          anchors,
          graph: newGraph
        };
      });
    },
    removeAnchors: (ids) => {
      set((state) => {
        const removeAnchor = [...state.anchors];
        const newGraph = state.graph.copy();
        removeAnchorsFromGraph({ graph: newGraph, anchors: removeAnchor });
        return {
          anchors: state.anchors.filter((anchor) => !ids.includes(anchor.id)),
          graph: newGraph
        };
      });
    }
  };
};

export default createAnchorSlice;

import { getGraphData } from "world/core/Anchor/Anchor.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Anchor } from "world/core/Anchor/Anchor.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding anchors to the graph.
 * @param props
 */
export const addAnchorsToGraph = (props: {
  graph: Graph;
  anchors: Anchor[];
}) => {
  const { graph, anchors } = props;

  const anchorsGraphData = anchors.map((anchor) => getGraphData(anchor));
  addComponentsToGraph({ graph, components: anchorsGraphData });
};

/**
 * Mutates the graph by removing anchors from the graph.
 * @param props
 */
export const removeAnchorsFromGraph = (props: {
  graph: Graph;
  anchors: Anchor[];
}) => {
  const { graph, anchors } = props;

  const anchorsGraphData = anchors.map((anchor) => {
    return getGraphData(anchor);
  });

  removeComponentsFromGraph({
    graph,
    components: anchorsGraphData,
    force: true
  });
};

export const setAnchorsInGraph = (props: {
  graph: Graph;
  newAnchor: Anchor[];
  oldAnchor: Anchor[];
}) => {
  const { graph, newAnchor, oldAnchor } = props;
  removeAnchorsFromGraph({ graph, anchors: oldAnchor });
  addAnchorsToGraph({ graph, anchors: newAnchor });
};

import { Palette } from "@mui/material";

const CornerMode3 = (props: { palette: Palette }) => {
  const { palette } = props;
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15"
        y="43"
        width="20"
        height="8"
        transform="rotate(-90 15 43)"
        fill={palette.world.box.main}
        fillOpacity="0.6"
      />
      <rect
        x="3.5"
        y="14.5"
        width="19"
        height="7"
        fill={palette.world.placeholder.main}
        fillOpacity="0.2"
        stroke={palette.world.placeholder.main}
      />
      <rect
        x="24.5"
        y="23.5"
        width="19"
        height="7"
        fill={palette.world.placeholder.main}
        fillOpacity="0.2"
        stroke={palette.world.placeholder.main}
      />
      <rect
        x="10.183"
        y="5.68301"
        width="19"
        height="7"
        transform="rotate(30 10.183 5.68301)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <rect
        x="24.683"
        y="23.183"
        width="19"
        height="7"
        transform="rotate(-30 24.683 23.183)"
        stroke={palette.world.placeholder.main}
        strokeDasharray="1 1"
      />
      <circle cx="23" cy="23" r="2.5" fill={palette.world.axes.x} />
    </svg>
  );
};

export default CornerMode3;

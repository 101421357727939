import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { WorldPlaneSlice } from "./worldPlane.interface";
import  { sliceResetFns } from "store/store";

import { WorldPlane } from "shared/interfaces/firestore";

/** Initial state */
const initialWorldPlaneState = {
  worldPlane: {
    id: "",
    size: 1000,
  } as WorldPlane
};

/** Slice creation */
const createWorldPlaneSlice: StateCreator<Store, [], [], WorldPlaneSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialWorldPlaneState));

  /** Return state */
  return {
    ...initialWorldPlaneState,
    worldPlaneActions: {
        set: (worldPlane: WorldPlane) => {
          set({ worldPlane });
        },
    }
  };
};


export default createWorldPlaneSlice;

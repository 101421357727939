import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import {
  componentsDecks,
  componentsStairs,
  componentsStartStairs
} from "../components";
import { calculateOptimalSegmentLengthCombination } from "math/optimizers";
import { BoxFrame } from "shared/interfaces/firestore";

export const generateOptimalPlankConfiguration = (props: {
  length: number;
  width: number;
  plankType?: SCAFFOLD_PLANKTYPE;
}) => {
  const { length, width, plankType } = props;
  if (!plankType) return [];
  const decks = componentsDecks.filter((deck) => deck.material === plankType);

  const correctLengthDecks = decks.filter((deck) => deck.length === length);

  if (correctLengthDecks.length === 0) {
    return [];
  }

  const uniqueWidths = [
    ...new Set(correctLengthDecks.map((deck) => deck.width))
  ];

  return calculateOptimalSegmentLengthCombination({
    lengthSegments: uniqueWidths,
    targetLength: width
  });
};

export const getStair = (props: {
  sortedFrames: BoxFrame[];
  currentFrameIdx: number;
  lowestCollarHeight: number;
  length: number;
}) => {
  const { sortedFrames, currentFrameIdx, lowestCollarHeight, length } = props;
  const stairComponents = [
    ...componentsStartStairs,
    ...componentsStairs.filter((stair) => stair.length === length)
  ];
  let lowerFrameHeight = lowestCollarHeight;
  const currentFrame = sortedFrames[currentFrameIdx];
  if (!currentFrame) return;

  if (currentFrameIdx < sortedFrames.length - 1) {
    lowerFrameHeight = sortedFrames[currentFrameIdx + 1]?.height;
  }

  const heightBetweenFrames = currentFrame.height - lowerFrameHeight;
  const suitableStairs = stairComponents.filter(
    (stair) => stair.height && stair.height <= heightBetweenFrames
  );

  return suitableStairs.sort((a, b) => (b.height ?? 0) - (a.height ?? 0))[0];
};

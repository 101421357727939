import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsDiagonalBraces: Component[] = [
  /** Diagonal braces 2m height */
  {
    material: "",
    label_no: "Diagonalstag 2,0x1,57m (med kile)",
    label_se: "Diagonalstag 2,0x1,57m (med kil)",
    article_id: "e284815",
    weight: 5.9,
    height: 2,
    width: 0,
    label_en: "Diagonal brace 2,0x1,57m (with wedge)",
    length: 1.57
  },
  {
    length: 1.77,
    weight: 8.2,
    width: 0,
    height: 2,
    label_se: "Diagonalstag 1,77m",
    article_id: "e285179",
    label_no: "Diagonalstag 1,77m",
    material: "",
    label_en: "Diagonal brace 1,77m"
  },
  {
    weight: 7.6,
    material: "",
    height: 2,
    width: 0,
    length: 2.57,
    article_id: "e284825",
    label_se: "Diagonalstag 2,0x2,57m (med kil)",
    label_no: "Diagonalstag 2,0x2,57m (med kile)",
    label_en: "Diagonal brace 2,0x2,57m (with wedge)"
  },
  {
    label_se: "Diagonalstag 2,0x2,57m",
    material: "",
    width: 0,
    height: 2,
    weight: 7.4,
    article_id: "e284725",
    length: 2.57,
    label_en: "Diagonal brace 2,0x2,57m",
    label_no: "Diagonalstag 2,0x2,57m"
  },
  {
    article_id: "e284730",
    label_no: "Diagonalstag 2,0x3,07m",
    material: "",
    height: 2,
    width: 0,
    label_se: "Diagonalstag 2,0x3,07m",
    label_en: "Diagonal brace 2,0x3,07m",
    weight: 8.3,
    length: 3.07
  },
  {
    label_se: "Diagonalstag 2,0x2,07m",
    length: 2.07,
    height: 2,
    width: 0,
    material: "",
    label_en: "Diagonal brace 2,0x2,07m",
    weight: 6.6,
    label_no: "Diagonalstag 2,0x2,07m",
    article_id: "e284720"
  },
  {
    width: 0,
    height: 2,
    material: "",
    label_se: "Diagonalstag 2,0x1,57m",
    length: 1.57,
    weight: 5.8,
    article_id: "e284715",
    label_no: "Diagonalstag 2,0x1,57m",
    label_en: "Diagonal brace 2,0x1,57m"
  },
  {
    label_en: "Diagonal brace 2,0x3,07m (with wedge)",
    label_no: "Diagonalstag 2,0x3,07m (med kile)",
    length: 3.07,
    height: 2,
    material: "",
    label_se: "Diagonalstag 2,0x3,07m (med kil)",
    width: 0,
    weight: 8.5,
    article_id: "e284830"
  },
  {
    label_en: "Diagonal brace 2,0x2,07m (with wedge)",
    article_id: "e284820",
    label_no: "Diagonalstag 2,0x2,07m (med kile)",
    length: 2.07,
    height: 2,
    width: 0,
    label_se: "Diagonalstag 2,0x2,07m (med kil)",
    weight: 6.8,
    material: ""
  },
  {
    label_en: "Diagonal brace 1,95m",
    width: 0,
    article_id: "e285119",
    label_no: "Diagonalstag 1,95m",
    length: 1.95,
    height: 2,
    weight: 8.8,
    label_se: "Diagonalstag 1,95m",
    material: ""
  }
];

export const componentsTrussbeams: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    length: 6,
    label_en: "Truss beam steel 0,4x6,0m",
    weight: 60.3,
    label_no: "Fagverksbjelke stål 0,4x6,0m",
    article_id: "e503360",
    label_se: "Fackverksbalk stål 0,4x6,0m"
  },
  {
    width: 0,
    label_en: "Truss beam steel 0,4x3,24m",
    weight: 36.4,
    article_id: "e503230",
    length: 3.24,
    label_se: "Fackverksbalk stål 0,4x3,24m",
    label_no: "Fagverksbjelke stål 0,4x3,24m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_en: "Truss beam aluminium 0,4x4,0m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Fagverksbjelke aluminium 0,4x4,0m",
    article_id: "e501240",
    label_se: "Fackverksbalk aluminium 0,4x4,0m",
    length: 4,
    weight: 17,
    width: 0
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Fackverksbalk aluminium 0,4x5,24m",
    length: 5.24,
    label_en: "Truss beam aluminium 0,4x5,24m",
    weight: 20.9,
    article_id: "e501252",
    width: 0,
    label_no: "Fagverksbjelke aluminium 0,4x5,24m"
  },
  {
    weight: 41.3,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "e503340",
    label_en: "Truss beam steel 0,4x4,0m",
    label_se: "Fackverksbalk stål 0,4x4,0m",
    width: 0,
    label_no: "Fagverksbjelke stål 0,4x4,0m",
    length: 4
  },
  {
    article_id: "e287761",
    weight: 62.6,
    label_no: "Fagverksbjelke stål 0,4x6,14m",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    label_se: "Fackverksbalk stål 0,4x6,14m",
    label_en: "Truss beam steel 0,4x6,14m",
    length: 6.14
  },
  {
    label_no: "Fagverksbjelke aluminium 0,5x4,24m",
    label_en: "Truss beam aluminium 0,5x4,24m",
    label_se: "Fackverksbalk aluminium 0,5x4,24m",
    article_id: "e501340",
    weight: 18.8,
    length: 4.24,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0
  },
  {
    label_se: "Fackverksbalk aluminium 0,5x3,24m",
    width: 0,
    label_no: "Fagverksbjelke aluminium 0,5x3,24m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "e501330",
    length: 3.24,
    label_en: "Truss beam aluminium 0,5x3,24m",
    weight: 14.9
  },
  {
    width: 0,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 4.24,
    weight: 45.6,
    label_no: "Fagverksbjelke stål 0,4x4,24m",
    article_id: "e503240",
    label_en: "Truss beam steel 0,4x4,24m",
    label_se: "Fackverksbalk stål 0,4x4,24m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 3,
    width: 0,
    article_id: "e503330",
    weight: 30.6,
    label_no: "Fagverksbjelke stål 0,4x3,0m",
    label_se: "Fackverksbalk stål 0,4x3,0m",
    label_en: "Truss beam steel 0,4x3,0m"
  },
  {
    length: 6.14,
    label_no: "Fagverksbjelke stål 0,4x6,14m - gjennomgang",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Truss beam steel 0,4x6,14m - pass through",
    label_se: "Fackverksbalk stål 0,4x6,14m - genomgång",
    weight: 62.1,
    article_id: "e287762",
    width: 0
  },
  {
    article_id: "e287754",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    length: 5.14,
    label_se: "Fackverksbalk stål 0,4x5,14m",
    label_en: "Truss beam steel 0,4x5,14m",
    label_no: "Fagverksbjelke stål 0,4x5,14m",
    weight: 56
  },
  {
    label_no: "Fagverksbjelke aluminium 0,4x8,0m",
    article_id: "e501280",
    weight: 33.2,
    label_se: "Fackverksbalk aluminium 0,4x8,0m",
    length: 8,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0,
    label_en: "Truss beam aluminium 0,4x8,0m"
  },
  {
    article_id: "e501260",
    width: 0,
    length: 6,
    label_no: "Fagverksbjelke aluminium 0,4x6,0m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Truss beam aluminium 0,4x6,0m",
    label_se: "Fackverksbalk aluminium 0,4x6,0m",
    weight: 24.7
  },
  {
    label_en: "Truss beam aluminium 0,5x5,24m",
    length: 5.24,
    weight: 22.6,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0,
    article_id: "e501350",
    label_se: "Fackverksbalk aluminium 0,5x5,24m",
    label_no: "Fagverksbjelke aluminium 0,5x5,24m"
  },
  {
    article_id: "e503320",
    label_no: "Fagverksbjelke aluminium 0,4x2,0m",
    length: 2,
    weight: 21.1,
    label_se: "Fackverksbalk aluminium 0,4x2,0m",
    width: 0,
    label_en: "Truss beam aluminium 0,4x2,0m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_en: "Truss beam steel 0,4x5,24m",
    length: 5.24,
    label_se: "Fackverksbalk stål 0,4x5,24m",
    article_id: "e503250",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    label_no: "Fagverksbjelke stål 0,4x5,24m",
    weight: 54.8
  },
  {
    length: 6.24,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    weight: 64.8,
    label_se: "Fackverksbalk stål 0,4x6,24m",
    article_id: "e503260",
    label_en: "Truss beam steel 0,4x6,24m",
    label_no: "Fagverksbjelke stål 0,4x6,24m"
  },
  {
    label_se: "Fackverksbalk aluminium 0,4x3,0m",
    article_id: "e501230",
    length: 3,
    label_en: "Truss beam aluminium 0,4x3,0m",
    width: 0,
    weight: 12.7,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Fagverksbjelke aluminium 0,4x3,0m"
  },
  {
    length: 6.24,
    weight: 26.4,
    label_se: "Fackverksbalk aluminium 0,5x6,24m",
    label_en: "Truss beam aluminium 0,5x6,24m",
    article_id: "e501360",
    width: 0,
    label_no: "Fagverksbjelke aluminium 0,5x6,24m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    length: 6.24,
    width: 0,
    label_no: "Fagverksbjelke aluminium 0,4x6,24m",
    article_id: "e501262",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Truss beam aluminium 0,4x6,24m",
    weight: 25.1,
    label_se: "Fackverksbalk aluminium 0,4x6,24m"
  }
];

export const componentsDecks: Component[] = [
  //   {
  //     label_no: "Stålplanke 0,3x2,0m med beskyttelse",
  //     label_en: "Steel plank  0,3x2,0m with protection",
  //     weight: 13,
  //     width: 0.3,
  //     article_id: "e494120",
  //     length: 2,
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_se: "Stålplank 0,3x2,0m med skydd"
  //   },
  {
    label_en: "Steel plank 0,19x2,07m - U",
    label_no: "Stålplank 0,19x2,07m - U",
    length: 2.07,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.19,
    label_se: "Stålplank 0,19x2,07m - U",
    article_id: "e491820",
    weight: 11.6
  },
  {
    article_id: "e491310",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Stålplank 0,32x1,09m - U",
    label_no: "Stålplank 0,32x1,09m - U",
    label_en: "Steel plank 0,32x1,09m - U",
    weight: 8.5,
    length: 1.09,
    width: 0.32
  },
  {
    article_id: "e491320",
    weight: 15.1,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 2.07,
    width: 0.32,
    label_en: "Steel plank 0,32x2,07m - U",
    label_se: "Stålplank 0,32x2,07m - U",
    label_no: "Stålplank 0,32x2,07m - U"
  },
  {
    length: 1.57,
    width: 0.32,
    article_id: "e491315",
    label_no: "Stålplank 0,32x1,57m - U",
    weight: 11.7,
    label_se: "Stålplank 0,32x1,57m - U",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank 0,32x1,57m - U"
  },
  //   {
  //     article_id: "e495025",
  //     width: 0.3,
  //     label_no: "Stålplanke 0,3x2.5m",
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_en: "Steel plank  0,3x2.5m",
  //     label_se: "Stålplank 0,3x2.5m",
  //     weight: 16,
  //     length: 2.5
  //   },
  {
    label_en: "Steel plank 0,19x1,57m - U",
    label_no: "Stålplank 0,19x1,57m - U",
    label_se: "Stålplank 0,19x1,57m - U",
    length: 1.57,
    weight: 9,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.19,
    article_id: "e491815"
  },
  {
    label_se: "Stålplank 0,19x2,57m - U",
    label_en: "Steel plank 0,19x2,57m - U",
    weight: 14.3,
    label_no: "Stålplank 0,19x2,57m - U",
    width: 0.19,
    length: 2.57,
    article_id: "e491825",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    width: 0.32,
    label_se: "Stålplank 0,32x4,14m - U",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "e491440",
    weight: 29.6,
    label_no: "Stålplank 0,32x4,14m - U",
    label_en: "Steel plank 0,32x4,14m - U",
    length: 4.14
  },
  {
    weight: 4.6,
    article_id: "e491807",
    length: 0.73,
    label_no: "Stålplank 0,19x0,73m - U",
    label_se: "Stålplank 0,19x0,73m - U",
    width: 0.19,
    label_en: "Steel plank 0,19x0,73m - U",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  //   {
  //     width: 0.3,
  //     label_no: "Stålplanke 0,3x3.0m",
  //     label_en: "Steel plank  0,3x3.0m",
  //     length: 3,
  //     weight: 19,
  //     article_id: "e495030",
  //     label_se: "Stålplank 0,3x3.0m",
  //     material: SCAFFOLD_PLANKTYPE.STEEL
  //   },
  {
    label_en: "Steel plank 0,32x3,07m - U",
    length: 3.07,
    weight: 21.9,
    label_se: "Stålplank 0,32x3,07m - U",
    label_no: "Stålplank 0,32x3,07m - U",
    article_id: "e491330",
    width: 0.32,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  {
    label_se: "Stålplank 0,32x2,57m - U",
    article_id: "e491325",
    label_no: "Stålplank 0,32x2,57m - U",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    length: 2.57,
    width: 0.32,
    weight: 18.5,
    label_en: "Steel plank 0,32x2,57m - U"
  },
  {
    label_se: "Stålplank 0,19x1,09m - U",
    label_en: "Steel plank 0,19x1,09m - U",
    length: 1.09,
    article_id: "e491810",
    weight: 6.5,
    label_no: "Stålplank 0,19x1,09m - U",
    width: 0.19,
    material: SCAFFOLD_PLANKTYPE.STEEL
  },
  //   {
  //     width: 0.3,
  //     weight: 10,
  //     label_en: "Steel plank  0,3x1,5m with protection",
  //     length: 1.5,
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_no: "Stålplanke 0,3x1,5m med beskyttelse",
  //     article_id: "e494115",
  //     label_se: "Stålplank 0,3x1,5m med skydd"
  //   },
  //   {
  //     label_no: "Stålplanke 0,19x1,0m med beskyttelse",
  //     label_en: "Steel plank  0,19x1,0m with protection",
  //     weight: 5.2,
  //     length: 1,
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_se: "Stålplank 0,19x1,0m med skydd",
  //     article_id: "e494210",
  //     width: 0.19
  //   },
  //   {
  //     weight: 10.1,
  //     label_se: "Stålplank 0,19x2,0m med skydd",
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_no: "Stålplanke 0,19x2,0m med beskyttelse",
  //     length: 2,
  //     label_en: "Steel plank  0,19x2,0m with protection",
  //     width: 0.19,
  //     article_id: "e494220"
  //   },
  //   {
  //     article_id: "e495015",
  //     length: 1.5,
  //     label_en: "Steel plank 0,3x1.5m",
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_se: "Stålplank 0,3x1.5m",
  //     label_no: "Stålplanke 0,3x1.5m",
  //     weight: 9.9,
  //     width: 0.3
  //   },
  //   {
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     weight: 7,
  //     width: 0.3,
  //     length: 1,
  //     label_no: "Stålplanke 0,3x1,0m med beskyttelse",
  //     label_se: "Stålplank 0,3x1,0m med skydd",
  //     article_id: "e494110",
  //     label_en: "Steel plank  0,3x1,0m with protection"
  //   },
  //   {
  //     label_no: "Stålplanke 0,19x1,5m med beskyttelse",
  //     article_id: "e494215",
  //     label_en: "Steel plank  0,19x1,5m with protection",
  //     label_se: "Stålplank 0,19x1,5m med skydd",
  //     length: 1.5,
  //     weight: 7.6,
  //     width: 0.19,
  //     material: SCAFFOLD_PLANKTYPE.STEEL
  //   },
  {
    weight: 6,
    label_se: "Stålplank 0,32x0,73m - U",
    label_no: "Stålplank 0,32x0,73m - U",
    label_en: "Steel plank 0,32x0,73m - U",
    length: 0.73,
    article_id: "e491307",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.32
  },
  //   {
  //     article_id: "e495020",
  //     length: 2,
  //     weight: 12.9,
  //     label_se: "Stålplank 0,3x2.0m",
  //     label_no: "Stålplanke 0,3x2.0m",
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     width: 0.3,
  //     label_en: "Steel plank  0,3x2.0m"
  //   },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel plank 0,19x3,07m - U",
    article_id: "e491830",
    label_no: "Stålplank 0,19x3,07m - U",
    width: 0.19,
    weight: 16.9,
    length: 3.07,
    label_se: "Stålplank 0,19x3,07m - U"
  }
];

export const componentsPlatforms: Component[] = [
  {
    length: 1.57,
    article_id: "e491915",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluminiumsplattform 0,61x1,57m",
    label_en: "Aluminium platform 0,61x1,57m",
    weight: 13.8,
    label_se: "Aluminiumplattform 0,61x1,57m",
    width: 0.61
  },
  {
    label_no: "Aluminiumsplattform 0,61x3,07m",
    article_id: "e491930",
    label_se: "Aluminiumplattform 0,61x3,07m",
    weight: 23,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Aluminium platform 0,61x3,07m",
    width: 0.61,
    length: 3.07
  },
  {
    label_no: "Aluminiumsplattform 0,61x2,57m",
    width: 0.61,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.57,
    article_id: "e491925",
    label_en: "Aluminium platform 0,61x2,57m",
    label_se: "Aluminiumplattform 0,61x2,57m",
    weight: 19.9
  },
  {
    weight: 10.7,
    article_id: "e491910",
    label_no: "Aluminiumsplattform 0,61x1,09m",
    length: 1.09,
    label_se: "Aluminiumplattform 0,61x1,09m",
    width: 0.61,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_en: "Aluminium platform 0,61x1,09m"
  },
  {
    weight: 17,
    width: 0.61,
    article_id: "e491920",
    label_no: "Aluminiumsplattform 0,61x2,07m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.07,
    label_se: "Aluminiumplattform 0,61x2,07m",
    label_en: "Aluminium platform 0,61x2,07m"
  }
];

export const componentsPassageFrames: Component[] = [
  {
    label_en: "Passthrough frame 1,5x2,2m",
    weight: 35.8,
    width: 1.55,
    article_id: "e202195",
    label_no: "Gjennomgang ramme 1,55x2,2m",
    material: "",
    label_se: "Genomgångsram 1,5x2,2m",
    length: 2.2
  }
  //   {
  //     article_id: "e492530",
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     label_en: "Walk-through platform made of aluminium. 0.61x3.07m - U",
  //     weight: 25.2,
  //     label_se: "Genomgångsplattform av aluminium. 0,61x3,07m - U",
  //     length: 3.07,
  //     width: 0.61,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x3,07m - U"
  //   },
  //   {
  //     width: 0.61,
  //     label_se: "Genomgångsplattform av aluminium. 0,61x3,07m - O",
  //     label_en: "Walk-through platform made of aluminium. 0.61x3.07m - O",
  //     weight: 31,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x3,07m - O",
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     length: 3.07,
  //     article_id: "e492630"
  //   },
  //   {
  //     weight: 16.2,
  //     width: 0.61,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x1,57m - U",
  //     label_en: "Walk-through platform made of aluminium. 0.61x1.57m - U",
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     label_se: "Genomgångsplattform av aluminium. 0,61x1,57m - U",
  //     article_id: "e492515",
  //     length: 1.57
  //   },
  //   {
  //     label_se: "Genomgångsplattform av aluminium. 0,61x2,57m - U",
  //     article_id: "e492525",
  //     weight: 22.3,
  //     width: 0.61,
  //     length: 2.57,
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x2,57m - U",
  //     label_en: "Walk-through platform made of aluminium. 0.61x2.57m - U"
  //   },
  //   {
  //     label_en: "Walk-through platform made of aluminium. 0.61x2.57m - O",
  //     weight: 28.1,
  //     label_se: "Genomgångsplattform av aluminium. 0,61x2,57m - O",
  //     width: 0.61,
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     article_id: "e492625",
  //     length: 2.57,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x2,57m - O"
  //   },
  //   {
  //     label_en: "Walk-through platform made of aluminium. 0.61x2.07m - O",
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     width: 0.61,
  //     weight: 20.3,
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x2,07m - O",
  //     article_id: "e492620",
  //     label_se: "Genomgångsplattform av aluminium. 0,61x2,07m - O",
  //     length: 2.07
  //   },
  //   {
  //     label_se: "Genomgångsplattform av aluminium. 0,61x2,07m - U",
  //     weight: 19.4,
  //     length: 2.07,
  //     width: 0.61,
  //     material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //     label_en: "Walk-through platform made of aluminium. 0.61x2.07m - U",
  //     label_no: "Gjennomgangsplattform av aluminium. 0,61x2,07m - U",
  //     article_id: "e492520"
  //   }
];

export const componentsTopFrames: Component[] = [
  {
    width: 0.73,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 1,
    article_id: "e203080",
    label_no: "Rekkverksramme aluminium. Plus 0,73x1,0m",
    label_en: "Gabel rail frame aluminium. Plus 0,73x1,0m",
    weight: 6.1,
    label_se: "Gavelräckesram aluminium. Plus 0,73x1,0m",
    variant: FRAME_VARIANT.TOP_END
  },
  {
    label_en: "Gabel rail frame 0,73x1,0m",
    weight: 11.3,
    material: "",
    article_id: "e202023",
    label_no: "Rekkverksramme 0,73x1,0m",
    label_se: "Gavelräckesram 0,73x1,0m",
    length: 1,
    width: 0.73,
    variant: FRAME_VARIANT.TOP_END
  },
  {
    weight: 3.3,
    width: 0.73,
    label_en: "Railing post aluminium. 0,73x1,0m (with protection)",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 1,
    article_id: "e203083",
    label_no: "Rekkverksstolpe aluminium. 0,73x1,0m (med beskyttelse)",
    label_se: "Räckesstolpe aluminium. 0,73x1,0m (med skydd)",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    label_no: "Rekkverksstolpe Plus 0,73x1,0m (med beskyttelse)",
    label_se: "Räckesstolpe Plus 0,73x1,0m (med skydd)",
    article_id: "e202091",
    length: 1,
    width: 0.73,
    weight: 5.8,
    label_en: "Railing post Plus 0,73x1,0m (with protection)",
    material: "",
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  },
  {
    weight: 2.8,
    label_no: "Rekkverksstolpe aluminium.1,0m",
    width: 0.73,
    article_id: "e203084",
    label_se: "Räckesstolpe aluminium. 1,0m",
    length: 1,
    label_en: "Railing post aluminium. 1,0m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_no: "Rekkverksstolpe Plus 1,0m",
    weight: 5.4,
    width: 0.73,
    article_id: "e202085",
    label_se: "Räckesstolpe Plus 1,0m",
    label_en: "Railing post Plus 1,0m",
    material: "",
    length: 1,
    variant: FRAME_VARIANT.TOP_INTERMEDIATE
  }
  //   {
  //     length: 1,
  //     article_id: "e491001",
  //     label_en: "Railing post",
  //     label_no: "Rekkverksstolpe",
  //     weight: 2.5,
  //     label_se: "Räckesstolpe",
  //     width: 0,
  //     material: "",
  //     variant: FRAME_VARIANT.TOP_INTERMEDIATE
  //   }
];

export const componentsFrames: Component[] = [
  //   {
  //     length: 2,
  //     label_no: "Bypass ramme 0,73x2,0m",
  //     article_id: "e202019",
  //     width: 0.73,
  //     weight: 25.1,
  //     material: "",
  //     label_en: "Bypass frame 0,73x2,0m",
  //     label_se: "By-pass ram 0,73x2,0m"
  //   },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Steel frame. Plus 0,73x2,0m",
    label_se: "Stålram. Plus 0,73x2,0m",
    length: 2,
    article_id: "e202026",
    label_no: "Stålramme. Plus 0,73x2,0m",
    weight: 19.7,
    width: 0.73
  },
  {
    width: 0.73,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 16.1,
    article_id: "e202027",
    label_se: "Stålram. Plus 0,73x1,5m",
    length: 1.5,
    label_no: "Stålramme. Plus 0,73x1,5m",
    label_en: "Steel frame. Plus 0,73x1,5m"
  },
  //   {
  //     material: SCAFFOLD_PLANKTYPE.STEEL,
  //     label_en: "Steel frame. Plus 0,36x2,0m",
  //     length: 2,
  //     width: 0.36,
  //     weight: 17.8,
  //     label_no: "Stålramme. Plus 0,36x2,0m",
  //     article_id: "e202018",
  //     label_se: "Stålram. Plus 0,36x2,0m"
  //   },
  {
    width: 0.73,
    article_id: "e202029",
    length: 0.6,
    weight: 10.2,
    label_en: "Steel frame. Plus 0,73x0,6m",
    label_se: "Stålram. Plus 0,73x0,6m",
    label_no: "Stålramme. Plus 0,73x0,6m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  },

  {
    label_se: "Stålram. Plus 0,73x1,0m",
    length: 1,
    article_id: "e202028",
    weight: 13,
    width: 0.73,
    label_en: "Steel frame. Plus 0,73x1,0m",
    label_no: "Stålramme. Plus 0,73x1,0m",
    material: SCAFFOLD_PLANKTYPE.STEEL
  }
];

export const componentsBaseplates: Component[] = [
  {
    material: "",
    label_no: "Bottenskrue 0,8m atlenkiama ledad",
    weight: 7.9,
    length: 0.8,
    label_se: "Bottenskruv 0,8m atlenkiama ledad",
    article_id: "e511408",
    width: 0,
    label_en: "Bottom screw 0,8m atlenkiama ledad"
  },
  {
    material: "",
    label_en: "Bottom screw 0,8m",
    article_id: "e511208",
    length: 0.8,
    width: 0,
    weight: 5.3,
    label_no: "Bottenskrue 0,8m",
    label_se: "Bottenskruv 0,8m"
  },
  {
    weight: 9.8,
    label_se: "Bottenskruv 1,5m",
    label_no: "Bottenskrue 1,5m",
    article_id: "e511313",
    length: 1.5,
    material: "",
    label_en: "Bottom screw 1,5m",
    width: 0
  },
  {
    weight: 3.4,
    label_en: "Bottom screw 0,4m",
    width: 0,
    label_se: "Bottenskruv 0,4m",
    article_id: "e511204",
    material: "",
    label_no: "Bottenskrue 0,4m",
    length: 0.4
  },
  {
    weight: 4.4,
    length: 0.6,
    width: 0,
    label_se: "Bottenskruv 0,6m",
    article_id: "e511206",
    material: "",
    label_en: "Bottom screw 0,6m",
    label_no: "Bottenskrue 0,6m"
  }
];

export const componentsULedgers: Component[] = [
  {
    label_no: "U-tverrstang 0,73m",
    article_id: "e285379",
    material: "",
    label_en: "U-ledge beam 0,73m",
    length: 0.73,
    width: 0,
    label_se: "U-tvärbom 0,73m",
    weight: 3.3,
    variant: LEDGER_VARIANT.U_LEDGER
  },
  {
    label_no: "U-tverrstang 1,09m",
    width: 0,
    label_se: "U-tvärbom 1,09m",
    length: 1.09,
    label_en: "U-ledge beam 1,09m",
    weight: 6,
    article_id: "e285319",
    material: "",
    variant: LEDGER_VARIANT.U_LEDGER
  }
];

export const componentsStarterTransoms: Component[] = [
  /*4a-b*/
  {
    label_se: "U-tvärbom för bottenbomlag 0,73m",
    weight: 3,
    material: "",
    article_id: "e286207",
    label_no: "U-tverrstang for bunn bomlag 0,73m",
    length: 0.73,
    height: 0.1,
    width: 0,
    label_en: "U-ledge beam for bottom deck 0,73m",
    variant: LEDGER_VARIANT.U_LEDGER
  }
];

export const componentsOLedgers: Component[] = [
  {
    label_no: "Horisontalstag 2.07m",
    width: 0,
    length: 2.07,
    weight: 9.2,
    material: "",
    article_id: "e283820",
    label_en: "Horisontal brace 2.07m",
    label_se: "Horisontalstag 2.07m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    article_id: "e283830",
    material: "",
    width: 0,
    length: 3.07,
    weight: 13,
    label_no: "Horisontalstag 3.07m",
    label_se: "Horisontalstag 3.07m",
    label_en: "Horisontal brace 3.07m",
    variant: LEDGER_VARIANT.O_LEDGER
  },
  {
    label_se: "Horisontalstag 2.57m",
    label_en: "Horisontal brace 2.57m",
    article_id: "e283825",
    material: "",
    length: 2.57,
    width: 0,
    label_no: "Horisontalstag 2.57m",
    weight: 11.1,
    variant: LEDGER_VARIANT.O_LEDGER
  }
];

export const componentsGuardRails: Component[] = [
  {
    length: 0.73,
    width: 0.5,
    label_en: "Gabel rail 0,73m (with wedge)",
    label_se: "Gavelräcke 0,73m (med kil)",
    weight: 3.8,
    label_no: "Gavlrekkverk 0,73m (med kile)",
    material: "",
    article_id: "e283007",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    width: 0.5,
    weight: 3.7,
    article_id: "e283907",
    material: "",
    length: 0.73,
    label_en: "Gabel rail 0,73m",
    label_no: "Gavlrekkverk 0,73m",
    label_se: "Gavelräcke 0,73m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    label_en: "Double rail aluminium. 3,07m ",
    article_id: "e284330",
    label_se: "Dubbelräcke aluminium. 3,07m ",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.7,
    width: 0.5,
    length: 3.07,
    label_no: "Dobbelt rekkverk aluminium. 3,07m ",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 2.07,
    article_id: "e284320",
    label_se: "Dubbelräcke aluminium. 2,07m ",
    label_no: "Dobbelt rekkverk aluminium. 2,07m ",
    weight: 4.8,
    label_en: "Double rail aluminium. 2,07m ",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0.5,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "e284325",
    length: 2.57,
    label_se: "Dubbelräcke aluminium. 2,57m ",
    width: 0.5,
    label_no: "Dobbelt rekkverk aluminium. 2,57m ",
    label_en: "Double rail aluminium. 2,57m ",
    weight: 5.8,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },

  {
    label_en: "Double rail steel. 1,57m ",
    article_id: "e284215",
    width: 0.5,
    weight: 7.3,
    label_no: "Dobbelt rekkverk stål. 1,57m ",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Dubbelräcke stål. 1,57m ",
    length: 1.57,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 1.57,
    width: 0.5,
    weight: 3.9,
    label_en: "Double rail aluminium. 1,57m ",
    label_no: "Dobbelt rekkverk aluminium. 1,57m ",
    article_id: "e284315",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_se: "Dubbelräcke aluminium. 1,57m ",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    width: 0.5,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "e284310",
    weight: 2.7,
    length: 1.09,
    label_en: "Double rail aluminium. 1,09m ",
    label_se: "Dubbelräcke aluminium. 1,09m ",
    label_no: "Dobbelt rekkverk aluminium. 1,09m ",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    length: 3.07,
    label_en: "Double rail steel. 3,07m ",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Dubbelräcke stål. 3,07m ",
    article_id: "e284230",
    width: 0.5,
    weight: 13.2,
    label_no: "Dobbelt rekkverk stål. 3,07m ",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0.5,
    weight: 11.4,
    article_id: "e284225",
    label_en: "Double rail steel. 2,57m ",
    label_se: "Dubbelräcke stål. 2,57m ",
    length: 2.57,
    label_no: "Dobbelt rekkverk stål. 2,57m ",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    weight: 9.1,
    label_en: "Double rail steel. 2,07m ",
    label_se: "Dubbelräcke stål. 2,07m ",
    label_no: "Dobbelt rekkverk stål. 2,07m ",
    length: 2.07,
    width: 0.5,
    article_id: "e284220",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  }
];
export const componentsSingleGuardRails: Component[] = [
  {
    weight: 1.5,
    width: 0,
    length: 0.73,
    article_id: "e383607",
    label_se: "Enkelräcke stål. 0,73m ",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_en: "Simple rail steel. 0,73m",
    label_no: "Enkelt rekkverk stål. 0,73m"
  },
  {
    label_en: "Simple rail steel. 1,09m ",
    label_no: "Enkelt rekkverk stål. 1,09m ",
    width: 0,
    length: 1.09,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "e283610",
    label_se: "Enkelräcke stål. 1,09m ",
    weight: 2.1
  },
  {
    width: 0,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    label_se: "Enkelräcke stål. 2,07m ",
    article_id: "e283620",
    label_en: "Simple rail steel. 2,07m ",
    label_no: "Enkelt rekkverk stål. 2,07m ",
    length: 2.07,
    weight: 3.8
  },
  {
    label_en: "Simple rail steel. 1,57m ",
    weight: 3,
    label_no: "Enkelt rekkverk stål. 1,57m ",
    width: 0,
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "e283615",
    length: 1.57,
    label_se: "Enkelräcke stål. 1,57m "
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    article_id: "e283630",
    label_no: "Enkelt rekkverk stål. 3,07m ",
    label_se: "Enkelräcke stål. 3,07m ",
    weight: 5.5,
    label_en: "Simple rail steel. 3,07m ",
    length: 3.07
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    width: 0,
    label_se: "Enkelräcke stål. 2,57m ",
    article_id: "e283625",
    label_en: "Simple rail steel. 2,57m ",
    weight: 4.7,
    label_no: "Enkelt rekkverk stål. 2,57m ",
    length: 2.57
  }
];

export const componentsStairs: Component[] = [
  {
    weight: 29.9,
    height: 2,
    label_no: "Stillasstige aluminium 3,07m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    article_id: "e286230",
    label_se: "Ställningstrappa i aluminium 3,07m",
    length: 3.07,
    label_en: "Scaffold stair aluminium 3,07m",
    width: 0.64
  },
  {
    label_no: "Stillasstige aluminium 2,57m",
    width: 0.64,
    height: 2,
    label_se: "Ställningstrappa i aluminium 2,57m",
    article_id: "e286225",
    label_en: "Scaffold stair aluminium 2,57m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    length: 2.57,
    weight: 25.1
  }
];

export const componentsStairDecks: Component[] = [
  {
    label_no: "Aluminiumsplattform med stige 0,61x3,07m",
    weight: 29.7,
    label_se: "Aluminiumplattform med stege 0,61x3,07m",
    article_id: "e492130",
    width: 0.61,
    length: 3.07,
    label_en: "ALUMINIUM platform with ladder 0.61x3.07m",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM
  },
  {
    label_se: "Aluminiumplattform med stege 0,61x2,57m",
    weight: 26.8,
    article_id: "e492125",
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    label_no: "Aluminiumsplattform med stige 0,61x2,57m",
    width: 0.61,
    length: 2.57,
    label_en: "ALUMINIUM platform with ladder 0.61x2.57m"
  }
];

export const componentsAnchors: Component[] = [
  {
    label_en: "Wall anchor  1,3m",
    length: 1.3,
    article_id: "e286508",
    material: "",
    weight: 4.5,
    label_no: "Veggforankring 1,3m",
    width: 0,
    label_se: "Väggförankring 1,3m"
  },
  {
    label_se: "Väggförankring 0,4m",
    weight: 1.6,
    width: 0,
    length: 0.4,
    label_en: "Wall anchor 0,4m",
    label_no: "Veggforankring 0,4m",
    material: "",
    article_id: "e286300"
  },
  {
    weight: 2.7,
    material: "",
    label_no: "Veggforankring med krok",
    label_se: "Väggförankring med krok",
    length: 0,
    width: 0,
    label_en: "Wall anchor with hook",
    article_id: "e286606"
  },
  {
    weight: 2.9,
    label_no: "Veggforankring 0,8m",
    article_id: "e286504",
    label_se: "Väggförankring 0,8m",
    length: 0.8,
    material: "",
    label_en: "Wall anchor  0,8m",
    width: 0
  },
  {
    article_id: "e286515",
    label_en: "Wall anchor  1,5m",
    material: "",
    length: 1.5,
    weight: 5.2,
    label_no: "Veggforankring 1,5m",
    label_se: "Väggförankring 1,5m",
    width: 0
  }
];

export const componentsConsoles: Component[] = [
  {
    length: 0,
    weight: 19.5,
    article_id: "e285589",
    label_en: "Console with clamp 0,73m (High)",
    width: 0.73,
    label_no: "Konsoll med klemme 0,73m (høy)",
    material: "",
    label_se: "Konsol med klämma 0,73m (hög)",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    material: "",
    label_se: "Konsol med klämma 0,73m",
    weight: 6.3,
    article_id: "e285579",
    label_no: "Konsoll med klemme 0,73m",
    length: 0,
    label_en: "Console with clamp 0,73m",
    width: 0.73,
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  },
  {
    label_se: "Konsol med klämma 0,36m",
    label_en: "Console with clamp 0,36m",
    weight: 3.4,
    material: "",
    width: 0.36,
    label_no: "Konsoll med klemme 0,36m",
    length: 0,
    article_id: "e285539",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: "",
    weight: 4.9,
    length: 0,
    label_se: "Konsol med klämma 0,50m",
    label_no: "Konsoll med klemme 0,50m",
    label_en: "Console with clamp 0,50m",
    width: 0.5,
    article_id: "e285550",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    weight: 10.4,
    label_en: "Console with clamp 1,09m",
    length: 0,
    width: 1.09,
    label_se: "Konsol med klämma 1,09m",
    article_id: "e285519",
    material: "",
    label_no: "Konsoll med klemme 1,09m",
    variant: CONSOLE_VARIANT.DIAGONAL_CONNECTOR_SPIGOT
  }
];
export const componentsConsoleBracketBraces: Component[] = [
  {
    article_id: "e285179",
    label_no: "Diagonalstag 1,77m",
    label_se: "Diagonalstag 1,77m",
    label_en: "Diagonal brace 1,77m",
    material: "",
    weight: 8.2,
    length: 1.77,
    height: 2,
    width: 0.73
  },
  {
    article_id: "e285119",
    label_no: "Diagonalstag 1,95m",
    label_se: "Diagonalstag 1,95m",
    label_en: "Diagonal brace 1,95m",
    material: "",
    weight: 8.8,
    length: 1.95,
    height: 2,
    width: 1.09
  }
];

export const componentsToeBoards: Component[] = [
  {
    weight: 1.7,
    label_no: "Fotlist for gavl 0,73m",
    material: "",
    label_en: "Toe board for gable 0,73m",
    label_se: "Sparklist för gavel 0,73m",
    length: 0.73,
    width: 0.15,
    article_id: "e286807"
  },
  {
    length: 2.07,
    width: 0.15,
    label_se: "Sparklist för längsgående sida 2,07m",
    label_en: "Toe board for longitudinal side 2,07m",
    article_id: "e286820",
    weight: 5.4,
    label_no: "Fotlist for langsgående side 2,07m",
    material: ""
  },
  {
    material: "",
    label_no: "Fotlist for gavl 1,09m",
    label_se: "Sparklist för gavel 1,09m",
    weight: 2.4,
    article_id: "e286810",
    label_en: "Toe board for gable 1,09m",
    width: 0.15,
    length: 1.09
  },
  {
    width: 0.15,
    label_en: "Toe board for longitudinal side 1,57m",
    label_se: "Sparklist för längsgående sida 1,57m",
    length: 1.57,
    article_id: "e286815",
    label_no: "Fotlist for langsgående side 1,57m",
    weight: 4.2,
    material: ""
  },
  {
    label_en: "Toe board for longitudinal side 3,07m",
    label_no: "Fotlist for langsgående side 3,07m",
    article_id: "e286830",
    weight: 7.9,
    width: 0.15,
    material: "",
    length: 3.07,
    label_se: "Sparklist för längsgående sida 3,07m"
  },
  {
    label_en: "Toe board for longitudinal side 2,57m",
    label_se: "Sparklist för längsgående sida 2,57m",
    material: "",
    length: 2.57,
    article_id: "e286825",
    width: 0.15,
    label_no: "Fotlist for langsgående side 2,57m",
    weight: 6.7
  },
  {
    label_en: "Toe board for longitudinal side 1,09m",
    label_se: "Sparklist för längsgående sida 1,09m",
    article_id: "e286813",
    weight: 3,
    material: "",
    length: 1.09,
    label_no: "Fotlist for langsgående side 1,09m",
    width: 0.15
  }
];

export const componentsStairHatches: Component[] = [
  {
    label_en: "Ladder 0,32x2,14m",
    label_se: "Stege 0,32x2,14m",
    width: 0,
    weight: 11.1,
    article_id: "e511600",
    length: 2.14,
    label_no: "Stige 0,32x2,14m",
    material: ""
  },
  {
    weight: 4.26,
    article_id: "e492600",
    label_no: "Aluminiumsstige (plattformer e4923xx)",
    label_en: "ALUMINIUM ladder (platforms e4923xx)",
    length: 0,
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    width: 0,
    label_se: "Aluminiumstege (plattformar e4923xx)"
  }
];
export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    label_se: "Innerräcke 2,0x3,0m",
    length: 3,
    label_no: "Indre trapprekkverk 2,0x3,0m",
    article_id: "e286330",
    weight: 12.8,
    label_en: "Internal stair rail 2,0x3,0m",
    material: "",
    width: 0,
    height: 2,
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    label_se: "Trappräcke 2,57m",
    weight: 16,
    label_en: "Stair rail 2,57m",
    length: 2.57,
    material: "",
    width: 0,
    height: 2,
    article_id: "e286325",
    label_no: "Trapperekkverk 2,57m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  },
  {
    article_id: "e286330",
    material: "",
    label_se: "Trappräcke 3,07m",
    width: 0,
    height: 2,
    length: 3.07,
    weight: 17.8,
    label_en: "Stair rail 3,07m",
    label_no: "Trapperekkverk 3,07m",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsCouplers: Component[] = [
  {
    weight: 0.8,
    article_id: "e581119",
    material: "",
    label_se: "Normalkoppling",
    width: 0,
    label_en: "Normal coupling",
    label_no: "Normalkobling",
    length: 0.07
  }
];

export const componentsScrews: Component[] = [
  {
    label_en: "Ring screw 0.12m",
    width: 0.012,
    material: "",
    article_id: "e511012",
    weight: 0.2,
    label_se: "Förankring med ögla 0.12m",
    label_no: "Forankringsløkke 0.12m",
    length: 0.12
  },
  {
    label_en: "Ring screw 0.19m",
    width: 0.012,
    material: "",
    article_id: "e511019",
    weight: 0.3,
    label_se: "Förankring med ögla 0.19m",
    label_no: "Forankringsløkke 0.19m",
    length: 0.19
  },
  {
    label_en: "Ring screw 0.23m",
    width: 0.012,
    material: "",
    article_id: "e511023",
    weight: 0.4,
    label_se: "Förankring med ögla 0.23m",
    label_no: "Forankringsløkke 0.23m",
    length: 0.23
  }
];

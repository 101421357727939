import { ReactElement } from "react";
import LoadingProps from "./Loading.types";
import { CircularProgress, Stack } from "@mui/material";
import Text from "ui/core/Text";

const Loading = (props: LoadingProps): ReactElement => {
  const { progress } = props;
  return (
    <Stack gap={2} alignItems={"center"}>
      <CircularProgress
        value={progress}
        variant={progress ? "determinate" : undefined}
      />
      {progress && <Text variant="body1">{`${progress.toString()}%`}</Text>}
    </Stack>
  );
};

export default Loading;

export enum SCAFFOLD_SUPPLIER {
  LAYHER = "layher",
  PLUSEIGHT = "pluseight",
  HAKI = "haki",
  RAMSCAFF = "ramscaff",
  MOSTOSTALRAM = "mostostalram",
  PLETTAC = "plettac",
  SPEEDYSCAF = "speedyscaf",
  ALUSCAFF = "aluscaff",
  SCAFOMRUX = "scafomrux",
  ALFIX = "alfix",
  UPFLEX = "upflex",
  SUPER9 = "super9"
}

export enum SCAFFOLD_COMPONENT {
  BASECOLLARS = "baseCollars",
  BASEPLATES = "basePlates",
  CLOTHING = "clothings",
  COLANDERS = "colanders",
  CONSOLEDECKS = "consoleDecks",
  CONSOLERAILPOST = "consoleRailPost",
  CONSOLES = "consoles",
  COUPLER = "coupler",
  DECKS = "decks",
  DIAGONALBRACE = "diagonalBraces",
  ELEVATORCABLEGUIDES = "elevatorCableGuides",
  ELEVATORFIXBRACKETS = "elevatorFixBrackets",
  ELEVATORFIXCOUPLERS = "elevatorFixCouplers",
  ELEVATORFIXPIPES = "elevatorFixPipes",
  ELEVATORLANDINGCAMS = "elevatorLandingCams",
  ELEVATORLANDINGEQUIPMENTS = "elevatorLandingEquipments",
  ELEVATORMASTS = "elevatorMasts",
  ELEVATORPIPES = "elevatorPipes",
  ELEVATORS = "elevators",
  ELEVATORSLIDINGGATES = "elevatorSlidingGates",
  ELEVATORSWIVELCOUPLERS = "elevatorSwivelCouplers",
  ELEVATORTIEINBRACKETS = "elevatorTieInBrackets",
  FRAMES = "frames",
  FRAMESUPPORT = "frameSupport",
  GUARDRAIL = "guardRail",
  LEDGER = "ledger",
  LIFTOFFPREVENTER = "liftOffPreventer",
  LOCK = "lock",
  MESHPANEL = "messhPanel",
  PIPEANCHORS = "pipeAnchors",
  RAIL = "rail",
  RAILPOST = "railPost",
  RINGSCREW = "ringScrew",
  STAIRDECK = "stairDeck",
  STAIRFENCE = "stairFence",
  STAIRHATCH = "stairHatch",
  STAIRS = "stairs",
  STAIRWAYGUARDRAILPOSTS = "stairWayGuardRailPosts",
  STAIRWAYGUARDRAILS = "stairWayGuardRails",
  STAIRWAYGUARDRAILSWIDEINNER = "stairWayGuardRailsWideInner",
  STAIRWAYGUARDRAILSINNER = "stairWayGuardRailsInner",
  STAIRWAYS = "stairWays",
  STANDARDS = "standards",
  TOEBOARD = "toeBoard",
  TRUSSBEAMJOINT = "trussBeamJoint",
  TRUSSBEAMS = "trussBeams",
  VANCHORS = "vAnchors",
  SOLEBOARD = "soleBoard"
}

export enum SCAFFOLD_PLANKTYPE {
  ALUMINIUM_STEEL = "aluminium/steel",
  ALUMINIUM_PLASTIC = "aluminium/plastic",
  ALUMINIUM = "aluminium",
  ALUMINIUM_ALUMINIUM = "aluminium/aluminium",
  ALUMINIUM_DUCKBOARD = "aluminium/duckboard",
  ALUMINIUM_PLYWOOD = "aluminium/plywood",
  COMPOSITE = "composite",
  COMPOSITE_DUCKBOARD = "composite/duckboard",
  DUCKBOARD = "duckboard",
  HOOKPLANE = "hookplane",
  PLYWOOD = "plywood",
  ROBUST = "robust",
  STEEL = "steel",
  STEEL_DUCKBOARD = "steel/duckboard",
  WOOD = "wood",
  WOOD_DUCKBOARD = "wood/duckboard",
  WOOD_SOUTH_DUCKBOARD = "wood/south/duckboard"
}

export enum SCAFFOLD_STANDARDLENGTH {
  M4 = 4,
  M3 = 3,
  M2 = 2,
  M1 = 1
}

export enum SCAFFOLD_PLANKORIENTATION {
  TRANSVERSE = "transverse",
  LONGITUDINAL = "longitudinal"
}

export enum SCAFFOLD_LOADCLASS {
  LOADCLASS1 = 1,
  LOADCLASS2 = 2,
  LOADCLASS3 = 3,
  LOADCLASS4 = 4,
  LOADCLASS5 = 5,
  LOADCLASS6 = 6
}

export enum SCAFFOLD_CORNER {
  ABOVE_BAY = "aboveBay",
  ABOVE_BAY_EXTRA = "aboveBayExtra",
  IN_BAY = "inBay",
  IN_BAY_EXTRA = "inBayExtra"
}

export enum SCAFFOLD_CORNER_ROTATION_DEGREES {
  DEGREES_90 = 90,
  DEGREES_270 = 270
}

export enum SCAFFOLD_ANCHORDENSITY {
  EVERY_TWO = 2,
  EVERY_THREE = 3,
  EVERY_FOUR = 4
}

export enum SCAFFOLD_CLOTHING {
  NET = "net",
  PLASTIC = "plastic"
}

export enum SCAFFOLD_DIAGONAL_PATTERN {
  CROSSDIAGONAL = "crossDiagonal",
  INWARDDIAGONAL = "inwardDiagonal",
  OUTWARDDIAGONAL = "outwardDiagonal",
  EVERYOTHER = "everyOther",
  EVERYOTHERREVERSE = "everyOtherReverse"
}

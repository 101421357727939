import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { CONSOLE_VARIANT } from "world/manager/ConsoleManager/ConsoleManager.enums";
import { GUARD_RAIL_VARIANT } from "world/manager/GuardRailManager/GuardRailManager.enums";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";
import { STAIRWAY_GUARD_RAIL_VARIANT } from "world/manager/StairwayGuardRailManager/StairwayGuardRailManager.enums";

export const componentsBaseplates: Component[] = [
  {
    material: "",
    weight: 3.5,
    length: 0.5,
    width: 0.034,
    article_id: "T00043",
    label_en: "Bottom screw hollowed 500 x 34 mm",
    label_se: "Bottenskruv ihålig 500 x 34 mm",
    label_no: "Bunnskrue hul 500 x 34 mm"
  },
  {
    material: "",
    weight: 5.8,
    length: 0.5,
    width: 0.034,
    article_id: "T000423",
    label_en: "Bottom screw massive 500 x 34 mm",
    label_se: "Bottenskruv massiv 500 x 34 mm",
    label_no: "Bunnskrue massiv 500 x 34 mm"
  },
  {
    material: "",
    weight: 8.3,
    length: 0.5,
    width: 0.034,
    article_id: "T00283",
    label_en: "Bottom screw jointed massive 500 x 34 mm",
    label_se: "Ledad bottenskruv massiv 500 x 34 mm",
    label_no: "Bunnskrue leddet massiv 500 x 34 mm"
  }
];

export const componentsStandards: Component[] = [
  {
    material: "",
    weight: 9.2,
    length: 3.0,
    width: 0.0,
    article_id: "R01300",
    label_en: "Standard with bayonet plate 3 m",
    label_se: "Spira med bajonettplatta 3.0 m",
    label_no: "Spire med bajonettplate 3 m"
  },
  {
    material: "",
    weight: 6.4,
    length: 2.0,
    width: 0.0,
    article_id: "R01200",
    label_en: "Standard with bayonet plate 2 m",
    label_se: "Spira med bajonettplatta 2.0 m",
    label_no: "Spire med bajonettplate 2 m"
  },
  {
    material: "",
    weight: 5.0,
    length: 1.5,
    width: 0.0,
    article_id: "R01150",
    label_en: "Standard with bayonet plate 1 m",
    label_se: "Spira med bajonettplatta 1.5 m",
    label_no: "Spire med bajonettplate 1 m"
  },
  {
    material: "",
    weight: 3.5,
    length: 1.0,
    width: 0.0,
    article_id: "R01100",
    label_en: "Standard with bayonet plate 1 m",
    label_se: "Spira med bajonettplatta 1.0 m",
    label_no: "Spire med bajonettplate 1 m"
  },
  {
    material: "",
    weight: 2.1,
    length: 0.5,
    width: 0.0,
    article_id: "R01050",
    label_en: "Standard with bayonet plate 1 m",
    label_se: "Spira med bajonettplatta 0.5 m",
    label_no: "Spire med bajonettplate 1 m"
  },
  {
    material: "",
    weight: 8.8,
    length: 3.0,
    width: 0.0,
    article_id: "R02300",
    label_en: "Standard without bayonet plate 3 m",
    label_se: "Spira utan bajonettplatta 3.0 m",
    label_no: "Spire uten bajonettplate 3 m"
  },
  {
    material: "",
    weight: 6.0,
    length: 2.0,
    width: 0.0,
    article_id: "R02200",
    label_en: "Standard without bayonet plate 2 m",
    label_se: "Spira utan bajonettplatta 2.0 m",
    label_no: "Spire uten bajonettplate 2 m"
  },
  {
    material: "",
    weight: 4.6,
    length: 1.5,
    width: 0.0,
    article_id: "R02150",
    label_en: "Standard without bayonet plate 1.5 m",
    label_se: "Spira utan bajonettplatta 1.5 m",
    label_no: "Spire uten bajonettplate 1.5 m"
  },
  {
    material: "",
    weight: 3.2,
    length: 1.0,
    width: 0.0,
    article_id: "R02100",
    label_en: "Standard without bayonet plate 1 m",
    label_se: "Spira utan bajonettplatta 1.0 m",
    label_no: "Spire uten bajonettplate 1 m"
  },
  {
    material: "",
    weight: 1.8,
    length: 0.5,
    width: 0.0,
    article_id: "R02050",
    label_en: "Standard without bayonet plate 0.5 m",
    label_se: "Spira utan bajonettplatta 0.5 m",
    label_no: "Spire uten bajonettplate 0.5 m"
  },
  {
    material: "",
    weight: 1.1,
    length: 0.35,
    width: 0.0,
    article_id: "R02035T",
    label_en: "Top standard 0.35 m",
    label_se: "Toppspira 0.35 m",
    label_no: "Topp spire 0.35 m"
  },
  {
    material: "",
    weight: 2.5,
    length: 0.85,
    width: 0.0,
    article_id: "R02085T",
    label_en: "Top standard 0.85 m",
    label_se: "Toppspira 0.85 m",
    label_no: "Topp spire 0.85 m"
  }
];

export const componentsOLedgers: Component[] = [
  {
    material: "",
    weight: 7.6,
    length: 4.0,
    width: 0.0,
    article_id: "R05400",
    label_en: "Beam, longitudinal 4.00 m",
    label_se: "Längdbalk 4.00 m",
    label_no: "Lengdebjelke 4.00 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 8.6,
    length: 3.0,
    width: 0.0,
    article_id: "R05300",
    label_en: "Beam, longitudinal 3m",
    label_se: "Längdbalk 3m",
    label_no: "Lengdebjelke 3m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 8.6,
    length: 3.0,
    width: 0.0,
    article_id: "R05300",
    label_en: "Beam, longitudinal 3.0 m",
    label_se: "Längdbalk 3.0 m",
    label_no: "Lengdebjelke 3.0 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 7.1,
    length: 2.45,
    width: 0.0,
    article_id: "R05240",
    label_en: "Beam, longitudinal 2.45 m",
    label_se: "Längdbalk 2.45 m",
    label_no: "Lengdebjelke 2.45 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    // Changed length from 1.9 to 1.9 to match XYZ
    material: "",
    weight: 5.7,
    length: 1.9,
    width: 0.0,
    article_id: "R05190",
    label_en: "Beam, longitudinal 1.9 m",
    label_se: "Längdbalk 1.9 m",
    label_no: "Lengdebjelke 1.9 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    // changed length from 1.6 to 1.6 m to match baywidth
    material: "",
    weight: 4.9,
    length: 1.6,
    width: 0.0,
    article_id: "R05160",
    label_en: "Beam, longitudinal 1.6 m",
    label_se: "Längdbalk 1.6 m",
    label_no: "Lengdebjelke 1.6 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    // changed length from 1.2 to 1.2 to match bay width
    material: "",
    weight: 3.7,
    length: 1.2,
    width: 0.0,
    article_id: "R05120",
    label_en: "Beam, longitudinal 1.2 m",
    label_se: "Längdbalk 1.2 m",
    label_no: "Lengdebjelke 1.2 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    // changed length from 1.0 to 1 to match baywidth
    material: "",
    weight: 3.2,
    length: 1,
    width: 0.0,
    article_id: "R05100",
    label_en: "Beam, longitudinal 1 m",
    label_se: "Längdbalk 1 m",
    label_no: "Lengdebjelke 1 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 2.5,
    length: 0.7,
    width: 0.0,
    article_id: "R05072",
    label_en: "Beam, longitudinal 0.7m",
    label_se: "Längdbalk 0.7m",
    label_no: "Lengdebjelke 0.7m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 1.9,
    length: 0.5,
    width: 0.0,
    article_id: "R05050",
    label_en: "Beam, longitudinal 0.5m",
    label_se: "Längdbalk 0.5m",
    label_no: "Lengdebjelke 0.5m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  },
  {
    material: "",
    weight: 1.4,
    length: 0.3,
    width: 0.0,
    article_id: "R05030",
    label_en: "Beam, longitudinal 0.30 m",
    label_se: "Längdbalk 0.30 m",
    label_no: "Lengdebjelke 0.30 m",
    variant: LEDGER_VARIANT.LBL_LEDGER
  }
];

export const componentsULedgers: Component[] = [
  {
    material: "",
    weight: 3.8,
    length: 1.2,
    width: 0.0,
    article_id: "R06120",
    label_en: "Simple tube beam 1.2 m",
    label_se: "Enrörsbalk. förstärkt 1.2 m",
    label_no: "Enrörsbjelke forsterket 1.2 m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    material: "",
    weight: 3.2,
    length: 1,
    width: 0.0,
    article_id: "R06100",
    label_en: "Simple tube beam 1 m",
    label_se: "Enrörsbalk. förstärkt 1 m",
    label_no: "Enrörsbjelke forsterket 1 m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    material: "",
    weight: 2.4,
    length: 0.7,
    width: 0.0,
    article_id: "R06072",
    label_en: "Simple tube beam 0.72 m",
    label_se: "Enrörsbalk. förstärkt 0.72 m",
    label_no: "Enrörsbjelke forsterket 0.72 m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    material: "",
    weight: 4.4,
    length: 1.6,
    width: 0.0,
    article_id: "R06160A",
    label_en: "Simple tube beam 1.6 m",
    label_se: "Enrörsbalk. förstärkt 1.6 m",
    label_no: "Enrörsbjelke forsterket 1.6 m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  },
  {
    material: "",
    weight: 3.5,
    length: 1.2,
    width: 0.0,
    article_id: "R06120A",
    label_en: "Simple tube beam 1.20 m",
    label_se: "Enrörsbalk. förstärkt 1.20 m",
    label_no: "Enrörsbjelke forsterket 1.20 m",
    variant: LEDGER_VARIANT.U_REINFORCED_LEDGER
  }
];

export const componentsDecks: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 12.5,
    length: 3.0,
    width: 0.23,
    article_id: "R18305",
    label_en: "Aluminium platform 0.23 x 3.00 m",
    label_se: "Aluminiumplank 0.23 x 3.00 m",
    label_no: "Aluminiumsplattform 0.23 x 3.00 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 10.6,
    length: 2.45,
    width: 0.23,
    article_id: "R18250",
    label_en: "Aluminium platform 0.23 x 2.45 m",
    label_se: "Aluminiumplank 0.23 x 2.45 m",
    label_no: "Aluminiumsplattform 0.23 x 2.45 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 8.8,
    length: 1.9,
    width: 0.23,
    article_id: "R18196",
    label_en: "Aluminium platform 0.23 x 1.96m",
    label_se: "Aluminiumplank 0.23 x 1.96m",
    label_no: "Aluminiumsplattform 0.23 x 1.96m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 7.8,
    length: 1.6,
    width: 0.23,
    article_id: "R18165",
    label_en: "Aluminium platform 0.23 x 1.65m",
    label_se: "Aluminiumplank 0.23 x 1.65m",
    label_no: "Aluminiumsplattform 0.23 x 1.65m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.4,
    length: 1.2,
    width: 0.23,
    article_id: "R18125",
    label_en: "Aluminium platform 0.23 x 1.25m",
    label_se: "Aluminiumplank 0.23 x 1.25m",
    label_no: "Aluminiumsplattform 0.23 x 1.25m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.7,
    length: 1,
    width: 0.23,
    article_id: "R18105",
    label_en: "Aluminium platform 0.23 x 1.05m",
    label_se: "Aluminiumplank 0.23 x 1.05m",
    label_no: "Aluminiumsplattform 0.23 x 1.05m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.8,
    length: 0.7,
    width: 0.23,
    article_id: "R18077",
    label_en: "Aluminium platform 0.23 x 0.72m",
    label_se: "Aluminiumplank 0.23 x 0.72m",
    label_no: "Aluminiumsplattform 0.23 x 0.72m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.0,
    length: 0.5,
    width: 0.23,
    article_id: "R18055",
    label_en: "Aluminium platform 0.23 x 0.55m",
    label_se: "Aluminiumplank 0.23 x 0.55m",
    label_no: "Aluminiumsplattform 0.23 x 0.55m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 10.5,
    length: 3.0,
    width: 0.17,
    article_id: "R17305",
    label_en: "Aluminium platform 0.17 x 3.00 m",
    label_se: "Aluminiumplank 0.17 x 3.00 m",
    label_no: "Aluminiumsplattform 0.17 x 3.00 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 9.0,
    length: 2.45,
    width: 0.17,
    article_id: "R17250",
    label_en: "Aluminium platform 0.17 x 2.45 m",
    label_se: "Aluminiumplank 0.17 x 2.45 m",
    label_no: "Aluminiumsplattform 0.17 x 2.45 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 7.5,
    length: 1.9,
    width: 0.17,
    article_id: "R17196",
    label_en: "Aluminium platform 0.17 x 1.96m",
    label_se: "Aluminiumplank 0.17 x 1.96m",
    label_no: "Aluminiumsplattform 0.17 x 1.96m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.7,
    length: 1.6,
    width: 0.17,
    article_id: "R17165",
    label_en: "Aluminium platform 0.17 x 1.65m",
    label_se: "Aluminiumplank 0.17 x 1.65m",
    label_no: "Aluminiumsplattform 0.17 x 1.65m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.6,
    length: 1.2,
    width: 0.17,
    article_id: "R17125",
    label_en: "Aluminium platform 0.17 x 1.25m",
    label_se: "Aluminiumplank 0.17 x 1.25m",
    label_no: "Aluminiumsplattform 0.17 x 1.25m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.1,
    length: 1,
    width: 0.17,
    article_id: "R17105",
    label_en: "Aluminium platform 0.17 x 1.05m",
    label_se: "Aluminiumplank 0.17 x 1.05m",
    label_no: "Aluminiumsplattform 0.17 x 1.05m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.3,
    length: 0.7,
    width: 0.17,
    article_id: "R17077",
    label_en: "Aluminium platform 0.17 x 0.72m",
    label_se: "Aluminiumplank 0.17 x 0.72m",
    label_no: "Aluminiumsplattform 0.17 x 0.72m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.0,
    length: 0.5,
    width: 0.17,
    article_id: "R17055",
    label_en: "Aluminium platform 0.17 x 0.55m",
    label_se: "Aluminiumplank 0.17 x 0.55m",
    label_no: "Aluminiumsplattform 0.17 x 0.55m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.0,
    length: 3,
    width: 0.1,
    article_id: "R10305",
    label_en: "Aluminium platform 0.10 x 3m",
    label_se: "Aluminiumplank 0.10 x 3m",
    label_no: "Aluminiumsplattform 0.10 x 3m"
  }
  //   {
  //     material: SCAFFOLD_PLANKTYPE.COMPOSITE,
  //     weight: 8.8,
  //     length: 1.2,
  //     width: 0.49,
  //     article_id: "T00077",
  //     label_en: "Deck composite 0.49 x 1.25m",
  //     label_se: "Däck komposit 0.49 x 1.25m",
  //     label_no: "Dekk kompositt 0.49 x 1.25m"
  //   }
];

export const componentsGuardRails: Component[] = [
  {
    material: "",
    weight: 9.8,
    length: 3.0,
    width: 0.0,
    article_id: "R11300",
    label_en: "Double rail 3.00 m",
    label_se: "Dubbelräcke 3.00 m",
    label_no: "Dobbelt rekkverk 3.00 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 8.3,
    length: 2.45,
    width: 0.0,
    article_id: "R11245",
    label_en: "Double rail 2.45 m",
    label_se: "Dubbelräcke 2.45 m",
    label_no: "Dobbelt rekkverk 2.45 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 6.9,
    length: 1.9,
    width: 0.0,
    article_id: "R11190",
    label_en: "Double rail 1.90 m",
    label_se: "Dubbelräcke 1.90 m",
    label_no: "Dobbelt rekkverk 1.90 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 5.6,
    length: 1.6,
    width: 0.0,
    article_id: "R11160",
    label_en: "Double rail 1.6 m",
    label_se: "Dubbelräcke 1.6 m",
    label_no: "Dobbelt rekkverk 1.6 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 3.5,
    length: 1,
    width: 0.0,
    article_id: "R11100",
    label_en: "Double rail 1 m",
    label_se: "Dubbelräcke 1 m",
    label_no: "Dobbelt rekkverk 1 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 4.1,
    length: 1.2,
    width: 0.0,
    article_id: "R11120",
    label_en: "Double rail 1.2 m",
    label_se: "Dubbelräcke 1.2 m",
    label_no: "Dobbelt rekkverk 1.2 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 3.0,
    length: 0.7,
    width: 0.0,
    article_id: "R11072",
    label_en: "Double rail 0.7 m",
    label_se: "Dubbelräcke 0.7 m",
    label_no: "Dobbelt rekkverk 0.7 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 7.3,
    length: 2.3,
    width: 0.0,
    article_id: "T00082",
    label_en: "Double rail 2.30 m",
    label_se: "Dubbelräcke 2.30 m",
    label_no: "Dobbelt rekkverk 2.30 m",
    variant: GUARD_RAIL_VARIANT.VERTICAL_GUARD_RAIL
  },
  {
    material: "",
    weight: 3.2,
    length: 0.8,
    width: 0.0,
    article_id: "T00433",
    label_en: "Double rail 0.8 m",
    label_se: "Dubbelräcke 0.8 m",
    label_no: "Dobbelt rekkverk 0.8 m"
  }
];

export const componentsGuardRailsSingle: Component[] = [
  {
    material: "",
    weight: 4.4,
    length: 3.0,
    width: 0.0,
    article_id: "R10300",
    label_en: "Simple rail 3.00 m",
    label_se: "Enkelräcke 3.00 m",
    label_no: "Enkelt rekkverk 3.00 m"
  },
  {
    material: "",
    weight: 3.7,
    length: 2.45,
    width: 0.0,
    article_id: "R10245",
    label_en: "Simple rail 2.45 m",
    label_se: "Enkelräcke 2.45 m",
    label_no: "Enkelt rekkverk 2.45 m"
  },
  {
    material: "",
    weight: 3.0,
    length: 1.9,
    width: 0.0,
    article_id: "R10190",
    label_en: "Simple rail 1.90 m",
    label_se: "Enkelräcke 1.90 m",
    label_no: "Enkelt rekkverk 1.90 m"
  },
  {
    material: "",
    weight: 2.6,
    length: 1.6,
    width: 0.0,
    article_id: "R10160",
    label_en: "Simple rail 1.60 m",
    label_se: "Enkelräcke 1.60 m",
    label_no: "Enkelt rekkverk 1.60 m"
  },
  {
    material: "",
    weight: 2.1,
    length: 1.2,
    width: 0.0,
    article_id: "R10120",
    label_en: "Simple rail 1.20 m",
    label_se: "Enkelräcke 1.20 m",
    label_no: "Enkelt rekkverk 1.20 m"
  },
  {
    // changed length from 1.0 to 1 to match baywidth
    material: "",
    weight: 1.9,
    length: 1,
    width: 0.0,
    article_id: "R10100",
    label_en: "Simple rail 1 m",
    label_se: "Enkelräcke 1 m",
    label_no: "Enkelt rekkverk 1 m"
  },
  {
    material: "",
    weight: 1.5,
    length: 0.7,
    width: 0.0,
    article_id: "R10072",
    label_en: "Simple rail 0.72 m",
    label_se: "Enkelräcke 0.72 m",
    label_no: "Enkelt rekkverk 0.72 m"
  },
  {
    material: "",
    weight: 1.2,
    length: 0.5,
    width: 0.0,
    article_id: "R10050",
    label_en: "Simple rail 0.50 m",
    label_se: "Enkelräcke 0.50 m",
    label_no: "Enkelt rekkverk 0.50 m"
  },
  {
    material: "",
    weight: 1.0,
    length: 0.3,
    width: 0.0,
    article_id: "R10030",
    label_en: "Simple rail 0.30 m",
    label_se: "Enkelräcke 0.30 m",
    label_no: "Enkelt rekkverk 0.30 m"
  }
];

export const componentsDiagonalBraces: Component[] = [
  /** Diagonal braces for 2m height */
  {
    material: "",
    weight: 6.2,
    length: 3.0,
    width: 0.0,
    height: 2,
    article_id: "R15300",
    label_en: "Diagonal brace baylength 3m",
    label_se: "Diagonalstag facklängd 3m",
    label_no: "Diagonalstag facklängd 3m"
  },
  {
    material: "",
    weight: 6.2,
    length: 3.0,
    height: 2,
    width: 0.0,
    article_id: "R15300",
    label_en: "Diagonal brace baylength 3.0 m",
    label_se: "Diagonalstag facklängd 3.0 m",
    label_no: "Diagonalstag facklängd 3.0 m"
  },
  {
    material: "",
    weight: 5.6,
    length: 2.45,
    width: 0.0,
    height: 2,
    article_id: "R15250",
    label_en: "Diagonal brace baylength 2.45 m",
    label_se: "Diagonalstag facklängd 2.45 m",
    label_no: "Diagonalstag facklängd 2.45 m"
  },
  {
    // chnaged from 1.90 to 1.9 to match XYZ
    material: "",
    weight: 4.9,
    length: 1.9,
    width: 0.0,
    height: 2,
    article_id: "R15190",
    label_en: "Diagonal brace baylength 1.9 m",
    label_se: "Diagonalstag facklängd 1.9 m",
    label_no: "Diagonalstag facklängd 1.9 m"
  },
  {
    // changed length from 1.6 to 1.6
    material: "",
    weight: 4.6,
    length: 1.6,
    height: 2,
    width: 0.0,
    article_id: "R15160",
    label_en: "Diagonal brace baylength 1.6 m",
    label_se: "Diagonalstag facklängd 1.6 m",
    label_no: "Diagonalstag facklängd 1.6 m"
  },
  {
    // Chnaged from 1.20 to 1.2 to match XYZ
    material: "",
    weight: 4.2,
    length: 1.2,
    height: 2,
    width: 0.0,
    article_id: "R15120",
    label_en: "Diagonal brace baylength 1.2 m",
    label_se: "Diagonalstag facklängd 1.2 m",
    label_no: "Diagonalstag facklängd 1.2 m"
  }
];

export const componentsStartStairs: Component[] = [
  {
    material: "",
    height: 1,
    weight: 7.5,
    length: 1.2,
    width: 0.64,
    article_id: "R30100",
    label_en: "Stair 1 x 1.2 m",
    label_se: "Trappa 1 x 1.2 m",
    label_no: "Stige 1 x 1.2 m"
  },
  {
    material: "",
    weight: 13.6,
    height: 1,
    length: 1,
    width: 0.64,
    article_id: "R30110",
    label_en: "Stair 1.0 x 1.0 m",
    label_se: "Trappa 1.0 x 1.0 m",
    label_no: "Stige 1.0 x 1.0 m"
  }
];

export const componentsStairs: Component[] = [
  {
    material: "",
    weight: 18.1,
    length: 1.6,
    height: 2,
    width: 0.64,
    article_id: "R30160",
    label_en: "Stair 2 x 1.6 m",
    label_se: "Trappa 2 x 1.6 m",
    label_no: "Stige 2 x 1.6 m"
  },
  {
    material: "",
    weight: 14.0,
    length: 1.2,
    width: 0.64,
    height: 2,
    article_id: "R30120",
    label_en: "Stair 2.0 x 1.2 m",
    label_se: "Trappa 2.0 x 1.2 m",
    label_no: "Stige 2.0 x 1.2 m"
  },
  {
    material: "",
    weight: 32.2,
    length: 3,
    width: 0.64,
    height: 2,
    article_id: "R30305",
    label_en: "Stair 2 x 3.0 m",
    label_se: "Trappa 2 x 3.0 m",
    label_no: "Stige 2 x 3.0 m"
  }
];

export const componentsStairHatches: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.0,
    length: 2.18,
    width: 0.0,
    article_id: "R35218",
    label_en: "Ladder alu 2.18 m",
    label_se: "Stege alu 2.18 m",
    label_no: "Stige alu 2.18 m"
  },
  {
    // Changed length from 0.7 to 0.7 to match XYZ
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.0,
    length: 0.7,
    width: 0.0,
    article_id: "202021A",
    label_en: "Ladder alu for platform with hatch 1.2 x 0.7 m",
    label_se: "Stege alu för lucka plattform 1.2 x 0.7 m",
    label_no: "Stige alu for plattform med luke 1.2 x 0.7 m"
  }
];

export const componentsToeBoards: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 6.7,
    length: 3.0,
    width: 0.15,
    article_id: "R91305",
    label_en: "Toe board alu. 3.00 m",
    label_se: "Fotlist alu. 3.00 m",
    label_no: "Fotlist alu. 3.00 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 5.8,
    length: 2.45,
    width: 0.15,
    article_id: "R91250",
    label_en: "Toe board alu. 2.45 m",
    label_se: "Fotlist alu. 2.45 m",
    label_no: "Fotlist alu. 2.45 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.6,
    length: 1.9,
    width: 0.15,
    article_id: "R91196",
    label_en: "Toe board alu. 1.9m",
    label_se: "Fotlist alu. 1.9m",
    label_no: "Fotlist alu. 1.9m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.0,
    length: 1.6,
    width: 0.15,
    article_id: "R91165",
    label_en: "Toe board alu. 1.6 m",
    label_se: "Fotlist alu. 1.6 m",
    label_no: "Fotlist alu. 1.6 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.1,
    length: 1.2,
    width: 0.15,
    article_id: "R91125",
    label_en: "Toe board alu. 1.2 m",
    label_se: "Fotlist alu. 1.2 m",
    label_no: "Fotlist alu. 1.2 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 0.9,
    length: 1,
    width: 0.15,
    article_id: "R9100",
    label_en: "Toe board alu. 1 m",
    label_se: "Fotlist alu. 1 m",
    label_no: "Fotlist alu. 1 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 0.5,
    length: 0.7,
    width: 0.15,
    article_id: "R91072",
    label_en: "Toe board alu. 0.7 m",
    label_se: "Fotlist alu. 0.7 m",
    label_no: "Fotlist alu. 0.7 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 0.4,
    length: 0.5,
    width: 0.15,
    article_id: "R91050",
    label_en: "Toe board alu. 0.50 m",
    label_se: "Fotlist alu. 0.50 m",
    label_no: "Fotlist alu. 0.50 m"
  }
];

export const componentsConsoles: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 0.8,
    length: 0.0,
    width: 0.3,
    article_id: "R25030",
    label_en: "Console bracket alu. 0.3 m",
    label_se: "Konsol aluminium 0.3 m",
    label_no: "Konsoll alu. 0.3 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 2.6,
    length: 0.0,
    width: 0.5,
    article_id: "R25051",
    label_en: "Console bracket alu. 0.5 m",
    label_se: "Konsoll aluminium 0.5 m",
    label_no: "Konsoll alu. 0.5 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.3,
    length: 0.0,
    width: 0.7,
    article_id: "R25070",
    label_en: "Console bracket alu. 0.7 m",
    label_se: "Konsoll aluminium 0.7 m",
    label_no: "Konsoll alu. 0.7 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  // {
  //   material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
  //   weight: 6.9,
  //   length: 0.0,
  //   width: 1.2,
  //   article_id: "R25120",
  //   label_en: "Console bracket alu. 1.2 m",
  //   label_se: "Konsoll aluminium 1.2 m",
  //   label_no: "Konsoll alu. 1.2 m"
  // },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 7.5,
    length: 0.0,
    width: 0.7,
    article_id: "109072",
    label_en: "Console bracket steel 0.72 m",
    label_se: "Konsol stål 0.72 m",
    label_no: "Konsoll stål 0.72 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 6.3,
    length: 0.0,
    width: 0.5,
    article_id: "109050",
    label_en: "Console bracket steel 0.5 m",
    label_se: "Konsol stål 0.50 m",
    label_no: "Konsoll stål 0.5 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.5,
    length: 0.0,
    width: 0.47,
    article_id: "109047",
    label_en: "Console bracket steel 0.47 m",
    label_se: "Konsol stål 0.47 m",
    label_no: "Konsoll stål 0.47 m",
    variant: CONSOLE_VARIANT.SPIGOT
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 2.3,
    length: 0.0,
    width: 0.24,
    article_id: "109024",
    label_en: "Console bracket steel 0.24 m",
    label_se: "Konsol stål 0.24 m",
    label_no: "Konsoll stål 0.24 m",
    variant: CONSOLE_VARIANT.SPIGOT
  }
];

export const componentsTrussbeams: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 12.0,
    length: 3.2,
    width: 0.4,
    article_id: "C000255",
    label_en: "Truss beam alu 3.2 x 0.4 m",
    label_se: "Fackverksbalk alu 3.2 x 0.4 m",
    label_no: "Fagverksbjelke alu 3.2 x 0.4 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 15.0,
    length: 4.2,
    width: 0.4,
    article_id: "C000257",
    label_en: "Truss beam alu 4.2 x 0.4 m",
    label_se: "Fackverksbalk alu 4.2 x 0.4 m",
    label_no: "Fagverksbjelke alu 4.2 x 0.4 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 20.0,
    length: 5.2,
    width: 0.4,
    article_id: "C000258",
    label_en: "Truss beam alu 5.2 x 0.4 m",
    label_se: "Fackverksbalk alu 5.2 x 0.4 m",
    label_no: "Fagverksbjelke alu 5.2 x 0.4 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 23.0,
    length: 6.2,
    width: 0.4,
    article_id: "C000259",
    label_en: "Truss beam alu 6.2 x 0.4 m",
    label_se: "Fackverksbalk alu 6.2 x 0.4 m",
    label_no: "Fagverksbjelke alu 6.2 x 0.4 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 31.0,
    length: 8.1,
    width: 0.4,
    article_id: "C000261",
    label_en: "Truss beam alu 8.1 x 0.4 m",
    label_se: "Fackverksbalk alu 8.1 x 0.4 m",
    label_no: "Fagverksbjelke alu 8.1 x 0.4 m"
  }
];

export const componentsAnchors: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 3.0,
    length: 1.0,
    width: 0.0,
    article_id: "R03000",
    label_en: "Wall anchor alu.",
    label_se: "Väggförankringsrör alu.",
    label_no: "Veggforankring alu."
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 4.8,
    length: 1.5,
    width: 0.0,
    article_id: "T00008U",
    label_en: "Wall anchor steel 1.5 m",
    label_se: "Väggförankringsrör i stål 1.5 m",
    label_no: "Veggforankring stål 1.5 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 4.0,
    length: 1.2,
    width: 0.0,
    article_id: "T00054U",
    label_en: "Wall anchor steel 1.2 m",
    label_se: "Väggförankringsrör i stål 1.2 m",
    label_no: "Veggforankring stål 1.2 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.8,
    length: 1.0,
    width: 0.0,
    article_id: "T00178U",
    label_en: "Wall anchor steel 1.0 m",
    label_se: "Väggförankringsrör i stål 1.0 m",
    label_no: "Veggforankring stål 1.0 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 3.0,
    length: 0.8,
    width: 0.0,
    article_id: "T00056U",
    label_en: "Wall anchor steel 0.8 m",
    label_se: "Väggförankringsrör i stål 0.8 m",
    label_no: "Veggforankring stål 0.8 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 2.0,
    length: 0.6,
    width: 0.0,
    article_id: "T00053U",
    label_en: "Wall anchor steel 0.6 m",
    label_se: "Väggförankringsrör i stål 0.6 m",
    label_no: "Veggforankring stål 0.6 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 1.6,
    length: 0.4,
    width: 0.0,
    article_id: "T00007U",
    label_en: "Wall anchor steel 0.4 m",
    label_se: "Väggförankringsrör i stål 0.4 m",
    label_no: "Veggforankring stål 0.4 m"
  },
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 8.5,
    length: 3.0,
    width: 0.0,
    article_id: "T00240U",
    label_en: "Wall anchor steel 3.0 m",
    label_se: "Väggförankringsrör stål 3.0 m",
    label_no: "Veggforankring stål 3.0 m"
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    material: "",
    weight: 11.8,
    length: 3.0,
    width: 0,
    height: 2,
    article_id: "R31001",
    label_en: "Stair rail to R30305",
    label_se: "Trappräcke till R30305",
    label_no: "Trapperekkverk til R30305",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.OUTER
  }
];

export const componentsStairwayGuardrailsInternal: Component[] = [
  {
    material: "",
    weight: 12.8,
    length: 3.0,
    width: 0,
    height: 2,
    article_id: "C286300",
    label_en: "Inside stair rail to R30305",
    label_se: "Invändigt trappräcke till R30305",
    label_no: "Innsiden trapperekkverk til R30305",
    variant: STAIRWAY_GUARD_RAIL_VARIANT.INTERNAL
  }
];

export const componentsRailPosts: Component[] = [
  {
    material: SCAFFOLD_PLANKTYPE.STEEL,
    weight: 5.3,
    length: 1.0,
    width: 0.0,
    article_id: "202010",
    label_en: "Railing post steel",
    label_se: "Räckestolpe stål",
    label_no: "Rekkverksstolpe steel"
  },
  {
    material: SCAFFOLD_PLANKTYPE.ALUMINIUM,
    weight: 2.8,
    length: 1.0,
    width: 0.0,
    article_id: "202010A",
    label_en: "Railing post alu.",
    label_se: "Räckestolpe alu",
    label_no: "Rekkverksstolpe alu."
  }
];

export const componentsCouplers: Component[] = [
  {
    material: "",
    weight: 1.3,
    length: 0.1,
    width: 0.0,
    article_id: "T00003",
    label_en: "Coupler SK",
    label_se: "Koppling SK",
    label_no: "Kobling SK"
  },
  {
    material: "",
    weight: 1.5,
    length: 0.0,
    width: 0.0,
    article_id: "T00001",
    label_en: "Coupler Grawlock",
    label_se: "Koppling Grawlock",
    label_no: "Kobling Grawlock"
  },
  {
    material: "",
    weight: 1.6,
    length: 0.0,
    width: 0.0,
    article_id: "T00179",
    label_en: "Coupler Grawlock-rotatable",
    label_se: "Koppling Grawlock-vridbar",
    label_no: "Kobling Grawlock-roterbar"
  }
];

export const componentsScrews: Component[] = [
  {
    label_en: "Ring screw",
    width: 0.012,
    material: "",
    article_id: "-",
    weight: 1.6,
    label_se: "Förankringsögla",
    label_no: "Forankringsløkke",
    length: 0.095
  }
];

export const componentsBeamSpigots: Component[] = [
  {
    length: 0.2,
    width: 0,
    label_se: "BVR u. topplatta",
    material: "",
    label_no: "BVR u. topplatte",
    article_id: "R73200",
    label_en: "BVR without top plate",
    weight: 1.3
  },
  {
    length: 0.2,
    width: 0,
    label_se: "BVR m. topplatta",
    material: "",
    label_no: "BVR m. topplatte",
    article_id: "R73201",
    label_en: "BVR with top plate",
    weight: 1.4
  }
];

import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component, {
  BayBoxProps,
  LANGUAGE_CODE,
  LockRange,
  PassageBoxProps,
  ReplacedComponent,
  StairBoxProps,
  Supplier
} from "suppliers/scaffold/scaffold.interface";
import {
  componentsAnchors,
  componentsBaseCollars,
  componentsBaseplates,
  componentsBeamSpigots,
  componentsConsoleBracketBraces,
  componentsConsoles,
  componentsCouplers,
  componentsDecks,
  componentsDiagonalBraces,
  componentsGuardRails,
  componentsOLedgers,
  componentsScrews,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsStairwayGuardrailsInternal,
  componentsStandards,
  componentsStartStairs,
  componentsStartStandards,
  componentsToeBoards,
  componentsULedgers
} from "./components";
import { calculateSegmentLengthCombinations } from "math/optimizers";
import {
  generateOptimalPlankConfiguration,
  getStandardReductionHeight,
  getValidAnchorLevels,
  getValidConsoleLevels,
  getValidFrames
} from "./utils/utils";
import { BasePlate, Box, BoxFrame } from "shared/interfaces/firestore";
import { getOrderedStandardYPosition } from "pages/ScaffoldPage/deps/ToolManager/tools/BoxTool/BoxTool.utils";
import {
  calcLengthPartitions,
  getBoxStandardPositions,
  getStandardsRailTop
} from "suppliers/scaffold/scaffold.utils";
import { Euler, Vector3 } from "three";
import { minus, plus } from "math";
import { DEFAULT_FALL_PROTECTION_HEIGHT } from "suppliers/scaffold/constants";
import { halfPi } from "math/constants";
import { Ledger } from "world/core/Ledger/Ledger.types";
import { Standard } from "world/core/Standard/Standard.types";
import { BaseCollar } from "world/core/BaseCollar/BaseCollar.types";
import { BaseBoard } from "world/core/BaseBoard/BaseBoard.types";
import { GuardRail } from "world/core/GuardRail/GuardRail.types";
import {
  generateBaseComponents,
  generateConsoleComponents,
  generateDiagonalBraces,
  generateFrameAnchors,
  generateFrameDeckPlanks,
  generateFrameLedgers,
  generateGuardRails,
  generatePassageComponents,
  generateStairComponents,
  generateStandards,
  generateToeBoards
} from "./utils/components";
import Graph from "graphology";
import { componentsBaseBoards } from "suppliers/scaffold/components";
import { Coupler } from "world/core/Coupler/Coupler.types";
import { Screw } from "world/core/Screw/Screw.types";
import { Anchor } from "world/core/Anchor/Anchor.types";

class SystemScaffold implements Supplier {
  private ledgerComponents: Component[] = [];

  constructor() {
    this.ledgerComponents = [...componentsULedgers, ...componentsOLedgers];
  }

  isFrameSupplier(): boolean {
    return false;
  }
  getPlankMaterials(): SCAFFOLD_PLANKTYPE[] {
    const plankTypes = componentsDecks.map(
      (component) => component.material
    ) as SCAFFOLD_PLANKTYPE[];

    const uniquePlankTypes = new Set(plankTypes);

    return [...uniquePlankTypes];
  }

  getBayBoxLengths(): number[] {
    const oLedgerLenghts = componentsOLedgers.map(
      (component) => component.length
    );

    const uniqueLengths = new Set([...oLedgerLenghts]);
    return [...uniqueLengths];
  }

  getBayBoxWidths(): number[] {
    const uLedgerLenghts = componentsULedgers.map(
      (component) => component.length
    );
    const uniqueLengths = new Set([...uLedgerLenghts]);

    return [...uniqueLengths];
  }

  getLedgerAlternatives(props: { length: number }) {
    const { length } = props;

    return this.ledgerComponents.filter((ledger) => ledger.length === length);
  }

  getPassageBaseBoxWidths(): number[] {
    const uLedgerLenghts = componentsULedgers.map(
      (component) => component.length
    );
    const uniqueLengths = new Set([...uLedgerLenghts]);
    return [...uniqueLengths];
  }

  getStandardLengths() {
    const uniqueLengths = new Set(
      componentsStandards.map((standard) => standard.length)
    );
    return [...uniqueLengths];
  }

  getComponents(): Component[] {
    return [
      ...componentsDecks,
      ...componentsStandards,
      ...componentsULedgers,
      ...componentsOLedgers,
      ...componentsDiagonalBraces,
      ...componentsToeBoards,
      ...componentsStairs,
      ...componentsConsoles,
      ...componentsBaseplates,
      ...componentsStairwayGuardrails,
      ...componentsStartStairs,
      ...componentsStairwayGuardrailsInternal,
      ...componentsGuardRails,
      ...componentsAnchors,
      ...componentsBaseBoards,
      ...componentsBeamSpigots,
      ...componentsConsoleBracketBraces,
      ...componentsCouplers,
      ...componentsScrews,
      ...componentsStartStandards,
      ...componentsBaseCollars
    ];
  }

  getStairBoxLengths() {
    const uniqueLengths = [
      ...new Set(
        componentsStairs.map((componentsStair) => componentsStair.length)
      )
    ];

    return uniqueLengths;
  }

  getStairBoxWidths(): number[] {
    const allLengths = this.getBayBoxLengths();

    const stairMinWidth = Math.min(
      ...componentsStairs.map((component) => component.width, 0)
    );
    return allLengths.filter((length) => length >= stairMinWidth);
  }

  getAvailableLockAnchorRanges(): LockRange[] {
    return [{ min: 0, max: 360 }];
  }

  getPlankConfigurations = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    const { length, width, plankType } = props;
    if (!plankType) return [];
    const decks = componentsDecks.filter((deck) => deck.material === plankType);

    const correctLengthDecks = decks.filter((deck) => deck.length === length);

    if (correctLengthDecks.length === 0) {
      return [];
    }

    const uniqueWidths = [
      ...new Set(correctLengthDecks.map((deck) => deck.width))
    ];

    const plankConfigurations = calculateSegmentLengthCombinations({
      lengthSegments: uniqueWidths,
      targetLength: width
    });

    return plankConfigurations;
  };

  getOptimalPlankConfiguration = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    return generateOptimalPlankConfiguration(props);
  };
  getComponentLabel = (id: string, languageCode: LANGUAGE_CODE) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );
    if (!component) return "";
    switch (languageCode) {
      case LANGUAGE_CODE.SE:
        return component.label_se;
      case LANGUAGE_CODE.NO:
        return component.label_no;
      case LANGUAGE_CODE.EN:
      default:
        return component.label_en;
    }
  };
  getComponentWeight = (id: string) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );
    if (!component) return 0;
    return component.weight;
  };

  getConsoleWidths = () => {
    const consoleWidths = componentsConsoles.map(
      (component) => component.width
    );
    const uniqueWidths = new Set(consoleWidths);
    return [...uniqueWidths];
  };

  generateBayBoxComponents(props: {
    boxProps: BayBoxProps;
    allBoxes?: Box[];
    skipStandardColumnComponents?: boolean;
    diagonalFrames?: BoxFrame[];
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
    preStateCommitGraph: Graph;
  }) {
    const {
      boxProps,
      allBoxes,
      skipStandardColumnComponents,
      diagonalFrames,
      preStateStandards,
      preStateCommitGraph,
      preStateLedgers,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates
    } = props;
    /** Extract box properties */
    const { height, depth, width, rotation, options, position, stair, id } =
      boxProps;

    const ledgers: Ledger[] = [];
    const guardRails: GuardRail[] = [];
    const standards: Standard[] = [];
    const baseCollars: BaseCollar[] = [];
    const baseBoards: BaseBoard[] = [];
    const basePlates: BasePlate[] = [];
    const couplers: Coupler[] = [];
    const screws: Screw[] = [];
    const anchors: Anchor[] = [];

    const replacedComponents: ReplacedComponent[] = [];

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position),
          new Vector3().fromArray(options?.standardPositions[3].position),
          new Vector3().fromArray(options?.standardPositions[1].position),
          new Vector3().fromArray(options?.standardPositions[0].position)
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });
    /** Top deck height */
    const topDeckHeight = plus(height, position[1]);

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let standardsRailTop = getStandardsRailTop(guardRailsOptions);

    const diagonalBraceOptions = options?.diagonals ?? [
      false,
      false,
      false,
      false
    ];

    const anchorOptions = options?.anchors ?? [false, false, false, false];

    const frames = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });
    const consoleLevels = getValidConsoleLevels({
      consoleLevels: options?.consoleLevels,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });
    const anchorLevels = getValidAnchorLevels({
      anchorLevels: options?.anchorLevels,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    const standardReductionHeight = getStandardReductionHeight(
      options?.initialComponentVariant
    );

    const standardLengths = standardPositions.map((pos, index) => {
      return {
        pos: pos.clone().setY(plus(pos.y, standardReductionHeight)),
        standardLength: calcLengthPartitions(
          plus(
            minus(topDeckHeight, pos.y, standardReductionHeight),
            0.1,
            standardsRailTop[index] ? fallProtectionHeight : 0
          ),
          this.getStandardLengths()
        ),
        initialComponentVariant: options?.initialComponentVariant
      };
    });

    if (!skipStandardColumnComponents) {
      /** Creation of standards */
      const standardComponents = generateStandards({
        standardLengths,
        boxRotation: rotation,
        boxId: id,
        allBoxes,
        preStateStandards,
        availableStandardLengths: this.getStandardLengths(),
        preStateCommitGraph
      });
      standards.push(...standardComponents.standards);
      replacedComponents.push(...standardComponents.replacedStandards);

      /** Creation of:
       * - Base collars
       * - Base boards
       * - Base plates
       * - Starter Standards
       */
      const baseComponents = generateBaseComponents({
        standardLengths,
        boxOptions: options,
        rayHitsYPosition,
        boxRotation: rotation,
        preStateBaseComponents: {
          baseCollars: preStateBaseCollars ?? [],
          baseBoards: preStateBaseBoards ?? [],
          basePlates: preStateBasePlates ?? [],
          standards: preStateStandards ?? []
        }
      });
      baseBoards.push(...baseComponents.baseBoards);
      baseCollars.push(...baseComponents.baseCollars);
      basePlates.push(...baseComponents.basePlates);
      standards.push(...baseComponents.standards);
      replacedComponents.push(...baseComponents.replacedComponents);
    }

    /** Creation of longitudinal and transverse frame ledgers
     * - Builds from the back -> front
     */

    const {
      ledgers: frameLedgers,
      replacedLedgers,
      guardRails: frameGuardRails
    } = generateFrameLedgers({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      standardsToSplit: standards,
      skipBottomLedgers: skipStandardColumnComponents,
      boxId: id,
      preStateLedgers: preStateLedgers
    });
    ledgers.push(...frameLedgers);
    replacedComponents.push(...replacedLedgers);

    /** Longitudinal Guard rails */

    const guardRailComponents = generateGuardRails({
      guardRailsOptions,
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      fallProtectionHeight,
      frames,
      standardsToSplit: standards
    });
    guardRails.push(...guardRailComponents.guardRails);
    ledgers.push(...guardRailComponents.ledgers);

    /** Diagonal braces */
    const diagonalBraces = generateDiagonalBraces({
      standardPositions,
      frames: diagonalFrames ?? frames,
      depth,
      width,
      boxRotation: rotation,
      standardsToSplit: standards,
      diagonalBraceOptions,
      diagonalPattern: options?.diagonalPattern
    });

    /** Anchors */
    const anchorComponents = generateFrameAnchors({
      standardPositions,
      anchorLevels,
      boxRotation: rotation,
      standardsToSplit: standards,
      anchorOptions
    });
    anchors.push(...anchorComponents.anchors);
    couplers.push(...anchorComponents.couplers);
    screws.push(...anchorComponents.screws);

    /** Planks */
    const frameDeckPlanks = generateFrameDeckPlanks({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      stair,
      ledgersToSplit: ledgers
    });

    /** Consoles */
    const {
      consoles,
      planks: consolePlanks,
      diagonalBraces: consoleDiagonalBraces
    } = generateConsoleComponents({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      consoleLevels,
      consoleOptions: options?.consoles,
      consoleWidth: options?.consoleWidth
    });

    /** Longitudinal and transverse toe boards */
    const toeBoards = generateToeBoards({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      guardRailsOptions
    });

    const components = {
      baseBoards,
      baseCollars,
      basePlates,
      consoles,
      ledgers,
      planks: [...frameDeckPlanks, ...consolePlanks],
      stairwayGuardRails: [],
      stairwayInnerGuardRails: [],
      stairways: [],
      beamSpigots: [],
      frames: [],
      standards,
      toeBoards,
      diagonalBraces: [...diagonalBraces, ...consoleDiagonalBraces],
      anchors,
      guardRails: [...guardRails, ...frameGuardRails],
      liftOffPreventers: [],
      couplers,
      screws
    };
    return {
      components,
      replacedComponents
    };
  }

  generatePassageBoxComponents(props: {
    boxProps: PassageBoxProps;
    allBoxes?: Box[];
    preStateCommitGraph: Graph;
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
  }) {
    const {
      boxProps,
      allBoxes,
      preStateCommitGraph,
      preStateStandards,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates,
      preStateLedgers
    } = props;
    const { height, depth, width, rotation, options, position, id } = boxProps;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position), //FR
          new Vector3().fromArray(options?.standardPositions[3].position), // FL
          new Vector3().fromArray(options?.standardPositions[1].position), //BL
          new Vector3().fromArray(options?.standardPositions[0].position) // BR
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    /** Top deck height */
    const topDeckHeight = plus(position[1], height);

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let standardsRailTop = getStandardsRailTop(guardRailsOptions);

    /** passage Base height  */
    const topBaseHeight = plus(position[1], options?.baseHeight ?? 0);

    const baseHeight = options?.baseHeight ?? 0;
    const baseWidth = options?.baseWidth ?? width;

    const directionVector = new Vector3(0, 0, 1)
      .applyEuler(new Euler().fromArray(rotation))
      .applyAxisAngle(new Vector3(0, 1, 0), halfPi); // perpendicular to the box to get direction of width

    const scalarVector = directionVector.multiplyScalar(width);

    const standardReductionHeight = getStandardReductionHeight(
      options?.initialComponentVariant
    );

    const topFRStandardPosition = standardPositions[1]
      .clone()
      .add(scalarVector);
    const topBRStandardPosition = standardPositions[2]
      .clone()
      .add(scalarVector);

    const topStandard = [
      { railIdx: 0, pos: topFRStandardPosition.clone() },
      { railIdx: 3, pos: topBRStandardPosition.clone() }
    ] as { railIdx: number; pos: Vector3 }[];
    const standardLengths = standardPositions.map((pos, index) => {
      const isOuterStandard = index === 0 || index === 3;
      const railTop = standardsRailTop[index] ? fallProtectionHeight : 0;

      const standardHeight = isOuterStandard
        ? topBaseHeight
        : topDeckHeight + railTop;

      return {
        pos: pos.clone().setY(plus(pos.y, standardReductionHeight)),
        standardLength: calcLengthPartitions(
          plus(minus(standardHeight, pos.y, standardReductionHeight), 0.1),
          this.getStandardLengths()
        ),
        initialComponentVariant: options?.initialComponentVariant
      };
    });

    const topOuterstandardLengths = topStandard.map((standard) => {
      const railTop = standardsRailTop[standard.railIdx]
        ? fallProtectionHeight
        : 0;

      return {
        pos: standard.pos.clone().setY(plus(position[1], baseHeight)),
        standardLength: calcLengthPartitions(
          plus(minus(topDeckHeight + railTop, position[1], baseHeight), 0.1),
          this.getStandardLengths()
        )
      };
    });

    const topBayComponents = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        options: {
          ...boxProps.options,
          standardPositions: [
            // Screw up order of standard positions again
            { position: topBRStandardPosition.toArray() },
            { position: standardPositions[2].toArray() },
            { position: topFRStandardPosition.toArray() },
            { position: standardPositions[1].toArray() }
          ]
        }
      },
      allBoxes,
      skipStandardColumnComponents: true,
      diagonalFrames: options?.frames?.filter(
        (frame) => frame.height > plus(topBaseHeight, 2)
      ),
      preStateCommitGraph,
      preStateStandards,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates,
      preStateLedgers
    });
    /** Passage components */
    const passageComponents = generatePassageComponents({
      baseStandardLengths: standardLengths,
      topOuterStandardPositions: [topStandard[0].pos, topStandard[1].pos],
      boxOptions: options,
      rayHitsYPosition,
      boxRotation: rotation,
      depth,
      baseWidth,
      boxPosition: position,
      availableStandardLengths: this.getStandardLengths(),
      preStateCommitGraph,
      preStateStandards,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates,
      preStateLedgers
    });
    const innerStandardLengths = [standardLengths[1], standardLengths[2]];

    const innerStandards = generateStandards({
      standardLengths: innerStandardLengths,
      boxRotation: rotation,
      boxId: id,
      allBoxes,
      availableStandardLengths: this.getStandardLengths(),
      preStateCommitGraph,
      preStateStandards
    });

    const outerStandards = generateStandards({
      standardLengths: topOuterstandardLengths,
      boxRotation: rotation,
      boxId: id,
      allBoxes,
      availableStandardLengths: this.getStandardLengths(),
      isTopOuterPassageStandard: true,
      preStateCommitGraph,
      preStateStandards
    });

    const components = {
      ...topBayComponents.components,
      guardRails: [
        ...passageComponents.guardRails,
        ...topBayComponents.components.guardRails
      ],
      baseCollars: passageComponents.baseCollars,
      beamSpigots: passageComponents.beamSpigots,
      basePlates: passageComponents.basePlates,
      baseBoards: passageComponents.baseBoards,
      ledgers: [
        ...topBayComponents.components.ledgers,
        ...passageComponents.ledgers
      ],
      standards: [
        ...passageComponents.standards,
        ...innerStandards.standards,
        ...outerStandards.standards
      ]
    };

    return {
      components,
      replacedComponents: [
        ...topBayComponents.replacedComponents,
        ...innerStandards.replacedStandards,
        ...outerStandards.replacedStandards,
        ...passageComponents.replacedComponents
      ]
    };
  }

  generateStairBoxComponents(props: {
    boxProps: StairBoxProps;
    allBoxes?: Box[];
    preStateStandards?: Standard[];
    preStateLedgers?: Ledger[];
    preStateBasePlates?: BasePlate[];
    preStateBaseBoards?: BaseBoard[];
    preStateBaseCollars?: BaseCollar[];
    preStateCommitGraph: Graph;
  }) {
    const {
      boxProps,
      allBoxes,
      preStateStandards,
      preStateCommitGraph,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates,
      preStateLedgers
    } = props;
    const { height, depth, width, rotation, options, position } = boxProps;

    const {
      components: boxComponents,
      replacedComponents: replacedBoxComponents
    } = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        stair: true
      },
      allBoxes,
      preStateStandards,
      preStateBaseBoards,
      preStateBaseCollars,
      preStateBasePlates,
      preStateLedgers,
      preStateCommitGraph
    });

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    const frames = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    /** Extract standardposition from box properties */
    const { FL, FR, BR, BL } = getBoxStandardPositions({
      position,
      rotation,
      depth,
      width,
      rayHitsYPosition
    });

    const standardPositions = [FL, FR, BR, BL] as [
      Vector3,
      Vector3,
      Vector3,
      Vector3
    ];

    /** Stairs */
    const { stairwayInnerGuardRails, stairways, stairwayGuardRails } =
      generateStairComponents({
        standardPositions,
        boxRotation: rotation,
        depth,
        frames,
        width,
        stairwayInnerGuardRail: options?.stairwayInnerGuardRail,
        stairwayGuardRail: options?.stairwayGuardRail
      });

    const components = {
      ...boxComponents,
      stairwayGuardRails,
      stairwayInnerGuardRails,
      stairways
    };

    return {
      components,
      replacedComponents: replacedBoxComponents
    };
  }
}

export default SystemScaffold;

import { useEffect, useState } from "react";
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  useMediaQuery
} from "@mui/material";
import ThemeManagerProps from "./ThemeManager.types";
import Theme from "./theme";
import { useUserAppPreferences } from "store/selectors/user";

const ThemeManager = (props: ThemeManagerProps) => {
  const { children } = props;
  const userPreferences = useUserAppPreferences();
  const colorPreference = userPreferences?.appearance || "system";

  const [mode, setMode] = useState<PaletteMode>("light");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    if (colorPreference === "system")
      setMode(prefersDarkMode ? "dark" : "light");
    else if (colorPreference === "dark" || colorPreference === "light")
      setMode(colorPreference);
  }, [colorPreference, prefersDarkMode]);

  return (
    <ThemeProvider theme={Theme({ mode })}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeManager;

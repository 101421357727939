import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { RoofSlice } from "./roof.interface";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialRoofState = {
  roofs: []
};

/** Slice creation */
const createRoofSlice: StateCreator<Store, [], [], RoofSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialRoofState));

  /** Return state */
  return {
    ...initialRoofState,
    roofActions: {
      add: (roof) => {
        set((state) => ({
          roofs: [...state.roofs, roof]
        }));
      },
      remove: (ids) => {
        set((state) => ({
          roofs: state.roofs.filter(
            (roof) => !ids.includes(roof.roofId)
          )
        }));
      },
      update: (ids, data) => {
        set((state) => ({
          roofs: state.roofs.map((roof) =>
            ids.includes(roof.roofId)
              ? {
                  ...roof,
                  ...data
                }
              : roof
          )
        }));
      }
    }
  };
};

export default createRoofSlice;

import { AuthenticationServiceProvider } from "./authentication.types";
import FirebaseAuthentication from "../firebase/authentication";
import { FIREBASE_PROJECTID } from "services/firebase/enums";
import getConfig from "../firebase/config";

export const initAuthenticationService = (
  serviceProvider: AuthenticationServiceProvider
) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (serviceProvider === AuthenticationServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseAuthentication({
      config: firebaseConfig,
      isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseAuthentication({
    config: firebaseConfig,
    isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL
  });
};

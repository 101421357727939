import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Console } from "./Console.types";
import { getEndPointFromStartPoint } from "math/vectors";

export const getGraphData = (console: Console): GraphData => {
  const { length, position, rotation, endPosition: inputEndPosition } = console;

  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: console.id,
    type: ComponentType.CONSOLE,
    metadata: {
      elementVector: new Vector3(0, 0, 1)
        .applyEuler(new Euler().fromArray(rotation))
        .toArray()
    }
  };
};

export const getGraphSplitData = (console: Console): GraphData[] => {
  const { length, position, rotation, splits } = console;

  if (!splits) return [];

  const newGraphData: GraphData[] = [];
  const mergedLengths = [...splits, length].sort((a, b) => a - b);
  const vectorPosition = new Vector3(...position);

  let startPosition = vectorPosition.clone();
  for (let i = 0; i < mergedLengths.length; i++) {
    const length = mergedLengths[i];

    const endPosition = getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    });

    newGraphData.push({
      startPosition: startPosition.toArray(),
      endPosition: endPosition.toArray(),
      id: console.id,
      type: ComponentType.CONSOLE,
      metadata: {
        elementVector: new Vector3(0, 0, 1)
          .applyEuler(new Euler().fromArray(rotation))
          .toArray()
      }
    });

    startPosition = endPosition;
  }

  return newGraphData;
};

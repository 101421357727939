import { StateCreator } from "zustand";
import { BlueprintSlice, BlueprintWithIndexSignature } from "./blueprint.types";
import { Store } from "store/store.types";
import useStoreWithUndo, { sliceResetFns } from "store/store";
import { isVector3Tuple } from "validation/three";
import { Vector3Tuple } from "three";
import { updateSelectedObjectsPositionRotation } from "../world.utils";

/** Initial state */
const initialBlueprintState = {
  activeBlueprints: [],
  blueprints: [],
  blueprintPlaceholders: []
};

const createBlueprintSlice: StateCreator<Store, [], [], BlueprintSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBlueprintState));

  /** Return state */
  return {
    ...initialBlueprintState,
    blueprintActions: {
      add: (blueprints) =>
        set((state) => ({
          blueprints: [...state.blueprints, ...blueprints]
        })),
      set: (blueprints) => set({ blueprints }),
      update: (ids, data) => {
        set((state) => {
          return {
            blueprints: state.blueprints.map((blueprint) => {
              if (!ids.includes(blueprint.id)) return blueprint;

              const cleanData = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  if (isVector3Tuple(value)) {
                    const blueprintKeyValue = blueprint[key] as Vector3Tuple;

                    acc[key] = value.map((v, idx) =>
                      isNaN(v) ? blueprintKeyValue[idx] : v
                    ) as Vector3Tuple;
                  } else {
                    acc[key] = value;
                  }

                  return acc;
                },
                {} as Partial<BlueprintWithIndexSignature>
              );

              return {
                ...blueprint,
                ...cleanData
              };
            }),
            worldSelectedObjects: updateSelectedObjectsPositionRotation(
              state.worldSelectedObjects,
              ids,
              data
            )
          };
        });
      },
      setPlaceholders: (blueprintPlaceholders) =>
        set({ blueprintPlaceholders }),
      deletePlaceholders: (ids) =>
        set((state) => ({
          blueprintPlaceholders: state.blueprintPlaceholders.filter(
            (b) => !ids.includes(b.id)
          )
        })),
      remove: (blueprint) =>
        set((state) => ({
          blueprints: state.blueprints.filter((b) => b.id !== blueprint.id)
        })),
      deleteAll: () => set({ blueprints: [] }),
      deleteActive: () => {
        set((state) => ({
          blueprints: state.blueprints.filter(
            (b) => !state.activeBlueprints.includes(b.id)
          ),
          activeBlueprints: []
        }));
      },
      setActive: (ids) => set({ activeBlueprints: ids }),
      updateOpacity: (ids, opacity) =>
        set((state) => ({
          blueprints: state.blueprints.map((b) =>
            ids.includes(b.id) ? { ...b, opacity } : b
          )
        }))
    }
  };
};

export const applyBlueprintDimensions = (
  dimensions: {
    id: string;
    position: Vector3Tuple;
    rotation: Vector3Tuple;
  }[]
) => {
  const state = useStoreWithUndo.getState();

  const ids = dimensions.map((p) => p.id);

  return state.blueprints.map((blueprint) => {
    if (!ids.includes(blueprint.id)) return blueprint;

    const dimensionObject = dimensions.find((p) => p.id === blueprint.id);

    return {
      ...blueprint,
      position: dimensionObject?.position ?? blueprint.position,
      rotation: dimensionObject?.rotation ?? blueprint.rotation
    };
  });
};

export default createBlueprintSlice;

import {
  Analytics,
  getAnalytics,
  logEvent as fLogEvent
} from "firebase/analytics";
import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp
} from "firebase/app";
import { AnalyticsService } from "services/analytics/analytics.interface";

class FirebaseAnalytics implements AnalyticsService {
  private app: FirebaseApp;
  private analytics: Analytics;

  constructor(props: { config: FirebaseOptions & { functionsLink: string } }) {
    const { config } = props;
    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.analytics = getAnalytics(this.app);
  }

  public logEvent = (props: {
    event: "feature_usage";
    data: {
      [key: string]: string | number;
    };
  }) => {
    const { event, data } = props;
    fLogEvent(this.analytics, event, {
      ...data
    });
  };
}

export default FirebaseAnalytics;

import { ReactElement } from "react";
import IconButtonProps from "./IconButton.types";
import { IconButton as MUIIconButton, Stack } from "@mui/material";
import Tooltip from "../Tooltip";

const IconButton = (props: IconButtonProps): ReactElement => {
  const {
    children,
    color = "default",
    disabled,
    id,
    onClick,
    size = "medium",
    sx,
    stackSx,
    tooltipPlacement = "bottom",
    tooltipText = ""
  } = props;
  return (
    <Tooltip text={tooltipText} placement={tooltipPlacement}>
      <Stack width={"min-content"} sx={{ ...stackSx }}>
        <MUIIconButton
          color={color}
          disabled={disabled}
          onClick={onClick}
          size={size}
          sx={{ ...sx }}
          id={id}
        >
          {children}
        </MUIIconButton>
      </Stack>
    </Tooltip>
  );
};

export default IconButton;

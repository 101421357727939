import {
  getGraphData,
  getGraphSplitData
} from "world/core/Ledger/Ledger.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Ledger } from "world/core/Ledger/Ledger.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding ledgers to the graph.
 * @param props
 */
export const addLedgersToGraph = (props: {
  graph: Graph;
  ledgers: Ledger[];
}) => {
  const { graph, ledgers } = props;

  const ledgersGraphData = ledgers
    .map((ledger) => {
      if (ledger.splits) return getGraphSplitData(ledger);
      return getGraphData(ledger);
    })
    .flat();
  addComponentsToGraph({ graph, components: ledgersGraphData });
};

/**
 * Mutates the graph by removing ledgers from the graph.
 * @param props
 */
export const removeLedgersFromGraph = (props: {
  graph: Graph;
  ledgers: Ledger[];
}) => {
  const { graph, ledgers } = props;

  const ledgersGraphData = ledgers
    .map((ledger) => {
      if (ledger.splits) return getGraphSplitData(ledger);
      return getGraphData(ledger);
    })
    .flat();

  removeComponentsFromGraph({
    graph,
    components: ledgersGraphData,
    force: true
  });
};

export const setLedgersInGraph = (props: {
  graph: Graph;
  newLedgers: Ledger[];
  oldLedgers: Ledger[];
}) => {
  const { graph, newLedgers, oldLedgers } = props;
  removeLedgersFromGraph({ graph, ledgers: oldLedgers });
  addLedgersToGraph({ graph, ledgers: newLedgers });
};

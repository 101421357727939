export enum AnalyticsServiceProvider {
  FIREBASE = "firebase"
}
export enum AppFeatures {
  AUTO_SCAFFOLD = "auto_scaffold",
  MAP_IMPORT = "maps_import",
  IFC_IMPORT = "ifc_import",
  PDF_IMPORT = "pdf_import",
  FOLDER_DUPLICATE = "folder_duplicate",
  FOLDER_DELETE = "folder_delete",
  PROJECT_DUPLICATE = "project_duplicate",
  PROJECT_DELETE = "project_delete",
  PROJECT_MOVE = "project_move",
  DISTANCE_TO_WALL_TOGGLE = "distance_to_wall_toggle",
  GIZMO_TOGGLE = "gizmo_toggle",
  CAMERA_SNAPSHOT = "camera_snapshot",
  CAMERA_ZOOM_IN = "camera_zoom_in",
  CAMERA_ZOOM_OUT = "camera_zoom_out",
  CAMERA_FIT_TO_SCREEN = "camera_fit_to_screen",
  CAMERA_MODE_TOGGLE = "camera_mode_toggle",
  OPERATIONS_HEIGHT = "operations_height",
  COMPONENTS_PDF_EXPORT = "components_pdf_export",
  COMPONENTS_CSV_IMPORT = "components_csv_export",
  COMPONENTS_EXCEL_IMPORT = "components_excel_export",
  DIM_PDF_EXPORT = "dim_pdf_export",
  PROJECT_SHARE = "project_share",
  APPEARANCE_SETTINGS = "appearance_settings",
  LANGUAGE_SETTINGS = "language_settings",
  SCAFFOLD_SETTINGS = "scaffold_settings",
  WORLD_MODE = "world_mode",
  COMPONENT_MODIFICATION = "component_modification",
  UNDO = "undo",
  REDO = "redo",
  GROUND_SLOPE = "ground_slope",
  GROUND_FLAT = "ground_flat",
  HOUSE_BODY_RECTANGLE = "house_body_rectangle",
  HOUSE_BODY_POLYGON = "house_body_polygon",
  HOUSE_BODY_CYLINDER = "house_body_cylinder",
  ROOF = "roof",
  POLYGON_POINT_MODIFICATION = "polygon_point_modification",
  BOX = "box",
  BOX_STAIR = "box_stair",
  BOX_PASSAGE = "box_passage",
  MEASUREMENT = "measurement",
  SAVE = "save"
}

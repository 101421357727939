import { getGraphData } from "world/core/Stairway/Stairway.utils";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { Stairway } from "world/core/Stairway/Stairway.types";
import Graph from "graphology";

/**
 * Mutates the graph by adding stairways to the graph.
 * @param props
 */
export const addStairwaysToGraph = (props: {
  graph: Graph;
  stairways: Stairway[];
}) => {
  const { graph, stairways } = props;

  const stairwaysGraphData = stairways.map((stairway) =>
    getGraphData(stairway)
  );
  addComponentsToGraph({ graph, components: stairwaysGraphData });
};

/**
 * Mutates the graph by removing stairways from the graph.
 * @param props
 */
export const removeStairwaysFromGraph = (props: {
  graph: Graph;
  stairways: Stairway[];
}) => {
  const { graph, stairways } = props;

  const stairwaysGraphData = stairways.map((stairway) =>
    getGraphData(stairway)
  );
  removeComponentsFromGraph({
    graph,
    components: stairwaysGraphData,
    force: true
  });
};

export const setStairwaysInGraph = (props: {
  graph: Graph;
  newStairways: Stairway[];
  oldStairways: Stairway[];
}) => {
  const { graph, newStairways, oldStairways } = props;
  removeStairwaysFromGraph({ graph, stairways: oldStairways });
  addStairwaysToGraph({ graph, stairways: newStairways });
};

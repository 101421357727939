import { ComponentType, GraphData } from "../Standard/Standard.types";
import { Euler, Vector3 } from "three";
import { BaseCollar } from "./BaseCollar.types";
import { plus } from "math";

export const getGraphData = (baseCollar: BaseCollar): GraphData => {
  const {
    length,
    position,
    rotation,
    endPosition: inputEndPosition
  } = baseCollar;

  const endPosition =
    inputEndPosition ??
    new Vector3(...position).setY(plus(position[1], length)).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: baseCollar.id,
    type: ComponentType.BASE_COLLAR,
    metadata: {
      elementVector: new Vector3(0, 0, 1)
        .applyEuler(new Euler().fromArray(rotation))
        .toArray()
    }
  };
};

import Graph from "graphology";
import {
  addComponentsToGraph,
  removeComponentsFromGraph
} from "../world.utils";
import { getGraphData } from "world/core/Plank/Plank.utils";
import { Plank } from "world/core/Plank/Plank.types";

/**
 * Mutates the graph by adding base plates to the graph.
 * @param props
 */
export const addPlanksToGraph = (props: { graph: Graph; planks: Plank[] }) => {
  const { graph, planks } = props;

  const planksGraphData = planks.map((plank) => getGraphData(plank));

  addComponentsToGraph({ graph, components: planksGraphData });
};

/**
 * Mutates the graph by removing base plates from the graph.
 * @param props
 */
export const removePlanksFromGraph = (props: {
  graph: Graph;
  planks: Plank[];
}) => {
  const { graph, planks } = props;

  const planksGraphData = planks.map((plank) => getGraphData(plank));

  removeComponentsFromGraph({
    graph,
    components: planksGraphData,
    force: true
  });
};

export const setPlanksInGraph = (props: {
  graph: Graph;
  newPlanks: Plank[];
  oldPlanks: Plank[];
}) => {
  const { graph, newPlanks, oldPlanks } = props;
  removePlanksFromGraph({ graph, planks: oldPlanks });
  addPlanksToGraph({ graph, planks: newPlanks });
};

import { FIREBASE_PROJECTID } from "services/firebase/enums";
import { AnalyticsServiceProvider, AppFeatures } from "./analytics.enums";
import { getConfig } from "services/firebase/config";
import FirebaseAnalytics from "services/firebase/analytics";
import useStoreWithUndo from "store/store";
import { analytics } from "./analytics";

export const initAnalyticsService = (
  analyticsProvider: AnalyticsServiceProvider
) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (analyticsProvider === AnalyticsServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAnalytics service as the analytics service */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseAnalytics({
      config: firebaseConfig
    });
  }

  /** Default return: Get firebase config and return the FirebaseAnalytics service as the analytics service */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseAnalytics({
    config: firebaseConfig
  });
};

export const logFeatureEvent = (featureName: AppFeatures) => {
  const { userUId, companyId } = useStoreWithUndo.getState();
  analytics.logFeatureUsage({ featureName, userId: userUId, companyId });
};

import { StateCreator } from "zustand";
import { SnackbarSlice } from "./snackbar.types";

const createSnackbarSlice: StateCreator<
  SnackbarSlice,
  [],
  [],
  SnackbarSlice
> = (set) => ({
  snackbarSnacks: [],
  snackbarActions: {
    add: (snack) => {
      set((state) => ({
        snackbarSnacks: [...state.snackbarSnacks, snack]
      }));
    },
    close: (key) => {
      set((state) => ({
        snackbarSnacks: state.snackbarSnacks.filter(
          (snack) => snack.key !== key
        )
      }));
    }
  }
});

export default createSnackbarSlice;

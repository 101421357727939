import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { BaseCollarSlice } from "./baseCollar.interface";
import {
  addBaseCollarsToGraph,
  removeBaseCollarsFromGraph,
  setBaseCollarsInGraph
} from "./baseCollar.utils";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialBaseCollarState = {
  baseCollars: []
};

/** Slice creation */
const createBaseCollarSlice: StateCreator<Store, [], [], BaseCollarSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBaseCollarState));

  /** Return state */
  return {
    ...initialBaseCollarState,
    addBaseCollars: (baseCollars) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addBaseCollarsToGraph({ graph: newGraph, baseCollars });
        return {
          graph: newGraph,
          baseCollars: [...state.baseCollars, ...baseCollars]
        };
      });
    },
    setBaseCollars: (newBaseCollars) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setBaseCollarsInGraph({
          graph: newGraph,
          newBaseCollars,
          oldBaseCollars: [...state.baseCollars]
        });

        return {
          graph: newGraph,
          baseCollars: newBaseCollars
        };
      });
    },
    removeBaseCollars: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeBaseCollarsFromGraph({
          graph: newGraph,
          baseCollars: [...state.baseCollars]
        });

        return {
          graph: newGraph,
          baseCollars: state.baseCollars.filter(
            (baseCollar) => !ids.includes(baseCollar.id)
          )
        };
      });
    }
  };
};

export default createBaseCollarSlice;

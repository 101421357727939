import { FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { SCAFFOLD_DIAGONAL_PATTERN } from "../enums/scaffold";
import { STAIR_TYPE } from "../enums/world";
import {
  BoxConsole,
  BoxFrame,
  StandardPosition
} from "../interfaces/firestore";

export type BoxOptions = {
  standardPositions?: [
    StandardPosition,
    StandardPosition,
    StandardPosition,
    StandardPosition
  ];
  grounded?: [
    { isGrounded: boolean; groundId?: string },
    { isGrounded: boolean; groundId?: string },
    { isGrounded: boolean; groundId?: string },
    { isGrounded: boolean; groundId?: string }
  ];
  stair?: STAIR_TYPE;
  consoleWidth?: number;
  stairwayinnerGuardRail?: boolean;
  stairwayGuardRail?: boolean;
  diagonals?: [boolean, boolean, boolean, boolean];
  diagonalPattern?: SCAFFOLD_DIAGONAL_PATTERN;
  guardRails?: [boolean, boolean, boolean, boolean];
  consoles?: [boolean, boolean, boolean, boolean];
  anchors?: [boolean, boolean, boolean, boolean];
  frames?: BoxFrame[];
  consoleLevels?: BoxConsole[];
  anchorLevels?: number[];
  fallProtectionHeight?: number;
  topVariant?: FRAME_VARIANT;
  initialComponentVariant?: INITIAL_COMPONENT_VARIANT;
  [key: string]: any;
};

export enum INITIAL_COMPONENT_VARIANT {
  BASE_COLLAR = "base_collar",
  INITIAL_STANDARD = "initial_standard"
}

import { LicenseInfo } from "@mui/x-license";
import RouteManager from "managers/RouteManager/RouteManager";
import ThemeManager from "managers/ThemeManager";
import ErrorPage from "pages/page/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

const App = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PREMIUM as string);

  return (
    <ThemeManager>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(error, errorInfo) => {
          // log the error
          console.log("Error caught!");
          console.error(error);
          console.error(errorInfo);
        }}
        onReset={() => {
          // reloading the page to restore the initial state
          // of the current page
          window.location.reload();
        }}
      >
        <RouteManager />
      </ErrorBoundary>
    </ThemeManager>
  );
};

export default App;

import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { CouplerSlice } from "./coupler.interface";
import { sliceResetFns } from "store/store";
import {
  addCouplersToGraph,
  removeCouplersFromGraph,
  setCouplersInGraph
} from "./coupler.utils";

/** Initial state */
const initialCouplerState = {
  couplers: []
};

/** Slice creation */
const createCouplerSlice: StateCreator<Store, [], [], CouplerSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialCouplerState));

  /** Return state */
  return {
    ...initialCouplerState,
    addCouplers: (couplers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addCouplersToGraph({ graph: newGraph, couplers });
        return {
          graph: newGraph,
          couplers: [...state.couplers, ...couplers]
        };
      });
    },
    setCouplers: (couplers) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setCouplersInGraph({
          graph: newGraph,
          newCouplers: couplers,
          oldCouplers: [...state.couplers]
        });

        return {
          graph: newGraph,
          couplers
        };
      });
    },
    removeCouplers: (ids) => {
      set((state) => {
        const newGraph = state.graph.copy();
        removeCouplersFromGraph({
          graph: newGraph,
          couplers: [...state.couplers]
        });

        return {
          graph: newGraph,
          couplers: state.couplers.filter(
            (coupler) => !ids.includes(coupler.id)
          )
        };
      });
    }
  };
};

export default createCouplerSlice;

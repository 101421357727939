import { ReactElement } from "react";
import { Stack } from "@mui/material";

import InfoOverlayProps from "./InfoOverlay.types";
import { Outlet } from "react-router-dom";
import TrialInfo from "pages/sharedComponents/TrialInfo";
import { useIsAppLayout, useRightSidebarWidth } from "store/selectors/ui";
import VersionInfo from "../VersionInfo";
import Breakpoints from "managers/ThemeManager/theme/breakpoints";
import SnackbarInfo from "../SnackbarInfo";

const InfoOverlay = (props: InfoOverlayProps): ReactElement => {
  const isAppLayout = useIsAppLayout();
  const rightSidebarWidth = useRightSidebarWidth();

  const { belowSmallScreen } = Breakpoints();

  return (
    <>
      {belowSmallScreen ? (
        <>
          <Stack flexGrow={1}>
            <Stack
              position={"absolute"}
              padding={2}
              bottom={0}
              right={0}
              zIndex={1400}
              gap={1}
              alignItems={"flex-end"}
              sx={{
                overflow: "hidden"
              }}
            >
              <SnackbarInfo />
            </Stack>
            <Outlet />
            {!isAppLayout && (
              <Stack pt={1}>
                <TrialInfo />
                <VersionInfo />
              </Stack>
            )}
          </Stack>
        </>
      ) : (
        <>
          <Stack
            position={"absolute"}
            padding={2}
            bottom={0}
            right={!belowSmallScreen && isAppLayout ? rightSidebarWidth : 0}
            zIndex={1400}
            gap={1}
            alignItems={"flex-end"}
            sx={{
              overflow: "hidden"
            }}
          >
            <SnackbarInfo />
            <TrialInfo />
            <VersionInfo />
          </Stack>
          <Outlet />
        </>
      )}
    </>
  );
};

export default InfoOverlay;

import { StateCreator } from "zustand";
import { LightSlice } from "./light.types";
import { Store } from "store/store.types";
import { sliceResetFns } from "store/store";

/** Initial state */
const initialLightState = {
  lightsAmbient: [],
  lightsDirectional: [],
  lightsHemisphere: [],
  lightsPoint: [],
  lightsSpot: []
};

/** Slice creation */
const createLightSlice: StateCreator<Store, [], [], LightSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialLightState));

  /** Return state */
  return {
    ...initialLightState,
    lightActions: {
      addAmbient: (lights) => {
        set((state) => ({
          lightsAmbient: [...state.lightsAmbient, ...lights]
        }));
      },
      addDirectional: (lights) => {
        set((state) => ({
          lightsDirectional: [...state.lightsDirectional, ...lights]
        }));
      },
      addHemisphere: (lights) => {
        set((state) => ({
          lightsHemisphere: [...state.lightsHemisphere, ...lights]
        }));
      },
      addPoint: (lights) => {
        set((state) => ({
          lightsPoint: [...state.lightsPoint, ...lights]
        }));
      },
      addSpot: (lights) => {
        set((state) => ({
          lightsSpot: [...state.lightsSpot, ...lights]
        }));
      },
      setAmbient: (lightsAmbient) => {
        set(() => ({ lightsAmbient }));
      },
      setDirectional: (lightsDirectional) => {
        set(() => ({ lightsDirectional }));
      },
      setHemisphere: (lightsHemisphere) => {
        set(() => ({ lightsHemisphere }));
      },
      setPoint: (lightsPoint) => {
        set(() => ({ lightsPoint }));
      },
      setSpot: (lightsSpot) => {
        set(() => ({ lightsSpot }));
      },
      updateAmbient: (ids, data) => {
        set((state) => {
          return {
            lightsAmbient: state.lightsAmbient.map((light) => {
              if (!ids.includes(light.id)) return light;

              return {
                ...light,
                ...data
              };
            })
          };
        });
      },
      updateDirectional: (ids, data) => {
        set((state) => {
          return {
            lightsDirectional: state.lightsDirectional.map((light) => {
              if (!ids.includes(light.id)) return light;

              return {
                ...light,
                ...data
              };
            })
          };
        });
      },
      updateHemisphere: (ids, data) => {
        set((state) => {
          return {
            lightsHemisphere: state.lightsHemisphere.map((light) => {
              if (!ids.includes(light.id)) return light;

              return {
                ...light,
                ...data
              };
            })
          };
        });
      },
      updatePoint: (ids, data) => {
        set((state) => {
          return {
            lightsPoint: state.lightsPoint.map((light) => {
              if (!ids.includes(light.id)) return light;

              return {
                ...light,
                ...data
              };
            })
          };
        });
      },
      updateSpot: (ids, data) => {
        set((state) => {
          return {
            lightsSpot: state.lightsSpot.map((light) => {
              if (!ids.includes(light.id)) return light;

              return {
                ...light,
                ...data
              };
            })
          };
        });
      },
      removeAmbient: (ids) => {
        set((state) => ({
          lightsAmbient: state.lightsAmbient.filter((l) => !ids.includes(l.id))
        }));
      },
      removeDirectional: (ids) => {
        set((state) => ({
          lightsDirectional: state.lightsDirectional.filter(
            (l) => !ids.includes(l.id)
          )
        }));
      },
      removeHemisphere: (ids) => {
        set((state) => ({
          lightsHemisphere: state.lightsHemisphere.filter(
            (l) => !ids.includes(l.id)
          )
        }));
      },
      removePoint: (ids) => {
        set((state) => ({
          lightsPoint: state.lightsPoint.filter((l) => !ids.includes(l.id))
        }));
      },
      removeSpot: (ids) => {
        set((state) => ({
          lightsSpot: state.lightsSpot.filter((l) => !ids.includes(l.id))
        }));
      }
    }
  };
};
export default createLightSlice;

import { ReactElement, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material";
import {
  useSnackbarActions,
  useSnackbarSnacks
} from "store/selectors/snackbar";
import {
  SnackbarProvider,
  enqueueSnackbar,
  MaterialDesignContent,
  SnackbarKey,
  closeSnackbar
} from "notistack";
import { Close } from "@mui/icons-material";
import IconButton from "ui/core/IconButton";
import SnackbarInfoProps from "./SnackbarInfo.types";

import "./deps/style.css";

const StyledMuiContent = styled(MaterialDesignContent)(() => {
  const palette = useTheme().palette;

  return {
    "&.notistack-MuiContent-success": {
      backgroundColor: palette.success.main,
      color: palette.success.contrastText
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: palette.error.main,
      color: palette.error.contrastText
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: palette.warning.main,
      color: palette.warning.contrastText
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: palette.info.main,
      color: palette.info.contrastText
    }
  };
});

const SnackbarInfo = (props: SnackbarInfoProps): ReactElement => {
  const snackbarSnacks = useSnackbarSnacks();
  const snackbarActions = useSnackbarActions();

  const activeSnacks = useRef<SnackbarKey[]>([]);

  useEffect(() => {
    /** Start new snackbars if not in activeSnacks */
    snackbarSnacks.forEach((snack) => {
      if (!activeSnacks.current.includes(snack.key)) {
        activeSnacks.current = [...activeSnacks.current, snack.key];

        enqueueSnackbar(snack.text, {
          key: snack.key,
          variant: snack.type
        });
      }
    });

    /** Close active snacks if not in snackbarsSnacks */
    activeSnacks.current.forEach((key) => {
      if (!snackbarSnacks.map((s) => s.key).includes(key)) {
        activeSnacks.current = activeSnacks.current.filter((k) => k !== key);

        closeSnackbar(key);
      }
    });
  }, [snackbarSnacks]);

  return (
    <SnackbarProvider
      autoHideDuration={6000}
      hideIconVariant
      Components={{
        success: StyledMuiContent,
        error: StyledMuiContent,
        warning: StyledMuiContent,
        info: StyledMuiContent
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={(_, __, key) => {
        if (!key) {
          console.error("Snackbar key is missing");
          return;
        }

        snackbarActions.close(key);
      }}
      action={(key) => (
        <IconButton
          color="inherit"
          size="small"
          onClick={() => snackbarActions.close(key)}
        >
          <Close />
        </IconButton>
      )}
    />
  );
};

export default SnackbarInfo;

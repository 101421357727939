import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { DiagonalBraceSlice } from "./diagonalBrace.interface";
import {
  addDiagonalBracesToGraph,
  removeDiagonalBracesFromGraph,
  setDiagonalBracesInGraph
} from "./diagonalBrace.utils";

/** Initial state */
const initialDiagonalBraceState = {
  diagonalBraces: []
};

/** Slice creation */
const createDiagonalBraceSlice: StateCreator<
  Store,
  [],
  [],
  DiagonalBraceSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialDiagonalBraceState));

  /** Return state */
  return {
    ...initialDiagonalBraceState,
    addDiagonalBraces: (diagonalBraces) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addDiagonalBracesToGraph({ graph: newGraph, diagonalBraces });

        return {
          diagonalBraces: [...state.diagonalBraces, ...diagonalBraces],
          graph: newGraph
        };
      });
    },
    setDiagonalBraces: (diagonalBraces) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setDiagonalBracesInGraph({
          graph: newGraph,
          newDiagonalBraces: diagonalBraces,
          oldDiagonalBraces: [...state.diagonalBraces]
        });

        return {
          diagonalBraces,
          graph: newGraph
        };
      });
    },
    removeDiagonalBraces: (ids) => {
      set((state) => {
        const removeDiagonalBraces = [...state.diagonalBraces];
        const newGraph = state.graph.copy();
        removeDiagonalBracesFromGraph({
          graph: newGraph,
          diagonalBraces: removeDiagonalBraces
        });
        return {
          diagonalBraces: state.diagonalBraces.filter(
            (diagonalBrace) => !ids.includes(diagonalBrace.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createDiagonalBraceSlice;

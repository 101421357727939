import { sliceResetFns } from "store/store";
import { Store } from "store/store.types";
import { StateCreator } from "zustand";
import { BeamSpigotSlice } from "./beamSpigot.interface";
import {
  addBeamSpigotsToGraph,
  removeBeamSpigotsFromGraph,
  setBeamSpigotsInGraph
} from "./beamSpigot.utils";

/** Initial state */
const initialBeamSpigotState = {
  beamSpigots: []
};

/** Slice creation */
const createBeamSpigotSlice: StateCreator<Store, [], [], BeamSpigotSlice> = (
  set
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialBeamSpigotState));

  /** Return state */
  return {
    ...initialBeamSpigotState,
    addBeamSpigots: (beamSpigots) => {
      set((state) => {
        const newGraph = state.graph.copy();
        addBeamSpigotsToGraph({ graph: newGraph, beamSpigots });

        return {
          beamSpigots: [...state.beamSpigots, ...beamSpigots],
          graph: newGraph
        };
      });
    },
    setBeamSpigots: (beamSpigots) => {
      set((state) => {
        const newGraph = state.graph.copy();
        setBeamSpigotsInGraph({
          graph: newGraph,
          newBeamSpigots: beamSpigots,
          oldBeamSpigots: [...state.beamSpigots]
        });

        return {
          beamSpigots,
          graph: newGraph
        };
      });
    },
    removeBeamSpigots: (ids) => {
      set((state) => {
        const removeBeamSpigots = [...state.beamSpigots];
        const newGraph = state.graph.copy();
        removeBeamSpigotsFromGraph({
          graph: newGraph,
          beamSpigots: removeBeamSpigots
        });
        return {
          beamSpigots: state.beamSpigots.filter(
            (beamSpigot) => !ids.includes(beamSpigot.id)
          ),
          graph: newGraph
        };
      });
    }
  };
};

export default createBeamSpigotSlice;

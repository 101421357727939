import useStoreWithUndo from "store/store";

export const useCompanyActions = () =>
  useStoreWithUndo((state) => state.companyActions);
export const useCompanyId = () => useStoreWithUndo((state) => state.companyId);
export const useCompanyName = () =>
  useStoreWithUndo((state) => state.companyName);
export const useCompanyUsers = () =>
  useStoreWithUndo((state) => state.companyUsers);
export const useCompanyQuotaLimit = () =>
  useStoreWithUndo((state) => state.companyQuotaLimit);
export const useCompanyQuotaRenewalDays = () =>
  useStoreWithUndo((state) => state.companyQuotaRenewalDays);
export const useCompanyQuotaStartDate = () =>
  useStoreWithUndo((state) => state.companyQuotaStartDate);
export const useCompanyQuotaEndDate = () =>
  useStoreWithUndo((state) => state.companyQuotaEndDate);
export const useCompanyQuotaUsage = () =>
  useStoreWithUndo((state) => state.companyQuotaUsage);

import { Euler, Vector3 } from "three";
import { ComponentType, GraphData } from "../Standard/Standard.types";
import { getEndPointFromStartPoint } from "math/vectors";
import { Screw } from "./Screw.types";
const START_VECTOR = new Vector3();
const ELEMENT_VECTOR = new Vector3(0, 0, 1);
const EULER = new Euler();
export const getGraphData = (liftOffPreventer: Screw): GraphData => {
  const {
    length,
    position,
    rotation,
    endPosition: inputEndPosition
  } = liftOffPreventer;
  START_VECTOR.set(...position);
  EULER.set(...rotation);
  ELEMENT_VECTOR.set(0, 0, 1);

  const endPosition =
    inputEndPosition ??
    getEndPointFromStartPoint({
      startPosition: position,
      length,
      rotation
    }).toArray();

  return {
    startPosition: position,
    endPosition: endPosition,
    id: liftOffPreventer.id,
    type: ComponentType.LEDGER,
    metadata: {
      elementVector: ELEMENT_VECTOR.applyEuler(EULER).toArray()
    }
  };
};
